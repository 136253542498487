<template>
  <div class="user-home-activity">
    <div class="user-home-activity-title">
      <p>
        <span
          :class="{ active: activityTagIndex == 1 }"
          @click="activityTagIndex = 1"
          >我的活动</span
        >
        <span
          :class="{ active: activityTagIndex == 2 }"
          @click="activityTagIndex = 2"
          >我的课程</span
        >
      </p>
      <span @click="activityAll">更多</span>
    </div>
    <template v-if="list != ''">
      <div class="user-home-activity-list" v-if="activityTagIndex == 1">
        <div v-for="el in list" :key="el.activityId" @click="activeInfo(el.activityId,el.actActivity)">
          <v-img :attachment-id="el.coverUrl" :default-img="require('@/assets/img/kctp.png')" />
          <div>
            <h6>{{el.conferenceName}}</h6>
            <p v-if="el.actActivity">{{el.actActivity.summary}}</p>
            <span>活动时间：{{el.applyTime}}</span>
          </div>
        </div>
      </div>
      <div class="user-home-activity-list" v-if="activityTagIndex == 2">
        <div v-for="el in list" :key="el.courseId" @click="dataInfo(el.id,el.domain)">
          <v-img :attachment-id="el.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')" />
          <div>
            <h6>{{ el.courseName }}</h6>
            <p>{{ el.summary }}</p>
            <span>主讲人：{{ el.coursePresentecoursePresenter }}</span>
          </div>
        </div>
      </div>
    </template>
    <a-empty v-else/>
  </div>
</template>

<script>
import { getActivity,getCourse } from "@/api/activity";
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
import {getTenantInfo} from "@/api/common";
export default {
    components:{
        VImg
    },
  data() {
    return {
      activityTagIndex: 1,
      page: {
        current: 1,
        size: 2,
      },
      list:[]
    };
  },
  watch:{
    activityTagIndex:function(){
        this.loadGetData()
    }
  },
  mounted(){
    this.loadGetData()
  },
  computed: mapGetters(["userInfo"]),
  methods:{
    loadGetData(){
        let {activityTagIndex,page} = this
        if(activityTagIndex == 1){
            getActivity(page,{phone: this.userInfo.phone,keyword:''}).then((res)=>{
                this.list = res.records
            })
        }else{
            getCourse(page, {keyword:''}).then((res) => {
                this.list = res.records;
            });
        }
    },
    activityAll(){
        let {activityTagIndex} = this
        if(activityTagIndex == 1){
            this.$router.push('/interactive/userActive')
        }else{
            this.$router.push('/interactive/userList')
        }
    },

    activeInfo(id,com){
      if(id && com){
        let params={id:com.tenantIds[0]}
        getTenantInfo(params).then(res=>{
          window.open(`${res.domain}/activity/activityInfo?id=${id}`,'_blank')
        })
      }else if(id){
        window.open(`${process.env.VUE_APP_MAINSITE_PREFIX}/activity/activityInfo?id=${id}`,'_blank')
      }
    },
    dataInfo(id,url){
      if(id && url){
        window.open(`${url}/activity/courseInfo?id=${id}`,'_blank')
      }else if(id){
        window.open(`${process.env.VUE_APP_MAINSITE_PREFIX}/activity/courseInfo?id=${id}`,'_blank')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-home-activity {
  margin-top: 24px;
  .user-home-activity-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    > p {
      display: flex;
      > span {
        font-size: 16px;
        color: #595959;
        line-height: 22px;
        margin-right: 24px;
        cursor: pointer;
        &.active {
          color: #ea0b06;
        }
      }
    }
    > span {
      font-size: 14px;
      color: #ea0b06;
      cursor: pointer;
    }
  }
  .user-home-activity-list {
    > div {
      margin-bottom: 16px;
      display: flex;
      cursor: pointer;
      > img {
        width: 204px;
        height: 115px;
        margin-right: 24px;
      }
      > div {
        flex: 1;
        position: relative;
        > h6 {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          margin-bottom: 8px;
        }
        > p {
          font-size: 12px;
          color: #595959;
          line-height: 20px;
          max-height: 60px;
          overflow: hidden;
        }
        > span {
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 20px;
        }
      }
    }
  }
}
</style>
