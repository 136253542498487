<template>
  <a-menu
    v-if="pageType == 'info'"
    mode="inline"
    :default-selected-keys="['用户首页']"
    :default-open-keys="['sub1']"
    :style="{ height: '100%', borderRight: 0 }"
  >
    <template v-for="e in menus">
      <a-sub-menu v-if="e.children != ''" :key="e.title">
        <span slot="title"><icon-font :type="e.icon" />{{ e.title }}</span>
        <a-menu-item
          v-for="el in e.children"
          :key="el.title"
          class="layput-menu-list"
        >
          <router-link :to="el.url">{{ el.title }}</router-link>
        </a-menu-item>
      </a-sub-menu>
      <template v-else>
        <a-menu-item :key="e.title" class="layput-menu-list">
          <router-link tag="p" :to="e.url" :key="e.title">
            <icon-font :type="e.icon" />
            {{ e.title }}
          </router-link>
        </a-menu-item>
      </template>
    </template>
  </a-menu>
  <a-menu
    v-else-if="pageType == 'settings'"
    mode="inline"
    :default-selected-keys="['基本资料']"
    :default-open-keys="['sub1']"
    :style="{ height: '100%', borderRight: 0 }"
  >
    <template v-for="e in settingsList">
      <a-menu-item :key="e.title" class="layput-menu-list">
        <router-link tag="p" :to="e.url" :key="e.title">
          <a-icon :type="e.icon" />
          {{ e.title }}
        </router-link>
      </a-menu-item>
    </template>
  </a-menu>
  <a-menu
    v-else-if="pageType == 'message'"
    mode="inline"
    :default-selected-keys="['基本资料']"
    :default-open-keys="['sub1']"
    :style="{ height: '100%', borderRight: 0 }"
  >
    <template v-for="e in messageList">
      <a-menu-item :key="e.title" class="layput-menu-list menu-item">
        <router-link tag="p" :to="e.url" :key="e.title">
          <a-icon :type="e.icon" />
          {{ e.title }}
        </router-link>
        <div class="num-show" v-if="e.num">{{ e.num }}</div>
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
import iconFont from "@/components/iconFont";
import { mapGetters } from "vuex";

export default {
  components: {
    iconFont,
  },
  computed: {
    ...mapGetters(["permissions", "msg", "serverMsg"]),
    menus() {
      const { permissions, menuList } = this;
      const permissionCodes = permissions.map((e) => e.permissionCode);
      const menus = menuList
        .filter(
          (menu) =>
            !menu.permissions ||
            !menu.permissions.length ||
            permissionCodes.indexOf(menu.permissions[0]) !== -1
        )
        .map((menu) => {
          return {
            ...menu,
            children: menu.children.filter(
              (e) =>
                !e.permissions ||
                !e.permissions.length ||
                permissionCodes.indexOf(e.permissions[0]) !== -1
            ),
          };
        });
      console.log(menus);
      return menus;
    },
  },
  data() {
    return {
      pageType: "info",
      menuList: [
        {
          title: "首页概况",
          url: "/",
          icon: "icon-shouye",
          children: [],
          permissions: ["home"],
        },
        // {
        //   title: "场景管理",
        //   url: "",
        //   icon: "icon-linianyoushi_",
        //   // permissions: ["scene"],
        //   children: [
        //     {
        //       title: "我的场景",
        //       url: "/userLayout/myScenes",
        //       // permissions: ["scene_list"],
        //     },
        //     {
        //       title: "我的榜单",
        //       url: "/userLayout/myBangDan",
        //       // permissions: ["acv_list"],
        //     },
        //   ],
        // },
        {
          title: "迁移管理",
          url: "/userLayout/userScenes",
          icon: "icon-changjing",
          children: [],
          permissions: ["transfer_list"],
        },
        {
          title: "需求管理",
          url: "/demandManage",
          icon: "icon-shilileixing",
          children: [],
          permissions: ["demand_list"],
        },
        {
          title: "服务管理",
          url: "/serveManage",
          icon: "icon-shilileixing",
          children: [],
          permissions: ["service_list"],
        },
        /*   {
          title: "开发管理",
          url: "/devManage",
          icon: "icon-shujukaifa",
          children: [],
          permissions: ["develop_list"],
        },*/
        {
          title: "企业管理",
          url: "",
          icon: "icon-qiyeguanli",
          permissions: ["company"],
          children: [
            {
              title: "企业资料",
              url: "/enterpriseManage/info",
              permissions: ["company_detail"],
            },
            {
              title: "企业认证",
              url: "/enterpriseManage/verify",
              permissions: ["company_certificate"],
            },
            {
              title: "成员部门",
              url: "/enterpriseManage/member",
              permissions: ["company_member"],
            },
            {
              title: "角色权限",
              url: "/enterpriseManage/role",
              permissions: ["company_urole_permission"],
            },
          ],
        },
        {
          title: "互动中心",
          url: "/userLayout/userInteractive",
          icon: "icon-qizhi",
          permissions: ["personal"],
          children: [
            {
              title: "我的关注",
              url: "/interactive/userCollect",
              permissions: ["personal_focus"],
            },
            {
              title: "我的活动",
              url: "/interactive/userActive",
              permissions: ["personal_activity"],
            },
            {
              title: "我的咨询",
              url: "/interactive/userConsult",
              permissions: ["personal_consult"],
            },
            {
              title: "我的课程",
              url: "/interactive/userList",
              permissions: ["personal_course"],
            },
          ],
        },
      ],
      settingsList: [
        {
          title: "基本资料",
          url: "/userLayout/userInfo",
          icon: "user",
        },
        {
          title: "个人实名",
          url: "/userLayout/userCer",
          icon: "schedule",
        },
        {
          title: "安全设置",
          url: "/userLayout/userSettings",
          icon: "setting",
        },
      ],
      messageList: [
        {
          title: "服务消息",
          url: "/userLayout/userMessage?id=2",
          icon: "user",
        },
        {
          title: "系统消息",
          url: "/userLayout/userMessage?id=1",
          icon: "schedule",
        },
        {
          title: "互动消息",
          url: "/userLayout/userMessage?id=3",
          icon: "setting",
        },
      ],
    };
  },
  created() {
    this.$set(this.messageList[0], "num", this.msg.serverMsg);
    this.$set(this.messageList[1], "num", this.msg.systemMsg);
    this.$set(this.messageList[2], "num", this.msg.interactMsg);
  },
  mounted() {
    this.pageType = this.$route.meta.type;
    this.$router.afterEach((to, from) => {
      this.pageType = to.meta.type;
      this.$emit("input", to.meta.type);
    });
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  position: relative;
  > .num-show {
    position: absolute;
    top: 0;
    left: 103px;
    height: 14px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    z-index: 10;
    padding: 0 3px;
    background: #ea0b06;
    color: #ffffff !important;
    border-radius: 7px;
  }
}
.layout-nav {
  height: 100%;
  width: 240px;
  background: #f8f8f8;
  padding-top: 24px;

  > a {
    display: flex;
    height: 40px;
    padding-left: 44px;
    align-items: center;
    color: #262626;
    border-left: 4px solid transparent;
    transition: all 0.3s;

    > i {
      transition: all 0.3s;
      color: #8c8c8c;
      margin-right: 8px;
      font-size: 16px;
    }

    &:hover {
      background: #f2f2f2;
      border-color: #ea0b06;
      color: #ea0b06;

      > i {
        color: #ea0b06;
      }
    }

    &.active {
      background: #f2f2f2 !important;
      border-color: #ea0b06 !important;
      color: #ea0b06 !important;

      > i {
        color: #ea0b06 !important;
      }
    }
  }
}
</style>
<style>
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #fff2f2;
}

.ant-menu-inline .ant-menu-item::after {
  border-color: #ea0b06;
}

.ant-menu-item-selected,
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu-item > a:hover {
  color: #ea0b06;
}
</style>
