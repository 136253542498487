<template>
  <div class="user-cer-result">


    <div class="cer-result-body" :style="`height:${boxHeight}px`">
      <!--    <div class="cer-result-body">-->

      <p>个人实名</p>

      <div class="cer-result-box">
        <img
          v-if="userInfo.verifyStatus == 2"
          src="@/assets/img/cer2.png"
          alt=""
        />
        <img
          v-else-if="userInfo.verifyStatus == 3"
          src="@/assets/img/cer1.png"
          alt=""
        />
        <img
          v-else-if="userInfo.verifyStatus == 4"
          src="@/assets/img/cer3.png"
          alt=""
        />

        <div>实名{{ userInfo.verifyStatus | dict("VERIFY_TYPE") }}</div>
        <p v-if="userInfo.verifyStatus == 4">驳回原因：{{ userInfo.verifyFailedReason }}</p>
        <a-button class="btn-red" @click="editVerify"> 修改个人实名 </a-button>

        <div class="cer-result-info">
          <p>您的实名信息</p>

          <div>
            <div class="item">
              <div>
                <span>证件类型：</span>
                <span>中华人民共和国居民身份证</span>
              </div>
              <div>
                <span>姓名：</span>
                <span>{{ userInfo.realName }}</span>
              </div>
            </div>

            <div class="item">
              <div>
                <span>提交时间：</span>
                <span>{{ userInfo.applyTime }}</span>
              </div>
              <div>
                <span>证件号码：</span>
                <span>{{ userInfo.citizenIdNo }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "userCerResult",
  data() {
    return {
      boxHeight: "",
    };
  },
  created() {
    this.boxHeight = document.body.clientHeight - 141;
  },
  computed: mapGetters(["userInfo", "dict"]),
  mounted() {},
  methods: {
    editVerify() {
      this.$emit("input", 1);
    },
  },
};
</script>

<style scoped lang="scss">
.user-cer-result {
  > .title {
    margin: 16px 0;
  }
}

.cer-result-body {
  background: #ffffff;
  border-radius: 2px;
  padding-top: 25px;
  > p {
    margin-left: 32px;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-bottom: 32px;
  }

  > .cer-result-box {
    width: 1028px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      display: block;
      width: 72px;
      height: 72px;
      margin-bottom: 23px;
    }
    > :nth-child(2) {
      font-size: 24px;
      color: rgba(0,0,0,0.85);
      line-height: 32px;
    }
    > p {
      font-size: 14px;
      color: #8c8c8c;
      line-height: 22px;
      text-align: center;
      margin-top: 16px;
    }
    >button{
      margin-top: 28px;
    }
    .cer-result-info {
      width: 100%;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: 24px 40px;
      margin-top: 32px;
      > p {
        font-size: 16px;
        color: #262626;
        line-height: 24px;
        margin-bottom: 20px;
      }

      > div {
        > :not(:last-child) {
          margin-bottom: 25px;
        }
        > .item {
          display: flex;
          > div {
            > :first-child {
              font-size: 14px;
              color: #262626;
              line-height: 22px;
            }
            > :last-child {
              font-size: 14px;
              color: #595959;
              line-height: 22px;
            }
          }
          > :first-child {
            width: 366px;
          }
        }
      }
    }
  }
}
</style>
