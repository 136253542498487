<template>
  <div>
    <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>成员部门</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="enterprise-member">
      <div>
        <p>成员部门</p>
        <div class="enterprise-member-content">
          <div class="enterprise-member-tag">
            <span :class="{ active: tagIndex == 1 }" @click="tagIndex = 1"
              >成员管理</span
            >
            <span :class="{ active: tagIndex == 2 }" @click="tagIndex = 2"
              >部门管理</span
            >
          </div>
          <div class="enterprise-member-box">
            <div
              class="enterprise-member-tree"
              :class="{ entMove: !move }"
              v-show="tagIndex == 2"
            >
              <a-tree
                class="move-tree"
                v-if="treeData != ''"
                :auto-expand-parent="true"
                :default-expand-all="true"
                :tree-data="treeData"
              />
              <span @click="move = !move"
                ><img src="@/assets/img/treeicon.png" alt="图片"
              /></span>
            </div>
            <div class="enterprise-member-table">
              <div v-show="tagIndex == 1">
                <div class="enterprise-member-table-head">
                  <div>
                    成员姓名：<a-input
                      v-model="memberName"
                      placeholder="请输入成员姓名"
                      style="width: 272px; margin-right: 10px"
                    />
                    成员状态：<a-select
                      v-model="memberState"
                      allowClear
                      style="width: 272px; margin-right: 10px"
                      placeholder="请选择成员状态"
                    >
                      <!--                        <a-select-option :value="''">全部</a-select-option>-->
                      <a-select-option value="1">待加入</a-select-option>
                      <a-select-option value="2">已同意</a-select-option>
                      <a-select-option value="3">已拒绝</a-select-option>
                    </a-select>
                    部门：
                    <a-tree-select
                      v-model="departmentId"
                      style="width: 272px"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      :tree-data="treeData"
                      allow-clear
                      placeholder="请选择部门"
                      tree-default-expand-all
                    />

                    <a-button @click="inquireMember">查 询</a-button>
                  </div>
                  <div>
                    <a-button @click="resetMember">重 置</a-button>
                  </div>
                </div>
                <a-table
                  :columns="columns"
                  :data-source="data"
                  :pagination="pagination"
                  @change="memberCurrent"
                >
                  <span slot="num" slot-scope="text, itme, index">{{
                    index + 1
                  }}</span>
                  <p
                    slot="verifyStatus"
                    slot-scope="text"
                    class="enterprise-member-table-state"
                  >
                    <template v-if="!text">-</template>
                    <template v-else>
                      <i :class="{ 1: 'djr', 3: 'yjj', 2: 'yty' }[text]"></i
                      >{{ text == "1" ? "待加入" : ""
                      }}{{ text == "2" ? "已加入" : ""
                      }}{{ text == "3" ? "已拒绝" : "" }}
                    </template>
                  </p>
                  <div
                    slot="operate"
                    slot-scope="text, itme"
                    class="enterprise-member-table-operate"
                  >
                    <template v-if="itme.verifyStatus == '3'"
                      ><span>-</span></template
                    >
                    <template v-else-if="itme.verifyStatus == '1'">
                      <a-popconfirm
                        placement="topLeft"
                        ok-text="确 定"
                        cancel-text="取 消"
                        @confirm="agreeJoin(itme.id)"
                      >
                        <template slot="title">
                          <p>你确定同意加入该企业吗？</p>
                        </template>
                        <span>同意</span>
                      </a-popconfirm>
                      <span @click="refuseJoin(itme.id)">拒绝</span>
                    </template>
                    <template v-else-if="itme.verifyStatus == '2'">
                      <a-popconfirm
                        placement="topLeft"
                        ok-text="确 定"
                        cancel-text="取 消"
                        @confirm="removeChengyuan(itme.id)"
                      >
                        <template slot="title">
                          <p>你确定要移除吗？</p>
                        </template>
                        <span>移除</span>
                      </a-popconfirm>
                      <span @click="resetPass(itme)">重置密码</span>
                    </template>
                    <template v-else-if="isOwner">
                      <span @click="transferEnterprise(itme)">转让企业</span>
                      <span @click="resetPass(itme)">重置密码</span>
                    </template>
                  </div>
                </a-table>
              </div>
              <div v-show="tagIndex == 2">
                <div class="enterprise-member-table-head">
                  <div>
                    部门名称：<a-input
                      v-model="departmentName"
                      placeholder="请输入部门名称"
                      style="width: 272px"
                    />
                    <a-button @click="inquireDepartment">查 询</a-button>
                  </div>
                  <div>
                    <a-button @click="resetDepartment">重 置</a-button>
                    <a-button class="btn-red" @click="addBumen"
                      >新建部门</a-button
                    >
                  </div>
                </div>
                <a-table
                  :columns="bumenHead"
                  :data-source="bumenData"
                  :pagination="bumenPage"
                  @change="bumenCurrent"
                >
                  <div
                    slot="operate"
                    slot-scope="text, el"
                    class="enterprise-member-table-operate"
                  >
                    <span @click="editBumen(el)">编辑</span>
                    <a-popconfirm
                      title="您确定删除吗？"
                      @confirm="delBumen(el)"
                    >
                      <a-icon
                        slot="icon"
                        type="question-circle-o"
                        style="color: red"
                      />
                      <span>删除</span>
                    </a-popconfirm>
                    <span @click="addPersonnelBtn(el)">添加成员</span>
                  </div>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <add-bumen
        v-if="addBumenShow"
        :visible="addBumenShow"
        v-model="addBumenShow"
        :list="treeData"
        :edit="editData"
      />
      <transfer-enterprise
        v-if="transferEnterpriseShow"
        :visible="transferEnterpriseShow"
        v-model="transferEnterpriseShow"
        :info="memberInfo"
      />
      <reset-password
        v-if="resetPasswordShow"
        :visible="resetPasswordShow"
        v-model="resetPasswordShow"
        :info="memberInfo"
      />
      <add-personnel
        v-if="addPersonnelShow"
        :visible="addPersonnelShow"
        v-model="addPersonnelShow"
        :info="memberInfo"
      />
      <a-modal v-model="refuseJoinShow" title="拒绝理由" @cancel="cancelCefuse">
        <p style="margin-bottom: 12px">拒绝理由：</p>
        <a-textarea
          v-model="denialReason"
          placeholder="请输入拒绝理由"
          :rows="4"
        />
        <template slot="footer">
          <a-button @click="cancelCefuse">取消</a-button>
          <a-button class="btn-red" @click="confirmRefuseJoin">确定</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "编号",
    dataIndex: "num",
    key: "num",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "成员姓名",
    dataIndex: "appliedByName",
    key: "appliedByName",
  },
  {
    title: "手机号",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "所属部门",
    dataIndex: "departmentName",
    key: "departmentName",
  },
  {
    title: "角色",
    dataIndex: "memberTypeName",
    key: "memberTypeName",
  },
  {
    title: "状态",
    dataIndex: "verifyStatus",
    key: "verifyStatus",
    scopedSlots: { customRender: "verifyStatus" },
  },
  {
    title: "操作",
    dataIndex: "operate",
    key: "operate",
    scopedSlots: { customRender: "operate" },
  },
];

const bumenHead = [
  {
    title: "部门名称",
    dataIndex: "departmentName",
    key: "departmentName",
    width: 250,
    ellipsis: true,
  },
  // {
  //   title: "成员数量",
  //   dataIndex: "num",
  //   key: "num",
  // },
  {
    title: "部门排序",
    dataIndex: "sortNum",
    key: "sortNum",
    width: 100,
  },
  {
    title: "部门描述",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "日期",
    dataIndex: "createTime",
    key: "createTime",
    width: 200,
  },
  {
    title: "操作",
    dataIndex: "operate",
    key: "operate",
    scopedSlots: { customRender: "operate" },
    width: 200,
  },
];
import {
  getDepartmentTree,
  getDepartmentList,
  delDepartment,
  getCompanyMember,
  agreeJoinCompany,
  refuseJoinCompany,
  companyIsOwner,
  joinCompanyRemove,
} from "@/api/enterpriseManage";
import addBumen from "./components/addBumen.vue";
import transferEnterprise from "./components/transferEnterprise.vue";
import resetPassword from "./components/resetPassword.vue";
import addPersonnel from "./components/addPersonnel.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    addBumen,
    transferEnterprise,
    resetPassword,
    addPersonnel,
  },
  data() {
    return {
      tagIndex: 1,
      treeData: [],
      selectedKeys: [],
      columns,
      data: [],
      pagination: {
        showQuickJumper: true,
        total: 20,
        showTotal: (total) => `共 ${total} 项`,
        current: 1,
      },
      move: true,
      bumenHead,
      bumenData: [],
      bumenPage: {
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
        current: 1,
      },
      bumenList: [],
      addBumenShow: false,
      transferEnterpriseShow: false,
      resetPasswordShow: false,
      addPersonnelShow: false,
      departmentName: "",
      editData: "",
      memberName: "",
      memberState: "",
      memberInfo: "",
      departmentId: "",
      refuseJoinShow: false,
      refuseId: "",
      denialReason: "",
      isOwner: false,
    };
  },
  computed: mapGetters(["companyId"]),
  watch: {
    addBumenShow: function (val) {
      if (!val) {
        this.editData = "";
        this.loadTree();
        this.loadDepartmentList();
      }
    },
    memberState: function () {
      this.loadCompanyMember();
    },
    departmentId: function () {
      this.loadCompanyMember();
    },
    transferEnterpriseShow: function (val) {
      if (!val) {
        this.loadCompanyMember();
        this.loadCompanyIsOwner();
      }
    },
    tagIndex: function (val) {
      if (val == 1) {
        this.loadCompanyMember();
      } else {
        this.loadDepartmentList();
      }
    },
  },
  mounted() {
    this.loadTree();
    this.loadCompanyMember();
    this.loadDepartmentList();
    this.loadCompanyIsOwner();
    this.departmentList();
  },
  methods: {
    ...mapActions(["UserCompany"]),
    loadTree() {
      getDepartmentTree({ companyId: this.companyId }).then((res) => {
        this.treeData = res;
      });
    },
    // 成员列表
    loadCompanyMember() {
      let data = {
        companyId: this.companyId,
        appliedByName: this.memberName,
        verifyStatus: this.memberState,
        departmentId: this.departmentId,
      };
      getCompanyMember(this.pagination, data).then((res) => {
        this.data = res.records;
        this.pagination.total = res.total;
      });
    },
    // 部门列表
    departmentList() {
      let data = {
        companyId: this.companyId,
      };
      getDepartmentList({}, data).then((res) => {
        this.bumenList = res.records;
      });
    },
    // 部门列表
    loadDepartmentList() {
      let data = {
        companyId: this.companyId,
        departmentName: this.departmentName,
      };
      getDepartmentList(this.bumenPage, data).then((res) => {
        this.bumenData = res.records;
        this.bumenPage.total = res.total;
      });
    },
    // 查询部门
    inquireDepartment() {
      this.loadDepartmentList();
    },
    inquireMember() {
      this.loadCompanyMember();
    },
    // 重置部门
    resetDepartment() {
      this.departmentName = "";
      this.loadDepartmentList();
    },
    resetMember() {
      this.memberName = "";
      this.memberState = "";
      this.loadCompanyMember();
    },
    // 删除/编辑部门
    delBumen(el) {
      delDepartment(el.id).then((res) => {
        this.$message.success("删除成功");
        this.loadTree();
        this.loadDepartmentList();
      });
    },
    editBumen(el) {
      this.editData = el;
      this.addBumenShow = true;
    },
    // 分页
    memberCurrent(e) {
      this.pagination.current = e;
    },
    bumenCurrent(e) {
      this.bumenPage.current = e;
    },
    // 同意/拒绝加入
    agreeJoin(id) {
      agreeJoinCompany(id).then((res) => {
        this.$message.success("加入成功");
        this.loadCompanyMember();
      });
    },
    refuseJoin(id) {
      this.refuseId = id;
      this.refuseJoinShow = true;
    },
    confirmRefuseJoin() {
      if (this.denialReason == "") {
        this.$message.warning("请输入拒绝理由");
        return;
      }
      let data = {
        denialReason: this.denialReason,
        id: this.refuseId,
      };
      refuseJoinCompany(data).then((res) => {
        this.$message.success("操作成功");
        this.refuseJoinShow = false;
        this.denialReason = "";
        this.loadCompanyMember();
      });
    },
    cancelCefuse() {
      this.refuseJoinShow = false;
    },
    loadCompanyIsOwner() {
      companyIsOwner(this.companyId).then((res) => {
        this.isOwner = res;
      });
    },

    onSelect(selectedKeys, info) {
      if (selectedKeys.length > 0) {
        this.selectedKeys = selectedKeys;
      } else {
        this.selectedKeys = [];
      }
    },
    removeChengyuan(id) {
      joinCompanyRemove(id).then((res) => {
        this.$message.success("操作成功");
        this.loadCompanyMember();
        this.UserCompany();
      });
    },
    addBumen() {
      this.addBumenShow = true;
    },
    transferEnterprise(el) {
      this.transferEnterpriseShow = true;
      this.memberInfo = el;
    },
    resetPass(el) {
      this.resetPasswordShow = true;
      this.memberInfo = el;
    },
    addPersonnelBtn(el) {
      this.addPersonnelShow = true;
      this.memberInfo = el;
    },
  },
};
</script>

<style lang="scss" scoped>
.enterprise-member {
  padding-top: 16px;
  height: calc(100% - 38px);
  > div {
    background: #ffffff;
    border-radius: 2px;
    padding: 24px 0;
    min-height: 100%;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 24px;
      padding: 0 32px;
    }
    .enterprise-member-tag {
      display: flex;
      padding: 0 32px;
      border-bottom: 1px solid #e8eaec;
      > span {
        font-size: 14px;
        color: #595959;
        line-height: 22px;
        padding-bottom: 15px;
        border-bottom: 2px solid transparent;
        margin-right: 64px;
        cursor: pointer;
        &.active {
          color: #ea0b06;
          border-color: #ea0b06;
        }
      }
    }
    .enterprise-member-box {
      display: flex;
      .entMove {
        width: 0 !important;
        transition: 0.3s ease;
        padding: 0 !important;
        border: 0 !important;
        .move-tree {
          //display: none;
          opacity: 0;
        }
        > span {
          right: -21px !important;
          transform: rotateZ(180deg);
        }
      }
      .move-tree {
        transition: 0.3s ease;
      }
      .enterprise-member-tree {
        width: 320px;
        border-right: 1px solid #e8eaec;
        padding: 12px 24px;
        position: relative;
        transition: 0.3s ease;
        > span {
          position: absolute;
          right: -1px;
          top: 24px;
          z-index: 2;
          cursor: pointer;
        }
        &::v-deep .ant-tree-node-content-wrapper {
          width: 90%;
        }
        &::v-deep {
          .ant-tree-title {
            display: inline-block;
            max-width: 227px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
        }
      }
      .enterprise-member-table {
        flex: 1;
        padding: 24px 24px 0;
        .enterprise-member-table-state {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #595959;
          > i {
            width: 6px;
            height: 6px;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            margin-right: 8px;
            &.djr {
              background: #facc14;
            }
            &.yjj {
              background: #f5222d;
            }
            &.yty {
              background: #1890ff;
            }
          }
        }
        .enterprise-member-table-operate {
          display: flex;
          > span {
            color: #ea0b06;
            font-size: 14px;
            line-height: 14px;
            border-right: 1px solid #e9e9e9;
            padding-right: 8px;
            margin-right: 8px;
            cursor: pointer;
            &:last-child {
              border-right: 0;
            }
          }
        }
        .enterprise-member-table-head {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          > div {
            display: flex;
            align-items: center;
            > button {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
