<template>
  <div class="user-collect">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>互动中心</a-breadcrumb-item>
      <a-breadcrumb-item>我的关注</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="user-collect-body">
      <p>我的关注</p>

      <div class="select">
        <div>
          关注类型：
          <a-select
            placeholder="请选择"
            allow-clear
            style="width: 272px"
            v-model="payType"
            @change="typeChange"
          >
            <a-select-option
              :value="item.itemValue"
              v-for="(item, index) in dict.PRODUCT_TYPE"
              :key="index"
              >{{ item.itemText }}</a-select-option
            >
          </a-select>
        </div>

        <a-input-search
          placeholder="请输入关键词搜索"
          style="width: 272px"
          @search="onSearch"
          allowClear
          v-model="activityNameVal"
        />
      </div>

      <a-table
        :columns="columns"
        :data-source="collectList"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template slot="focusType" slot-scope="text, record">
          <p>{{ text | dict("PRODUCT_TYPE") }}</p>
        </template>

        <template slot="followers" slot-scope="text, record">
          <p>{{ text || 0 }}</p>
        </template>

        <template slot="operate" slot-scope="text, record">
          <p class="user-scene-table-operate">
            <span
              @click="
                collectInfo(record.recordId, record.focusType, record.domain)
              "
              >详情</span
            >
          </p>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const columns = [
  {
    title: "名称",
    dataIndex: "productName",
    key: "productName",
    ellipsis: true,
  },
  {
    title: "关注类型",
    dataIndex: "focusType",
    key: "focusType",
    scopedSlots: { customRender: "focusType" },
  },
  {
    title: "关注时间",
    dataIndex: "focusTime",
    key: "focusTime",
  },
  /* {
    title: '来源',
    key: 'tenantName',
    dataIndex: 'tenantName',
  },*/
  {
    title: "关注量",
    key: "followers",
    dataIndex: "followers",
    scopedSlots: { customRender: "followers" },
  },
  {
    title: "操作",
    key: "operate",
    dataIndex: "operate",
    scopedSlots: { customRender: "operate" },
  },
];

import { getFocusList, getFocusRemove } from "@/api/collect";

export default {
  name: "userCollect",
  data() {
    return {
      activityNameVal: "",
      payType: undefined,
      columns,
      loading: false,
      dataInfo: {
        focusType: "",
      },
      collectList: [],
      page: {
        current: 1,
        size: 10,
      },
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 项`,
        total: 0,
      },
    };
  },
  mounted() {
    this.loadgetFocusList();
  },
  computed: {
    ...mapGetters(["dict"]),
  },
  methods: {
    loadgetFocusList() {
      let { page } = this;
      this.loading = true;
      getFocusList(page, this.dataInfo).then((res) => {
        this.collectList = res.records;

        this.$set(this.pagination, "total", res.total);

        this.loading = false;
      });
    },

    handleTableChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadgetFocusList();
    },

    typeChange(val) {
      this.dataInfo.focusType = val;
      this.loadgetFocusList();
    },

    // 详情
    collectInfo(id, type, url) {
      if (type == "6") {
        window.open(`${url}/service/details?id=${id}`, "_blank");
      } else if (type == "5") {
        window.open(`${url}/replace/info?id=${id}`, "_blank");
      } else {
        let typeName = "";
        if (type == "2") {
          typeName = "soc";
        } else if (type == "3") {
          typeName = "dev";
        } else if (type == "4") {
          typeName = "os";
        }
        window.open(
          `${url}/replace/productInfo/${typeName}?id=${id}`,
          "_blank"
        );
      }
    },

    onSearch() {
      this.page.current = 1;
      this.loadgetFocusList({ keyword: this.activityNameVal });
    },
  },
};
</script>

<style scoped lang="scss">
.user-collect {
  padding-top: 16px;
  height: 100%;
  box-sizing: border-box;
  > .title {
    margin-bottom: 16px;
  }
}

.user-collect-body {
  background: #ffffff;
  border-radius: 2px;
  min-height: calc(100% - 37px);
  overflow-y: auto;
  padding: 24px 32px 36px;

  > P {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-bottom: 24px;
  }

  > .select {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .user-scene-table-operate {
    > span {
      cursor: pointer;
      margin-right: 16px;
      color: #ea0b06;
    }
  }
}
</style>
