<template>
  <div class="project-case">
    <div class="add-project" @click="projectCaseVisible = true">
      <a-icon type="plus" class="icon" />添加项目案例
    </div>
    <project-card ref="project" @edit="edit"></project-card>

    <a-modal
      class="modal"
      v-model="projectCaseVisible"
      title="添加项目案例"
      :confirm-loading="confirmLoading"
      @ok="projectCaseHandleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="projectCaseForm"
        :model="projectCaseForm"
        :rules="projectCaseRules"
        layout="horizontal"
        v-bind="{
          labelCol: { span: 4 },
          wrapperCol: { span: 20 },
        }"
      >
        <a-form-model-item label="案例名称" prop="caseName">
          <a-input
            style="height: 32px"
            v-model="projectCaseForm.caseName"
            :max-length="40"
            placeholder="请填写案例名称"
          />
        </a-form-model-item>
        <!--        <a-form-model-item
          label="案例图片"
          prop="caseCoverId"
          style="height: 88px"
        >
          <VUploadAvatar
            class="upload-form"
            file-type-code="1002"
            v-model="projectCaseForm.caseCoverId"
            upload-text="上传图片"
          />
        </a-form-model-item>-->
        <a-form-model-item label="采购方" prop="purchaseBy">
          <a-input
            style="height: 32px"
            v-model="projectCaseForm.purchaseBy"
            :max-length="40"
            placeholder="请填写采购方名称"
          />
        </a-form-model-item>
        <a-form-model-item label="交付周期" prop="deliverCycle">
          <a-input
            style="height: 32px"
            :max-length="40"
            v-model="projectCaseForm.deliverCycle"
            placeholder="请填写交付周期"
          />
        </a-form-model-item>
        <a-form-model-item label="成交金额" prop="transactionAmount">
          <a-input-number
            style="margin-right: 8px; width: 122px; height: 32px"
            v-model="projectCaseForm.transactionAmount"
            :min="0"
            placeholder="请填写成交金额"
          />元
        </a-form-model-item>
        <a-form-model-item
          label="案例简介"
          prop="summary"
          style="margin-bottom: 0"
        >
          <a-textarea
            v-model="projectCaseForm.summary"
            :auto-size="{ minRows: 3, maxRows: 9 }"
            placeholder="请填写案例简介"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import projectCard from "./components/projectCard";
import VUploadAvatar from "../../components/VUploadAvatar";
import { saveProjectCase, removeProjectCase } from "@/api/companyInfo";
import { mapGetters } from "vuex";
export default {
  name: "projectCase",
  components: {
    projectCard,
    VUploadAvatar,
  },
  data() {
    return {
      confirmLoading: false,
      loading: false,
      projectCaseVisible: false,
      projectCaseList: [],
      projectCaseCondition: {},
      page: {
        current: 1,
        size: 5,
        total: 0,
      },
      projectCaseForm: {},
      projectCaseRules: {
        caseName: [
          { required: true, message: "案例名称不能为空", trigger: "blur" },
        ],
        purchaseBy: [
          { required: true, message: "采购方不能为空", trigger: "blur" },
        ],
        deliverCycle: [
          { required: true, message: "交付周期不能为空", trigger: "blur" },
        ],
        summary: [
          { required: true, message: "案例简介不能为空", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  methods: {
    projectCaseHandleOk() {
      this.$refs.projectCaseForm.validate((valid) => {
        if (valid) {
          const { projectCaseForm, companyInfo } = this;
          projectCaseForm.businessLicense = companyInfo.socialCreditCode;
          this.confirmLoading = true;
          saveProjectCase(projectCaseForm).then(() => {
            this.projectCaseVisible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.projectCaseForm = {};
            this.$refs.project.initProjectCaseData();
          });
        } else {
          return false;
        }
      });
    },

    edit(record) {
      this.projectCaseForm = {
        id: record.id,
        caseName: record.caseName,
        purchaseBy: record.purchaseBy,
        deliverCycle: record.deliverCycle,
        transactionAmount: record.transactionAmount,
        summary: record.summary,
      };
      this.projectCaseVisible = true;
    },

    handleCancel() {
      this.projectCaseVisible = false;
      this.$refs.projectCaseForm.resetFields();
      this.projectCaseForm = {};
    },
  },
};
</script>

<style scoped lang="scss">
.project-case {
  height: calc(100% - 152px);
  box-sizing: border-box;
  padding: 24px 32px 0;
  > .add-project {
    height: 32px;
    cursor: pointer;
    background: #ffffff;
    border-radius: 2px;
    border: 1px dashed #d9d9d9;
    font-size: 14px;
    color: #595959;
    line-height: 32px;
    text-align: center;
    margin-bottom: 20px;
    > .icon {
      margin-right: 10px;
    }
  }
}

.modal {
  &::v-deep {
    .ant-modal {
      width: 640px !important;
    }
  }
}

.upload-form {
  &::v-deep {
    .ant-upload-select {
      width: 88px !important;
      height: 88px !important;
      background-color: #fafafa !important;
      border: 1px dashed #d9d9d9 !important;
      .anticon {
        font-size: 14px;
      }
      .ant-upload-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
      }
    }
  }
}

.page {
  text-align: right;
  margin-top: 20px;
}
</style>
