<template>
  <div class="user-message">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>消息中心</a-breadcrumb-item>
      <a-breadcrumb-item>{{ title }}</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="user-message-content">
      <div>
        <div class="user-message-list-title">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          />
          <a-button @click="delBtn">删除</a-button>
          <a-button @click="readBtn" class="btn-red">全部标为已读</a-button>
        </div>
        <div class="user-message-list-box">
          <template v-if="messageList != ''">
            <a-checkbox-group
              v-model="checkedList"
              @change="onChange"
              style="width: 100%"
            >
              <div
                class="user-message-list"
                v-for="item in messageList"
                :key="item.id"
              >
                <div>
                  <div class="user-message-list-info">
                    <i :class="{ red: !item.hasRead }"></i>
                    <a-checkbox :value="item.id" />
                    <p>{{ item.content }}</p>
                    <span @click="messageInfo(item)"> 查看详情 ></span>
                  </div>
                  <div class="user-message-list-date">
                    <p>{{ item.sendTime }}</p>
                    <a-icon type="delete" @click="messageDel(item.id)" />
                  </div>
                </div>
              </div>
            </a-checkbox-group>
          </template>
          <div v-else style="padding: 100px 0">
            <a-empty />
          </div>
        </div>
        <div class="user-message-page">
          <pagination
            :total="page.total"
            :current="page.current"
            @changes="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTenantInfo } from "@/api/common";

const defaultCheckedList = [];
import pagination from "../components/pagination.vue";
import iconFont from "@/components/iconFont";
import VImg from "@/components/VImg";
import {
  getMessageList,
  getMessageRemove,
  getMessageRead,
  getMessageRemoveAll,
  getAllMessageCount
} from "@/api/message";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    iconFont,
    VImg,
    pagination,
  },
  data() {
    return {
      checkedList: defaultCheckedList,
      indeterminate: false,
      checkAll: false,
      plainOptions: [],
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      messageList: [],
    };
  },
  computed: {
    ...mapGetters(["msg"]),
    messageTypeId() {
      return this.$route.query.id;
    },
    title() {
      let titleList = { 2: "服务消息", 1: "系统消息", 3: "互动消息" };
      return titleList[this.messageTypeId];
    },
  },
  watch: {
    messageTypeId() {
      this.loadGetMessageList();
    },
  },
  mounted() {
    this.loadGetMessageList();

  },
  methods: {
    ...mapActions(["LoadMsg", "GetInfo","getMsgNum"]),
    loadGetMessageList() {
      let { page, messageTypeId } = this;
      let data = {
        messageType: messageTypeId,
      };
      getMessageList(page, data).then((res) => {
        console.log(res);
        this.messageList = res.records;
        this.page.total = res.total;
        res.records.forEach((element) => {
          this.plainOptions.push(element.id);
        });
      });
      this.LoadMsg();
      getAllMessageCount().then(res=>{
        console.log(res,'res--------')
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetMessageList();
    },
    messageType(id) {
      this.messageTypeId = id;
      this.loadGetMessageList();
    },
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    // 标记已读
    readBtn() {
      let { checkedList } = this;
      getMessageRead(checkedList).then((res) => {
        console.log(res);
        this.loadGetMessageList();
        this.LoadMsg();
        this.checkedList = [];
      });
    },
    // 删除
    delBtn() {
      let { checkedList } = this;
      getMessageRemoveAll(checkedList).then(() => {
        this.loadGetMessageList();
      });
    },
    messageDel(id) {
      let _this = this;
      this.$confirm({
        title: "您确定要删除该消息吗?",
        onOk() {
          getMessageRemove(id).then((res) => {
            console.log(res);
            _this.$message.success("删除成功！");
            _this.loadGetMessageList();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    // 详情
    messageInfo(item) {
      const {
        recordId: id,
        messageSource: type,
        id: msgId,
        tenantId,
        companyId,
      } = item;
      let data = [];
      data.push(msgId);
      getMessageRead(data).then(() => {
        this.loadGetMessageList();
        this.LoadMsg();
        this.getMsgNum()
      });
      if (type == "1") {
        this.GetInfo();
        this.$router.push("/userLayout/userCer");
      } else if (type == "2") {
        this.$router.push("/enterpriseManage/verify");
        // this.$router.push({
        //   name: `enterpriseVerify`,
        //   params: { companyId },
        // });
      } else if (type == "3" || type == "11") {
        this.$router.push("/userLayout/userInfo");
      } else if (type == "4") {
        this.$router.push({
          path: "/userLayout/userScenes/info",
          query: { id },
        });
      } else if (type == "5") {
        this.$router.push("/interactive/userConsult");
      } else if (type == "6") {
        getTenantInfo({ id: tenantId }).then((res) => {
          window.open(`${res.domain}/activity/activityInfo?id=${id}`);
        });
        // this.$router.push({
        //   path: "/activity/activityInfo",
        //   query: { id },
        // });
      } else if (type == "7") {
        this.$router.push({
          path: "/activity/courseInfo",
          query: { id },
        });
      } else if (type == "8") {
        this.$router.push("/userLayout/myScenes/detail/" + id);
      } else if (type == "9") {
        this.$router.push("/enterpriseManage/member");
      } else if (type == "10") {
        this.$router.push("/serveManage");
      } else if (type == "12") {
        getTenantInfo({ id: tenantId }).then((res) => {
          window.open(`${res.domain}/homeScenes/detail?id=${id}`);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-message {
  height: 100%;
  padding-top: 16px;
  .title {
    margin-bottom: 16px;
  }

  .user-message-content {
    height: calc(100% - 37px);
    overflow-y: auto;
    > div {
      min-height: 100%;
      background: #fff;
      margin: 0 auto;
      padding: 24px 0;
      .user-message-list-title {
        padding: 0 38px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        > button {
          margin-left: 24px;
          &:last-child {
            margin-left: 16px;
          }
        }
      }
      .user-message-list-box {
        margin-bottom: 24px;
        .user-message-list {
          padding: 0 24px;
          transition: all 0.3s;
          > div {
            height: 80px;
            border-bottom: 1px solid #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .user-message-list-info {
              display: flex;
              align-items: center;
              > i {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin-right: 8px;
                &.red {
                  background: #ff4d4f;
                }
              }
              > p {
                margin-left: 24px;
                color: #262626;
              }
              > span {
                color: #ea0b06;
                cursor: pointer;
              }
            }
            .user-message-list-date {
              > p {
                color: #bfbfbf;
              }
              > i {
                color: #8c8c8c;
                cursor: pointer;
                display: none;
              }
            }
          }
          &:hover {
            background: #fafafa;
            > div {
              .user-message-list-date {
                > p {
                  display: none;
                }
                > i {
                  display: block;
                }
              }
            }
          }
        }
      }
      .user-message-page {
        padding: 0 24px;
        ::v-deep {
          .home-page {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
