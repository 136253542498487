<template>
  <a-radio-group
    v-model="val"
    :default-value="defaultValue"
    :placeholder="placeholder"
  >
    <a-radio
      v-for="(el, index) in dict[code]"
      :key="index"
      :value="el.itemValue"
    >
      {{ el.itemText }}
    </a-radio>
  </a-radio-group>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VRadioDict",
  props: {
    value: {
      type: [String, Array],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    code: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters(["dict"]),
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.LoadDictData([this.code]);
  },
  methods: {
    ...mapActions(["LoadDictData"]),
  },
};
</script>