<template>
  <div class="my-scenes-apply-body">
    <a-breadcrumb class="user-breadcrumb-box">
      <a-breadcrumb-item>场景管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link to="/sceneManage/scene" replace>我的场景</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>场景申报</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="my-scenes-apply-content-body">
      <p>场景申报</p>
      <a-spin :spinning="spinning">
      <div class="my-scenes-apply-info-body">
        <div class="my-scenes-apply-info">
          <a-form-model
            :model="sceneInfo"
            :rules="rules"
            ref="ruleForm"
            label-align="left"
            v-if="!verify"
          >
            <a-form-model-item label="场景名称" prop="title" ref="title">
              <a-input
                @blur="
                  () => {
                    $refs.title.onFieldBlur();
                  }
                "
                placeholder="请填写场景名称"
                v-model="sceneInfo.title"
              />
            </a-form-model-item>
            <a-form-model-item label="场景领域" prop="field">
              <v-select-dict
                code="SCENE_FIELD"
                placeholder="请选择场景领域"
                style="width: 630px"
                v-model="sceneInfo.field"
              />
            </a-form-model-item>
            <a-form-model-item label="场景应用行业" prop="industry">
              <v-select-dict
                code="SCENE_INDUSTRY"
                placeholder="请选择场景应用行业"
                style="width: 630px"
                v-model="sceneInfo.industry"
              />
            </a-form-model-item>
            <a-form-model-item label="场景类别" prop="skill">
              <v-select-dict
                code="SCENE_SKILL"
                placeholder="请选择场景类别"
                style="width: 630px"
                v-model="sceneInfo.skill"
              />
            </a-form-model-item>
            <a-form-model-item label="联合申报单位" prop="associate">
              <a-input
                placeholder="请填写联合申报单位"
                v-model="sceneInfo.associate"
              />
            </a-form-model-item>
            <a-form-model-item
              label="联系人"
              prop="contactPerson"
              ref="contactPerson"
            >
              <a-input
                @blur="
                  () => {
                    $refs.contactPerson.onFieldBlur();
                  }
                "
                placeholder="请填写联系人姓名"
                v-model="sceneInfo.contactPerson"
              />
            </a-form-model-item>
            <a-form-model-item
              label="联系方式"
              prop="contactPhone"
              ref="contactPhone"
            >
              <a-input
                @blur="
                  () => {
                    $refs.contactPhone.onFieldBlur();
                  }
                "
                placeholder="请填写联系方式"
                v-model="sceneInfo.contactPhone"
              />
            </a-form-model-item>
            <a-form-model-item
              label="场景地址"
              prop="sceneAddress"
              ref="sceneAddress"
            >
              <a-input
                @blur="
                  () => {
                    $refs.sceneAddress.onFieldBlur();
                  }
                "
                placeholder="请填写场景地址"
                v-model="sceneInfo.sceneAddress"
              />
            </a-form-model-item>
            <a-form-model-item
              label="场景关键词"
              prop="sceneKeywords"
              ref="sceneKeywords"
            >
              <a-select
                :token-separators="['.']"
                dropdown-class-name="select-keyword"
                mode="tags"
                style="width: 630px"
                v-model="sceneInfo.sceneKeywords"
              >
                <a-select-option value="sceneKeywords">
                  sceneKeywords
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="场景简介" prop="description">
              <a-input
                placeholder="可根据以下几点填写：
1.背景情况介绍；
2.场景具体描述（包括但不限于建设总体目标、建设内容、预期成效）等"
                type="textarea"
                v-model="sceneInfo.description"
              />
            </a-form-model-item>
            <a-form-model-item label="场景建设需求" prop="requirement">
              <a-input
                placeholder="可根据以下几点填写，如：
场景建设过程中产生的需求，如技术需求（技术和网络、SOC模组、行业终端、应用标准
等）、合作生态、资金需求、复制推广需求等"
                type="textarea"
                v-model="sceneInfo.requirement"
              />
            </a-form-model-item>
            <a-form-model-item label="场景配套资源" prop="resource">
              <a-input
                placeholder="请填写场景配套资源"
                type="textarea"
                v-model="sceneInfo.resource"
              />
            </a-form-model-item>
            <a-form-model-item
              label="预估启动建设年月"
              prop="expectedStartDate"
            >
              <v-date-picker
                placeholder="请选择预估启动建设年月"
                style="width: 630px"
                v-model="sceneInfo.expectedStartDate"
              />
            </a-form-model-item>
            <a-form-model-item
              label="预估成本"
              prop="fundAmount"
              ref="fundAmount"
            >
              <a-input-number
                :max="999999"
                :min="0"
                @blur="
                  () => {
                    $refs.fundAmount.onFieldBlur();
                  }
                "
                placeholder="请输入"
                style="width: 100px"
                v-model="sceneInfo.fundAmount"
              />
              <span style="margin-left: 10px">万</span>
            </a-form-model-item>
            <a-form-model-item label="预估资金来源" prop="fundSource">
              <a-input
                placeholder="请填写预估资金来源"
                type="textarea"
                v-model="sceneInfo.fundSource"
              />
            </a-form-model-item>
            <a-form-model-item style="padding-left: 242px">
              <a-button @click="handleSubmit" class="btn-red">
                提交审核
              </a-button>
              <a-button
                @click="handleSave"
                class="btn-white"
                style="margin-left: 10px"
                >保存
              </a-button>
            </a-form-model-item>
          </a-form-model>
          <!-- 审核中样式 -->
          <div class="verify-box" v-else>
            <img alt="" src="@/assets/img/cjsh.png" />
            <p>您申报的场景已提交至平台审核，请耐心等待...</p>
            <div>
              <a-button class="btn-red">查看详情</a-button>
              <a-button class="btn-gray" style="margin-left: 20px"
                >返回列表
              </a-button>
            </div>
          </div>
        </div>
      </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import VSelectDict from "@/components/VSelectDict";
import { saveScene, sceneDetail } from "@/api/cjkf/scn-scene";
import VDatePicker from "@/components/VDatePicker";
import {mapGetters} from "vuex";

export default {
  components: { VDatePicker, VSelectDict },
  computed: {
    id() {
      return this.$route.query.id;
    },
    ...mapGetters(["companyId","tenantId","company_status"]),
  },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      spinning:false,
      sceneInfo: {
        title: "",
        field: undefined,
        industry: undefined,
        skill: undefined,
        associate: "",
        contactPerson: "",
        contactPhone: "",
        sceneAddress: "",
        sceneKeywords: undefined,
        description: "",
        requirement: "",
        resource: "",
        expectedStartDate: undefined,
        fundAmount: undefined,
        fundSource: "",
      },
      verify: false,
      rules: {
        title: [
          {
            required: true,
            message: "请填写场景名称",
            trigger: "blur",
          },
        ],
        field: [
          {
            required: true,
            message: "请选择场景领域",
            trigger: "change",
          },
        ],
        industry: [
          {
            required: true,
            message: "请选择场景应用行业",
            trigger: "change",
          },
        ],
        contactPerson: [
          {
            required: true,
            message: "请填写联系人姓名",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "请填写联系方式",
            trigger: "blur",
          },
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        sceneAddress: [
          {
            required: true,
            message: "请填写场景地址",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请填写场景简介",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
    if(this.company_status!=='3'){
      this.$router.replace('/sceneManage/scene')
      this.$message.warning('请先完成企业认证！')
    }
  },
  methods: {
    async loadData() {
      if (this.id) {
        this.sceneInfo = await sceneDetail(this.id);
      }
    },
    noUrl() {
      this.$message.warning("暂无跳转链接！");
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.spinning=true
          await saveScene({ ...this.sceneInfo,companyId:this.companyId,tenantId:this.tenantId,sceneStatus: "2" });
          await this.$message.success("提交成功！", 1);
          this.spinning=false
          await this.$router.push("/sceneManage/scene");
        }
      });
    },
    handleSave() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.spinning=true
          await saveScene({ ...this.sceneInfo,companyId:this.companyId,tenantId:this.tenantId, sceneStatus: "1" });
          await this.$message.success("保存成功！", 1);
          this.spinning=false
          await this.$router.push("/sceneManage/scene");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-scenes-apply-body {
  padding-top: 16px;

  .my-scenes-apply-content-body {
    margin-top: 16px;
    background: #fff;
    border-radius: 2px;
    padding: 24px 30px;
    >p{
        font-size: 20px;
        color: #262626;
        line-height: 28px;
        margin-bottom: 24px;
    }
    .my-scenes-apply-info-body {
      .my-scenes-apply-info {
        display: flex;
        // justify-content: center;

        .ant-form-item {
          display: flex;
        }

        .verify-box {
          text-align: center;
          padding-top: 100px;

          > img {
            display: block;
            margin: 0 auto;
          }

          > p {
            line-height: 14px;
            margin-top: 40px;
          }

          > div {
            display: flex;
            justify-content: center;
            margin-top: 80px;
          }
        }
      }
    }

    .my-scenes-apply-faq {
      width: 280px;
      margin-left: 24px;

      .my-scenes-apply-cx {
        position: relative;
        width: 280px;
        height: 80px;

        > img {
          width: 100%;
          height: 100%;
        }

        > div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            > p {
              text-shadow: 2px 2px 2px rgba(000, 000, 000, 0.25);
              font-size: 18px;
              line-height: 20px;
              margin-bottom: 8px;
              color: #fff;
            }

            > span {
              font-size: 12px;
              display: block;
              line-height: 12px;
              color: #fff;
            }
          }

          > span {
            height: 24px;
            border: 1px solid #ffffff;
            line-height: 22px;
            padding: 0 10px;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .my-scenes-apply-faq-desc {
        margin-top: 24px;
        background: #fff;

        > p {
          display: flex;
          align-items: center;
          padding: 0 16px;
          height: 48px;
          border-bottom: 1px solid #f0f0f0;
        }

        > div {
          padding: 24px;
          line-height: 20px;
          text-align: justify;

          > p {
            margin-top: 20px;

            > span {
              color: #ea0b06;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.select-keyword {
  display: none !important;
}

.my-scenes-apply-info .ant-form-item-label {
  line-height: 32px !important;
  min-width: 242px !important;
}

.my-scenes-apply-info input {
  width: 630px;
  height: 32px;
  /* border: 1px solid #d9d9d9; */
  border-radius: 2px;
}

.my-scenes-apply-info select {
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.my-scenes-apply-info textarea {
  width: 630px;
  height: 88px !important;
}

.my-scenes-apply-info .ant-form-item-control {
  line-height: 32px;
}

.submit-draft-box .ant-modal-confirm-btns > button {
  height: 32px;
  line-height: 32px;
  background: #ea0b06 !important;
  border-radius: 2px;
  color: #fff !important;
  border-color: #ea0b06 !important;
}
</style>
