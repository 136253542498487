<template>
  <div class="createBody">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>
        <router-link to="/userLayout/userInfo" replace>基本资料</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>创建企业</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="createCom">
      <p>创建企业</p>
      <div class="create-form-box">
        <a-form-model
          ref="ruleForm"
          :model="company"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
        >
          <p class="title">基本信息</p>

          <a-divider />

          <div class="form-top">
            <div class="form-flex">
              <a-form-model-item
                ref="companyName"
                prop="companyName"
                class="upload-box"
              >
                <v-upload-avatar
                  width="106px"
                  height="106px"
                  :background="
                    company.companyLogoAttmId
                      ? ''
                      : require('@/assets/img/uploadamo.png')
                  "
                  :background-text="
                    company.companyName
                      ? company.companyName.substring(0, 4)
                      : ''
                  "
                  :value="company.companyLogoAttmId"
                  v-model="company.companyLogoAttmId"
                  font-size="32px"
                  file-type-code="1011"
                />
                <div class="upload-text" v-if="!company.companyLogoAttmId">
                  上传图片
                </div>
              </a-form-model-item>

              <a-form-model-item
                ref="companyName"
                label="企业名称"
                prop="companyName"
                class="formName"
              >
                <a-input
                  style="width: 630px"
                  v-model="company.companyName"
                  placeholder="请输入企业名称"
                  :disabled="company.id != null"
                  @blur="
                    () => {
                      $refs.companyName.onFieldBlur();
                    }
                  "
                />
              </a-form-model-item>
            </div>
            <a-form-model-item
              ref="companyType"
              label="企业类型"
              prop="companyType"
            >
              <!--              <v-select-dict
                v-model="company.companyType"
                placeholder="请选择所属类型"
                style="width: 630px"
                code="COMPANY_TYPE"
              />-->

              <a-select
                v-model="company.companyType"
                mode="multiple"
                style="width: 630px"
                placeholder="请选择所属类型"
              >
                <a-select-option
                  v-for="el in companyTypeList"
                  :key="el.id"
                  :value="el.itemValue"
                >
                  {{ el.itemText }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="companyField"
              label="所属领域（选填）"
              prop="companyField"
            >
              <a-select
                v-model="company.companyField"
                mode="multiple"
                style="width: 630px"
                placeholder="请选择所属领域"
              >
                <a-select-option
                  v-for="el in companyFieldList"
                  :key="el.id"
                  :value="el.itemValue"
                >
                  {{ el.itemText }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="industryType"
              label="所属行业"
              prop="industryType"
            >
              <v-select-dict
                v-model="company.industryType"
                placeholder="请选择所属行业"
                style="width: 630px"
                code="INDUSTRY"
              />
            </a-form-model-item>

            <a-form-model-item
              ref="companyScaleType"
              label="企业规模"
              prop="companyScaleType"
            >
              <v-select-dict
                v-model="company.companyScaleType"
                placeholder="请选择企业规模"
                style="width: 630px"
                code="COMPANY_SCALE_TYPE"
              />
            </a-form-model-item>

            <a-form-model-item
              ref="establishmentDate"
              label="成立时间"
              prop="establishmentDate"
            >
              <a-date-picker
                @change="companyTimeChange"
                v-model="company.establishmentDate"
                placeholder="请选择日期"
                format="YYYY-MM-DD"
                style="width: 630px"
              />
            </a-form-model-item>

            <a-form-model-item
              ref="legalPersonName"
              label="法人代表"
              prop="legalPersonName"
            >
              <a-input
                style="width: 630px"
                v-model="company.legalPersonName"
                placeholder="请输入法人代表姓名"
                :disabled="company.id != null"
                @blur="
                  () => {
                    $refs.legalPersonName.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item
              ref="socialCreditCode"
              label="统一社会信用代码"
              prop="socialCreditCode"
            >
              <a-input
                style="width: 630px"
                v-model="company.socialCreditCode"
                placeholder="请输入统一社会信用代码"
                :disabled="company.id != null"
                @blur="
                  () => {
                    $refs.socialCreditCode.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item
              ref="companyAddress"
              label="公司地址"
              prop="companyAddress"
            >
              <a-input
                style="width: 630px"
                v-model="company.companyAddress"
                placeholder="请输入公司地址"
                :disabled="company.id != null"
                @blur="
                  () => {
                    $refs.companyAddress.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item
              ref="website"
              label="公司网址（选填）"
              prop="website"
            >
              <a-input
                style="width: 630px"
                v-model="company.website"
                placeholder="请输入公司网址"
                @blur="
                  () => {
                    $refs.website.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item
              ref="companyBrief"
              label="企业简介"
              prop="companyBrief"
            >
              <a-input
                style="width: 630px"
                v-model="company.companyBrief"
                type="textarea"
                :auto-size="{ minRows: 5, maxRows: 8 }"
                placeholder="请填写企业简介300字以内"
                :max-length="300"
                @blur="
                  () => {
                    $refs.companyBrief.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item label="主营业务（选填）"  prop="mainProducts">
              <a-textarea
                v-model="company.mainProducts"
                placeholder="请输入主营业务"
                style="width: 630px"
                :rows="3"
              />
            </a-form-model-item>

<!--            <a-form-model-item label="关联区域" ref="showTenantIds" prop="showTenantIds">-->
<!--                <v-select-tenant-->
<!--                    v-model="company.showTenantIds"-->
<!--                    :multiple="true"-->
<!--                    placeholder="请选择关联区域"-->
<!--                    style="width: 630px"-->
<!--                    @blur="-->
<!--                      () => {-->
<!--                        $refs.showTenantIds.onFieldBlur();-->
<!--                      }-->
<!--                    "-->
<!--                    @areaChange="getArea"-->
<!--                />-->
<!--              <p class="certificate-annex-title">关联区域为该企业和上架的服务数据可以在哪些平台展示</p>-->
<!--            </a-form-model-item>-->

          </div>

          <p class="title">联系信息</p>

          <a-divider />

          <div class="form-bottom">
            <a-form-model-item
              ref="contactPerson"
              label="联系人"
              prop="contactPerson"
            >
              <a-input
                style="width: 630px"
                v-model="company.contactPerson"
                placeholder="请输入联系人"
                @blur="
                  () => {
                    $refs.contactPerson.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              ref="contactPhone"
              label="联系电话"
              prop="contactPhone"
            >
              <a-input
                style="width: 630px"
                v-model="company.contactPhone"
                placeholder="请输入联系电话"
                @blur="
                  () => {
                    $refs.contactPhone.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item label="邮箱（选填）" prop="email">
              <a-input
                style="width: 630px"
                v-model="company.email"
                placeholder="请填写邮箱"
              />
            </a-form-model-item>

            <a-form-model-item label="座机（选填）" prop="landline">
              <a-input
                style="width: 630px"
                v-model="company.landline"
                placeholder="请填写座机"
              />
            </a-form-model-item>
          </div>

          <a-form-model-item style="margin-left: 274px">
            <a-button class="btn-red" @click="onSubmit">
              {{ company.id ? "编辑" : "创建" }}
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import VSelectDict from "@/components/VSelectDict";
import VSelectTenant from "@/components/VSelectTenant";
import {
  changeLogo,
  createCompany,
  editCompany,
  getCurrentCompany,
  getCompanyInfo,
} from "@/api/res-company";
import VUploadAvatar from "@/components/VUploadAvatar";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "createCompany",
  components: { VSelectDict, VUploadAvatar, VSelectTenant },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 15 },
      company: {
        id: null,
        companyName: "",
        companyType: undefined,
        industryType: undefined,
        companyScaleType: undefined,
        companyField: [],
        establishmentDate: "",
        legalPersonName: "",
        socialCreditCode: "",
        companyAddress: "",
        companyBrief: "",
        contactPerson: "",
        website: "", //网址
        mainProducts: "",
        contactPhone: "",
        email: "",
        landline: "",
        // showTenantIds: null
      },
      companyTypeList: [],
      companyFieldList: [],
      areaText:[],
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        companyType: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
        industryType: [
          {
            required: true,
            message: "请选择所属行业",
            trigger: "change",
          },
        ],
        companyScaleType: [
          {
            required: true,
            message: "请选择企业规模",
            trigger: "change",
          },
        ],
        establishmentDate: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        legalPersonName: [
          {
            required: true,
            message: "请输入法人代表姓名",
            trigger: "blur",
          },
        ],
        socialCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        companyAddress: [
          {
            required: true,
            message: "请输入企业地址",
            trigger: "blur",
          },
        ],
        companyBrief: [
          {
            required: true,
            message: "请输入企业简介",
            trigger: "blur",
          },
        ],
        contactPerson: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        // showTenantIds:[
        //   {
        //     required: true,
        //     message: "请选择关联区域",
        //     trigger: "blur",
        //   },
        // ],
        contactPhone: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
      },
    };
  },
  computed: mapGetters(["companyId", "userInfo", "company_status", "dict"]),
  created() {
    this.LoadDictData(["COMPANY_FIELD"]).then((res) => {
      this.companyFieldList = res.COMPANY_FIELD;
    });
    this.LoadDictData(["COMPANY_TYPE"]).then((res) => {
      this.companyTypeList = res.COMPANY_TYPE;
    });
  },
  mounted() {
    // this.loadCompany();
    if (this.$route.query.id) {
      this.loadCompanyInfo();
    }
  },
  methods: {
    ...mapActions(["UserCompany", "LoadDictData"]),
    getArea(val,lab,x){
      this.areaText=[]
      lab.forEach(item=>{
        this.areaText.push(item.componentOptions.children[0].text)
      })
    },
    // 编辑时获取i详情
    loadCompanyInfo() {
      getCompanyInfo(this.$route.query.id).then((res) => {
        console.log(res);
        this.company = res;
      });
    },
    loadCompany() {
      getCurrentCompany().then((company) => {
        this.company = company || {};
      });
    },
    handleChangeCompanyLogo(id) {
      changeLogo(id).then(() => {
        this.loadCompany();
      });
    },

    companyTimeChange(data, date) {
      this.company.establishmentDate = date;
    },

    onSubmit() {
      let { company } = this;
      console.log(company)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          createCompany(company).then(async (res) => {
            if (res) {
              this.$warning({
                title: res,
              });
            } else {
              await this.UserCompany();
              this.$message.success(company.id ? "修改成功" : "创建成功");
              this.$router.push("/userLayout/userInfo");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.createBody {
  .title {
    margin: 16px 0;
  }
}

.createCom {
  background: #fff;
  border-radius: 2px;
  padding-top: 24px;
  padding-bottom: 45px;
  //padding: 24px 32px 40px;
  > p {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin: 0 0 32px 32px;
  }
  > .create-form-box {
    .title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      margin-left: 32px;
    }

    &::v-deep {
      .ant-form-item-label {
        width: 242px;
      }
    }
    .formName::v-deep {
      .ant-form-item-label {
        display: block;
        float: none;
      }
    }
    .form-top {
      padding: 24px 0 32px 32px;
    }

    .form-bottom {
      padding: 24px 0 32px 32px;
    }

    .form-flex {
      display: flex;
    }

    .upload-box {
      position: relative;
      margin-right: 136px;
      .upload-text {
        position: absolute;
        bottom: 4.5px;
        left: 0;

        width: 106px;
        height: 22px;
        background: #ffffff;
        //border-radius: 0px 0px 4px 4px;
        opacity: 0.9;
        text-align: center;

        font-size: 12px;
        color: #ea0b06;
        line-height: 22px;
      }
      &::v-deep {
        .user-info-enterprise-avatar-box {
          line-height: 45px;
        }
        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          padding: 0;
        }
        .ant-upload.ant-upload-select-picture-card {
          border: none;
        }
      }
    }
  }
}
</style>
