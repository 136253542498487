<template>
  <div class="release-serve">
    <a-breadcrumb class="user-breadcrumb-box">
      <a-breadcrumb-item>
        <router-link to="/serveManage" replace>服务管理</router-link>

      </a-breadcrumb-item>
      <a-breadcrumb-item>发布服务</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="release-serve-box">
      <p>发布服务</p>
      <div>
        <a-spin :spinning="spinning">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
        >
          <a-form-model-item ref="serviceName" label="服务名称" class="serveType" prop="serviceName">
            <a-input
              :maxLength="40"
              v-model="form.serviceName"
              placeholder="请填写服务名称"
              @blur=" () => { $refs.serviceName.onFieldBlur(); }"
            />
          </a-form-model-item>


          <a-form-model-item label="服务类型" prop="serviceType">
            <a-tree-select
                v-model="form.serviceType"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="treeData"
                allow-clear
                placeholder="请选择服务分类"
                tree-default-expand-all
                @change="onChange"
            />

          </a-form-model-item>


          <a-form-model-item label="交付方式" prop="deliverType">
            <a-select
              v-model="form.deliverType"
              placeholder="请选择交付方式"
            >
              <a-select-option :value="item.itemValue" v-for="(item,index) in dict.DELIVER_TYPE" :key="index">{{item.itemText}}</a-select-option>
            </a-select>
          </a-form-model-item>


          <a-form-model-item label="购买方式" prop="buyType">
            <a-select
              v-model="form.buyType"
              placeholder="请选择服务购买方式"
            >
              <a-select-option :value="item.itemValue" v-for="(item,index) in dict.BUY_TYPE" :key="index">{{item.itemText}}</a-select-option>
            </a-select>
          </a-form-model-item>


          <a-form-model-item label="服务价格" prop="pricingType">
            <a-radio-group v-model="form.pricingType">
              <a-radio value="1">商品定价</a-radio>
              <a-radio value="2">议价定制</a-radio>
            </a-radio-group>
          </a-form-model-item>



          <a-form-model-item class="serveType" label="服务规格" prop="serveModel" v-if="form.pricingType==='1'">
            <p class="serve-model-border"></p>
            <div class="serve-model-box">
              <div class="serve-model-set">
                <span>规格设置：</span>
                <div>
                  <div>


                    <a-tag style="background-color: #FFFFFF" v-for="(item,index) in comboVersionDefault" :key="item"  :closable="comDisable"    @close="removeComboVersion(item)">

                      {{ item }}
                    </a-tag>

                  </div>
<!--                  <p><a-button icon="plus">添加套餐版本</a-button></p>-->
                  <p>
                    <tag-input @tagChange="getComTage" name="添加套餐版本"/>
                  </p>

                  <div>

                      <a-tag style="background-color: #FFFFFF" v-for="(item,index) in buyHourDefault" :key="item" :closable="buyDisable"    @close="removeBuyHour(item)">

                      {{ item }}
                    </a-tag>


                  </div>
                  <p> <tag-input @tagChange="getBuyTage" name="添加购买时长"/></p>
                </div>
              </div>
              <div class="serve-model-info">
                <span>规格信息：</span>
                <div>
                  <a-table :columns="columns" :data-source="pricingConfigData" size="middle" >
                    <template slot="price" slot-scope="text,record">
                      <a-input-number
                          v-model="record.price"
                          :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="value => value.replace(/\￥\s?|(,*)/g, '')"
                          :max="1000000"
                          :min="0"
                          style="width:160px">
                      </a-input-number>
                    </template>


<!--                    <template slot="operate" slot-scope="text,record,index">

                      <span v-show="pricingConfigData.length>1" style="color:#EA0B06;cursor: pointer;" @click="priceChange(record)">删除</span>

                    </template>-->


                  </a-table>
                </div>
              </div>
            </div>
          </a-form-model-item>


          <a-form-model-item label="服务简介" prop="summary">
            <a-textarea v-model="form.summary" placeholder="请填写服务简介100字以内" :rows="4" :max-length="100"/>
          </a-form-model-item>


          <a-form-model-item label="服务图片" prop="serviceCover">
            <v-upload-img
                width="120px"
                height="120px"
                v-model="form.serviceCover"
                font-size="32px"
                file-type-code="1011"
            />
            <p class="serve-img-text">注：建议图片尺寸622px*350px</p>
          </a-form-model-item>



          <a-form-model-item label="产品详情" prop="productDetail">
<!--            <v-quill-editor v-model="form.productDetail" :value="form.productDetail"/>-->
            <v-editor
                v-model="form.productDetail"
            />
          </a-form-model-item>

          <a-form-model-item label="使用指南" prop="userGuidesAttmId">
<!--            <upload-files text="上传使用指南" file-type-code="1011"/>-->

            <v-multi-upload
                v-model="form.userGuidesAttmId"
                file-type-code="4004"
            >
              <a-button icon="upload">上传使用指南</a-button>
            </v-multi-upload>


          </a-form-model-item>

          <a-form-model-item prop="deliverArea">
            <template slot="label"><span>交付区域 <a-tooltip title="服务可交付的地区"><a-icon type="info-circle" /></a-tooltip> </span></template>
            <a-radio-group v-model="form.deliverArea" @change="areaChange">
              <a-radio value="1">全国范围（默认选择）</a-radio>
              <a-radio value="2">指定交付区域限制</a-radio>
            </a-radio-group>
            <div v-if="form.deliverArea == 2">
              <v-select-tenant
                  v-if="form.deliverArea==='2'"
                  v-model="form.deliverTenantId"
                  :multiple="true"
                  placeholder="请选择交付区域"
                  @areaChange="getArea"
              />
            </div>
          </a-form-model-item>



          <a-form-model-item label="售后支持" prop="afterSalesSupport">
<!--            <v-quill-editor v-model="form.afterSalesSupport" />-->
            <v-editor
                v-model="form.afterSalesSupport"
            />
          </a-form-model-item>



          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button class="btn-red" @click="onSubmit"> 提 交 </a-button>
            <a-button style="margin-left: 10px" @click="resetForm">保 存</a-button>
            <a-button style="margin-left: 10px"  class="btn-color-red" @click="preview">预 览</a-button>
          </a-form-model-item>
        </a-form-model>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VUploadImg from '../components/VUploadImg.vue'
import VQuillEditor from '@/components/VQuillEditor.vue'
import uploadFiles from '@/components/uploadFiles.vue'
import VSelectTenant from "@/components/VSelectTenant";
import tagInput from "@/page/serveManage/compantents/tagInput";
import VMultiUpload from "@/components/VMultiUpload";
import VEditor from '@/components/VEditor'
import {
  getIsvListByKeyWord,
  getServiceMarket,
  getServiceTypeTreeData,
  saveService
} from '@/api/service'

const columns = [
  {
    title: '套餐版本',
    dataIndex: 'comboVersion',
    key: 'comboVersion'
  },
  {
    title: '购买周期',
    dataIndex: 'buyHour',
    key: 'buyHour'
  },
  {
    title: '服务价格',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' }
  },
/*  {
    title: '操作',
    dataIndex: 'operate',
    key: 'operate',
    scopedSlots: { customRender: 'operate' },
  }*/
];

export default {
  components:{
    VUploadImg,
    VQuillEditor,
    uploadFiles,
    VSelectTenant,
    VMultiUpload,
    tagInput,
    VEditor,
  },

  data() {
    return {
      columns,
      treeData: [],
      pricingConfigData:[],
      comboVersionDefault: ['基础版', '高级版', '旗舰版'],
      buyHourDefault: ['1个月', '3个月', '半年', '一年'],
      comDisable:true,
      buyDisable:true,
      areaText:[],
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
      other: "",
      spinning:false,
      serviceTypeName:'',
      form: {
        companyId:'',
        serviceName: null,
        serviceType: null,
        deliverType: null,
        buyType: null,
        pricingType: '1',
        comboVersion: null,
        buyHour: null,
        pricingConfigJson: null,
        serviceIsvId: null,
        summary: null,
        serviceCover: null,
        productDetail: null,
        userGuidesAttmId: null,
        deliverArea: '1',
        deliverTenantId: null,
        afterSalesSupport: null,
        lowestPrice: 0.0,
        isPlatform: null,
        tenantId: null,
        verifyFailedReason: null
      },
      rules: {
        serviceType: [
          {
            required: true,
            message: "请选择服务分类",
            trigger: "change",
          },
        ],
        deliverType: [
          {
            required: true,
            message: "请选择交付方式",
            trigger: "change",
          },
        ],
        buyType: [
          {
            required: true,
            message: "请选择购买方式",
            trigger: "change",
          },
        ],
        pricingType:[
          {
            required: true,
            message: "请选择服务价格",
            trigger: "change",
          },
        ],
        servePrice: [{ required: true, message: '请选择服务价格', trigger: 'change' },],
        summary:[{ required: true, message: '请选择服务规格', trigger: 'change' }],
        serviceCover:[{ required: true, message: '请上传服务图片', trigger: 'change' }],
        deliverArea:[{ required: true, message: '请选择交付区域', trigger: 'change' },],
      },
    };
  },
  watch:{
    buyHourDefault(val){
      console.log(val,'buy-----')
      if(val.length<2){
        this.buyDisable=false
      } else {
        this.buyDisable=true
      }
    },
    comboVersionDefault(val){
      console.log(val,'com-----')
      if(val.length<2){
        this.comDisable=false
      } else {
        this.comDisable=true
      }
    }
  },
  computed:{
    ...mapGetters(["dict",'companyId',"company_status"]),
    id () {
      return this.$route.query.id
    },
  },
  mounted() {
    if(this.company_status!=='3'){
      this.$router.replace('/serveManage')
      this.$message.warning('请先完成企业认证！')
    }


    this.LoadDictData(["DELIVER_TYPE"]);
    this.LoadDictData(["BUY_TYPE"]);
    this.initServiceTypeData();
    this.initPricingConfigData();
    this.initData();
  },
  methods: {
    ...mapActions(["LoadDictData"]),

    initData () {
      if (this.id) {
        getServiceMarket(this.id).then(res => {
          this.form = res
          this.form.deliverTenantId = this.form.deliverTenantId ? this.form.deliverTenantId.split(',') : []
          this.comboVersionDefault = this.form.comboVersion ? this.form.comboVersion.split(',') : []
          this.buyHourDefault = this.form.buyHour ? this.form.buyHour.split(',') : []
          this.pricingConfigData = JSON.parse(this.form.pricingConfigJson)
          this.serviceTypeName=this.form.serviceTypeName
        })
      }
      if (this.type === '3') {
        this.disabled = true
      }

      this.initIsvData(this.form.serviceIsvId === null ? '' : this.form.serviceIsvId)
    },

    initServiceTypeData: function () {
      getServiceTypeTreeData().then(res => {
        this.treeData = res
      })
    },

    initPricingConfigData () {
      const oldPricingConfigData = this.pricingConfigData
      this.pricingConfigData = []
      let index = 0
      this.comboVersionDefault.map(comboVersion => {
        this.buyHourDefault.map(buyHour => {
          const oldPricing = oldPricingConfigData.filter(e => e.comboVersion === comboVersion && e.buyHour === buyHour)[0]
          const data = {
            key: index++,
            comboVersion: comboVersion,
            buyHour: buyHour,
            price: oldPricing !== undefined ? oldPricing.price : null
          }
          this.pricingConfigData.push(data)
        })
      })
    },

    initIsvData (value) {
      getIsvListByKeyWord(value).then(res => {
        this.isvData = res
      })
    },



    removeComboVersion (removedTag) {
      this.comboVersionDefault = this.comboVersionDefault.filter(tag => tag !== removedTag)
      this.initPricingConfigData()
    },

    removeBuyHour (removedTag) {

      this.buyHourDefault = this.buyHourDefault.filter(tag => tag !== removedTag)
      this.initPricingConfigData()
    },

    getComTage(val){
      if (this.comboVersionDefault.findIndex(e => e === val) !== -1) {
        this.$message.warning('套餐版本名不允许重复！')
        return false
      }
      this.$set(this.comboVersionDefault,this.comboVersionDefault.length,val)
      this.initPricingConfigData()
    },

    getBuyTage(val){
      if (this.buyHourDefault.findIndex(e => e === val) !== -1) {
        this.$message.warning('购买时长名不允许重复！')
        return false
      }

      this.$set(this.buyHourDefault,this.buyHourDefault.length,val)
      this.initPricingConfigData()
    },

    priceChange(record){
      this.pricingConfigData.splice(record.key,1)
    },

    onChange(val,lab,x){
      this.serviceTypeName=lab[0]
    },

    getArea(val,lab,x){
      this.areaText=[]
      lab.forEach(item=>{
        this.areaText.push(item.componentOptions.children[0].text)
      })
    },

    //交付区域类型变更
    areaChange(){
      this.areaText=[]
      this.form.deliverTenantId=null
    },


    doSave (publishStatus) {
      const { form } = this

      if (form.serviceName === null) {
        this.$message.warning('服务名称不允许为空！')
        return false
      }

      if(publishStatus === '4'){

      this.$refs.ruleForm.validate(valid => {

        if (!valid) {
          form.pricingConfigJson = null
          return false
        }

        if (form.deliverArea === '2' && !form.deliverTenantId) {
          this.$message.warning('请选择指定交付区域！')
          return false
        }

        this.dataProcess(publishStatus)

      })

      }else {

        this.dataProcess(publishStatus)
      }
    },

    dataProcess(publishStatus){
      const { form } = this
      if(form.pricingType === '1'){
        form.pricingConfigJson = JSON.stringify(this.pricingConfigData)
        form.lowestPrice = this.pricingConfigData[0].price
        let count = 0
        this.pricingConfigData.map(pricingConfig => {
          if (!pricingConfig.price && pricingConfig.price !== 0) {
            count++
          } else {
            form.lowestPrice = form.lowestPrice < pricingConfig.price ? form.lowestPrice : pricingConfig.price
          }
        })

        if (count > 0  && publishStatus ==='4') {
          this.$message.warning('服务规格不能为空，请完善服务规格！')
          return false
        }
        this.spinning=true

        form.comboVersion = this.comboVersionDefault.join(',')
        form.buyHour = this.buyHourDefault.join(',')
      }else {
        form.comboVersion = null
        form.buyHour = null
        form.pricingConfigJson = null
        form.lowestPrice = null
      }
      form.companyId=this.companyId
      form.deliverTenantId = form.deliverArea === '1' ? null : form.deliverTenantId.join(',')

      saveService({
        ...form,
        publishStatus
      }).then(() => {
        this.$message.success('操作成功！')
        this.$router.push('/serveManage')
        this.spinning=false
      })
    },


    onSubmit() {
      this.doSave('4')
    },

    resetForm() {
      this.doSave('1')
    },

    //预览
    preview(){

      const { form } = this

      this.$refs.ruleForm.validate(valid=>{
        form.pricingConfigJson = JSON.stringify(this.pricingConfigData)
        if (!valid) {
          form.pricingConfigJson = null
          return false
        }

        if(form.pricingType === '1'){
        form.lowestPrice = this.pricingConfigData[0].price
        let count = 0
        this.pricingConfigData.map(pricingConfig => {
          if (!pricingConfig.price && pricingConfig.price !== 0) {
            count++
          } else {
            form.lowestPrice = form.lowestPrice < pricingConfig.price ? form.lowestPrice : pricingConfig.price
          }
        })
        if (count > 0 && form.pricingType==='1') {
          this.$message.warning('服务价格不能为空，请完善服务价格！')
          return false
        }
        form.comboVersion = this.comboVersionDefault.join(',')
        form.buyHour = this.buyHourDefault.join(',')
        }else {
          form.comboVersion = null
          form.buyHour = null
          form.pricingConfigJson = null
        }

        form.serviceTypeName=this.serviceTypeName
        form.areaText=this.areaText


        localStorage.setItem('service_preview',JSON.stringify(form))


        let routeUrl=this.$router.resolve({
          path: '/serveManage/details',
        })

        window.open(routeUrl.href, '_blank');

      })


    }
  },
};
</script>

<style lang="scss" scoped>
.release-serve {
  padding-top: 16px;
  .release-serve-box {
    margin-top: 16px;
    background: #fff;
    padding: 24px 32px;
    border-radius: 2px;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 32px;
    }
    .serve-model-border{
      height: 40px;
      border-bottom: 1px solid #E8E8E8;
    }
    .serve-model-box{
      >div{
        margin-top: 24px;
        position: relative;
        >span{
          position: absolute;
          left: -160px;
          font-size: 14px;
          color: #262626;
          line-height: 24px;
        }
      }
      .serve-model-set{
        padding: 0 12px 12px;
        border-radius: 2px;
        border: 1px solid #E8E8E8;
        >div{
          >div{
            display: flex;
            flex-wrap: wrap;
            padding: 16px 12px 0;
            >span{
              margin-bottom: 16px;
              min-width: 74px;
              height: 32px;
              border-radius: 2px;
              border: 1px solid #D9D9D9;
              padding: 0 16px;
              line-height: 30px;
              text-align: center;
              font-size: 14px;
              color: #595959;
              margin-right: 12px;
            }
            >i{
              line-height: 32px;
              color: #EA0B06;
              cursor: pointer;
            }
          }
          >p{
            padding: 12px;
            display: flex;
            background: #FAFAFA;
          }
        }
      }
    }
  }
}
.serveType::v-deep{

    label::before{
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      line-height: 1;
      content: '*';
    }


}

.serve-img-text{
  font-size: 14px;
  color: #8C8C8C;
  line-height: 22px;
}
</style>
