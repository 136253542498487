<template>
  <div class="user-home-focus">
    <div class="user-home-focus-title">
        <p>我的关注</p>
        <span @click="$router.push('/interactive/userCollect')">更多</span>
    </div>
    <div class="user-home-focus-box">
        <div class="user-home-focus-tag">
            <span :class="{'active':el.itemValue == focusTagIndex}" v-for="el in dict.PRODUCT_TYPE" :key="el.itemValue" @click="focusTagIndex = el.itemValue">{{el.itemText}}</span>
        </div>
        <div class="user-home-focus-list" v-if="list != ''">
            <div v-for="el in list" :key="el.recordId" @click="productInfo(el.recordId,el.domain)">
                <span>
                    <v-img :attachment-id="el.coverAttmId" :default-img="require('@/assets/img/kctp.png')" />
                </span>
                <div>
                    <span>{{el.productName}}</span>
                    <p>超低照度、图像逼真柔和、清晰透彻、亮度等级高，超低照度、图像逼真柔和、清晰透彻、亮度等级高</p>
                </div>
            </div>
        </div>
        <a-empty v-else/>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import {mapGetters} from "vuex";
import { getFocusList } from "@/api/collect";
export default {
    components:{
        VImg
    },
    data(){
        return{
            focusTagIndex:'2',
            page:{
                current: 1,
                size: 4,
            },
            list:[]
        }
    },
    computed:mapGetters(["dict"]),
    watch:{
        focusTagIndex:function(){
            this.loadData()
        }
    },
    mounted(){
        this.loadData()
    },
    methods:{
        loadData(){
            getFocusList(this.page,{focusType:this.focusTagIndex}).then((res)=>{
                this.list = res.records
            })
        },
        productInfo(id,domain){
            let {focusTagIndex} = this
            let type = ''
            if(focusTagIndex == '6'){
                window.open(`${domain}/service/details?id=${id}`,'_blank')
            }else if(focusTagIndex == '5'){
                window.open(`${domain}/replace/info?id=${id}`,'_blank')
            }else{
                if(focusTagIndex == '2'){
                    type = 'soc'
                }else if(focusTagIndex == '3'){
                    type = 'dev'
                }else if(focusTagIndex == '4'){
                    type = 'os'
                }
                window.open(`${domain}/replace/productInfo/${type}?id=${id}`,'_blank')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.user-home-focus{
    margin-top: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #E8E8E8;
    .user-home-focus-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        >p{
            font-size: 16px;
            color: #262626;
            line-height: 24px;
        }
        >span{
            font-size: 14px;
            color: #EA0B06;
            cursor: pointer;
        }
    }
    .user-home-focus-box{
        .user-home-focus-tag{
            display: flex;
            margin-bottom: 32px;
            >span{
                font-size: 14px;
                color: #262626;
                line-height: 22px;
                cursor: pointer;
                margin-right: 24px;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -8px;
                    background: transparent;
                    height: 2px;
                }
                &.active{
                    color: #EA0B06;
                    &::after{
                        background: #EA0B06;
                    }
                }
            }
        }
        .user-home-focus-list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            >div{
                display: flex;
                width: 483px;
                padding: 20px 16px;
                cursor: pointer;
                margin-bottom: 16px;
                &:hover{
                    background: url(../../../assets/img/gzxz.png) no-repeat;
                    background-size: 100% 100%;
                }
                >span{
                    width: 68px;
                    height: 68px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 16px;
                    >img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                >div{
                    flex: 1;
                    >span{
                        font-size: 14px;
                        display: block;
                        color: #262626;
                        line-height: 24px;
                        margin-bottom: 8px;
                    }
                    >p{
                        font-size: 12px;
                        color: #595959;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
</style>
