<template>
  <a-modal v-model="visibleShow" title="转让企业" width="480px" @cancel="cancel">
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
    >
        <a-form-model-item ref="name" label="被转让者" prop="name">
            <a-select v-model="form.name" placeholder="请选择被转让者姓名">
              <a-select-option v-for="el in list" :key="el.id" :value="el.id">{{el.realName}}</a-select-option>
            </a-select>
        </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button @click="resetForm">取 消</a-button>
      <a-button class="btn-red" @click="onSubmit">确 定</a-button>
    </template>
  </a-modal>
</template>

<script>
import {mapGetters,mapActions } from "vuex";
import { transferCompany,getCompanyOwner } from "@/api/enterpriseManage";
export default {
  props: ["visible","info"],
  data() {
    return {
      visibleShow: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        name: undefined,
      },
      rules: {
        name: [{ required: true, message: '请填写被转让者姓名', trigger: 'change' },],
      },
      list:[]
    };
  },
  computed: mapGetters(["companyId"]),
  watch: {
    visible: function (val) {
      this.visibleShow = val;

    },
  },
  mounted(){
    this.visibleShow = this.visible;
    this.loadCompanyOwner()
  },
  methods:{
    loadCompanyOwner(){
      let data = {
        companyId:this.companyId,
        oldOwnerBy:this.info.appliedBy
      }
      getCompanyOwner(data).then((res)=>{
        this.list = res
      })
    },
    cancel() {
      this.$emit("input", false);
    },
    onSubmit() {
      let _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = {
            companyId:this.companyId,
            newOwnerBy:this.form.name,
            oldOwnerBy:this.info.appliedBy
          }
          transferCompany(data).then((res)=>{
            console.log(res)
            _this.$message.success('修改成功');
            _this.$refs.ruleForm.resetFields();
            _this.$emit("input", false);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$emit("input", false);
    },
  }
};
</script>

<style>

</style>