<template>
  <div>
  <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
    <a-breadcrumb-item>场景管理</a-breadcrumb-item>
    <a-breadcrumb-item>我的场景</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="my-scenes-body">

    <div>
      <p>我的场景</p>
      <div class="my-scenes-filter-box">
        <div class="my-scenes-filter-tab">
          <div class="my-scenes-filter-tab-list">
            <div
              :class="{ active: !condition.sceneStatus }"
              @click="condition.sceneStatus = ''"
            >
              全部
            </div>
            <div
              v-for="(item, index) in statuses"
              :key="item"
              :class="{ active: condition.sceneStatus === item }"
              @click="condition.sceneStatus = item"
              :style="{ left: -(index + 1) + 'px' }"
            >
              {{ item | dict("SCENE_STATUS") }}
            </div>
          </div>
        </div>
        <div class="my-scenes-filter-btn">
          <a-input-search
            placeholder="请输入场景名称搜索"
            allowClear
            v-model="condition.title"
            style="width: 272px; margin-right: 10px"
            @search="searchScenes"
          />
          <a-button v-if="tenantId" class="btn-red" @click="handleAdd">场景申报 </a-button>
        </div>
      </div>
      <div class="my-scenes-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="pages"
          :loading="loading"
          @change="tablePage"
          :rowKey="(record) => record.id"
        >
          <div slot="fundAmount" slot-scope="text">
            {{ (text && `￥${text}`) || "--" }}
          </div>
          <div slot="field" slot-scope="text">
            {{ text | dict("SCENE_FIELD") }}
          </div>
          <div slot="industry" slot-scope="text">
            {{ text | dict("SCENE_INDUSTRY") }}
          </div>
          <div slot="skill" slot-scope="text">
            {{ text | dict("SCENE_SKILL") }}
          </div>
          <div slot="sceneStatus" slot-scope="text, record">
            <span class="my-scenes-status">
              <i v-if="record.sceneStatus === '2'" class="yus"></i>
              <i v-else-if="record.sceneStatus === '4'" class="yans"></i>
              <!--              <i v-else-if="record.sceneStatus == '待发榜'" class="fb"></i>-->
              <i v-else-if="record.sceneStatus === '3'" class="btg"></i>
              <i v-else-if="record.sceneStatus === '5'" class="btg"></i>
              <i v-else></i>
              {{ record.sceneStatus | dict("SCENE_STATUS") }}
            </span>
          </div>
          <div slot="operating" slot-scope="text, record">
            <div class="my-scenes-operating">
              <span @click="handleDetail(record)">详情</span>
              <template v-if="record.sceneStatus === '1'">
                <span @click="handleEdit(record)">编辑</span>
                <span @click="scenesDel(record.id)">删除</span>
              </template>
            </div>
          </div>
        </a-table>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getOwnCase, sceneDelete } from "@/api/cjkf/scn-scene";
import { getIsCreater } from "@/api/res-company";
const columns = [
  {
    title: "场景信息",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    width: 260,
  },
  {
    title: "场景领域",
    dataIndex: "field",
    key: "field",
    scopedSlots: { customRender: "field" },
    ellipsis: true,
  },
  {
    title: "应用行业",
    dataIndex: "industry",
    key: "industry",
    scopedSlots: { customRender: "industry" },
    ellipsis: true,
  },
  {
    title: "类别",
    dataIndex: "skill",
    key: "skill",
    scopedSlots: { customRender: "skill" },
  },
  {
    title: "预估成本(万元)",
    dataIndex: "fundAmount",
    key: "fundAmount",
    scopedSlots: { customRender: "fundAmount" },
  },
  {
    title: '创建人',
    dataIndex: 'creatorName',
    key: 'creatorName',
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    key: "updateTime",
    width: 190,
  },
  {
    title: "状态",
    dataIndex: "sceneStatus",
    key: "sceneStatus",
    scopedSlots: { customRender: "sceneStatus" },
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    width: 160,
    scopedSlots: { customRender: "operating" },
  },
];
export default {
  data() {
    return {
      condition: {
        sceneStatus: "",
        title: "",
      },
      loading: true,
      columns,
      pages: {
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      statuses: ["1", "2", "3", "4", "5"],
      list: [],
      page: {
        current: 1,
        size: 10,
      },
      isCreater: false,
    };
  },
  mounted() {
    this.loadgetIsCreater();
    this.loadData();
    this.LoadDictData(["SCENE_FIELD", "SCENE_INDUSTRY", "SCENE_SKILL"]);
  },
  computed: mapGetters([
    "userInfo",
    "dict",
    "companyId",
    "tenantId",
    "company_status",
  ]),
  watch: {
    "condition.sceneStatus"() {
      this.loadData();
    },
  },
  methods: {
    ...mapActions(["LoadDictData"]),

    loadgetIsCreater() {
      this.loading = true;
      getIsCreater(this.companyId).then((res) => {
        this.isCreater = res;
        this.loading = false;
      });
    },


    noUrl() {
      this.$message.warning("暂无跳转链接！");
    },
    loadData() {
      this.loading = true;
      this.condition.currentTenantId = this.tenantId;
      this.condition.companyId = this.companyId;
      getOwnCase(this.page, this.condition).then((res) => {
        this.list = res.records;
        this.pages.total = res.total;
        this.loading = false;
      });
    },
    searchScenes() {
      this.page={
        current: 1,
        size: 10,
      },
      this.loadData();
    },
    tablePage(page) {
      this.page.current = page.current;
      this.loadData();
    },
    scenesDel(id) {
      let _this = this;
      this.$confirm({
        title: "您确定删除吗?删除后不可恢复！",
        onOk() {
          sceneDelete(id).then(() => {
            _this.condition.sceneStatus = "";
            _this.condition.title = "";
            _this.page.current = 1;
            _this.loadData();
          });
        },
        onCancel() {},
      });
    },
    handleAdd() {
      let _this = this;
      if (this.company_status !== "3") {
        this.$confirm({
          title: "提示",
          class: "declare-modal",
          okText: '企业认证',
          content: (h) => <div>请查看企业认证是否通过</div>,
          onOk() {
            _this.$router.push(`/enterpriseManage/verify`);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        return;
      }
      this.$router.push(`/sceneManage/addScene`);
    },
    handleEdit(record) {
      let _this = this;
      if (this.company_status !== "3") {
        this.$confirm({
          title: "提示",
          class: "declare-modal",
          content: (h) => <div>请查看企业认证是否通过</div>,
          onOk() {
            _this.$router.push(`/enterpriseManage/verify`);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        return;
      }
      this.$router.push(`/sceneManage/addScene/?id=${record.id}`);
    },
    handleDetail(record) {
      this.$router.push(`/sceneManage/sceneInfo/?id=${record.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-scenes-body {
  height: 100%;
  padding-top: 16px;
  > div {
    background: #fff;
    height: 100%;
    padding: 24px 32px;
    > p {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
    }
  }
  .my-scenes-head-box {
    display: flex;

    > div {
      position: relative;
      width: 280px;
      height: 80px;
      margin-right: 16px;

      > img {
        width: 100%;
        height: 100%;
      }

      > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          > p {
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.24);
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 8px;
            color: #fff;
          }

          > span {
            font-size: 12px;
            display: block;
            line-height: 12px;
            color: #fff;
          }
        }

        > span {
          height: 24px;
          border: 1px solid #ffffff;
          line-height: 22px;
          padding: 0 10px;
          color: #fff;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .my-scenes-filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .my-scenes-filter-tab {
      display: flex;
      align-items: center;

      .my-scenes-filter-tab-list {
        display: flex;

        > div {
          width: 96px;
          height: 32px;
          background: #fff;
          border: 1px solid #d9d9d9;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          position: relative;
          &.active {
            z-index: 2;
            color: #ea0b06;
            border-color: #ea0b06;
          }
        }
      }

      .my-scenes-filter-search {
        margin-left: 24px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        overflow: hidden;
      }
    }

    .my-scenes-filter-btn {
      display: flex;
      align-items: center;

      > button {
        margin-right: 16px;
      }

      > i {
        color: #8c8c8c;
      }
    }
  }

  .my-scenes-list {
    background: #fff;
    margin-top: 24px;

    .my-scenes-status {
      display: flex;
      align-items: center;

      > i {
        width: 6px;
        height: 6px;
        background: #bfbfbf;
        border-radius: 50%;
        margin-right: 8px;

        &.yus {
          background: #faad14;
        }

        &.yans {
          background: #52c41a;
        }

        &.fb {
          background: #1890ff;
        }

        &.btg {
          background: #ff4d4f;
        }
      }
    }

    .my-scenes-operating {
      display: flex;

      > span {
        cursor: pointer;
        color: #ea0b06;
        margin-right: 20px;
        white-space: nowrap;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
<style>
.my-scenes-filter-search input {
  width: 280px !important;
  height: 32px;
  background: #ffffff;
  border: none;
  border-radius: 2px;
}

.my-scenes-filter-search button {
  background: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
  color: #8c8c8c !important;
  box-shadow: none;
  border-radius: 0;
}

.my-scenes-list th,
.my-scenes-list td {
  border-color: #f0f0f0 !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}
.declare-modal .ant-btn-primary {
  background-color: #ea0b06;
  border-color: #ea0b06;
  color: #fff !important;
}
</style>
