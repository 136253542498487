<template>
  <div class="user-interactive-box">
    <div class="user-interactive-content-box">
      <div class="user-interactive-filter">
        <div>
          <a-input-search
            style="width: 320px"
            placeholder="请输入关键词搜索"
            v-model="activityNameVal"
            @search="onSearch"
          >
            <a-button slot="enterButton"> <a-icon type="search" /> </a-button>
          </a-input-search>
        </div>
      </div>
      <div class="user-interactive-list-box">
        <div class="user-interactive-list" v-for="(item,index) in interactiveList" :key="index" @click="interactiveInfo(item.activityId)">
          <div class="user-interactive-list-img">
<!--            <span class="bg1" v-if="item.actActivity.processStatus == 2">报名进行中</span>
            <span class="bg2" v-if="item.actActivity.processStatus == 3">报名已结束</span>-->
            <v-img :attachment-id="item.coverUrl" :default-img="require('@/assets/img/kctp.png')" />
          </div>
          <div class="user-interactive-list-info">
            <p>{{item.conferenceName}}</p>
            <span>活动时间：{{item.applyTime}}</span>
            <span v-if="item.address">活动地点：{{item.address}}</span>
<!--            <div>
              <span>{{item.actActivity.enrollTotal || '0'}}人报名</span>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <pagination :total="page.total" :current="page.current" @changes="pageChange"/>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import pagination from '../components/pagination.vue'
import { getActivity } from "@/api/activity";
import { mapGetters } from "vuex";
export default {
  components: {
    VImg,
    pagination
  },
  computed: mapGetters(["userInfo"]),
  data() {
    return {
      interactiveList:[],
      activityNameVal:'',
      page:{
        current:1,
        size:10,
      }
    };
  },
  mounted(){
    this.loadGetActivity()
  },
  methods: {
    // 活动列表
    loadGetActivity(){
      let {page,activityNameVal} = this
      let data = {
        keyword:activityNameVal,
        phone: this.userInfo.phone,
      }
      getActivity(page,data).then((res)=>{
        this.interactiveList = res.records
        this.page.total = res.total
      })
    },
    pageChange(val){
      this.page.size = val.pageSize
      this.page.current = val.current
      this.loadGetActivity()
    },
    // 搜索
    onSearch() {
      this.page.current = 1
      this.loadGetActivity()
    },
    // 详情
    interactiveInfo(id){
      /*this.$router.push({
        path: "/activity/activityInfo",
        query: { id },
      });*/
      console.log(
        process.env.VUE_APP_MAINSITE_URL,
        "process.env.VUE_APP_MAINSITE_URL-----"
      );
      window.open(`${process.env.VUE_APP_MAINSITE_PREFIX}/activity/activityInfo?id=${id}`,'_blank')
    }
  },
};
</script>

<style lang="scss" scoped>
.user-interactive-box{
  height: 100%;
  .user-interactive-content-box{
    height: calc(100% - 42px);
    overflow-y: auto;
    margin-bottom: 10px;
  }
}
.user-interactive-filter {
  display: flex;
  > div {
    margin-right: 24px;
  }
}
.user-interactive-list-box {
  display: flex;
  flex-wrap: wrap;
  .user-interactive-list {
    width: 19.5%;
    margin: 16px 0.625% 0 0;
    transition: all 0.3s;
    background: #FFFFFF;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
    &:nth-child(5n) {
      margin-right: 0;
    }
    &:hover{
        transform: translateY(-4px);
        box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.08);
    }
    .user-interactive-list-img {
      position: relative;
      height: 175px;
      > span {
        position: absolute;
        top: 8px;
        left: 0;
        width: 88px;
        height: 24px;
        color: #fff;
        line-height: 24px;
        text-align: center;
        &.bg1{
          background: url(../../assets/img/hdicon.png) no-repeat;
          background-size: 100% 100%;
        }
        &.bg2{
          background: url(../../assets/img/hdicon2.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .user-interactive-list-info{
        padding: 24px;
        >p{
            font-size: 16px;
            line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
        }
        >span{
            line-height: 11px;
            font-size: 12px;
            color: #8C8C8C;
            display: block;
            margin-top: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        >div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            >span{
                line-height: 11px;
                font-size: 12px;
                color: #BFBFBF;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            >p{
                display: flex;
                >img{
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    position: relative;
                }
            }
        }
    }
  }
}
</style>
