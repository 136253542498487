<template>
  <a-modal
    v-model="visible"
    width="640px"
    wrap-class-name="edit-email-modal"
    title="更改邮箱"
    @cancel="visible = false"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="旧邮箱">
        <div>
          <a-input style="width: 100%" :value="email" disabled />
        </div>
      </a-form-model-item>
      <a-form-model-item label="验证码" prop="code">
        <a-row :gutter="16">
          <a-col :span="16">
            <a-input
              v-model="form.code"
              style="width: 100%"
              placeholder="请输入邮箱验证码"
            />
          </a-col>
          <a-col :span="8">
            <v-count-down
              class="verify-code"
              :disabled="!userInfo.email"
              :trigger="() => sendEmailCode(userInfo.email)"
            />
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="新邮箱" prop="newEmail">
        <a-input v-model="form.newEmail" placeholder="请输入新邮箱" />
      </a-form-model-item>
      <a-form-model-item label="验证码" prop="newCode">
        <a-row :gutter="16">
          <a-col :span="16">
            <a-input
              v-model="form.newCode"
              style="width: 100%"
              placeholder="请输入邮箱验证码"
            />
          </a-col>
          <a-col :span="8">
            <v-count-down
              class="verify-code"
              :disabled="!form.newEmail"
              :trigger="() => sendEmailCode(form.newEmail)"
            />
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <template #footer>
      <a-button @click="visible = false">取 消</a-button>
      <a-button @click="handleSubmit" class="btn-red">确 定</a-button>
    </template>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VCountDown from "@/components/VCountDown";
import { sendEmail } from "@/api/common";
import { editEmail } from "@/api/res-user";

export default {
  components: { VCountDown },
  data() {
    return {
      visible: false,
      form: {
        code: "",
        newEmail: "",
        newCode: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      rules: {
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        newEmail: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        newCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    email() {
      return this.$options.filters.capitalize(this.userInfo.email);
    },
  },
  methods: {
    ...mapActions(["GetInfo"]),
    open() {
      this.visible = true;
    },
    sendEmailCode(email) {
      sendEmail(email).then((code) => {
        this.$notification.success({
          message: "提示",
          description: `您的验证码为:${code}`,
        });
      });
    },
    handleSubmit() {
      const { code, newEmail, newCode } = this.form;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          editEmail({ code, newEmail, newCode }).then(() => {
            this.$message.info("修改成功！");
            this.GetInfo();
            this.visible = false;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  color: #EA0B06;
  cursor: pointer;
}

.verify-code {
  display: inline-block;
  height: 32px;
  background: #ffffff;
  border: 1px solid #EA0B06;
  border-radius: 2px;
  font-size: 14px;
  color: #EA0B06;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
</style>
