<template>
  <div>
    <div class="my-demand-body">
      <div>
        <div class="my-demand-filter-box">
          <div class="my-demand-filter-tab">
            <div class="my-demand-filter-tab-list">
              <div :class="{ active: bidStatus == '' }" @click="bidStatus = ''">
                全部
              </div>
              <div
                v-for="(item, index) in dict.DEMAND_BID_STATUS"
                :key="item.itemValue"
                :class="{ active: bidStatus == item.itemValue }"
                @click="bidStatus = item.itemValue"
                :style="{ left: -(index + 1) + 'px' }"
              >
                {{ item.itemText }}
              </div>
            </div>
          </div>
        </div>
        <div class="my-demand-bid-list">
          <a-table
            :columns="columns"
            :data-source="demandData"
            :pagination="pagination"
            @change="pageChange"
            :loading="loading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="bidStatus" slot-scope="text">
              <p>{{ text | dict("DEMAND_BID_STATUS") }}</p>
            </template>

            <div slot="operating" slot-scope="text, record">
              <div class="my-demand-operating">
                <template>
                  <span
                    @click="
                      demandDetail(record.demandTenantId, record.demandId)
                    "
                    >详情</span
                  >
                </template>
              </div>
            </div>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getMyDemandBidList } from "@/api/dmdDemand";
import { getTenantInfo } from "@/api/common";

const columns = [
  {
    title: "需求名称",
    dataIndex: "demandName",
  },
  {
    title: "需求单位",
    dataIndex: "demandCompanyName",
    scopedSlots: { customRender: "demandCompanyName" },
  },
  {
    title: "方案报价",
    dataIndex: "bidPrice",
    scopedSlots: { customRender: "bidPrice" },
  },
  {
    title: "申请人",
    dataIndex: "bidCreatorName",
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
  },
  {
    title: "状态",
    dataIndex: "bidStatus",
    scopedSlots: { customRender: "bidStatus" },
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    width: 160,
    scopedSlots: { customRender: "operating" },
  },
];

export default {
  name: "MyDemandBid",
  data() {
    return {
      columns,
      bidStatus: "",
      demandData: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      loading: true,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["dict", "companyId", "tenantId"]),
  },
  watch: {
    bidStatus: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.LoadDictData(["DEMAND_CATALOGUE"]);
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadData() {
      let { page } = this;
      let data = {
        bidStatus: this.bidStatus,
        bidCompanyId: this.companyId,
        tenantId: this.tenantId,
      };
      this.loading = true;
      // pageListByme(page, data).then((res) => {
      //   this.demandData = res.records;
      //   this.$set(this.pagination, "total", res.total);
      //   this.loading = false;
      // });

      getMyDemandBidList(page, data).then((res) => {
        this.demandData = res.records;
        this.$set(this.pagination, " ", res.total);
        this.loading = false;
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadData();
    },

    demandDetail(tenantId, demandId) {
      getTenantInfo({ id: tenantId }).then((res) => {
        window.open(`${res.domain}/demand/detail?id=${demandId}`);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-demand-body {
  padding-top: 16px;
  height: 100%;
  > div {
    background: #fff;
    padding: 24px 32px;
    min-height: 100%;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }

  .my-demand-filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .my-demand-filter-tab {
      display: flex;
      align-items: center;

      .my-demand-filter-tab-list {
        display: flex;

        > div {
          width: 96px;
          height: 32px;
          background: #fff;
          border: 1px solid #d9d9d9;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          transition: all 0.3s;
          position: relative;

          &.active {
            color: #ea0b06;
            background: rgba(234, 11, 6, 0.05);
            border-color: #ea0b06;
            z-index: 2;
          }
        }
      }
    }

    .my-demand-filter-btn {
      display: flex;
      align-items: center;
    }
  }

  .my-demand-bid-list {
    margin-top: 24px;

    .my-demand-status {
      display: flex;
      align-items: center;

      > i {
        width: 6px;
        height: 6px;
        background: #bfbfbf;
        border-radius: 50%;
        margin-right: 8px;

        &.yus {
          background: #faad14;
        }

        &.yans {
          background: #52c41a;
        }

        &.fb {
          background: #1890ff;
        }

        &.btg {
          background: #ff4d4f;
        }
      }
    }

    .my-demand-operating {
      display: flex;

      > span {
        cursor: pointer;
        color: #ea0b06;
        margin-right: 20px;
        white-space: nowrap;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
<style>
.my-demand-bid-list th,
.my-demand-bid-list td {
  border-color: #f0f0f0 !important;
}
</style>
