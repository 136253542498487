<template>
  <a-modal
    :visible.sync="visible"
    title="修改截止时间"
    width="900px"
    @cancel="handleCancel"
    @ok="saveChangeDemandEndTime"
  >
    <a-form-model :model="info" label-align="left">
      <a-form-model-item label="报名截止时间" prop="demandEndTime">
        <v-date-picker-after v-model="info.demandEndTime" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getDemandDetail, changeDemandEndTime } from "@/api/dmdDemand";
import VDatePickerAfter from "@/components/VDatePickerAfter";

export default {
  name: "ChangeDemandEndTime",
  data() {
    return {
      visible: false,
      info: {
        id: null,
        demandEndTime: null,
      },
    };
  },
  components: { VDatePickerAfter },
  methods: {
    async open(id) {
      if (id) {
        this.visible = true;
        this.info = await getDemandDetail(id);
      }
    },
    handleCancel() {
      this.visible = false;
    },
    saveChangeDemandEndTime() {
      if (this.info.id && this.info.demandEndTime) {
        let data = { id: this.info.id, demandEndTime: this.info.demandEndTime };
        changeDemandEndTime(data).then((res) => {
          this.visible = false;
          this.$emit("refreshData", false);
        });
      }
    },
  },
};
</script>
