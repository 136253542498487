<template>
  <div class="user-scenes-info">
    <div class="user-scenes-info-head">
      <span @click="$router.go(-1)"><a-icon type="left" /> 返回上一页 / 订单详情</span>

    </div>
    <div class="user-scenes-info-step">
      <template v-if="orderInfo.orderStatus != 5">
        <a-steps :current="orderInfo.orderStatus - 1">
          <a-step title="需求提交" />
          <a-step title="客服审核" />
          <a-step title="方案制作" />
          <a-step title="方案完成" />
        </a-steps>
      </template>
      <template v-if="orderInfo.orderStatus == 5">
        <a-steps :current="1" status="error" style="width:500px;margin: 0 auto;">
          <a-step title="需求提交" />
          <a-step title="审核不通过" />
        </a-steps>
      </template>
    </div>
    <div class="user-scenes-info-content">
      <div class="user-scenes-info-desc">
          <p>企业联系信息</p>
          <div>
              <p><icon-font type="icon-qiyeguanli" />需求单位：{{orderInfo.companyName}}</p>
              <!-- <p><icon-font type="icon-shilileixing" />企业类型：<template v-for="item in orderInfo.companyType">{{dict.COMPANY_TYPE[item].itemText}} </template></p> -->
               <p><icon-font type="icon-fuzeren" />联系人：{{orderInfo.contactPerson}}</p>
              <p><icon-font type="icon-31dianhua" />联系电话：{{orderInfo.contactPhone}}</p>
              <p><icon-font type="icon-icon_address" />所在地：{{orderInfo.companyAddress}}</p>
          </div>
      </div>
      <div class="user-scenes-info-list">
        <p>我想迁移适配：硬件产品</p>
        <a-table :columns="columns" :data-source="orderInfo.orderDeviceList" :pagination="false" :rowKey="(record,index)=>{return index}">
          <template slot="soc" slot-scope="text,record">{{text | dict("DEVICE_SOC")}} <template v-if="text == '8'"> - {{record.socOther}}</template></template>
          <template slot="os" slot-scope="text,record">{{text | dict("DEVICE_OS")}} <template v-if="text == '8'"> - {{record.osOther}}</template></template>
          <template slot="deviceAttmIds" slot-scope="text,record">
            <div style="display: flex;">
              <span v-for="el in record.deviceAttmIds" :key="el" class="user-scenes-info-list-img">
                <v-img :attachment-id="el" :default-img="require('@/assets/img/cmrt.png')" />
              </span>
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="user-scenes-info-dynamic">
        <p>进展动态 <a-button  v-if="orderInfo.orderStatus == 4 && orderInfo.finishedAttmIds" @click="downloadAnnex(orderInfo.finishedAttmIds)" class="btn-red">下载迁移方案</a-button></p>
        <div>
          <div v-if="orderInfo.orderStatus == 2">
            <img alt="图片" src="@/assets/img/dddsh.png">
            <p>您的需求已提交，请您耐心等待平台审核</p>
          </div>
          <div v-if="orderInfo.orderStatus == 3">
            <img alt="图片" src="@/assets/img/shtg.png">
            <p>您的需求已通过平台审核</p>
          </div>
          <div v-if="orderInfo.orderStatus == 5">
            <img alt="图片" src="@/assets/img/shjj.png">
            <p>平台拒绝了您的需求，请重新提交</p>
            <p>拒绝原因：{{orderInfo.previewComment}}</p>
          </div>
          <div v-if="orderInfo.orderStatus == 4">
            <img alt="图片" src="@/assets/img/zxwc.png">
            <p>您提交的需求咨询已完成</p>
            <div>

            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    key: 'deviceName',
  },
  {
    title: '设备型号',
    dataIndex: 'deviceModel',
    key: 'deviceModel',
  },
  {
    title: '主控芯片',
    dataIndex: 'soc',
    key: 'soc',
    scopedSlots: { customRender: 'soc' },
  },
  {
    title: '芯片型号',
    key: 'socModel',
    dataIndex: 'socModel',
  },
  {
    title: '操作系统',
    key: 'os',
    dataIndex: 'os',
    scopedSlots: { customRender: 'os' },
  },
  {
    title: '设备及照片',
    key: 'deviceAttmIds',
    scopedSlots: { customRender: 'deviceAttmIds' },
  },
];
import { getOrderInfo } from "@/api/order";
import { getFileDownload } from "@/api/common";
import iconFont from "@/components/iconFont";
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
export default {
  components: {
    iconFont,
    VImg,
  },
  computed: {
    ...mapGetters(["dict"]),
    id:function(){
      return this.$route.query.id;
    }
  },
  data(){
    return{
      orderInfo:'',
      columns,

    }
  },
  mounted(){
    this.loadOrderInfo()
  },
  methods:{
    loadOrderInfo(){
      getOrderInfo(this.id).then((res)=>{
        this.orderInfo = res
      })
    },
    downloadAnnex (val) {
      for (let index = 0; index < val.length; index++) {
        window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${val[index]}`);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-scenes-info {
  min-height: 100%;
  padding: 16px 24px 24px;
  .user-scenes-info-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      cursor: pointer;
      font-size: 14px;
      color: #8c8c8c;
    }
    > div {
      > button {
        &:last-child {
          margin-left: 16px;
        }
      }
    }
  }
  .user-scenes-info-step {
    background: #fff;
    padding: 24px;
    margin: 16px 0;
  }
  .user-scenes-info-content{
      margin-bottom: 16px;
      background: #fff;
      padding: 16px 24px 0;
      .user-scenes-info-desc{
          >p{
            font-size: 16px;
            font-weight: bold;
            line-height: 16px;
          }
          >div{
              display: flex;
              flex-wrap: wrap;
              width: 70%;
              >p{
                  width: 33%;
                  margin-top: 22px;
                  line-height: 20px;
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  >i{
                      margin-right: 8px;
                      color: #939393;
                  }
              }
          }
      }
      .user-scenes-info-list{
        margin-top: 32px;
        border-top: 1px solid #f0f0f0;
        padding-top: 32px;
        >p{
          font-size: 16px;
          font-weight: bold;
          color: #262626;
          line-height: 18px;
          margin-bottom: 24px;
        }
      }
  }
  .user-scenes-info-dynamic{
      background: #fff;
      >p{
        height: 48px;
        border-bottom: 1px solid #f0f0f0;
        padding: 0 24px;
        line-height: 48px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      >div{
        padding: 100px 0;
        display: flex;
        justify-content: center;
        >div{
          text-align: center;
          >p{
            line-height: 12px;
            margin-top: 24px;
          }
        }
      }
  }
}
</style>
<style>
.user-scenes-info-step
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #EA0B06;
}
.user-scenes-info-step .ant-steps-item-finish .ant-steps-item-icon,
.user-scenes-info-step .ant-steps-item-process .ant-steps-item-icon {
  border-color: #EA0B06;
}
.user-scenes-info-step
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #EA0B06;
}
.user-scenes-info-step .ant-steps-item-process .ant-steps-item-icon {
  background: #EA0B06;
}
.user-scenes-info-step .ant-steps-item-title {
  font-size: 14px;
}
.user-scenes-info-list .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
  background: transparent;
}
.user-scenes-info-list-img{
  width: 78px;
  height: 78px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-scenes-info-list-img:last-child{
  margin-right: 0;
}
.user-scenes-info-list-img>img{
  display: block;
  max-width: 100%;
  max-height: 100%;
}
</style>
