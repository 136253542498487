<template>
  <div class="user-interactive">
    <div class="user-interactive-tab">
      <div :class="{'active':interactiveTabIndex == 1}" @click="interactiveTabIndex = 1"><span>我的活动</span></div>
      <div :class="{'active':interactiveTabIndex == 2}" @click="interactiveTabIndex = 2"><span>我的课程</span></div>
    </div>
    <div class="user-interactive-content">
      <interactive v-if="interactiveTabIndex == 1"/>
      <course v-else/>
    </div>
  </div>
</template>

<script>
import interactive from "./interactive.vue";
import course from "./course.vue";
export default {
  components: {
    interactive,
    course
  },
  data(){
    return{
      interactiveTabIndex:1
    }
  }
};
</script>

<style lang="scss" scoped>
.user-interactive {
  height: 100%;
  .user-interactive-tab {
    background: #fff;
    display: flex;
    padding: 0 24px;
    > div {
      height: 56px;
      border-bottom: 2px solid transparent;
      margin-right: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border-color: #EA0B06;
        > span {
          color: #EA0B06;
        }
      }
    }
  }
  .user-interactive-content {
    height: calc(100% - 56px);
    padding: 16px 24px 24px;
    overflow-y: auto;
  }
}
</style>
