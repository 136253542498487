<template>
  <div class="pt-bangdan-body">
    <div class="pt-bangdan-tab-body">
      <router-link
        tag="span"
        v-for="(item, index) in headNav"
        :key="index"
        active-class="active"
        :to="item.url"
        >{{ item.name }}</router-link
      >
    </div>
    <div class="pt-bangdan-content-body">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headNav: [
        {
          name: "我的发榜",
          url: "/userLayout/myBangDan/faBang",
        },
        {
          name: "我的揭榜",
          url: "/userLayout/myBangDan/jieBang",
        },
        {
          name: "我的邀约",
          url: "/userLayout/myBangDan/invite",
        },
      ],
      tabIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.pt-bangdan-body {
  height: 100%;
  background: #f5f5f5;
  .pt-bangdan-tab-body {
    padding: 0 24px;
    background: #fff;
    display: flex;
    > span {
      height: 56px;
      line-height: 56px;
      border-bottom: 2px solid transparent;
      margin-right: 40px;
      cursor: pointer;
      &.active {
        border-color: #EA0B06;
        color: #EA0B06;
      }
    }
  }
  .pt-bangdan-content-body {
    height: calc(100% - 56px);
    padding: 24px;
    overflow-y: auto;
  }
}
</style>
