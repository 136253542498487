<template>
  <div class="user-scene-info">
    <a-breadcrumb class="user-breadcrumb-box">
      <a-breadcrumb-item>
        <router-link to="/demandManage" replace>需求管理</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>详情页</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="user-scene-info-head">
      <div class="user-scene-info-head-content">
        <div class="user-scene-info-head-info">
          <h5>{{ demandDetail.title }}</h5>
          <div>
            <p>
              需求编号：<span>{{ demandDetail.id }}</span>
            </p>
            <p>
              需求名称：<span>{{ demandDetail.name }}</span>
            </p>
            <p>
              预算金额：

              <span>
                {{ demandDetail.fundAmount || "-" }}元
                <span v-if="demandDetail.fundAmountStatus == true">
                  [可议价]
                </span></span
              >
            </p>
            <p>
              实施周期：<span
                >{{ demandDetail.carryOutStartTime }}-{{
                  demandDetail.carryOutEndTime
                }}</span
              >
            </p>
            <p>
              需求类型：<span>{{ demandDetail.demandTypeName }}</span>
            </p>
            <p>
              报名截止时间：<span>{{ demandDetail.demandEndTime }}</span>
            </p>
            <p>
              需求企业：<span>{{ demandDetail.companyName }}</span>
            </p>
            <p>
              联系人：<span>{{ demandDetail.contactPerson }}</span>
            </p>
            <p>
              联系电话：<span>{{ demandDetail.contactPhone }}</span>
            </p>
          </div>
        </div>
        <div class="user-scene-info-head-state">
          <span>状态</span>
          <p>{{ demandDetail.demandStatus | dict("DEMAND_STATUS") }}</p>
        </div>
      </div>

      <div class="user-scene-info-head-tag">
        <span class="active"></span>
      </div>
    </div>

    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="需求说明">
        <div class="user-scene-info-desc" :style="`min-height:${bodyheight}px`">
          <div>
            <h6>需求描述</h6>
            <div v-html="demandDetail.demandDescribe"></div>
          </div>

          <div>
            <h6>需求附件</h6>
            <v-attachment
              :attachment-id="item"
              :key="item"
              v-for="item in demandDetail.applyingAttmIds"
            />
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        tab="驳回理由"
        force-render
        v-if="demandDetail.demandStatus == 3"
      >
        <div class="user-scene-info-desc" :style="`min-height:${bodyheight}px`">
          <div>
            <div v-html="demandDetail.reviewedComment"></div>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { getDemandDetail } from "@/api/dmdDemand";
import { mapGetters } from "vuex";
import VAttachment from "@/components/VAttachment";

export default {
  data() {
    return {
      detailTabIndex: 0,
      demandDetail: {},
      bodyheight: 230,
    };
  },
  computed: {
    ...mapGetters(["dict"]),
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    this.bodyheight = document.body.clientHeight - 390;
  },
  components: {
    VAttachment,
  },
  mounted() {
    this.loadData(this.id);
  },
  methods: {
    async loadData() {
      this.demandDetail = await getDemandDetail(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-scene-info {
  padding-top: 16px;

  .user-scene-info-head {
    margin-top: 16px;
    background: #fff;
    padding: 24px 32px 0;

    .user-scene-info-head-content {
      display: flex;
      justify-content: space-between;

      .user-scene-info-head-info {
        width: 80%;

        > h5 {
          font-size: 20px;
          color: #262626;
          line-height: 28px;
          margin-bottom: 10px;
        }

        > div {
          margin-bottom: 24px;
          display: flex;
          flex-wrap: wrap;

          > p {
            width: 33.3%;
            display: flex;
            font-size: 14px;
            color: #262626;
            line-height: 22px;
            margin-top: 8px;

            > span {
              color: #595959;
            }
          }
        }
      }

      .user-scene-info-head-state {
        > span {
          display: block;
          font-size: 14px;
          color: #8c8c8c;
          line-height: 22px;
          text-align: right;
          margin-bottom: 4px;
        }

        > p {
          font-size: 20px;
          color: #262626;
          line-height: 28px;
          text-align: right;
        }
      }
    }

    .user-scene-info-head-tag {
      display: flex;

      > span {
        border-bottom: 2px solid transparent;
        font-size: 14px;
        color: #262626;
        line-height: 22px;
        padding-bottom: 10px;
        cursor: pointer;

        &.active {
          color: #ea0b06;
          border-color: #ea0b06;
        }
      }
    }
  }

  .user-scene-info-desc {
    margin-top: 24px;
    background: #fff;
    border-radius: 2px;
    padding: 0 32px;

    > div {
      padding: 32px 0;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: 0;
      }

      > h6 {
        font-size: 16px;
        color: #262626;
        line-height: 24px;
        margin-bottom: 16px;
      }

      > p {
        font-size: 14px;
        color: #595959;
        line-height: 22px;
      }
    }
  }
}
</style>
