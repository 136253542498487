<template>
  <div class="layout-body">
    <a-layout id="components-layout-demo-top-side-2">
      <a-layout-header
        class="header"
        style="
          background: #fff;
          padding: 0 24px;
          box-shadow: inset 0px -1px 0px 0px #e8eaec;
        "
      >
        <div class="user-layout-header">
          <div class="logo">
            <img
              :title="(currentTenantInfo || {}).tenantName"
              src="@/assets/img/homelogo.png"
              @click="toPortal"
              style="cursor: pointer"
            />

            <div class="header-home" @click="toHome">
              <img src="@/assets/img/home.png" alt="" />
              <p>控制台</p>
            </div>
          </div>

          <div class="user-layout-header-menu-box">
            <div>
              <a-dropdown
                :trigger="['click']"
                placement="bottomRight"
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode;
                  }
                "
              >
                <template slot="overlay">
                  <div class="user-layout-header-area">
                    <p>区域切换</p>
                    <div>
                      <div
                        :class="{ active: el.id == tenantId }"
                        v-for="el in tenantList"
                        :key="el.id"
                        @click="toggleTenant(el.id, el)"
                      >
                        <img src="@/assets/img/userqyqh.png" />
                        {{ el.tenantName }}
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  @click="(e) => e.preventDefault()"
                  class="user-layout-header-area-text"
                >
                  <div>
                    <img src="@/assets/img/userquxz.png" />
                    <p>{{ currentTenantInfo.tenantName }}</p>
                    <a-icon type="down" />
                  </div>
                </div>
              </a-dropdown>
            </div>
            <div class="user-layout-header-menu">
              <template v-if="companyList != '' && !visible">
                <a-dropdown
                  :trigger="['click']"
                  placement="bottomLeft"
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode;
                    }
                  "
                >
                  <template slot="overlay">
                    <div class="user-layout-header-enterprise">
                      <p>企业切换</p>
                      <div>
                        <div
                          class="company-vody"
                          v-for="(el, index) in companyList"
                          :key="el.id"
                          @click="toggleCompany(el, index)"
                        >
                          <div class="imgLogo" v-if="el.companyLogoAttmId">
                            <v-img :attachment-id="el.companyLogoAttmId" />
                          </div>

                          <span v-if="!el.companyLogoAttmId">{{
                            el.companyName.substring(0, 4)
                          }}</span>
                          <div class="company-box">
                            <span>{{ el.companyName }}</span>
                            <p>
                              <img
                                v-if="el.verifyStatus == 1"
                                src="@/assets/img/userinfo1.png"
                                alt="图片"
                              />
                              <img
                                v-else-if="el.verifyStatus == 2"
                                src="@/assets/img/userinfo4.png"
                                alt="图片"
                              />
                              <img
                                v-else-if="el.verifyStatus == 3"
                                src="@/assets/img/userinfo2.png"
                                alt="图片"
                              />
                              <img
                                v-else-if="el.verifyStatus == 4"
                                src="@/assets/img/userinfo3.png"
                                alt="图片"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <span @click="(e) => e.preventDefault()"
                    >{{ companyList[companyIndex].companyName
                    }}<a-icon type="down"
                  /></span>
                </a-dropdown>
              </template>

              <a-badge :count="msg.msgNum" class="message" @click="message">
                消息中心
              </a-badge>

              <a-dropdown
                :trigger="['click']"
                placement="bottomRight"
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode;
                  }
                "
              >
                <div
                  @click="(e) => e.preventDefault()"
                  class="layout-header-user-info"
                >
                  <span>
                    <v-img
                      :attachment-id="userInfo.profilePictureAttmId"
                      :default-img="require('@/assets/img/defaultavatar.png')"
                    />
                  </span>
                  <a-icon type="down" />
                </div>
                <template slot="overlay">
                  <div class="user-layout-header-info">
                    <div>
                      <img src="@/assets/img/defaultavatar.png" />
                      {{ userInfo.phone }}
                    </div>
                    <p @click="userInfoBtn">
                      <a-icon type="user" />
                      基本资料
                    </p>
                    <p @click="userCertification">
                      <a-icon type="schedule" />
                      实名认证
                    </p>
                    <p @click="setUp">
                      <a-icon type="setting" />
                      安全设置
                    </p>
                    <span @click="signOut">退出</span>
                  </div>
                </template>
              </a-dropdown>
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-layout>
        <a-spin :spinning="spinning" class="layout-spin">
          <a-layout-sider width="240" style="background: #fff" v-if="isToggle">
            <user-nav v-model="pageType" />
          </a-layout-sider>
          <a-layout v-if="isToggle">
            <a-layout-content
              class="user-layout-content"
              :style="{
                background: '#F5F5F5',
                padding: '0 24px 24px',
                margin: 0,
                minWidth: '1280px',
                minHeight: '280px',
              }"
            >
              <!-- <div class="layout-content"> -->
              <router-view style="flex: 1" />
              <!-- </div> -->
            </a-layout-content>
          </a-layout>
        </a-spin>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import iconFont from "@/components/iconFont";
import VImg from "@/components/VImg";
import userNav from "./userNav.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    iconFont,
    VImg,
    userNav,
  },
  data() {
    return {
      visible: false,
      num1: 0,
      num2: 0,
      data: {
        keywords: "",
      },
      page: {
        size: 10,
        current: 1,
      },
      msgNum: 0,
      pageType: "info",
      spinning: false,
      isToggle: true,
      // companyIndex: 0,
    };
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "dict",
      "companyId",
      "companyList",
      "tenantId",
      "tenantList",
      "companyIndex",
      "msg",
    ]),
    currentTenantInfo() {
      return this.tenantList.filter((e) => e.id === this.tenantId)[0];
    },
  },
  mounted() {
    console.log(this.companyList, "companyList---");
  },
  watch: {
    "$route.path"(value) {
      if (
        this.$route.path.startsWith("/userLayout/userInfo") ||
        this.$route.path.startsWith("/userLayout/userSettings") ||
        this.$route.path.startsWith("/userLayout/userMessage") ||
        this.$route.path.startsWith("/userLayout/userCer")
      ) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
  },
  methods: {
    ...mapMutations(["getCompanyIndex"]),
    ...mapActions(["ChangeTenant", "LoadPermissions"]),
    // 获取企业列表
    // 切换企业
    toggleCompany(el, index) {
      const { id } = el;
      this.$store.commit("SET_COMPANY_ID", id);
      this.LoadPermissions();
      this.$store.commit("SET_COMPANY_STATUS", el.verifyStatus);
      // this.companyIndex = index;
      this.getCompanyIndex(index);
      this.spinning = true;
      this.isToggle = false;
      setTimeout(() => {
        this.isToggle = true;
      }, 10);
      setTimeout(() => {
        this.spinning = false;
      }, 1000);
    },
    toggleTenant(id, el) {
      this.ChangeTenant(el.id);
      this.LoadPermissions();
      this.spinning = true;
      this.isToggle = false;
      setTimeout(() => {
        this.isToggle = true;
      }, 10);
      setTimeout(() => {
        this.spinning = false;
      }, 1000);
    },
    async signOut() {
      const $router = this.$router;
      this.$confirm({
        title: "确定退出登录?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          $router.push("/logout");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    toPortal() {
      const { currentTenantInfo, companyId } = this;
      let domain = currentTenantInfo.domain;
      if (process.env.NODE_ENV === "development") {
        domain = "http://localhost:8080";
      }
      // window.open(`${domain}?companyId=${companyId}`);
      window.open(
        `${
          process.env.VUE_APP_API_PORTAL_URL
        }/auth/render?redirect_uri=${encodeURIComponent(
          `${domain}?companyId=${companyId}`
        )}`
      );
      // this.$router.push("/");
    },

    toHome() {
      this.$router.push("/");
    },

    userInfoBtn() {
      this.$router.push("/userLayout/userInfo");
    },
    setUp() {
      this.$router.push("/userLayout/userSettings");
    },
    message() {
      this.$router.push("/userLayout/userMessage?id=2");
    },
    userCertification() {
      this.$router.push("/userLayout/userCer");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-body {
  height: 100%;

  .user-layout-header {
    display: flex;
    justify-content: space-between;

    .header-home {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 62px;
      cursor: pointer;
      &::before {
        position: absolute;
        content: "";
        top: 4px;
        left: -32px;
        width: 1px;
        height: 14px;
        background: #e8e8e8;
      }
      > img {
        display: block;
        width: 14px;
        height: 14px;
      }
      > p {
        margin-left: 4px;
        font-size: 14px;
        color: #262626;
        line-height: 22px;
      }
    }

    .user-layout-header-menu-box {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .user-layout-header-menu {
        display: flex;

        > span {
          cursor: pointer;
          margin-right: 30px;

          > i {
            margin-left: 3px;
          }
        }

        .layout-header-user-info {
          display: flex;
          align-items: center;
          cursor: pointer;

          > span {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 4px;

            > img {
              display: block;
              height: 24px;
              width: 24px;
              border-radius: 50%;
            }
          }

          > i {
            color: #999;
          }
        }

        &::v-deep {
          .ant-badge-count {
            position: absolute;
            top: 0;
            right: -5px;
            z-index: 1;
            transform: translate(50%, -50%);
            transform-origin: 100% 0%;
          }
          .ant-badge-multiple-words {
            padding: 0 4px;
          }
        }
      }
    }
  }

  .user-layout-header-area-text {
    padding: 16px 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      height: 32px;
      background: #f5f5f5;
      padding: 0 8px;
      cursor: pointer;

      > p {
        flex: 1;
        margin: 0 4px;
        line-height: 32px;
      }

      > i {
        color: #595959;
      }
    }
  }

  .user-layout-header-area {
    background: #ffffff;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
      0px 9px 28px 0px rgba(0, 0, 0, 0.05),
      0px 6px 16px -8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;

    > p {
      line-height: 40px;
      border-bottom: 1px solid #eee;
      font-size: 12px;
      color: #000;
      padding: 0 16px;
    }

    > div {
      padding: 8px 0;

      > div {
        height: 40px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #595959;
        cursor: pointer;
        transition: all 0.3s;

        > img {
          margin-right: 4px;
          visibility: hidden;
        }

        &:hover {
          background: #fafafa;
          color: #262626;

          > img {
            visibility: initial;
          }
        }

        &.active {
          background: #fafafa;
          color: #262626;

          > img {
            visibility: initial;
          }
        }
      }
    }
  }

  .user-layout-header-enterprise {
    width: 300px;
    background: #ffffff;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
      0px 9px 28px 0px rgba(0, 0, 0, 0.05),
      0px 6px 16px -8px rgba(0, 0, 0, 0.08);

    > p {
      line-height: 40px;
      border-bottom: 1px solid #eee;
      font-size: 12px;
      color: #000;
      padding: 0 16px;
    }
    .imgLogo {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }

    > div {
      > .company-vody {
        padding: 12px 16px;
        display: flex;
        cursor: pointer;

        > span {
          width: 40px;
          height: 40px;
          background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
          border-radius: 4px;
          margin-right: 12px;
          color: #fff;
          font-size: 12px;
          padding: 4px 8px;
          line-height: 16px;
        }

        > .company-box {
          flex: 1;

          > span {
            display: block;
            font-size: 14px;
            color: #000000;
            line-height: 18px;
            margin-bottom: 5px;
          }

          > p {
            display: flex;

            > span {
              display: flex;
              align-items: center;
              padding: 0 6px;
              height: 18px;
              background: linear-gradient(90deg, #e6e8eb 0%, #ffffff 100%);
              border-radius: 2px;
              border: 1px solid #f0f1f2;
              font-size: 12px;
              color: #a0acba;

              > i {
                margin-right: 3px;
              }
            }
          }
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }

  .user-layout-header-info {
    width: 240px;
    background: #ffffff;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
      0px 9px 28px 0px rgba(0, 0, 0, 0.05),
      0px 6px 16px -8px rgba(0, 0, 0, 0.08);

    > div {
      display: flex;
      align-items: center;
      padding: 12px 14px;
      font-size: 12px;
      color: #000;

      > img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    > p {
      height: 40px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #595959;
      cursor: pointer;

      > i {
        margin-right: 6px;
      }

      &:hover {
        background: #fafafa;
        color: #262626;
      }
    }

    > span {
      display: block;
      line-height: 40px;
      border-top: 1px solid #eee;
      color: #666;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }
  }

  .user-layout-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

.layout-spin {
  flex-direction: row;
  display: flex;
  flex: auto;
  min-height: 0;
  background: #f0f2f5;

  ::v-deep > .ant-spin-container {
    flex-direction: row;
    display: flex;
    flex: auto;
    min-height: 0;
    // background: #f0f2f5;
  }
}

.message {
  width: 60px;
  height: 22px;
  margin-top: 21px;
  font-size: 14px;
  color: #262626;
  line-height: 22px;
}
</style>
<style>
#components-layout-demo-top-side-2 {
  height: 100%;
}

#components-layout-demo-top-side-2 .logo {
  width: 240px;
  height: 31px;
  margin: 16px 0;
  float: left;
  display: flex;
  align-items: center;
}

#components-layout-demo-top-side-2 .logo > img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
</style>
