<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      locale: zhCN
    };
  },
};
</script>
<style>
#app {
  height: 100%;
}
</style>
