<template>
  <div class="certificate-list">
    <div class="table-top" style="margin-bottom: 8px">
      <div class="title">
        <div>专利</div>
        <a-button class="btn-red" icon="plus" @click="addItem('1', '添加专利')"
          >添加专利</a-button
        >
      </div>
      <patent-table ref="patent" @edit="edit"></patent-table>
    </div>
    <div>
      <div class="title">
        <div>软件著作权</div>
        <a-button class="btn-red" icon="plus" @click="addItem('2', '添加软著')"
          >添加软著</a-button
        >
      </div>
      <work-table ref="work" @edit="edit"></work-table>
    </div>
    <a-modal
      class="modal"
      v-model="visible"
      :title="title"
      @ok="qualificationCertificateHandleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="qualificationCertificateForm"
        :model="qualificationCertificateForm"
        :rules="qualificationCertificateRules"
        layout="horizontal"
        v-bind="{
          labelCol: { span: 4 },
          wrapperCol: { span: 20 },
        }"
      >
        <template v-if="qualificationCertificateForm.certificateType === '1'">
          <a-form-model-item label="专利名称" prop="patentName">
            <a-input
              v-model="qualificationCertificateForm.patentName"
              :max-length="40"
              placeholder="请填写专利名称"
            />
          </a-form-model-item>
          <a-form-model-item label="专利号" prop="patentNo">
            <a-input
              v-model="qualificationCertificateForm.patentNo"
              :max-length="40"
              placeholder="请填写专利号"
            />
          </a-form-model-item>
        </template>
        <template v-else>
          <a-form-model-item label="软著名称" prop="patentName">
            <a-input
              v-model="qualificationCertificateForm.patentName"
              :max-length="40"
              placeholder="请填写软著名称"
            />
          </a-form-model-item>
          <a-form-model-item label="登记号" prop="patentNo">
            <a-input
              v-model="qualificationCertificateForm.patentNo"
              :max-length="40"
              placeholder="请填写登记号"
            />
          </a-form-model-item>
          <a-form-model-item label="版本号" prop="versionNo">
            <a-input
              v-model="qualificationCertificateForm.versionNo"
              :max-length="40"
              placeholder="请填写版本号"
            />
          </a-form-model-item>
        </template>
        <a-form-model-item label="颁发日期" prop="issueDate">
          <a-date-picker
            v-model="qualificationCertificateForm.issueDate"
            placeholder="请选择颁发日期"
          />
        </a-form-model-item>
        <a-form-model-item label="证书图片" prop="certificateCoverId">
          <VUploadAvatar
            class="upload-form"
            file-type-code="1002"
            v-model="qualificationCertificateForm.certificateCoverId"
            upload-text="上传图片"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import patentTable from "./components/patentTable";
import workTable from "./components/workTable";
import { saveCertificate } from "@/api/companyInfo";
import VUploadAvatar from "../../components/VUploadAvatar";
import { mapGetters } from "vuex";
export default {
  name: "certificateList",
  components: {
    patentTable,
    VUploadAvatar,
    workTable,
  },
  data() {
    return {
      title: "",
      visible: false,
      qualificationCertificateForm: {},
      qualificationCertificateRules: {
        patentName: [
          { required: true, message: "专利名称不能为空", trigger: "blur" },
        ],
        issueDate: [
          { required: true, message: "颁发日期不能为空", trigger: "change" },
        ],
        certificateCoverId: [
          { required: true, message: "证书图片不能为空", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  methods: {
    qualificationCertificateHandleOk() {
      this.$refs.qualificationCertificateForm.validate((valid) => {
        if (valid) {
          const { qualificationCertificateForm, companyInfo } = this;
          // this.qualificationCertificateForm.certificateType = "1";
          qualificationCertificateForm.businessLicense =
            companyInfo.socialCreditCode;
          saveCertificate(qualificationCertificateForm).then(() => {
            this.visible = false;
            this.$message.success("保存成功");
            if (qualificationCertificateForm.certificateType === "1") {
              this.$refs.patent.loadGetCertificateList();
            } else {
              this.$refs.work.loadGetCertificateList();
            }
            this.qualificationCertificateForm = {};
          });
        } else {
          return false;
        }
      });
    },
    edit(record) {
      this.qualificationCertificateForm = {
        id: record.id,
        certificateType: record.certificateType,
        patentName: record.patentName,
        patentNo: record.patentNo,
        versionNo: record.versionNo,
        issueDate: record.issueDate,
        certificateCoverId: record.certificateCoverId,
        createTime: record.createTime,
        creator: record.creator,
        updateTime: record.updateTime,
        updater: record.updater,
      };
      this.visible = true;
    },
    addItem(type, title) {
      this.title = title;
      this.qualificationCertificateForm.certificateType = type;
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
      this.$refs.qualificationCertificateForm.resetFields();
      this.qualificationCertificateForm = {};
    },
  },
};
</script>

<style scoped lang="scss">
.certificate-list {
  padding: 0 32px;
}
.title {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    font-size: 16px;
    color: #000000;
    line-height: 64px;
  }
}
.upload-form {
  &::v-deep {
    .ant-upload-select {
      width: 88px !important;
      height: 88px !important;
      background-color: #fafafa !important;
      border: 1px dashed #d9d9d9 !important;
      .anticon {
        font-size: 14px;
      }
      .ant-upload-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
      }
      .ant-upload {
        padding: 8px;
      }
    }
    img {
      display: block;
      max-width: 88px;
      max-height: 88px;
    }
  }
}
.modal {
  &::v-deep {
    .ant-modal {
      width: 640px !important;
    }
  }
}
</style>
