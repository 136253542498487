<template>
  <a-upload
    :action="action"
    :file-list="fileList"
    :headers="headers"
    :multiple="true"
    name="file"
    @change="handleChange"
  >
    <slot>

    </slot>
  </a-upload>
</template>

<script>
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { getAttachments } from "@/api/common";

export default {
  name: "VMultiUpload",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    fileTypeCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    headers() {
      const token = storage.get(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },
    action() {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`;
    },
  },
  watch: {
    value(val, old) {
      console.log(`${old}===>${val}`);
      if (val !== old) {
        this.loadData();
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    handleChange(info) {
      this.fileList = info.fileList;
      if (info.fileList.filter((e) => e.status !== "done").length) {
        return;
      }
      const attachmentIds = info.fileList
        .filter((e) => e.status === "done")
        .map((e) => e.response.data.id);
      this.$emit("input", attachmentIds);
      this.$emit("change", attachmentIds);
    },
    loadData() {
      const { value } = this;
      if (value && value.length) {
        getAttachments(value).then((data) => {
          this.fileList = data.map((e) => {
            return {
              uid: e.id,
              name: e.fileName,
              status: "done",
              // url: `${VUE_APP_API_PATH}/file/${e.attachmentId}/download`,
              response: {
                data: {
                  ...e,
                },
              },
            };
          });
          console.log(this.fileList);
        });
      } else {
        this.fileList = [];
      }
    },
  },
};
</script>
