var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dev-manage"},[_c('div',[_c('p',[_vm._v("开发管理")]),_c('div',{staticClass:"my-dev-filter-box"},[_c('div',{staticClass:"my-dev-filter-tab"},[_c('div',{staticClass:"my-dev-filter-tab-list"},[_c('span',[_vm._v("所属类目：")]),_c('div',{class:{ active: _vm.devCode == '' },on:{"click":function($event){return _vm.changeType('')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.dict.PROJECT_TYPE),function(item,index){return _c('div',{key:item.itemValue,class:{ active: _vm.devCode == item.itemValue },style:({ left: -(index + 1) + 'px' }),on:{"click":function($event){return _vm.changeType(item.itemValue)}}},[_vm._v(" "+_vm._s(item.itemText)+" ")])})],2)]),_c('div',{staticClass:"my-dev-filter-btn"},[_c('a-input-search',{staticStyle:{"width":"272px","margin-right":"8px"},attrs:{"allowClear":"","placeholder":"请输入项目名称搜索"},on:{"search":_vm.onSearch},model:{value:(_vm.dataInfo.projectName),callback:function ($$v) {_vm.$set(_vm.dataInfo, "projectName", $$v)},expression:"dataInfo.projectName"}}),_c('a-button',{staticClass:"btn-red",attrs:{"icon":"plus"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("创建 ")])],1)]),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.devData,"pagination":_vm.pageData,"loading":_vm.loading,"rowKey":function (record, index) {
          return index;
        }},on:{"change":_vm.pageChange},scopedSlots:_vm._u([{key:"projectType",fn:function(text){return [_c('p',[_vm._v(_vm._s(_vm._f("dict")(text,"PROJECT_TYPE")))])]}},{key:"operating",fn:function(text, record){return _c('div',{},[_c('div',{staticClass:"my-dev-operating"},[(record.frontendUrl)?_c('span',{on:{"click":function($event){return _vm.toWorkspace(record)}}},[_vm._v("进入开发")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.projectSet(record)}}},[_vm._v("项目设置")])])])}}])})],1),_c('a-modal',{attrs:{"title":"创建项目","width":"480px"},on:{"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a-button',{attrs:{"disabled":_vm.spinning},on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('a-button',{staticClass:"btn-red",attrs:{"disabled":_vm.spinning},on:{"click":_vm.submit}},[_vm._v("确 定 ")])]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('a-form-model-item',{ref:"name",attrs:{"label":"项目名称","prop":"projectName"}},[_c('a-input',{attrs:{"placeholder":"请输入项目名称"},on:{"blur":function () {
                  _vm.$refs.name.onFieldBlur();
                }},model:{value:(_vm.form.projectName),callback:function ($$v) {_vm.$set(_vm.form, "projectName", $$v)},expression:"form.projectName"}})],1),_c('a-form-model-item',{ref:"type",attrs:{"label":"项目类型","prop":"projectType"}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.projectType),callback:function ($$v) {_vm.$set(_vm.form, "projectType", $$v)},expression:"form.projectType"}},_vm._l((_vm.dict.PROJECT_TYPE),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.itemValue}},[_vm._v(_vm._s(item.itemText)+" ")])}),1)],1)],1)],1)])],1),_c('a-modal',{attrs:{"title":"设置","width":"600px"},on:{"cancel":function($event){_vm.setting = false}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a-button',{on:{"click":function($event){_vm.setting = false}}},[_vm._v("取 消")]),_c('a-button',{staticClass:"btn-red",on:{"click":_vm.set}},[_vm._v("设置")])]},proxy:true}]),model:{value:(_vm.setting),callback:function ($$v) {_vm.setting=$$v},expression:"setting"}},[_c('div',[_c('a-form-model',{ref:"projectSet",attrs:{"model":_vm.formSet,"rules":_vm.rulesSet,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"right"}},[_c('a-form-model-item',{ref:"name",attrs:{"label":"项目名称","prop":"projectName"}},[_c('a-input',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请输入项目名称"},on:{"blur":function () {
                _vm.$refs.name.onFieldBlur();
              }},model:{value:(_vm.formSet.projectName),callback:function ($$v) {_vm.$set(_vm.formSet, "projectName", $$v)},expression:"formSet.projectName"}})],1),_c('a-form-model-item',{ref:"id",attrs:{"label":"Appid","prop":"appid"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{staticStyle:{"width":"350px","margin-right":"20px"},attrs:{"disabled":""},on:{"blur":function () {
                  _vm.$refs.name.onFieldBlur();
                }},model:{value:(_vm.formSet.appid),callback:function ($$v) {_vm.$set(_vm.formSet, "appid", $$v)},expression:"formSet.appid"}}),_c('a-button',{staticClass:"btn-red",on:{"click":_vm.copyText}},[_vm._v("复制")])],1)])],1),_c('a-popconfirm',{attrs:{"placement":"topRight","ok-text":"确认","cancel-text":"取消"},on:{"confirm":_vm.projectDel}},[_c('template',{slot:"title"},[_c('p',[_vm._v("应用删除后不可更改")])]),_c('a-button',{staticStyle:{"margin-left":"115px"}},[_vm._v("删除")])],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }