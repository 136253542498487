<template>
  <div :class="prefixCls" class="ql-editor-box">
    <quill-editor
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
      @change="onEditorChange($event)">
    </quill-editor>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
  // ["blockquote", "code-block"], // 引用  代码块
  // [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
  // [{ script: "sub" }, { script: "super" }], // 上标/下标
  [{ indent: '-1' }, { indent: '+1' }], // 缩进
  // [{'direction': 'rtl'}],                         // 文本方向
  //[{ size: ['small', false, 'large', 'huge'] }], // 字体大小
  //[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  //[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  //[{ font: [] }], // 字体种类
  [{ align: [] }], // 对齐方式
  // ["clean"], // 清除文本格式
  ['link', 'image'] // 链接、图片、视频, 'video'
]



export default {
  name: 'QuillEditor',
  components: {
    quillEditor
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-editor-quill'
    },
    // 表单校验用字段
    // eslint-disable-next-line
    value: {
      type: String,
    },

  },
  data () {
    return {
      editorOption: {
        // some quill options
        placeholder: "请输入正文",
         modules: {
           toolbar: {
             container: toolbarOptions,
           }
         },
        imageResize: {
          displaySize: true
        }
      }
    }
  },
  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    onEditorBlur (quill) {
      // console.log('editor blur!', quill)
    },
    onEditorFocus (quill) {
      // console.log('editor focus!', quill)
    },
    onEditorReady (quill) {
      // console.log('editor ready!', quill)
    },
    onEditorChange ({ quill, html, text }) {
      this.$emit('change', text)
    }
  }
}
</script>

<style>
.ql-editor-box{
  line-height: initial;
}
.ql-editor{
    height:200px;
}
</style>
