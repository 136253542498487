<template>
  <div class="serve-manage">

    <p>服务管理</p>

    <div class="serve-manage-filter">
      <div>
        <div>服务名称：<a-input placeholder="请输入服务名称" style="width:272px" allow-clear v-model="dataInfo.serviceName"/></div>
        <div>交付方式：
          <a-select placeholder="请选择" style="width: 272px" allow-clear v-model="dataInfo.deliverType">
            <a-select-option :value="item.itemValue" v-for="(item,index) in dict.DELIVER_TYPE" :key="index">{{item.itemText}}</a-select-option>

          </a-select>
        </div>
        <div>服务状态：
          <a-select placeholder="请选择" style="width: 272px" allow-clear v-model="dataInfo.publishStatus">
            <a-select-option :value="item.itemValue" v-for="(item,index) in dict.PUBLISH_STATUS" :key="index">{{item.itemText}}</a-select-option>
          </a-select>
        </div>
      </div>
      <p>
        <a-button @click="loadGetServicePageList">查 询</a-button>
        <a-button class="btn-red" style="margin-left:8px" @click="releaseServe">发布服务</a-button>
      </p>
    </div>

    <div>
      <a-table ref="table" :columns="columns" :loading="loading" :data-source="serviceList" :pagination="pagination" @change="handleTableChange">
        <template slot="serviceCover" slot-scope="text">
          <div class="user-serve-table-img">
             <v-img
                 :attachment-id="text"
                 :default-img="require('@/assets/img/kctp.png')"
             />
          </div>
        </template>

        <template slot="deliverType" slot-scope="text">
            <p>{{text | dict("DELIVER_TYPE")}}</p>
        </template>

        <template slot="lowestPrice" slot-scope="text">
          <p><span v-if="text">￥</span>{{text || "议价定制"}}</p>
        </template>


        <template slot="serviceSource" slot-scope="text">
          <p>{{text=="1"?'鸿联代建':'企业自建'}}</p>
        </template>


        <template slot="publishStatus" slot-scope="text,record">
          <p class="user-serve-table-state"><i :class="`bg-${text}`"></i>{{text | dict('PUBLISH_STATUS')}}</p>

          <div class="rea-img" v-if="text=='5'"  @click="reason(record)"></div>
        </template>

        <template slot="operate" slot-scope="text, record">
          <p class="user-serve-table-operate">

              <span v-if="record.publishStatus != '1'" @click="toDetail(record)">详情</span>

              <template v-if="record.publishStatus == '1' || record.publishStatus == '3' ||  record.publishStatus == '5'">
                <span @click="toAdd(record.id)">编辑</span>
                <a-popconfirm
                    title="确定删除吗？"
                    @confirm="removeService(record.id)"
                >
                  <span>删除</span>
                </a-popconfirm>

              </template>

              <span v-if="record.publishStatus == '3'"    @click="changeStatus(record.id,'2')">上架</span>
              <span v-if="record.publishStatus == '2'"  @click="changeStatus(record.id,'3')">下架</span>
          </p>
        </template>
      </a-table>
    </div>


    <a-modal v-model="visible" title="审核结果" width="480px" @cancel="cancel">

      <p class="rea-text">不通过原因：{{reasonText}}</p>

      <template #footer>
        <a-button class="btn-red"  @click="cancel">确 定</a-button>
      </template>
    </a-modal>


  </div>
</template>

<script>
const columns = [
  {
    title: '编号',
    dataIndex: 'index',
    key: 'index',
    width: 80,
    customRender: (text,record,index) => `${index+1}`,
  },
  {
    title: '服务图片',
    dataIndex: 'serviceCover',
    key: 'serviceCover',
    scopedSlots: { customRender: 'serviceCover' },
  },
  {
    title: '服务名称',
    dataIndex: 'serviceName',
    key: 'serviceName',
    ellipsis: true,
  },
  {
    title: '交付方式',
    dataIndex: 'deliverType',
    key: 'deliverType',
    scopedSlots: { customRender: 'deliverType' },
  },
  {
    title: '服务价格',
    dataIndex: 'lowestPrice',
    key: 'lowestPrice',
    scopedSlots: { customRender: 'lowestPrice' },
  },
  {
    title: '服务来源',
    dataIndex: 'serviceSource',
    key: 'serviceSource',
    scopedSlots: { customRender: 'serviceSource' },
  },
  {
    title: '创建人',
    dataIndex: 'resCreatorName',
    key: 'resCreatorName',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    key: 'updateTime',
    width: 190,
  },
  {
    title: '服务状态',
    dataIndex: 'publishStatus',
    key: 'publishStatus',
    scopedSlots: { customRender: 'publishStatus' },
  },
  {
    title: '操作',
    dataIndex: 'operate',
    key: 'operate',
    scopedSlots: { customRender: 'operate' },
  },
];

import {getServicePageList,updateStatus,deleteServiceMarket} from '@/api/service'
import {mapActions, mapGetters} from "vuex";
import VImg from "@/components/VImg";
import blockImg from '@/assets/img/question.png'
import redImg from '@/assets/img/question_red.png'
export default {
  data(){
    return{
      payType:undefined,
      serveState:undefined,
      visible:false,
      columns,
      warn:blockImg,
      reasonText:'',
      pagination:{
        showSizeChanger:true,
        showQuickJumper: true,
        total:0,
        showTotal: (total) => `共 ${total} 项`,
      },
      page:{
        current: 1,
        size: 10,
      },
      dataInfo:{
        deliverType:'',
        serviceName: '',
        publishStatus:'',
      },
      loading:false,
      serviceList:[],
    }
  },

  components:{VImg},


  computed:{
    ...mapGetters(["dict","company_status","companyId"]),
  },


  mounted() {
    this.loadGetServicePageList();
    this.LoadDictData(["DELIVER_TYPE"]);
  },




  methods:{

    ...mapActions(["LoadDictData"]),

    loadGetServicePageList(){
      this.loading=true
      this.dataInfo.companyId=this.companyId
      getServicePageList(this.page,this.dataInfo).then(res=>{
        this.serviceList=res.records
        this.$set(this.pagination,"total",res.total)

        this.serviceList.forEach(item=>{
          if(!item.resCreatorName){
            item.resCreatorName=item.sysCreatorName
          }
        })

        this.loading=false



      })
    },

    handleTableChange(val){
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetServicePageList();
    },

    //审核不通过原因
    reason(record){
      this.reasonText=record.verifyFailedReason
      this.visible=true
    },

    cancel(){
      this.visible=false
    },


    toDetail(record){

      this.$router.push({
        path:'/serveManage/details',
        query:{id:record.id}
      })
    },


    toAdd(id){
      this.$router.push({
        path:'/serveManage/releaseServe',
        query:{id}
      })
    },


    //更新状态
    changeStatus (id, publishStatus) {
      updateStatus(id, publishStatus, {}).then(res => {
        this.$message.success('操作成功')
        this.loadGetServicePageList();
      })
    },

    //删除
    removeService (id) {
      deleteServiceMarket(id).then(res => {
        this.$message.success('操作成功')
        this.loadGetServicePageList();
      })
    },





    releaseServe(){
      if(this.company_status == '3'){
        this.$router.push('/serveManage/releaseServe')
      }else {
        this.$message.warning('请先完成企业认证！')
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.serve-manage{
  margin-top: 16px;
  background: #fff;
  padding: 24px 32px;
  >p{
    font-size: 20px;
    color: #262626;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .serve-manage-filter{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    >div{
      display: flex;
      >div{
        margin-right: 24px;
      }
    }
    >p{
      display: flex;
    }
  }
}
.user-serve-table-state{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #595959;
    >i{
        width: 6px;
        height: 6px;
        background: rgba(0,0,0,0.25);
        border-radius: 50%;
        margin-right: 8px;
        &.bg-4{
            background: #FACC14;
        }
        &.bg-5{
            background: #F5222D;
        }
        &.bg-2,&.bg-3{
            background: #1890FF;
        }
    }
}
.user-serve-table-operate{
    display: flex;
    >span{
        white-space:nowrap;
        cursor: pointer;
        margin-right: 16px;
        color: #EA0B06;
        &:last-child{
            margin-right: 0;
        }
    }
}
.user-serve-table-img{
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  >img{
    max-width: 100%;
    max-height: 100%;
  }
}

.rea-text{
  font-size: 14px;
  color: #595959;
  line-height: 22px;
}

.rea-img{
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 14px;
  background: url("../../assets/img/question.png") no-repeat;
  background-size: cover;
  &:hover,&:active{
    background: url("../../assets/img/question_red.png") no-repeat;
  }
}

</style>
