<template>
    <div class="user-home-notice">
        <p>平台公告</p>
        <div v-if="list != ''">
            <div :key="el.id" @click="noticeInfo(el.id)" v-for="el in list">
                <div>
                    <p>{{el.createTime | moment("DD")}}</p>
                    <span>{{el.createTime | moment("MM")}}</span>
                </div>
                <p>{{el.title}}</p>
            </div>
        </div>
        <a-empty v-else/>
    </div>
</template>

<script>
    import {getAnnouncementList} from "@/api/news";
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                list: [],
            }
        },
        computed: mapGetters(["tenantId"]),
        mounted() {
            this.loadData()
        },
        methods: {
            loadData() {
                const {tenantId} = this
                getAnnouncementList({current: 1, size: 4}, {tenantId: tenantId}).then((res) => {
                    this.list = res.records
                })
            },
            noticeInfo(id) {
                window.open(`${process.env.VUE_APP_PROD_PREFIX}/announcement/info?id=${id}`, '_blank')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-home-notice {
        margin-top: 24px;
        padding-bottom: 24px;
        //border-bottom: 1px solid #E8E8E8;

        > p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            color: #262626;
        }

        > div {
            > div {
                display: flex;
                margin-bottom: 16px;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }

                > div {
                    width: 40px;
                    height: 48px;
                    margin-right: 16px;
                    background: url(../../../assets/img/rqbg.png) no-repeat;
                    background-size: 100% 100%;
                    padding-top: 4px;
                    text-align: center;

                    > p {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 22px;
                    }

                    > span {
                        display: block;
                        font-size: 12px;
                        color: #666;
                        line-height: 20px;
                    }
                }

                > p {
                    flex: 1;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    line-height: 22px;
                    padding: 2px 0;
                }
            }
        }
    }
</style>
