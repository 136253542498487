var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-scenes"},[_c('a-breadcrumb',{staticClass:"user-breadcrumb-box"},[_c('a-breadcrumb-item',[_c('router-link',{attrs:{"to":"/userLayout/userScenes","replace":""}},[_vm._v("迁移管理")])],1),_c('a-breadcrumb-item',[_vm._v("提交国产替代需求单")])],1),_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('div',{staticClass:"add-scenes-form-info"},[_c('p',[_vm._v("需求描述")]),_c('a-form-model-item',{ref:"adaptationType",attrs:{"label":"我想迁移适配","prop":"adaptationType"}},[_c('a-radio-group',{model:{value:(_vm.form.adaptationType),callback:function ($$v) {_vm.$set(_vm.form, "adaptationType", $$v)},expression:"form.adaptationType"}},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 硬件产品 ")]),_c('a-radio',{attrs:{"value":"2"}},[_vm._v(" 模组 ")])],1)],1),_c('a-form-model-item',{attrs:{"prop":"orderDeviceList","label":"设备名称"}},[_c('div',{staticClass:"add-scenes-migrate"},[_c('a-button',{attrs:{"icon":"plus-circle"},on:{"click":function($event){return _vm.scenesEdit('add','','')}}},[_vm._v("添加设备")]),_c('div',_vm._l((_vm.form.orderDeviceList),function(el,index){return _c('div',{key:index},[_c('p',[_vm._v(_vm._s(el.deviceName))]),_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteMigrate(index)}}}),_c('span',{on:{"click":function($event){return _vm.scenesEdit('edit',el,index)}}},[_vm._v("编辑")])],1)}),0)],1)])],1),_c('div',{staticClass:"add-scenes-form-info"},[_c('p',[_vm._v("企业联系信息")]),_c('a-form-model-item',{ref:"companyName",attrs:{"label":"企业名称","prop":"companyName"}},[_c('a-input',{staticStyle:{"width":"400px"},attrs:{"placeholder":"请输入企业名称"},on:{"blur":function () {
                _vm.$refs.companyName.onFieldBlur();
              }},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}})],1),_c('a-form-model-item',{ref:"companyAddress",attrs:{"label":"企业所在地","prop":"companyAddress"}},[_c('a-input',{staticStyle:{"width":"400px"},attrs:{"placeholder":"请输入企业所在地"},on:{"blur":function () {
                _vm.$refs.companyAddress.onFieldBlur();
              }},model:{value:(_vm.form.companyAddress),callback:function ($$v) {_vm.$set(_vm.form, "companyAddress", $$v)},expression:"form.companyAddress"}})],1),_c('a-form-model-item',{ref:"contactPerson",attrs:{"label":"联系人","prop":"contactPerson"}},[_c('a-input',{staticStyle:{"width":"400px"},attrs:{"placeholder":"请输入联系人姓名"},on:{"blur":function () {
                _vm.$refs.contactPerson.onFieldBlur();
              }},model:{value:(_vm.form.contactPerson),callback:function ($$v) {_vm.$set(_vm.form, "contactPerson", $$v)},expression:"form.contactPerson"}})],1),_c('a-form-model-item',{ref:"contactPhone",attrs:{"label":"手机号","prop":"contactPhone"}},[_c('a-input',{staticStyle:{"width":"400px"},attrs:{"placeholder":"请输入手机号","max-length":11},on:{"blur":function () {
                _vm.$refs.contactPhone.onFieldBlur();
              }},model:{value:(_vm.form.contactPhone),callback:function ($$v) {_vm.$set(_vm.form, "contactPhone", $$v)},expression:"form.contactPhone"}})],1)],1),_c('div',{staticClass:"add-scenes-form-info"},[_c('a-form-model-item',[_c('div',{staticStyle:{"display":"flex"}},[_c('a-button',{staticClass:"btn-red",attrs:{"loading":_vm.subloading},on:{"click":_vm.onSubmit}},[_vm._v(" 提 交 ")]),_c('a-button',{staticStyle:{"margin-left":"16px"},attrs:{"loading":_vm.saveloading},on:{"click":_vm.resetForm}},[_vm._v("保 存")])],1)])],1)]),(_vm.addVisible)?_c('add',{attrs:{"visible":_vm.addVisible,"type":_vm.scenesType,"info":_vm.scenesInfo,"index":_vm.scenesIndex},on:{"add":_vm.addMigrate,"edit":_vm.editMigrate},model:{value:(_vm.addVisible),callback:function ($$v) {_vm.addVisible=$$v},expression:"addVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }