<template>
  <div class="association-enterprise">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>
        <router-link to="/userLayout/userInfo" replace>基本资料</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>关联企业</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="association-enterprise-box">
      <div class="association-enterprise-head">
        <div class="association-enterprise-head-title">
          <img alt="图片" src="@/assets/img/icon8.png" />
          <p>请关联企业</p>
        </div>
        <div class="association-enterprise-head-info">
          <div class="association-enterprise-head-inp">
            <p>关联企业</p>
            <div>
              <a-select
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请输入关联企业名称"
                show-search
                style="width: 400px"
                v-model="associationCompany"
              >
                <a-select-option
                  :key="item.id"
                  :value="item.id"
                  v-for="item in companyList"
                >
                  {{ item.companyName }}
                </a-select-option>
              </a-select>
            </div>
            <div class="add-company">
              如该企业不存在 <span @click="createEnterprise">创建企业</span>
            </div>
          </div>
        </div>
      </div>
      <div class="association-enterprise-info" v-if="enterpriseSearchInfo">
        <div class="user-info-personal">
          <div class="user-info-enterprise-avatar-box">
            {{
              companyInfo.companyName
                ? companyInfo.companyName.substring(0, 4)
                : ""
            }}
          </div>
          <div class="user-info-personal-info-box" style="border: none">
            <p>企业信息</p>
            <div>
              <span>企业名称</span>
              <div>{{ companyInfo.companyName }}</div>
            </div>
            <div>
              <span>企业类型</span>
              <!--              <div>{{ companyInfo.companyType | dict("COMPANY_TYPE") }}</div>-->
              <div
                style="width: auto; margin-right: 10px"
                v-for="(el, index) in companyInfo.companyType"
                :key="index"
              >
                {{ el | dict("COMPANY_TYPE")
                }}<i v-if="index !== companyInfo.companyType.length - 1">、</i>
              </div>
            </div>
            <div>
              <span>所属行业</span>
              <div>{{ companyInfo.industryType | dict("INDUSTRY") }}</div>
            </div>
            <div>
              <span>企业规模</span>
              <div>
                {{ companyInfo.companyScaleType | dict("COMPANY_SCALE_TYPE") }}
              </div>
            </div>
            <div>
              <span>法人代表</span>
              <div>{{ companyInfo.legalPersonName }}</div>
            </div>
            <div>
              <span>统一社会信用代码</span>
              <div>{{ companyInfo.socialCreditCode }}</div>
            </div>
            <div>
              <span>企业地址</span>
              <div>{{ companyInfo.companyAddress }}</div>
            </div>
            <div>
              <span>企业简介</span>
              <div>{{ companyInfo.companyBrief }}</div>
            </div>
            <div>
              <span>联系人</span>
              <div>{{ companyInfo.contactPerson }}</div>
            </div>
            <div>
              <span>联系电话</span>
              <div>{{ companyInfo.contactPhone | capitalize }}</div>
            </div>
            <div>
              <a-button @click="cancelAssociation">取 消</a-button>
              <a-button @click="submitAssociation" class="btn-red"
                >确认关联</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getCompanyList } from "@/api/enterpriseManage";
import { getAddCompany, getCompanyInfo } from "@/api/res-company";

export default {
  data() {
    return {
      enterpriseSearchInfo: false,
      companyList: "",
      associationCompany: "",
      companyInfo: "",
    };
  },
  mounted() {
    this.loadgetCompanyList();
    this.LoadDictData(["COMPANY_TYPE"]);
    this.LoadDictData(["INDUSTRY"]);
    this.LoadDictData(["COMPANY_FIELD"]);
  },
  watch: {
    associationCompany: function (val) {
      this.enterpriseSearch();
    },
  },
  computed: mapGetters(["userInfo"]),
  methods: {
    ...mapActions(["LoadDictData"]),
    loadgetCompanyList() {
      getCompanyList({ companyName: "", isExcludeMeCompany: true }).then(
        (res) => {
          this.companyList = res;
        }
      );
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    createEnterprise() {
      this.$router.push("/userLayout/userInfo/createCompany");
    },
    enterpriseSearch() {
      let { associationCompany } = this;
      if (associationCompany != "") {
        getCompanyInfo(associationCompany).then((res) => {
          this.companyInfo = res;
        });
        this.enterpriseSearchInfo = true;
      }
    },
    cancelAssociation() {
      this.enterpriseSearchInfo = false;
      this.associationCompany = "";
    },
    submitAssociation() {
      let _this = this;
      if (this.userInfo.verifyStatus == 3) {
        getAddCompany(this.associationCompany).then((res) => {
          this.$router.push("/userLayout/userInfo");
        });
      } else {
        this.$confirm({
          title: "请先完成实名认证",
          okText: "去实名",
          okType: "danger",
          onOk() {
            _this.$router.push("/userLayout/userCer");
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.association-enterprise {
  padding-top: 16px;
  .association-enterprise-box {
    margin-top: 16px;
    .association-enterprise-head {
      background: #fff;
      padding: 40px 0;
      position: relative;
      display: flex;
      align-items: center;
      height: 200px;

      &::after {
        content: "";
        position: absolute;
        top: 40px;
        left: 480px;
        bottom: 40px;
        border-left: 1px dashed #f0f0f0;
      }

      .association-enterprise-head-title {
        width: 480px;
        padding: 0 40px;

        > img {
          display: block;
          margin: 0 auto 16px;
        }

        > p {
          text-align: center;
          font-size: 24px;
          line-height: 24px;
        }
      }

      .association-enterprise-head-info {
        flex: 1;
        padding: 0 40px;

        .association-enterprise-head-btn {
          display: flex;

          > button {
            &:first-child {
              margin-right: 16px;
            }
          }
        }

        .association-enterprise-head-hint {
          color: #666;
          margin-top: 12px;
        }

        .association-enterprise-head-inp {
          > p {
            line-height: 12px;
            margin-bottom: 16px;
          }

          .add-company {
            margin-top: 24px;
            font-size: #262626;
            display: flex;
            line-height: 16px;

            > span {
              color: #ea0b06;
              cursor: pointer;
            }
          }
        }
      }
    }

    .association-enterprise-info {
      background: #fff;
      margin-top: 24px;
      padding: 40px;
      min-height: 600px;

      .user-info-personal {
        display: flex;

        .user-info-personal-avatar-box {
          margin-right: 104px;
        }

        .user-info-enterprise-avatar-box {
          margin-right: 104px;
          width: 80px;
          height: 80px;
          background: #ea0b06;
          border-radius: 4px;
          color: #fff;
          font-size: 20px;
          padding: 14px 18px;
          letter-spacing: 2px;
          line-height: 26px;
        }

        .user-info-personal-info-box {
          flex: 1;
          border-bottom: 1px dashed #f0f0f0;
          padding-bottom: 24px;

          > p {
            line-height: 16px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 24px;
            display: flex;

            > span {
              cursor: pointer;
              font-size: 14px;
              line-height: 16px;
              color: #ea0b06;
              margin-left: 477px;
              font-weight: 500;
            }
          }

          > div {
            display: flex;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            > span {
              width: 140px;
              font-size: 14px;
              line-height: 22px;
              color: #8c8c8c;
            }

            > div {
              font-size: 14px;
              line-height: 22px;
              color: #595959;
              width: 380px;
              display: flex;
              margin-right: 20px;
              max-height: 66px;
              overflow: hidden;

              > span {
                height: 20px;
                border-radius: 2px;
                padding: 0 8px;
                line-height: 18px;
                font-size: 12px;
                margin-left: 16px;

                &.drz {
                  border: 1px solid #faad14;
                  color: #faad14;
                }

                &.yrz {
                  border: 1px solid #52c41a;
                  color: #52c41a;
                }
              }
            }

            > p {
              cursor: pointer;
              font-size: 14px;
              line-height: 22px;
              color: #ea0b06;
            }

            > button {
              margin-top: 40px;

              &:first-child {
                margin-right: 16px;
              }
            }
          }
        }
      }

      .association-enterprise-info-no {
        text-align: center;

        > img {
          display: block;
          margin: 0 auto 24px;
        }

        > p {
          font-size: 16px;
          line-height: 15px;
          margin-bottom: 16px;
        }

        > span {
          line-height: 12px;
          color: #8c8c8c;
          margin-bottom: 56px;
          display: block;
        }

        > div {
          display: flex;
          justify-content: center;

          > button {
            &:first-child {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
