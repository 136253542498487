<template>
  <a-modal
    v-model="visible"
    width="640px"
    wrap-class-name="edit-phone-modal"
    title="更改手机号"
    @cancel="visible = false"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="旧手机号">
        <div>
          <a-input style="width: 100%" :value="phone" disabled />
        </div>
      </a-form-model-item>
      <a-form-model-item label="验证码" prop="code">
        <a-row :gutter="16">
          <a-col :span="15">
            <a-input
              v-model="form.code"
              style="width: 100%"
              placeholder="请输入短信验证码"
            />
          </a-col>
          <a-col :span="9">
            <v-count-down
              class="verify-code"
              :disabled="!userInfo.phone"
              :trigger="() => sendMobileCode(userInfo.phone)"
            />
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="新手机号" prop="newPhone">
        <a-input v-model="form.newPhone" placeholder="请输入新手机号" />
      </a-form-model-item>
      <a-form-model-item label="验证码" prop="newCode">
        <a-row :gutter="16">
          <a-col :span="15">
            <a-input
              v-model="form.newCode"
              style="width: 100%"
              placeholder="请输入短信验证码"
            />
          </a-col>
          <a-col :span="9">
            <v-count-down
                :old-phone="userInfo.phone"
                :new-phone="form.newPhone"
              class="verify-code"
              :disabled="!form.newPhone"
              :trigger="() => sendMobileCode(form.newPhone)"
            />
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
    <template #footer>
      <a-button @click="visible = false">取 消</a-button>
      <a-button @click="handleSubmit" class="btn-red">确 定</a-button>
    </template>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VCountDown from "@/components/VCountDown";
import { sendMobile } from "@/api/common";
import { editPhone } from "@/api/res-user";

export default {
  components: { VCountDown },
  data() {
    return {
      visible: false,
      form: {
        code: "",
        newPhone: "",
        newCode: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      rules: {
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        newPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        newCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    phone() {
      return this.$options.filters.capitalize(this.userInfo.phone);
    },
  },
  methods: {
    ...mapActions(["GetInfo"]),
    open() {
      this.visible = true;
    },
    sendMobileCode(phone) {
      sendMobile(phone, "bind").then(() => {
        this.$message.success("发送成功！");
      });

    },
    handleSubmit() {
      const { code, newPhone, newCode } = this.form;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          editPhone({ code, newPhone, newCode }).then(() => {
            this.$message.info("修改成功！");
            this.GetInfo();
            this.visible = false;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  color: #EA0B06;
  cursor: pointer;
}

.verify-code {
  display: inline-block;
  height: 32px;
  background: #ffffff;
  border: 1px solid #EA0B06;
  border-radius: 2px;
  font-size: 14px;
  color: #EA0B06;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
</style>
