<template>
  <icon-font :type="type" />
</template>

<script>
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3178977_xx74vmbpume.js",
});
export default {
  components: {
    IconFont,
  },
  props: ["type"],
};
</script>