import { PortalRequest as request } from "@/utils/request";

/**
 * 服务市场分页查询
 */
export const getServicePageList = (params,data) => {
    return request({
        url: "/ser-service-market/page-list-me",
        method: "post",
        params: params,
        data,
    });
};

/**
 * 服务市场详情
 * @param id
 * @returns {*}
 */
export const getServiceMarket = (id) => {
    return request({
        url: `/ser-service-market/info/${id}`,
        method: 'get'
    })
}


/**
 * 服务市场-服务提供商
 * @param keyWord
 * @returns {*}
 */
export const getIsvListByKeyWord = (keyWord) => {
    return request({
        url: `/ser-service-market/isv-list?keyWord=${keyWord}`,
        method: 'get'
    })
}



/**
 * 创建服务
 * @param data
 * @returns {AxiosPromise}
 */
export const saveService = (data) => {
    return request({
        url: "/ser-service-market/save",
        method: "post",
        data,
    });
};

/**
 * 服务分类详情
 * @param id
 * @returns {*}
 */
export const getServiceType = (id) => {
    return request({
        url: `/ser-service-type/info/${id}`,
        method: 'get'
    })
}

/**
 * 删除服务市场
 * @param id
 * @returns {*}
 */
export const deleteServiceMarket = (id) => {
    return request({
        url: `/ser-service-market/remove/${id}`,
        method: 'delete'
    })
}


/**
 * 删除服务分类
 * @param id
 * @returns {*}
 */
export const deleteServiceType = (id) => {
    return request({
        url: `/ser-service-type/remove/${id}`,
        method: 'delete'
    })
}

/**
 * 检查服务分类是否存在
 * @param data
 * @returns {*}
 */
export const checkRepeatServiceType = (data) => {
    return request({
        url: '/ser-service-type/check-repeat',
        method: 'post',
        data
    })
}


/**
 * 服务分类树
 * @returns {*}
 */
export const getServiceTypeTreeData = (id) => {
    return request({
        url: '/ser-service-type/tree-data',
        method: 'get'
    })
}

/**
* 更新状态
* @param id
* @param status
* @returns {*}
*/
export const updateStatus = (id, status, data) => {
    return request({
        url: `/ser-service-market/${id}/${status}/updateStatus`,
        method: 'post',
        data
    })
}

/**
 * 分页查看服务市场-全部服务市场日志
 * @param pageParams
 * @param data
 * @returns {*}
 */
export const getServiceMarketLogList = (pageParams, data) => {
    return request({
        url: '/ser-service-market/log/page-list',
        method: 'post',
        params: pageParams,
        data
    })
}


// 服务商详情
export const getServiceIsvInfo = (id) => {
    return request({
        url: `/ser-service-market/isv-info/${id}`,
        method: "get",
    });
};


// 服务市场详情
export const getServiceInfo = (id) => {
    return request({
        url: `/ser-service-market/info/${id}`,
        method: "get",
    });
};

