<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
      :default-config="toolbarConfig"
      :editor="editor"
      :mode="mode"
      style="border-bottom: 1px solid #ccc"
    />
    <Editor
      v-model="val"
      :default-config="editorConfig"
      :mode="mode"
      style="height: 500px; overflow-y: hidden;"
      @onBlur="$emit('blur')"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { i18nChangeLanguage } from "@wangeditor/editor"
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { mapGetters } from 'vuex'

i18nChangeLanguage('zh-CN')

export default Vue.extend({
  components: {
    Editor,
    Toolbar
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    fileTypeCode: {
      type: String,
      default: '9999'
    },
    placeholder: {
      type: String,
      default: '请填写'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editor: null,
      toolbarConfig: {},
      editorConfig: {
        placeholder: this.placeholder
      },
      mode: 'default' // or 'simple'
    }
  },
  computed: {
    ...mapGetters(['settings']),
    uploadImage () {
      const {
        action,
        headers
      } = this
      const maxFileSize = 5 * 1024 * 1024
      const that = this
      const { settings } = this
      return {
        server: action,
        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
        fieldName: 'file',
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize,
        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/*'],
        // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
        // meta: {
        //   token: 'xxx',
        //   otherKey: 'yyy'
        // },
        // 将 meta 拼接到 url 参数中，默认 false
        // metaWithUrl: false,
        // 自定义增加 http  header
        headers,
        // 跨域是否传递 cookie ，默认为 false
        withCredentials: true,
        // 超时时间，默认为 10 秒
        timeout: 5 * 1000,
        // 上传之前触发
        onBeforeUpload (file) {
          // file 选中的文件，格式如 { key: file }
          return file
          // 可以 return
          // 1. return file 或者 new 一个 file ，接下来将上传
          // 2. return false ，不上传这个 file
        },
        // 上传进度的回调函数
        onProgress (progress) {
          // progress 是 0-100 的数字
          console.log('progress:', progress)
        },
        // 单个文件上传成功之后
        onSuccess (file, res) {
          console.log(`${file.name} 上传成功`, res)
        },
        // 单个文件上传失败
        onFailed (file, res) {
          console.log(`${file.name} 上传失败`, res)
        },
        // 上传错误，或者触发 timeout 超时
        onError (file, err, res) {
          if (file.size > maxFileSize) {
            that.$message.warn('上传图片文件不能超过5M')
          }
        },
        // 自定义插入图片
        customInsert (res, insertFn) {
          // res 即服务端的返回结果
          // 从 res 中找到 url alt href ，然后插图图片
          insertFn(`${settings.OBS_PREFIX}/${res.data.id}`, res.data.fileName, `${settings.OBS_PREFIX}/${res.data.id}`)
        }
      }
    },
    uploadVideo () {
      const {
        action,
        headers
      } = this
      const that = this
      const maxFileSize = 10 * 1024 * 1024
      return {
        server: action,
        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
        fieldName: 'file',
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize,
        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['video/*'],
        // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
        // meta: {
        //   token: 'xxx',
        //   otherKey: 'yyy'
        // },
        // 将 meta 拼接到 url 参数中，默认 false
        // metaWithUrl: false,
        // 自定义增加 http  header
        headers,
        // 跨域是否传递 cookie ，默认为 false
        withCredentials: true,
        // 超时时间，默认为 10 秒
        timeout: 5 * 1000,
        // 上传之前触发
        onBeforeUpload (file) {
          console.log('onBeforeUpload', file)
          // file 选中的文件，格式如 { key: file }
          return file
          // 可以 return
          // 1. return file 或者 new 一个 file ，接下来将上传
          // 2. return false ，不上传这个 file
        },
        // 上传进度的回调函数
        onProgress (progress) {
          // progress 是 0-100 的数字
          console.log('progress:', progress)
        },
        // 单个文件上传成功之后
        onSuccess (file, res) {
          console.log('onSuccess:', `${file.name} 上传成功`, res)
        },
        // 单个文件上传失败
        onFailed (file, res) {
          console.log('onFailed:', `${file.name} 上传失败`, res)
        },
        // 上传错误，或者触发 timeout 超时
        onError (file, err, res) {
          if (file.size > maxFileSize) {
            that.$message.warn('上传视频文件不能超过10M')
          }
          console.log(`${file.name} 上传出错`, file, err, res)
        },
        // 自定义插入
        customInsert (res, insertFn) {
          // res 即服务端的返回结果
          // 从 res 中找到 url alt href ，然后插图图片
          insertFn(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${res.data.id}`, res.data.fileName, `${process.env.VUE_APP_API_PORTAL_URL}/file/image/${res.data.id}`)
        }
      }
    },
    val: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    action () {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`
    },
    headers () {
      const token = storage.get(ACCESS_TOKEN)
      return {
        Authorization: `Bearer ${token}`
      }
    }
  },
  watch: {
    disabled (val, old) {
      const { editor } = this
      if (val && editor && !editor.isDisabled()) {
        editor.disable()
      } else if (!val && editor && editor.isDisabled()) {
        editor.enable()
      }
    }
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated (editor) {
      const {
        uploadImage,
        uploadVideo
      } = this
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      Object.keys(uploadImage).forEach(k => {
        this.editor.getMenuConfig('uploadImage')[k] = uploadImage[k]
      })
      Object.keys(uploadVideo).forEach(k => {
        this.editor.getMenuConfig('uploadVideo')[k] = uploadVideo[k]
      })
      if (this.disabled && !editor.isDisabled()) {
        editor.disable()
      } else if (!this.disabled && editor.isDisabled()) {
        editor.enable()
      }
    }
  }
})
</script>
