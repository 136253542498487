<template>
  <a-modal
    v-model="visible"
    width="640px"
    wrap-class-name="edit-phone-modal"
    title="重置账号密码"
    @cancel="cancelModal"
  >
    <div class="edit-password-box">
      <div>
        <span>旧密码：</span>
        <div>
          <a-input-password style="width: 464px" placeholder="请输入旧密码" v-model="oldPassword"/>
        </div>
      </div>
      <div>
        <span>新密码：</span>
        <div>
          <a-input-password
            style="width: 464px"
            placeholder="6~20位新密码，至少含字母和数字，不能包含空格"
            v-model="newPassword"
          />
        </div>
      </div>
      <div>
        <span>确认新密码：</span>
        <div>
          <a-input-password
            style="width: 464px"
            v-model="passVal"
            placeholder="请确认新密码"
          />
          <div class="password-grade">
            <div>
              <span :class="{'active':msgText > 0}"></span>
              <span :class="{'active':msgText > 1}"></span>
              <span :class="{'active':msgText > 2}"></span>
            </div>
            <span v-if="msgText > 0">密码强度：{{msgText == 0 || msgText <= 1?'低':''}}{{msgText == 2?'中':''}}{{msgText > 2?'高':''}}</span>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <a-button @click="cancelModal">取 消</a-button>
      <a-button class="btn-red" @click="submit">确 定</a-button>
    </template>
  </a-modal>
</template>
<script>
import { setResetPassword } from "@/api/register";
export default {
  props: ["show"],
  data() {
    return {
      visible: false,
      msgText: "",
      passVal: "",
      oldPassword:'',
      newPassword:''
    };
  },
  watch: {
    show: function (val) {
      this.visible = val;
    },
    passVal(newname) {
      if(newname.length >= 6){
          this.msgText = this.checkStrong(newname);
      }else{
          this.msgText = 0
      }
    },
  },
  methods: {
    // 提交修改
    submit(){
      let {oldPassword,newPassword,passVal} = this
      let reg = /^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{6,20}$/
      if(!oldPassword){
        this.$message.warning('请输入旧密码');
        return
      }
      if(!reg.test(newPassword)){
        this.$message.warning('新密码输入有误，由字母、数字、特殊字符，任意2种组成长度6-20位');
        return
      }
      if(passVal != newPassword){
        this.$message.warning('密码输入不一致');
        return
      }
      let data = {
        oldPassword,
        newPassword
      }
      setResetPassword(data).then((res)=>{
        this.$message.success('修改成功');
        this.visible = false;
        this.$emit("input", false);
      })
    },
    cancelModal() {
      this.visible = false;
      this.$emit("input", false);
    },
    // 密码强度
    checkStrong(sValue) {
      var modes = 0;
      //正则表达式验证符合要求的
      if (sValue.length < 1) return modes;
      if (/\d/.test(sValue)) modes++; //数字
      if (/[a-z]/.test(sValue)) modes++; //小写
      if (/[A-Z]/.test(sValue)) modes++; //大写
      if (/\W/.test(sValue)) modes++; //特殊字符

      //逻辑处理
      switch (modes) {
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
        case 4:
          return sValue.length < 4 ? 3 : 4;
      }
      return modes;
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-password-box {
  > div {
    display: flex;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    > span {
      line-height: 32px;
      width: 96px;
    }
    > div {
      .password-grade {
        display: flex;
        align-items: center;
        margin-top: 16px;
        height: 12px;
        > div {
          display: flex;
          > span {
            width: 80px;
            height: 4px;
            background: #f5f5f5;
            margin-right: 4px;
            &:last-child{
                margin-right: 16px;
            }
            &.active{
                background: #52C41A;
            }
          }
        }
        >span{
            font-size: 12px;
            line-height: 12px;
            color: #262626;
        }
      }
    }
  }
}
</style>
<style>
.edit-phone-modal .ant-modal-body {
  padding: 40px;
}
</style>
