<template>
  <component
    :is="component"
    v-model="val"
    :disabled="disabled"
    :file-type-code="fileTypeCode"
    :placeholder="placeholder"
    @blur="$emit('blur')"
  />
</template>

<script>

import VQuillEditor from '@/components/VQuillEditor'
import VWangEditor from '@/components/VWangEditor'

export default {
  name: 'VEditor',
  components: {
    // Qulli Editor [图片上传有问题]
    qulli: VQuillEditor,
    // Wang Editor
    wang: VWangEditor
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: null
    },
    fileTypeCode: {
      type: String,
      default: '9999'
    },
    placeholder: {
      type: String,
      default: '请填写'
    },
    component: {
      type: String,
      default: 'wang'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    val: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>
    .ql-editor {
        height: 300px;
    }
</style>
