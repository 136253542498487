import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);
const routes = [
  {
    path: "/",
    component: () => import("@/page/userLayout"),
    children: [
      {
        path: "/",
        name: "userHome",
        meta: {
          type: "info",
        },
        component: () => import("@/page/userHome"),
      },
      {
        path: "/sceneManage",
        name: "sceneManage",
        redirect: "/sceneManage/scene",
        component: () => import("@/page/sceneManage"),
        children: [
          {
            path: "/sceneManage/scene",
            name: "sceneList",
            meta: {
              type: "info",
            },
            component: () => import("@/page/sceneManage/scene"),
          },
          {
            path: "/sceneManage/addScene",
            name: "addScene",
            meta: {
              type: "info",
            },
            component: () => import("@/page/sceneManage/addScene"),
          },
          {
            path: "/sceneManage/sceneInfo",
            name: "sceneInfo",
            meta: {
              type: "info",
            },
            component: () => import("@/page/sceneManage/sceneInfo"),
          },
          {
            path: "/sceneManage/bangdan",
            name: "mybangdan",
            meta: {
              type: "info",
            },
            component: () => import("@/page/sceneManage/bangdan"),
          },
        ],
      },
      {
        path: "/userLayout/userScenes",
        name: "userScenes",
        meta: {
          type: "info",
        },
        component: () => import("@/page/userScenes"),
      },
      {
        path: "/userLayout/userScenes/info",
        name: "userScenesInfo",
        meta: {
          type: "info",
        },
        component: () => import("@/page/userScenes/info"),
      },
      {
        path: "/userLayout/userScenes/add",
        name: "userScenesAdd",
        meta: {
          type: "info",
        },
        component: () => import("@/page/userScenes/addScenes"),
      },
      {
        path: "/serveManage",
        name: "serveManage",
        meta: {
          type: "info",
        },
        component: () => import("@/page/serveManage"),
      },
      {
        path: "/serveManage/releaseServe",
        name: "releaseServe",
        meta: {
          type: "info",
        },
        component: () => import("@/page/serveManage/releaseServe"),
      },
      {
        path: "/serveManage/details",
        name: "service_details",
        meta: {
          type: "info",
        },
        component: () => import("@/page/serveManage/service_details"),
      },

      {
        path: "/demandManage",
        name: "demandManage",
        meta: {
          type: "info",
        },
        component: () => import("@/page/demandManage"),
      },
      {
        path: "/demandManage/demand",
        name: "demandDetail",
        meta: {
          type: "info",
        },
        component: () => import("@/page/demandManage/demand"),
      },
      {
        path: "/demandManage/detail",
        name: "demandInfo",
        meta: {
          type: "info",
        },
        component: () => import("@/page/demandManage/detail"),
      },

      {
        path: "/enterpriseManage",
        name: "enterpriseManage",
        redirect: "/enterpriseManage/info",
        component: () => import("@/page/enterpriseManage"),
        children: [
          {
            path: "/enterpriseManage/info",
            name: "enterpriseInfo",
            meta: {
              type: "info",
            },
            component: () => import("@/page/enterpriseManage/info"),
            children: [
              {
                path: "/enterpriseManage/info",
                name: "basicInfo",
                meta: {
                  type: "info",
                },
                component: () => import("@/page/enterpriseManage/basicInfo"),
              },
              {
                path: "/enterpriseManage/coreMembers",
                name: "coreMembers",
                meta: {
                  type: "info",
                },
                component: () => import("@/page/enterpriseManage/coreMembers"),
              },
              {
                path: "/enterpriseManage/projectCase",
                name: "projectCase",
                meta: {
                  type: "info",
                },
                component: () => import("@/page/enterpriseManage/projectCase"),
              },
              {
                path: "/enterpriseManage/certificateList",
                name: "certificateList",
                meta: {
                  type: "info",
                },
                component: () =>
                  import("@/page/enterpriseManage/certificateList"),
              },
            ],
          },
          {
            path: "/enterpriseManage/verify",
            name: "enterpriseVerify",
            meta: {
              type: "info",
            },
            component: () => import("@/page/enterpriseManage/verify"),
          },
          {
            path: "/enterpriseManage/member",
            name: "enterpriseMember",
            meta: {
              type: "info",
            },
            component: () => import("@/page/enterpriseManage/member"),
          },
          {
            path: "/enterpriseManage/role",
            name: "enterpriseRole",
            meta: {
              type: "info",
            },
            component: () => import("@/page/enterpriseManage/role"),
          },
        ],
      },
      // 互动中心
      {
        path: "/interactive",
        name: "interactive",
        redirect: "/interactive/userCollect",
        component: () => import("@/page/interactive"),
        children: [
          {
            path: "/interactive/userCollect",
            name: "userCollect",
            meta: {
              type: "info",
            },
            component: () => import("@/page/interactive/userCollect"),
          },
          {
            path: "/interactive/userActive",
            name: "userActive",
            meta: {
              type: "info",
            },
            component: () => import("@/page/interactive/userActive"),
          },
          {
            path: "/interactive/userConsult",
            name: "userConsult",
            meta: {
              type: "info",
            },
            component: () => import("@/page/interactive/userConsult"),
          },
          {
            path: "/interactive/userList",
            name: "userList",
            meta: {
              type: "info",
            },
            component: () => import("@/page/interactive/userList"),
          },
        ],
      },

      {
        path: "/devManage",
        name: "/devManage",
        meta: {
          type: "info",
        },
        component: () => import("@/page/devManage"),
      },
      {
        path: "/userLayout/userInteractive",
        name: "userInteractive",
        meta: {
          type: "info",
        },
        component: () => import("@/page/userInteractive"),
      },
      /*  {
        path: "/userLayout/userConsult",
        name: "userConsult",
        meta: {
          type: "info",
        },
        component: () => import("@/page/userConsult"),
      },
      {
        path: "/userLayout/userCollect",
        name: "userCollect",
        meta: {
          type: "info",
        },
        component: () => import("@/page/userCollect"),
      },*/
      {
        path: "/userLayout/userMessage",
        name: "userMessage",
        meta: {
          type: "message",
        },
        component: () => import("@/page/userMessage"),
      },

      {
        path: "/userLayout/userInfo",
        name: "userInfo",
        meta: {
          type: "settings",
        },
        component: () => import("@/page/userHub/userInfo"),
      },
      {
        path: "/userLayout/userInfo/addCompany",
        name: "addCompany",
        meta: {
          type: "settings",
        },
        component: () => import("@/page/userHub/addCompany"),
      },
      {
        path: "/userLayout/userInfo/createCompany",
        name: "createCompany",
        meta: {
          type: "settings",
        },
        component: () => import("@/page/userHub/createCompany"),
      },
      {
        path: "/userLayout/userInfo/associationEnterprise",
        name: "associationEnterprise",
        meta: {
          type: "settings",
        },
        component: () => import("@/page/userHub/associationEnterprise"),
      },
      {
        path: "/userLayout/userCer",
        name: "userCer",
        meta: {
          type: "settings",
        },
        component: () => import("@/page/userCertification/index"),
      },
      {
        path: "/userLayout/userCerResult",
        name: "userCerResult",
        meta: {
          type: "settings",
        },
        component: () => import("@/page/userCertification/userCerResult"),
      },

      {
        path: "/userLayout/userSettings",
        name: "userSettings",
        meta: {
          type: "settings",
        },
        component: () => import("@/page/userSettings"),
      },
      {
        path: "/userLayout/myScenes",
        name: "myScenes",
        redirect: "/userLayout/myScenes/list",
        component: () => import("@/page/myScenes"),
        meta: { permission: ["user"], type: "info" },
        children: [
          {
            path: "/userLayout/myScenes/list",
            name: "list",
            component: () => import("@/page/myScenes/list"),
            meta: { permission: ["user"], type: "info" },
          },
          {
            path: "/userLayout/myScenes/add",
            name: "myScenesAdd",
            component: () => import("@/page/myScenes/apply"),
            meta: { permission: ["user"], type: "info" },
          },
          {
            path: "/userLayout/myScenes/edit/:id",
            name: "myScenesEdit",
            component: () => import("@/page/myScenes/apply"),
            meta: { permission: ["user"], type: "info" },
          },
          {
            path: "/userLayout/myScenes/detail/:id",
            name: "myScenesDetail",
            component: () => import("@/page/myScenes/detail"),
            meta: { permission: ["user"], type: "info" },
          },
        ],
      },
      {
        path: "/userLayout/myBangDan",
        name: "myBangDan",
        redirect: "/userLayout/myBangDan/faBang",
        component: () => import("@/page/myBangDan"),
        meta: { permission: ["user"], type: "info" },
        children: [
          {
            path: "/userLayout/myBangDan/faBang",
            name: "faBang",
            component: () => import("@/page/myBangDan/faBang"),
            meta: { permission: ["user"], type: "info" },
          },
          {
            path: "/userLayout/myBangDan/jieBang",
            name: "jieBang",
            component: () => import("@/page/myBangDan/jieBang"),
            meta: { permission: ["user"], type: "info" },
          },
          {
            path: "/userLayout/myBangDan/invite",
            name: "invite",
            component: () => import("@/page/myBangDan/invite"),
            meta: { permission: ["user"], type: "info" },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
