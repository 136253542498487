<template>
  <a-upload
    name="file"
    list-type="picture-card"
    class="avatar-uploader"
    accept="image/*,.jpg,.png"
    :file-list="fileList"
    :action="action"
    :disabled="disabled"
    :before-upload="beforeUpload"
    :style="widthHeightStyle"
    :headers="headers"
    @change="handleChange"
    :remove="removeFile"
    :show-upload-list="{ showPreviewIcon: false }"
  >
    <div v-if="fileList.length < 3">
      <a-icon :type="loading ? 'loading' : 'plus'" />
      <div class="ant-upload-text">上传</div>
    </div>
  </a-upload>
</template>
<script>
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";

export default {
  name: "VUploadAvatar",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
    },
    lists: {
      type: Array,
      default: function () {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileTypeCode: {
      type: String,
      required: true,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    background: {
      type: String,
    },
    backgroundText: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      fileList: [],
    };
  },
  computed: {
    headers() {
      const token = storage.get(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },
    widthHeightStyle() {
      const { width, height } = this;
      return {
        width,
        height,
        // maxWidth: "100%",
      };
    },
    action() {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`;
    },
    src() {
      return `${process.env.VUE_APP_OBS_PREFIX}/${this.value}`;
    },
  },
  mounted() {
    console.log(this.lists);
    this.fileList = this.lists;
  },
  methods: {
    handleChange(info) {
      this.fileList = info.fileList;
      if (info.file.status === "uploading") {
        this.loading = true;
      } else if (info.file.status === "done") {
        this.loading = false;
        this.fileName = info.file.name;
        this.$emit("input", info.file.response.data.id);
        this.$emit("change", info.file.response.data.id);
        this.$emit("list", this.fileList);
      } else if (info.file.status === "error") {
        this.loading = false;
        this.$emit("error", info.file);
      }
    },
    removeFile(file) {
      let { fileList } = this;
      let index = 0;
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].uid === file.uid) {
          index = i;
          fileList.splice(i, 1);
        }
      }
      this.fileList = fileList;
      this.$emit("list", fileList);
      this.$emit("remove", index);
    },
    beforeUpload(file) {
      const isImage = file.type.indexOf("image") !== -1;
      if (!isImage) {
        this.$message.error("只能上传图片!");
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("文件不能超过10MB!");
      }
      return isImage && isLt10M;
    },
  },
};
</script>
<style>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.user-info-enterprise-avatar-box {
  width: 104px;
  height: 104px;
  background: #ea0b06;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  padding: 26px 23px;
  line-height: 26px;
  margin: 0 auto;
}
</style>
