<template>
  <img src="@/assets/img/cjspicon.png" alt="场景申报" v-if="type == 6"/>
  <img src="@/assets/img/cyjricon.png" alt="成员加入" v-else-if="type == 3 || type == 4"/>
  <!-- <img src="@/assets/img/sjyyicon.png" alt="上架服务" v-else-if="type == 7"/> -->
  <img src="@/assets/img/qyddicon.png" alt="迁移订单" v-else-if="type == 5"/>
  <img src="@/assets/img/qyrzicon.png" alt="企业认证" v-else-if="type == 1 || type == 2"/>
  <!-- <img src="@/assets/img/bbdsicon.png" alt="榜单定帅" v-else-if="type == 12"/> -->
  <img src="@/assets/img/fwddicon.png" alt="服务订单" v-else-if="type == 7"/>
</template>

<script>
export default {
    props:["type"]
}
</script>

<style>

</style>