<template>
  <span>
    {{ list.map((e) => e.permissionName).join("、") }}
  </span>
</template>

<script>
import { getTotalPermissionList } from "@/api/res-role";

export default {
  props: {
    permissionIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      permissions: [],
    };
  },
  computed: {
    list() {
      const { permissionIds } = this;
      return this.permissions.filter((e) => permissionIds.indexOf(e.id) !== -1);
    },
  },
  async mounted() {
    this.permissions = await getTotalPermissionList();
  },
};
</script>

<style scoped></style>
