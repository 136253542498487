import axios from "axios";
import store from "@/store";
import storage from "store";
import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "@/store/mutation-types";
//是否有请求正在刷新token
let isRefreshing = false;
// 重试请求队列 每一项都是一个待执行待函数
let requests = [];
// portal-api
const PortalRequest = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_PORTAL_URL,
  timeout: 50000, // 请求超时时间
});

PortalRequest.interceptors.request.use(
  (config) => {
    const token = storage.get(ACCESS_TOKEN);
    /*
     * 如果 token 存在
     * 让每个请求携带自定义 token 请根据实际情况自行修改
     */
    if (config.url.startsWith("/common") || config.url.startsWith("/auth")) {
      return config;
    }
    if (token && !config.headers.Authorization) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
PortalRequest.interceptors.response.use(
  (response) => {
    return response.data.data;
  },
  (error) => {
    if (error.response) {
      const data = error.response.data || {};
      const errorDescription = data.error_description || data.msg;
      const { config } = error.response;
      switch (error.response.status) {
        case 401:
          if (config.url === "/auth/refreshToken") {
            store.dispatch("Logout");
            isRefreshing = false;
            break;
          } else if (!isRefreshing) {
            isRefreshing = true;
            return store
              .dispatch("RefreshToken", storage.get(REFRESH_TOKEN))
              .then((access_token) => {
                config.headers["Authorization"] = `Bearer ${access_token}`;
                config.baseURL = process.env.VUE_APP_API_PORTAL_URL;
                // 已经刷新了token，将所有队列中的请求进行重试
                requests.forEach((cb) => cb(access_token));
                // 重试完了别忘了清空这个队列（掘金评论区同学指点）
                requests = [];
                return PortalRequest(config);
              })
              .catch((e) => {
                console.log(e);
                store.dispatch("Logout");
              })
              .finally(() => {
                isRefreshing = false;
              });
          } else {
            return new Promise((resolve) => {
              // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
              requests.push((token) => {
                config.baseURL = process.env.VUE_APP_API_PORTAL_URL;
                config.headers["Authorization"] = `Bearer ${token}`;
                resolve(PortalRequest(config));
              });
            });
          }
        default:
          notification.warning({
            message: "提示",
            description: errorDescription,
          });
          break;
      }
    }
    throw error;
  }
);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, PortalRequest);
  },
};

export default PortalRequest;

export { installer as VueAxios, PortalRequest };
