<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      class="enterprise-manage-form"
    >
      <div>
        <a-form-model-item
          :label-col="{ span: 4, offset: 0 }"
          ref="companyName"
          prop="companyName"
          class="enterprise-head-item"
        >
          <div class="enterprise-head-box">
            <div class="enterprise-logo">
              <v-upload-avatar
                width="106px"
                height="106px"
                :background="
                  form.companyLogoAttmId
                    ? ''
                    : require('@/assets/img/uploadamo.png')
                "
                :background-text="
                  form.companyName ? form.companyName.substring(0, 4) : ''
                "
                :value="form.companyLogoAttmId"
                v-model="form.companyLogoAttmId"
                font-size="32px"
                file-type-code="1011"
                :disabled="isEdit"
              />
              <div class="upload-text" v-if="!form.companyLogoAttmId">
                上传图片
              </div>
            </div>
            <div class="enterprise-name">
              <p><span>*</span>企业名称：</p>
              <a-input
                v-model="form.companyName"
                placeholder="请输入企业名称"
                @blur="
                  () => {
                    $refs.companyName.onFieldBlur();
                  }
                "
                style="width: 630px"
                :disabled="isEdit || company_status != 1"
              />
              <div v-if="company_status == 1">
                <a-button
                  class="btn-color-red"
                  @click="$router.push('/enterpriseManage/verify')"
                  >前往认证</a-button
                >
                <span>完成认证后可享受更多权益</span>
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item
          ref="companyType"
          label="企业类型"
          prop="companyType"
        >
          <a-select
            :disabled="isEdit"
            v-model="form.companyType"
            mode="multiple"
            style="width: 630px"
            placeholder="请选择所属类型"
          >
            <a-select-option
              v-for="el in companyTypeList"
              :key="el.id"
              :value="el.itemValue"
            >
              {{ el.itemText }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          ref="companyField"
          label="所属领域（选填）"
          prop="companyField"
        >
          <a-select
            :disabled="isEdit"
            v-model="form.companyField"
            mode="multiple"
            style="width: 630px"
            placeholder="请选择所属领域"
          >
            <a-select-option
              v-for="el in companyFieldList"
              :key="el.id"
              :value="el.itemValue"
            >
              {{ el.itemText }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="所属行业" prop="industryType">
          <v-select-dict
            :disabled="isEdit"
            v-model="form.industryType"
            placeholder="请选择所属行业"
            style="width: 630px"
            code="INDUSTRY"
          />
        </a-form-model-item>
        <a-form-model-item label="企业规模" prop="companyScaleType">
          <v-select-dict
            :disabled="isEdit"
            v-model="form.companyScaleType"
            placeholder="请选择企业规模"
            style="width: 630px"
            code="COMPANY_SCALE_TYPE"
          />
        </a-form-model-item>
        <a-form-model-item label="成立时间" required prop="establishmentDate">
          <a-date-picker
            :disabled="isEdit"
            @change="companyTimeChange"
            v-model="form.establishmentDate"
            placeholder="请选择日期"
            format="YYYY-MM-DD"
            style="width: 630px"
          />
        </a-form-model-item>
        <a-form-model-item
          label="法人代表"
          ref="legalPersonName"
          prop="legalPersonName"
        >
          <a-input
            v-model="form.legalPersonName"
            placeholder="请输入法人代表"
            @blur="
              () => {
                $refs.legalPersonName.onFieldBlur();
              }
            "
            style="width: 630px"
            :disabled="isEdit || company_status != 1"
          />
        </a-form-model-item>
        <a-form-model-item
          label="统一社会信用代码"
          ref="socialCreditCode"
          prop="socialCreditCode"
        >
          <a-input
            v-model="form.socialCreditCode"
            placeholder="请输入统一社会信用代码"
            @blur="
              () => {
                $refs.socialCreditCode.onFieldBlur();
              }
            "
            style="width: 630px"
            :disabled="isEdit || company_status != 1"
          />
        </a-form-model-item>
        <a-form-model-item
          label="公司地址"
          ref="companyAddress"
          prop="companyAddress"
        >
          <a-input
            v-model="form.companyAddress"
            placeholder="请输入公司地址"
            @blur="
              () => {
                $refs.companyAddress.onFieldBlur();
              }
            "
            style="width: 630px"
            :disabled="isEdit || company_status != 1"
          />
        </a-form-model-item>
        <a-form-model-item label="企业网址" prop="website">
          <a-input
            v-model="form.website"
            placeholder="请输入企业网址"
            style="width: 630px"
            :disabled="isEdit"
          />
        </a-form-model-item>
        <a-form-model-item label="企业简介" prop="companyBrief">
          <a-textarea
            v-model="form.companyBrief"
            :disabled="isEdit"
            placeholder="请输入企业简介"
            :auto-size="{ minRows: 5, maxRows: 8 }"
            style="width: 630px"
            :max-length="300"
            :rows="3"
          />
        </a-form-model-item>
        <a-form-model-item label="主营业务（选填）" prop="mainProducts">
          <a-textarea
            v-model="form.mainProducts"
            :disabled="isEdit"
            placeholder="请输入主营业务"
            style="width: 630px"
            :rows="3"
          />
        </a-form-model-item>
      </div>
      <p>联系信息</p>
      <div>
        <a-form-model-item
          label="联系人姓名"
          ref="contactPerson"
          prop="contactPerson"
        >
          <a-input
            v-model="form.contactPerson"
            placeholder="请输入联系人姓名"
            @blur="
              () => {
                $refs.contactPerson.onFieldBlur();
              }
            "
            style="width: 630px"
            :disabled="isEdit"
          />
        </a-form-model-item>
        <a-form-model-item
          label="联系手机号"
          ref="contactPhone"
          prop="contactPhone"
        >
          <a-input
            v-model="form.contactPhone"
            placeholder="请输入联系手机号"
            @blur="
              () => {
                $refs.contactPhone.onFieldBlur();
              }
            "
            style="width: 630px"
            :disabled="isEdit"
          />
        </a-form-model-item>
        <a-form-model-item label="邮箱" prop="email">
          <a-input
            v-model="form.email"
            placeholder="请输入邮箱"
            style="width: 630px"
            :disabled="isEdit"
          />
        </a-form-model-item>
        <a-form-model-item label="座机" prop="landline">
          <a-input
            v-model="form.landline"
            placeholder="请输入座机号"
            style="width: 630px"
            :disabled="isEdit"
          />
        </a-form-model-item>
      </div>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <template v-if="isEdit">
          <a-button class="btn-red" @click="editSubmit"> 修 改 </a-button>
          <!-- <a-button class="btn-color-red" style="margin-left: 10px" v-if="!isOwner" @click="unbind">退出企业</a-button> -->
        </template>
        <template v-else>
          <a-button class="btn-red" @click="onSubmit"> 保 存 </a-button>
          <a-button
            class="btn-color-red"
            style="margin-left: 10px"
            @click="resetForm"
            >取 消</a-button
          >
        </template>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import VUploadAvatar from "@/components/VUploadAvatar.vue";
import VSelectDict from "@/components/VSelectDict";
import { mapGetters, mapActions } from "vuex";
import { getCompanyInfo, createCompany } from "@/api/res-company";
import { companyIsOwner, quitCompany } from "@/api/enterpriseManage";
export default {
  components: {
    VUploadAvatar,
    VSelectDict,
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      isEdit: true,
      other: "",
      isOwner: false,
      companyTypeList: [],
      companyFieldList: [],
      form: {
        companyField: [],
        companyLogoAttmId: "",
        companyName: "",
        companyType: undefined,
        industryType: undefined,
        companyScaleType: undefined,
        establishmentDate: undefined,
        legalPersonName: "",
        socialCreditCode: "",
        companyAddress: "",
        website: "",
        companyBrief: "",
        mainProducts: "",
        contactPerson: "",
        contactPhone: "",
        email: "",
        landline: "",
      },
      rules: {
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        companyType: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        industryType: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        companyScaleType: [
          { required: true, message: "请选择企业规模", trigger: "change" },
        ],
        establishmentDate: [
          { required: true, message: "请选择成立时间", trigger: "change" },
        ],
        legalPersonName: [
          { required: true, message: "请输入法人代表", trigger: "blur" },
        ],
        socialCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        companyAddress: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
        companyBrief: [
          { required: true, message: "请输入企业简介", trigger: "blur" },
        ],
        contactPerson: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: mapGetters(["companyId", "userInfo", "company_status", "dict"]),
  created() {
    this.LoadDictData(["COMPANY_FIELD"]).then((res) => {
      this.companyFieldList = res.COMPANY_FIELD;
    });
    this.LoadDictData(["COMPANY_TYPE"]).then((res) => {
      this.companyTypeList = res.COMPANY_TYPE;
    });
  },
  mounted() {
    this.loadData();
    this.loadCompanyIsOwner();
  },
  methods: {
    ...mapActions(["UserCompany", "LoadDictData"]),
    loadData() {
      getCompanyInfo(this.companyId).then((company) => {
        this.form = company || {};
      });
    },
    editSubmit() {
      this.isEdit = false;
    },
    unbind() {
      let _this = this;
      this.$confirm({
        title: "提示",
        content: "您确定退出该企业？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          quitCompany({ companyId: _this.companyId }).then(async (res) => {
            _this.$store.commit("SET_COMPANY_ID", "");
            _this.$message.success("退出成功");
            await _this.UserCompany();
            setTimeout(() => {
              _this.$router.push("/");
            }, 500);
          });
        },
        onCancel() {},
      });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          createCompany(this.form).then(async (res) => {
            if (res) {
              this.$warning({
                title: res,
              });
            } else {
              await this.UserCompany();
              this.$message.success("修改成功");
              this.isEdit = true;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.isEdit = true;
      // this.$refs.ruleForm.resetFields();
    },
    companyTimeChange(data, date) {
      this.form.establishmentDate = date;
    },
    // 企业所有者还是成员
    loadCompanyIsOwner() {
      if (this.companyId) {
        companyIsOwner(this.companyId).then((res) => {
          this.isOwner = res;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.enterprise-head-box {
  display: flex;
  .enterprise-logo {
    width: 106px;
    height: 106px;
    margin-right: 152px;
    position: relative;
    .upload-text {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 106px;
      height: 22px;
      background: #ffffff;
      //border-radius: 0px 0px 4px 4px;
      opacity: 0.9;
      text-align: center;

      font-size: 12px;
      color: #ea0b06;
      line-height: 22px;
    }
    .enterprise-logo-name {
      width: 106px;
      height: 106px;
      background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
      border-radius: 4px;
      text-align: center;
      line-height: 106px;
      display: block;
      font-size: 52px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .enterprise-name {
    > p {
      font-size: 14px;
      color: #262626;
      line-height: 22px;
      margin-bottom: 12px;
      > span {
        color: #f5222d;
        margin-right: 2px;
      }
    }
    > div {
      display: flex;
      align-items: center;
      > span {
        margin-left: 8px;
        color: #8c8c8c;
        font-size: 12px;
      }
    }
  }
}
.enterprise-manage-form {
  > p {
    font-size: 16px;
    color: #262626;
    line-height: 24px;
    padding: 0 32px 15px;
    //padding-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;
  }
  > div {
    padding: 24px 32px 0;
    //padding-top: 24px;
  }
}
.enterprise-head-item {
  ::v-deep .ant-form-explain {
    padding-left: 258px;
  }
}
</style>
