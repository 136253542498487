import { PortalRequest as request } from "@/utils/request";

// 前台全部状态
export const commonCode = (params) => {
  return request({
    url: "/common-code/query",
    method: "get",
    params,
  });
};

// 前台场景列表
export const sceneList = (params, data) => {
  return request({
    url: "/scene/list-public-scene",
    method: "post",
    params,
    data,
  });
};

// 进行中的场景
export const sceneListOwns = (params, data) => {
  return request({
    url: "/scene/list-owns",
    method: "post",
    params,
    data,
  });
};

// 前台揭榜列表
export const biddingList = (params, data) => {
  return request({
    url: "/scene/list-admin-scene",
    method: "post",
    params,
    data,
  });
};
// 场景全部批次
export const batchList = () => {
  return request({
    url: "/scene/batch/list",
    method: "get",
  });
};
// 前台场景详情
export const sceneDetail = (sceneId) => {
  return request({
    url: `/scene/${sceneId}/info`,
    method: "get",
  });
};
// 前台相似场景
export const similarSceneList = (sceneId, params) => {
  return request({
    url: `/scene/${sceneId}/list-similar-scene`,
    method: "get",
    params,
  });
};
// 下载
export const downloadFile = (id) => {
  return request({
    url: `${process.env.VUE_APP_OBS_PREFIX}/${id}`,
    method: "get",
    responseType: "arraybuffer",
  });
};
// 前台全部方案记录
export const getSceneAllBid = (sceneId) => {
  return request({
    url: `/scene/${sceneId}/list-all-bid`,
    method: "get",
  });
};
// 前台方案详情
export const getSceneBidInfo = (sceneId, subid) => {
  return request({
    url: `/scene/${sceneId}/bid/${subid}/info`,
    method: "get",
  });
};

// 用户中心我的申报列表
export const getOwnCase = (params, data) => {
  return request({
    url: `/scene/list-own-case`,
    method: "post",
    params,
    data,
  });
};
// 用户中心直接提交一个申报
export const submitScene = (data) => {
  return request({
    url: `/scene/submit-from-zero`,
    method: "post",
    data,
  });
};
// 用户中心新建一个草稿
export const sceneCreateDraft = (data) => {
  return request({
    url: `/scene/create-draft`,
    method: "post",
    data,
  });
};
// 用户中心提交已有草稿申报
export const submitSceneDraft = (data) => {
  return request({
    url: `/scene/submit-from-draft`,
    method: "post",
    data,
  });
};
// 用户中心更新已有草稿
export const sceneUpdateDraft = (data) => {
  return request({
    url: `/scene/update-draft`,
    method: "post",
    data,
  });
};
// 用户中心我的揭榜列表
export const getBidScene = (params) => {
  return request({
    url: `/scene/list-bid-scene`,
    method: "post",
    params,
  });
};
// 用户中心我的发榜列表
export const getOwnScene = (params, data) => {
  return request({
    url: `/scene/list-own-scene`,
    method: "post",
    params,
    data,
  });
};
// 用户中心我的发榜终止揭榜
export const sceneCancelBid = (id) => {
  return request({
    url: `/scene/${id}/cancel-bid`,
    method: "post",
  });
};
// 我要揭榜
export const sceneBid = (id, data) => {
  return request({
    url: `/scene/${id}/bid`,
    method: "post",
    data,
  });
};
// 揭榜详情(我的方案)
export const sceneMyBidInfo = (id) => {
  return request({
    url: `/scene/${id}/my-bid-info`,
    method: "get",
  });
};
// 删除已有草稿
export const sceneDelete = (id) => {
  return request({
    url: `/scene/${id}/delete`,
    method: "delete",
  });
};
// 选择一个揭榜方
export const sceneChooseBid = (sceneid, bidid, data) => {
  return request({
    url: `/scene/${sceneid}/bid/${bidid}/choose`,
    method: "post",
    data,
  });
};
// 场景banner
export const getSceneAdBanner = () => {
  return request({
    url: `/ad/scene/background`,
    method: "get",
  });
};
export const getScenePublicity = () => {
  return request({
    url: `/ad/scene/publicity/list-all`,
    method: "get",
  });
};
