<template>
    <div class="user-home-my-consult">
        <div class="user-home-my-consult-title">
            <p>
                <span :class="{'active':tagIndex == 1}" @click="tagIndex = 1">最新服务</span>
                <span :class="{'active':tagIndex == 2}" @click="tagIndex = 2">最新场景</span>
            </p>
            <span @click="sceneList">更多</span>
        </div>
        <template v-if="list != ''">
            <div class="user-home-my-consult-list" v-show="tagIndex == 1">
                <div :key="el.id" v-for="el in list">
                    <p @click="sceneInfo(el.id)"><span>{{el.serviceName}}</span></p>
                    <span><i :class="{'4':'sh','2':'ds','3':'xj','5':'xj'}[el.publishStatus]"></i> {{el.publishStatus | dict('PUBLISH_STATUS')}}</span>
                </div>
            </div>
            <div class="user-home-my-consult-list" v-show="tagIndex == 2">
                <div :key="el.id" v-for="el in list">
                    <p @click="sceneInfo(el.id)"><span>{{el.title}}</span></p>
                    <span><i :class="{'2':'sh','3':'xj','4':'sh','5':'xj','6':'sh','8':'ds'}[el.sceneStatus]"></i> {{el.sceneStatus | dict('SCENE_STATUS')}}</span>
                </div>
            </div>
        </template>
        <a-empty v-else/>
    </div>
</template>

<script>
    import {getServicePageList} from '@/api/service'
    import {mapGetters} from "vuex";
    import {getOwnCase} from "@/api/cjkf/scn-scene";

    export default {
        data() {
            return {
                page: {
                    current: 1,
                    size: 4,
                },
                tagIndex: 1,
                list: ['1']
            }
        },
        computed: {
            ...mapGetters(["dict", "companyId", "tenantId"]),
        },
        watch: {
            tagIndex: function (val) {
                this.list = ['1']
                if (val === 1) {
                    this.loadGetServicePageList();
                } else {
                    this.loadData();
                }
            }
        },
        mounted() {
            this.loadGetServicePageList();
        },
        methods: {
            loadGetServicePageList() {
                let {companyId} = this
                getServicePageList(this.page, {companyId}).then(res => {
                    this.list = res.records
                })
            },
            loadData() {
                let {companyId, tenantId} = this
                getOwnCase(this.page, {companyId, tenantId}).then((res) => {
                    this.list = res.records;
                });
            },
            sceneInfo(id) {
                if (this.tagIndex == 1) {
                    this.$router.push(`/serveManage/details?id=${id}`);
                } else {
                    this.$router.push(`/sceneManage/sceneInfo?id=${id}`);
                }
            },
            sceneList() {
                if (this.tagIndex == 1) {
                    this.$router.push(`/serveManage`);
                } else {
                    this.$router.push(`/sceneManage`);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .user-home-my-consult {
        margin-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #e8e8e8;

        .user-home-my-consult-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            > p {
                display: flex;

                > span {
                    position: relative;
                    font-size: 16px;
                    color: #262626;
                    line-height: 24px;
                    margin-right: 24px;
                    cursor: pointer;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -8px;
                        height: 2px;
                        background: transparent;
                    }

                    &.active {
                        color: #ea0b06;

                        &::after {
                            background: #ea0b06;
                        }
                    }
                }
            }

            > span {
                color: #ea0b06;
                cursor: pointer;
                font-size: 14px;
            }
        }

        .user-home-my-consult-list {
            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                > p {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    > img {
                        width: 13px;
                        margin-right: 8px;
                    }

                    > span {
                        max-width: 420px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 22px;
                    }

                    &:hover {
                        > span {
                            color: #ea0b06;
                        }
                    }
                }

                > span {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #595959;
                    line-height: 22px;

                    > i {
                        width: 6px;
                        height: 6px;
                        margin-right: 8px;
                        border-radius: 50%;
                        background: #ccc;

                        &.jb {
                            background: #52c41a;
                        }

                        &.sh {
                            background: #facc14;
                        }

                        &.ds {
                            background: #1890ff;
                        }

                        &.xj {
                            background: #EA0B06;
                        }
                    }
                }
            }
        }
    }
</style>