<template>
  <div>
    <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>角色权限</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="enterprise-manage-role">
      <div>
        <p>角色权限</p>
        <a-table :columns="memberColumns" :data-source="roleList" row-key="id">
          <template slot="permissionIds" slot-scope="text, record">
            <permission-table-cell :permission-ids="text" />
          </template>
          <template slot="userNames" slot-scope="text, record">
            {{ (text || []).join("、") }}
            <!--          <role-user-table-cell :role-id="record.id" :company-id="companyId" />-->
          </template>
          <div
            slot="operate"
            class="enterprise-member-table-operate"
            slot-scope="text, record"
          >
            <span @click="handleEdit(record)" v-if="record.roleCode != '001'"
              >修改角色成员</span
            >
          </div>
        </a-table>
      </div>
      <role-user-modal ref="roleUserModalRef" @ok="loadData" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getResRoleList } from "@/api/res-role";
import PermissionTableCell from "@/page/components/PermissionTableCell";
import RoleUserTableCell from "@/page/components/RoleUserTableCell";
import RoleUserModal from "@/page/enterpriseManage/components/RoleUserModal";

const memberColumns = [
  {
    title: "角色编号",
    dataIndex: "roleCode",
    key: "roleCode",
    width: "100px",
  },
  {
    title: "角色名称",
    dataIndex: "roleName",
    key: "roleName",
    width: "120px",
  },
  {
    title: "角色权限",
    dataIndex: "permissionIds",
    key: "permissionIds",
    scopedSlots: { customRender: "permissionIds" },
    width: "500px",
  },
  {
    title: "角色成员",
    dataIndex: "userNames",
    key: "userNames",
    width: "135px",
    scopedSlots: { customRender: "userNames" },
  },
  {
    title: "操作",
    dataIndex: "operate",
    key: "operate",
    scopedSlots: { customRender: "operate" },
    width: "135px",
  },
];
export default {
  components: { RoleUserModal, RoleUserTableCell, PermissionTableCell },
  data() {
    return {
      memberColumns,
      roleList: [],
      visible: false,
    };
  },
  computed: {
    ...mapGetters(["companyId", "tenantId"]),
  },
  methods: {
    handleEdit(record) {
      this.$refs.roleUserModalRef.open(record);
    },
    async loadData() {
      const { companyId, tenantId } = this;
      this.roleList = await getResRoleList({ companyId, tenantId });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.enterprise-manage-role {
  height: calc(100% - 38px);
  padding-top: 24px;
  > div {
    min-height: 100%;
    background: #ffffff;
    border-radius: 2px;
    padding: 24px 32px;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 32px;
    }
    .enterprise-member-table-operate {
      display: flex;
      > span {
        color: #ea0b06;
        font-size: 14px;
        line-height: 14px;
        border-right: 1px solid #e9e9e9;
        padding-right: 8px;
        margin-right: 8px;
        cursor: pointer;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}
</style>
