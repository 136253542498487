<template>
  <verify-info v-if="state == 1" :companydata="company" :sourceTenantId="sourceTenantId" v-model="state" />
  <verify-state v-else-if="state == 2" v-model="state" />
</template>

<script>
import verifyInfo from "./components/verifyInfo.vue";
import verifyState from "./components/verifyState.vue";
import { getCompanyInfo, getSourceInfo } from "@/api/res-company";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    verifyInfo,
    verifyState,
  },
  data() {
    return {
      state: 0,
      company: {},
      routeCompanyId: this.$route.params.companyId,
      sourceTenantId: null,
    };
  },
  computed: mapGetters(["companyId", "companyList"]),
  mounted() {
    this.loadData();
    this.loadSourceInfo();
  },
  methods: {
    ...mapActions(["UserCompany", "LoadPermissions"]),
    async loadData() {
      const routeCompanyId = this.$route.params.companyId;
      console.log(routeCompanyId);
      if (this.routeCompanyId) {
        this.$store.commit("SET_COMPANY_ID", this.routeCompanyId);
        this.LoadPermissions();
      }
      await this.UserCompany();
      getCompanyInfo(this.companyId).then((company) => {
        this.company = company || {};
        this.$store.commit("SET_COMPANY_STATUS", company.verifyStatus);
        if (company.verifyStatus == 1) {
          this.state = 1;
        } else {
          this.state = 2;
        }
      });
    },
    async loadSourceInfo() {
      getSourceInfo().then(res=>{
        if(res){
          this.sourceTenantId = res.registerSource
        }
      })
    }
  },
};
</script>
