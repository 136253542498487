<template>
    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"   @change="handleTableChange">
        <template slot="state" slot-scope="text">
            <p class="user-scene-table-state"><i :class="`status-${text}`"></i>{{ text | dict("SCENE_STATUS") }}</p>
        </template>
        <template slot="operate" slot-scope="text, record">
            <div class="user-scene-table-operate">
                <span @click="scenesDetail(record.id,record.tenantId)">详情</span>
                <span
                        @click="scenesPause(record.id)"
                        v-if="record.sceneStatus === '6'">
          终止</span>
            </div>
        </template>
    </a-table>
</template>

<script>
    const columns = [
        {
            title: '榜单信息',
            dataIndex: 'title',
            key: 'title',
            width: 300,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            key: 'creatorName',
        },
        {
            title: '发布时间',
            dataIndex: 'reviewedTime',
            key: 'reviewedTime',
        },
        {
            title: '申请截止时间',
            key: 'applyDueDate',
            dataIndex: 'applyDueDate',
        },
        {
            title: '状态',
            key: 'sceneStatus',
            dataIndex: 'sceneStatus',
            scopedSlots: {customRender: 'state'},
        },
        {
            title: '操作',
            key: 'operate',
            dataIndex: 'operate',
            scopedSlots: {customRender: 'operate'},
        },
    ];

    import {getTenantInfo} from '@/api/common'
    import {mapActions, mapGetters} from "vuex";
    import {getOwnScene, sceneCancelBid} from "@/api/cjkf/scn-scene";

    export default {
        data() {
            return {
                columns,
                pagination: {
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: 0,
                    showTotal: (total) => `共 ${total} 项`,
                },
                data: [],
                page: {
                    current: 1,
                    size: 10,
                },
                loading: true,
            }
        },
        mounted() {
            this.LoadDictData(["SCENE_FIELD", "SCENE_INDUSTRY"]);
            this.loadGetOwnScene();

        },
        computed: {
            ...mapGetters(["companyId", "tenantId"]),
        },
        methods: {
            ...mapActions(["LoadDictData"]),

            loadGetOwnScene() {
                this.loading = true
                let data = {
                    companyId: this.companyId,
                    currentTenantId: this.tenantId
                }

                getOwnScene(this.page, data).then((res) => {
                    this.data = res.records;
                    this.pagination.total = res.total;
                    this.loading = false
                });
            },


            tablePage(page) {
                this.page.current = page.current;
                this.loadGetOwnScene();
            },

            scenesPause(id) {
                let _this = this;
                this.$confirm({
                    title: "您确定终止吗?",
                    onOk() {
                        sceneCancelBid(id).then((res) => {
                            _this.page.current = 1;
                            _this.loadGetOwnScene();
                        });
                    },
                    onCancel() {
                    },
                });
            },

            scenesDetail(itemId, id) {
                getTenantInfo({id}).then(res => {
                    window.open(`${res.domain}/homeScenes/detail?id=${itemId}`)
                })

            },

          handleTableChange(val){
            this.page.size = val.pageSize;
            this.page.current = val.current;
            this.loadGetOwnScene();
          },

        },
    };
</script>

<style lang="scss" scoped>
    .user-scene-table-state {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #595959;

        > i {
            width: 6px;
            height: 6px;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            margin-right: 8px;

            &.status-6 {
                background: #FACC14;
            }

            &.status-7 {
                background: #F5222D;
            }

            &.status-8 {
                background: #1890FF;
            }
        }
    }

    .user-scene-table-operate {
        display: flex;

        > span {
            cursor: pointer;
            margin-right: 16px;
            color: #EA0B06;

            &:last-child {
                margin-right: 0;
            }
        }
    }
</style>
