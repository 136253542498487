var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-body"},[_c('a-layout',{attrs:{"id":"components-layout-demo-top-side-2"}},[_c('a-layout-header',{staticClass:"header",staticStyle:{"background":"#fff","padding":"0 24px","box-shadow":"inset 0px -1px 0px 0px #e8eaec"}},[_c('div',{staticClass:"user-layout-header"},[_c('div',{staticClass:"logo"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"title":(_vm.currentTenantInfo || {}).tenantName,"src":require("@/assets/img/homelogo.png")},on:{"click":_vm.toPortal}}),_c('div',{staticClass:"header-home",on:{"click":_vm.toHome}},[_c('img',{attrs:{"src":require("@/assets/img/home.png"),"alt":""}}),_c('p',[_vm._v("控制台")])])]),_c('div',{staticClass:"user-layout-header-menu-box"},[_c('div',[_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomRight","getPopupContainer":function (triggerNode) {
                  return triggerNode.parentNode;
                }}},[_c('template',{slot:"overlay"},[_c('div',{staticClass:"user-layout-header-area"},[_c('p',[_vm._v("区域切换")]),_c('div',_vm._l((_vm.tenantList),function(el){return _c('div',{key:el.id,class:{ active: el.id == _vm.tenantId },on:{"click":function($event){return _vm.toggleTenant(el.id, el)}}},[_c('img',{attrs:{"src":require("@/assets/img/userqyqh.png")}}),_vm._v(" "+_vm._s(el.tenantName)+" ")])}),0)])]),_c('div',{staticClass:"user-layout-header-area-text",on:{"click":function (e) { return e.preventDefault(); }}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/userquxz.png")}}),_c('p',[_vm._v(_vm._s(_vm.currentTenantInfo.tenantName))]),_c('a-icon',{attrs:{"type":"down"}})],1)])],2)],1),_c('div',{staticClass:"user-layout-header-menu"},[(_vm.companyList != '' && !_vm.visible)?[_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomLeft","getPopupContainer":function (triggerNode) {
                    return triggerNode.parentNode;
                  }}},[_c('template',{slot:"overlay"},[_c('div',{staticClass:"user-layout-header-enterprise"},[_c('p',[_vm._v("企业切换")]),_c('div',_vm._l((_vm.companyList),function(el,index){return _c('div',{key:el.id,staticClass:"company-vody",on:{"click":function($event){return _vm.toggleCompany(el, index)}}},[(el.companyLogoAttmId)?_c('div',{staticClass:"imgLogo"},[_c('v-img',{attrs:{"attachment-id":el.companyLogoAttmId}})],1):_vm._e(),(!el.companyLogoAttmId)?_c('span',[_vm._v(_vm._s(el.companyName.substring(0, 4)))]):_vm._e(),_c('div',{staticClass:"company-box"},[_c('span',[_vm._v(_vm._s(el.companyName))]),_c('p',[(el.verifyStatus == 1)?_c('img',{attrs:{"src":require("@/assets/img/userinfo1.png"),"alt":"图片"}}):(el.verifyStatus == 2)?_c('img',{attrs:{"src":require("@/assets/img/userinfo4.png"),"alt":"图片"}}):(el.verifyStatus == 3)?_c('img',{attrs:{"src":require("@/assets/img/userinfo2.png"),"alt":"图片"}}):(el.verifyStatus == 4)?_c('img',{attrs:{"src":require("@/assets/img/userinfo3.png"),"alt":"图片"}}):_vm._e()])])])}),0)])]),_c('span',{on:{"click":function (e) { return e.preventDefault(); }}},[_vm._v(_vm._s(_vm.companyList[_vm.companyIndex].companyName)),_c('a-icon',{attrs:{"type":"down"}})],1)],2)]:_vm._e(),_c('a-badge',{staticClass:"message",attrs:{"count":_vm.msg.msgNum},on:{"click":_vm.message}},[_vm._v(" 消息中心 ")]),_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomRight","getPopupContainer":function (triggerNode) {
                  return triggerNode.parentNode;
                }}},[_c('div',{staticClass:"layout-header-user-info",on:{"click":function (e) { return e.preventDefault(); }}},[_c('span',[_c('v-img',{attrs:{"attachment-id":_vm.userInfo.profilePictureAttmId,"default-img":require('@/assets/img/defaultavatar.png')}})],1),_c('a-icon',{attrs:{"type":"down"}})],1),_c('template',{slot:"overlay"},[_c('div',{staticClass:"user-layout-header-info"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/defaultavatar.png")}}),_vm._v(" "+_vm._s(_vm.userInfo.phone)+" ")]),_c('p',{on:{"click":_vm.userInfoBtn}},[_c('a-icon',{attrs:{"type":"user"}}),_vm._v(" 基本资料 ")],1),_c('p',{on:{"click":_vm.userCertification}},[_c('a-icon',{attrs:{"type":"schedule"}}),_vm._v(" 实名认证 ")],1),_c('p',{on:{"click":_vm.setUp}},[_c('a-icon',{attrs:{"type":"setting"}}),_vm._v(" 安全设置 ")],1),_c('span',{on:{"click":_vm.signOut}},[_vm._v("退出")])])])],2)],2)])])]),_c('a-layout',[_c('a-spin',{staticClass:"layout-spin",attrs:{"spinning":_vm.spinning}},[(_vm.isToggle)?_c('a-layout-sider',{staticStyle:{"background":"#fff"},attrs:{"width":"240"}},[_c('user-nav',{model:{value:(_vm.pageType),callback:function ($$v) {_vm.pageType=$$v},expression:"pageType"}})],1):_vm._e(),(_vm.isToggle)?_c('a-layout',[_c('a-layout-content',{staticClass:"user-layout-content",style:({
              background: '#F5F5F5',
              padding: '0 24px 24px',
              margin: 0,
              minWidth: '1280px',
              minHeight: '280px',
            })},[_c('router-view',{staticStyle:{"flex":"1"}})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }