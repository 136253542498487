<template>
    <div>
        <a-table
                :columns="columns"
                :data-source="data"
                :loading="loading"
                :pagination="pagination"
                @change="handleTableChange"
        >
            <template slot="state" slot-scope="text">
                <p class="user-scene-table-state">
                    <i :class="`status-${text}`"></i>{{ text | dict("SCENE_STATUS") }}
                </p>
            </template>
            <template slot="operate" slot-scope="text, record">
                <div class="user-scene-table-operate">
          <span @click="scenesDetail(record.sceneId, record.tenantId)"
          >查看榜单</span
          >
                    <span @click="projectInfo(record)">查看榜单方案</span>
                </div>
            </template>
        </a-table>

        <a-modal title="我的方案" v-model="visible" width="800px" @cancel="visible = false">
            <div>
                <div class="see-info-head">
                    <span v-if="sceneInfo.bidCompanyLogo">
            <v-img
                :attachment-id="sceneInfo.bidCompanyLogo"
                :default-img="require('@/assets/img/kctp.png')"
            />
          </span>
                  <div v-else-if="sceneInfo.bidCompanyName" class="logoText">
                    {{ sceneInfo.bidCompanyName.slice(0, 4) }}
                  </div>
                    <div class="company-info">
                        <strong>{{ sceneInfo.bidCompanyName }}</strong>
                        <p>
                            <span><a-icon type="user"/>{{ sceneInfo.bidUserName }}</span>
                            <span><a-icon type="mobile"/>{{sceneInfo.bidContactPhone}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="see-plan-info-body">
                <div class="see-info-content-tab">
            <span class="active"
            >方案详情</span
            >
                </div>
                <div class="see-plan-date">
                    <div>
                        <p class="see-plan-title">预估成本</p>
                        <span>{{
              sceneInfo.bidPrice ? `￥${sceneInfo.bidPrice}万元` : "--"
            }}</span>
                    </div>
                    <div>
                        <p class="see-plan-title">提交时间</p>
                        <span>{{ sceneInfo.bidTime }}</span>
                    </div>
                </div>
                <div class="see-plan-info">
                    <p class="see-plan-title">方案说明</p>
                    <span>{{ sceneInfo.bidBrief }}</span>
                </div>
                <div class="see-plan-info">
                    <p class="see-plan-title">相关附件</p>
                    <div class="see-plan-annex">
                        <v-attachment
                                :attachment-id="item"
                                :key="item"
                                v-for="item in sceneInfo.bidAttmIds"
                        />
                    </div>
                </div>
            </div>
            <div slot="footer">
                <a-button @click="visible = false" class="btn-gray">取 消</a-button>
                <a-button @click="visible = false" class="btn-red">确 定</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import {getTenantInfo} from "@/api/common";
    import VAttachment from "@/components/VAttachment";
    import {getOwnSceneBids, sceneMyBidInfo} from "@/api/cjkf/scn-scene";
    import {mapGetters} from "vuex";

    const columns = [
        {
            title: "榜单信息",
            dataIndex: "sceneTitle",
            key: "sceneTitle",
            ellipsis: true,
        },
        {
            title: "发布单位",
            dataIndex: "sceneCompanyName",
            key: "sceneCompanyName",
        },
        {
            title: "发布时间",
            dataIndex: "reviewedTime",
            key: "reviewedTime",
        },
        {
            title: "揭榜人",
            key: "bidUserName",
            dataIndex: "bidUserName",
        },
        {
            title: "状态",
            key: "sceneStatus",
            dataIndex: "sceneStatus",
            scopedSlots: {customRender: "state"},
        },
        {
            title: "操作",
            key: "operate",
            dataIndex: "operate",
            scopedSlots: {customRender: "operate"},
        },
    ];
    export default {
        data() {
            return {
                loading: true,
                columns,
                data: [],
                pagination: {
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: 0,
                    showTotal: (total) => `共 ${total} 项`,
                },
                page: {
                    current: 1,
                    size: 10,
                },
                visible: false,
                sceneInfo: "",
            };
        },
        components: {
            VAttachment,
        },
        mounted() {
            this.loadData();
        },
        computed: {
            ...mapGetters(["companyId", "tenantId"]),
        },
        methods: {
            loadData() {
                this.loading = true;
                let data = {
                    bidCompanyId: this.companyId,
                    currentTenantId: this.tenantId,
                };
                getOwnSceneBids(this.page, data).then((res) => {
                    this.data = res.records;
                    this.pagination.total = res.total;
                    this.loading = false;
                });
            },

          handleTableChange(val){
            this.page.size = val.pageSize;
            this.page.current = val.current;
            this.loadData();
          },

            tablePage(page) {
                this.page.current = page.current;
                this.loadData();
            },

            scenesDetail(itemId, id) {
                getTenantInfo({id}).then((res) => {
                    window.open(`${res.domain}/homeScenes/detail?id=${itemId}`);
                });
            },

            projectInfo(res) {
                let obj = {}
                if (this.companyId) {
                    obj.bidCompanyId = this.companyId
                }
                sceneMyBidInfo(res.sceneId, obj).then((res) => {
                    this.sceneInfo = res;
                  console.log(this.sceneInfo,'this.sceneInfo---')
                });
                //   this.sceneInfo = res;
                this.visible = true;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .see-info-head {
        display: flex;
        margin-bottom: 24px;
      .logoText {
        width: 80px;
        height: 80px;
        background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
        border-radius: 4px;
        margin-right: 24px;
        color: #fff;
        font-size: 24px;
        padding: 7px 16px;
        line-height: 33px;
      }
        > span {
            width: 80px;
            height: 80px;
            margin-right: 24px;

            > img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        > .company-info {
            flex: 1;

            > strong {
                color: #262626;
                font-size: 20px;
                line-height: 20px;
                display: block;
                margin-bottom: 16px;
            }

            > p {
                display: flex;

                > span {
                    display: flex;
                    align-items: center;
                    margin-right: 24px;
                    color: #595959;
                    line-height: 14px;

                    > i {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .see-info-content-tab {
        background: #fafafa;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        margin-bottom: 20px;

        > span {
            height: 48px;
            line-height: 48px;
            border-bottom: 2px solid transparent;
            margin-right: 40px;
            cursor: pointer;

            &.active {
                border-color: #EA0B06;
                color: #EA0B06;
            }
        }
    }

    .user-scene-table-state {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #595959;

        > i {
            width: 6px;
            height: 6px;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            margin-right: 8px;

            &.status-6 {
                background: #facc14;
            }

            &.status-7 {
                background: #f5222d;
            }

            &.status-8 {
                background: #1890ff;
            }
        }
    }

    .user-scene-table-operate {
        display: flex;

        > span {
            cursor: pointer;
            margin-right: 16px;
            color: #ea0b06;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .see-plan-info-body {
        .see-plan-title {
            line-height: 16px;
            color: #8c8c8c;
            margin-bottom: 24px;
            padding-left: 12px;
            border-left: 4px solid #ea0b06;
        }

        .see-plan-date {
            display: flex;
            margin-bottom: 40px;

            > div {
                flex: 1;

                > span {
                    display: block;
                    line-height: 20px;
                    color: #262626;
                }
            }
        }

        .see-plan-info {
            margin-bottom: 40px;

            > span {
                line-height: 24px;
                color: #262626;
            }

            .see-plan-annex {
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 32px;
                    padding: 0 16px;
                    margin-bottom: 16px;
                    cursor: pointer;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    > div {
                        display: flex;
                        align-items: center;

                        > img {
                            margin-right: 8px;
                        }

                        > p {
                            max-width: 400px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    > span {
                        color: #ea0b06;
                        cursor: pointer;
                        display: none;
                    }

                    &:hover {
                        background: #f5f5f5;

                        > span {
                            display: block;
                        }
                    }
                }
            }
        }
    }
</style>
