<template>
  <div class="user-home-my-consult">
    <div class="user-home-my-consult-title">
      <p>
        <span @click="consultTagIndex = 1" :class="{'active':consultTagIndex == 1}">我的咨询</span>
        <span @click="consultTagIndex = 2" :class="{'active':consultTagIndex == 2}">我的迁移</span>
      </p>
      <span @click="consultAll">更多</span>
    </div>
    <div class="user-home-my-consult-list" v-if="list != ''">
      <template v-if="consultTagIndex == 1">
        <div v-for="el in list" :key="el.id">
            <p @click="consultInfo(el.id)">
                <img src="@/assets/img/syzxicon.png" alt="图片" /><span>{{el.consultTitle}}</span>
            </p>
            <span><i :class="{'3':'jb','1':'sh','2':'ds'}[el.consultStatus]"></i> {{el.consultStatus | dict('CONSULT_STATUS')}}</span>
        </div>
      </template>
      <template v-if="consultTagIndex == 2">
        <div v-for="el in list" :key="el.id" @click="orderInfo(el.id)">
            <p>
                <img src="@/assets/img/syzxicon.png" alt="图片" /><span>{{el.title}}</span>
            </p>
            <span><i class="sh"></i> {{el.orderStatusName}}</span>
        </div>
      </template>
    </div>
    <a-empty v-else/>
    <!-- <p v-else style="text-align:center;color:#595959">暂无待办</p> -->
    <consult-info :show="consultInfoShow" v-if="consultInfoShow" v-model="consultInfoShow" :id="consultId"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getConsultList } from "@/api/news";
import { getOrderList } from "@/api/order";
import consultInfo from '../../interactive/info.vue'
export default {
    components:{
        consultInfo
    },
  data() {
    return {
      consultTagIndex:1,
      list: ['1'],
      page: {
        current: 1,
        size: 4,
      },
      consultInfoShow:false,
      consultId:''
    };
  },
  computed: mapGetters([
    "companyId",
    "tenantId",
  ]),
  watch:{
    consultTagIndex:function(val){
        this.list = ['1']
        if(val === 1){
            this.loadGetNewsList();
        }else{
            this.loadGetOrderList();
        }
    }
  },
  mounted() {
    this.loadGetNewsList();
  },
  methods: {
    loadGetNewsList() {
      getConsultList(this.page, {}).then((res) => {
        this.list = res.records;
      });
    },
    loadGetOrderList(){
      let {page} = this
      let data = {
        orderStatusList : ['2','3','4','5'],
        tenantId:this.tenantId,
        companyId:this.companyId,
      }
      getOrderList(page,data).then((res)=>{
        this.list = res.records
      })
    },
    consultAll(){
        let {consultTagIndex} = this
        if(consultTagIndex == 1){
            this.$router.push('/interactive/userConsult')
        }else{
            this.$router.push('/userLayout/userScenes')
        }
    },
    orderInfo(id){
       this.$router.push({
        path: "/userLayout/userScenes/info",
        query: { id },
      });
    },
    consultInfo(id){
        this.consultId = id
        this.consultInfoShow = true
    }
  },
};
</script>

<style lang="scss" scoped>
.user-home-my-consult {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
  .user-home-my-consult-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    > p {
      display: flex;
      > span {
        position: relative;
        font-size: 16px;
        color: #262626;
        line-height: 24px;
        margin-right: 24px;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -8px;
          height: 2px;
          background: transparent;
        }
        &.active {
          color: #ea0b06;
          &::after {
            background: #ea0b06;
          }
        }
      }
    }
    > span {
      color: #ea0b06;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .user-home-my-consult-list {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      > p {
        display: flex;
        align-items: center;
        cursor: pointer;
        > img {
          width: 13px;
          margin-right: 8px;
        }
        > span {
          max-width: 420px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 22px;
        }
        &:hover {
          > span {
            color: #ea0b06;
          }
        }
      }
      > span {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #595959;
        line-height: 22px;
        > i {
          width: 6px;
          height: 6px;
          margin-right: 8px;
          border-radius: 50%;
          background: #ccc;
          &.jb {
            background: #52c41a;
          }
          &.sh {
            background: #facc14;
          }
          &.ds {
            background: #1890ff;
          }
        }
      }
    }
  }
}
</style>
