// 企业管理
import { PortalRequest as request } from "@/utils/request";

// 部门树
export const getDepartmentTree = (data) => {
    return request({
        url: "/res-department/tree",
        method: "get",
        params:data
    });
};

// 部门列表
export const getDepartmentList = (params,data) => {
    return request({
        url: "/res-department/page-list",
        method: "POST",
        params,
        data,
    });
};

// 添加部门
export const departmentSave = (data) => {
    return request({
        url: "/res-department/save",
        method: "POST",
        data
    });
};

// 删除部门
export const delDepartment = (id) => {
    return request({
        url: `/res-department/remove/${id}`,
        method: "DELETE",
    });
};

// 成员列表
export const getCompanyMember = (params,data) => {
    return request({
        url: `/join-company/page-list-me`,
        method: "POST",
        params,
        data
    });
};

// 添加成员
export const getAddMemberList = (data) => {
    return request({
        url: `/join-company/member-list`,
        method: "POST",
        data
    });
};

// 添加成员列表
export const getAddMember = (data) => {
    return request({
        url: `/res-department/add-member`,
        method: "POST",
        data
    });
};

// 转让企业
export const transferCompany = (params) => {
    return request({
        url: `/join-company/transfer-company`,
        method: "get",
        params
    });
};

// 重置成员密码
export const memberNewPassword = (data) => {
    return request({
        url: `/res-user/member/reset-password`,
        method: "POST",
        data
    });
};

// 转让企业成员列表
export const getCompanyOwner = (params) => {
    return request({
        url: `/join-company/new-owner-list`,
        method: "get",
        params
    });
};

// 获取公共列表
export const getCompanyList = (data) => {
    return request({
        url: `/res-company/common/company-list`,
        method: "POST",
        data
    });
};

// 同意加入企业
export const agreeJoinCompany = (id) => {
    return request({
        url: `/join-company/${id}/agree-join`,
        method: "get",
    });
};
// 拒绝加入企业
export const refuseJoinCompany = (data) => {
    return request({
        url: `/join-company/refuse-join`,
        method: "POST",
        data
    });
};

// 用户是否为企业所有者
export const companyIsOwner = (id) => {
    return request({
        url: `/join-company/${id}/is-owner`,
        method: "GET",
    });
};

// 成员管理移出
export const joinCompanyRemove = (id) => {
    return request({
        url: `/join-company/remove/${id}`,
        method: "DELETE",
    });
};

// 企业统计
export const companyStatistics = (params) => {
    return request({
        url: `/res-company/statistics`,
        method: "GET",
        params
    });
};
// 个人统计
export const userStatistics = () => {
    return request({
        url: `/res-user/statistics`,
        method: "GET",
    });
};

// 退出企业
export const quitCompany = (params) => {
    return request({
        url: `/join-company/quit-company`,
        method: "GET",
        params
    });
};