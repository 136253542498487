<template>
  <div>
    <!--    <div
      class="project-card"
      @mouseenter="delShow = true"
      @mouseleave="delShow = false"
      :class="{ cardPg: delShow }"
    >
      <v-img
        class="img"
        :attachment-id="val.caseCoverId"
        :default-img="require('@/assets/img/kctp.png')"
      />
      <div class="con-box">
        <div class="con-text">
          <div>{{ val.caseName }}</div>
          <p>
            {{ val.summary }}
          </p>
        </div>
        <div class="con-info">
          <div>
            <span>
              <span>成交金额：</span>
              <span>￥{{ val.transactionAmount }}元</span>
            </span>
            <span>
              <span>采购方：</span>
              <span>{{ val.purchaseBy }}</span>
            </span>
            <span>
              <span>交付周期：</span>
              <span>{{ Math.ceil(val.deliverCycle / 30) }}个月</span>
            </span>
          </div>
          <a-tooltip>
            <template slot="title"> 删除 </template>
            <img
              v-show="delShow"
              class="del"
              src="@/assets/img/delectIcon.png"
              alt=""
              @click="$emit('remove', val.id)"
            />
          </a-tooltip>
        </div>
      </div>
    </div>-->
    <a-table
      class="table-body"
      :columns="projectData"
      :data-source="projectList"
      :pagination="pagination"
      :row-key="(record) => record.id"
      :load="loading"
      @change="projectChange"
    >
      <!--      <template slot="caseCoverId" slot-scope="text, record">
        <v-img
          style="width: 142px; height: 80px"
          :attachment-id="record.caseCoverId"
          :default-img="require('@/assets/img/kctp.png')"
        />
      </template>-->
      <template slot="deliverCycle" slot-scope="text, record">
        <span>{{ text }}</span>
      </template>
      <template slot="transactionAmount" slot-scope="text, record">
        <span style="color: #ea0b06">￥{{ text }}</span>
      </template>
      <template slot="action" slot-scope="text, record">
        <a href="javascript:void(0)" @click="$emit('edit', record)"> 编辑 </a>
        <a-divider type="vertical" />
        <a-popconfirm title="确定删除吗？" @confirm="removeItem(record.id)">
          <a href="javascript:void(0)" style="color: red"> 删除 </a>
        </a-popconfirm>
      </template>
    </a-table>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { getProjectCaseList, removeProjectCase } from "@/api/companyInfo";
import { mapGetters } from "vuex";
export default {
  name: "projectCard",
  props: ["val"],
  components: {
    VImg,
  },
  data() {
    return {
      delShow: false,
      loading: false,
      projectData: [
        {
          title: "编号",
          dataIndex: "",
          key: "rowIndex",
          customRender: function (t, r, index) {
            return parseInt(index) + 1;
          },
        },
        /* {
          title: "系列图片",
          dataIndex: "caseCoverId",
          key: "caseCoverId",
          scopedSlots: { customRender: "caseCoverId" },
        },*/
        {
          title: "案例名称",
          dataIndex: "caseName",
          key: "caseName",
        },
        {
          title: "采购方单位",
          dataIndex: "purchaseBy",
          key: "purchaseBy",
        },
        {
          title: "交付周期",
          dataIndex: "deliverCycle",
          key: "deliverCycle",
          scopedSlots: { customRender: "deliverCycle" },
        },
        {
          title: "成交金额",
          dataIndex: "transactionAmount",
          key: "transactionAmount",
          scopedSlots: { customRender: "transactionAmount" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      projectList: [],
      pagination: {
        pageSizeOptions: ["5", "10", "15", "20"],
        defaultPageSize: 5,
        hideOnSinglePage: true,
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      page: {
        current: 1,
        size: 5,
      },
    };
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  mounted() {
    this.initProjectCaseData();
  },
  methods: {
    initProjectCaseData() {
      const { companyInfo, page } = this;
      if (companyInfo.socialCreditCode) {
        let params = {
          businessLicense: companyInfo.socialCreditCode,
        };
        this.loading = true;
        getProjectCaseList(page, params).then((res) => {
          if (res.total > 0 && res.records.length === 0) {
            this.page.current = this.page.current - 1;
            this.initProjectCaseData();
          } else {
            this.projectList = res.records;
            this.pagination.total = res.total;
            this.loading = false;
          }
        });
      }
    },
    projectChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.initProjectCaseData();
    },

    removeItem(id) {
      removeProjectCase(id).then(() => {
        this.initProjectCaseData();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-body::v-deep {
  .ant-table-row {
    height: 110px;
  }
}
.project-card {
  //width: 100%;
  height: 147px;
  padding: 16px 15px 16px 0;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  > .img {
    display: block;
    width: 204px;
    height: 115px;
    margin-right: 24px;
  }
  > .con-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .con-text {
      > div {
        font-size: 16px;
        color: #262626;
        line-height: 22px;
        margin-bottom: 9px;
      }
      > p {
        font-size: 12px;
        color: #595959;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    > .con-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        > span {
          font-size: 14px;
          color: #595959;
          line-height: 20px;
          > :first-child {
            color: #595959;
          }
          > :last-child {
            color: #8c8c8c;
          }
        }
        > :first-child {
          > :last-child {
            color: #ea0b06;
          }
        }
        > :not(:last-child) {
          margin-right: 32px;
        }
      }
      > .del {
        display: block;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
}

.cardPg {
  background: #fafafa;
}
</style>
