<template>
  <div class="user-active">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>互动中心</a-breadcrumb-item>
      <a-breadcrumb-item>我的课程</a-breadcrumb-item>
    </a-breadcrumb>


    <div class="user-active-body">

      <p>我的课程</p>

      <a-input-search class="search" placeholder="请输入关键词搜索" style="width: 272px" @search="onSearch"   v-model="activityNameVal"/>


      <a-skeleton active :loading="loading" :paragraph="{ rows: 10 }">
         <div v-if="activityList && activityList.length>0">
          <active-card
              type="list"
              v-for="item in activityList"
              @click.native="interactiveInfo(item.id,item.domain)"
              :key="item.id"
              :val="item"></active-card>
         </div>


        <div v-else style="padding:100px 0;display:block">
          <a-empty/>
        </div>



      </a-skeleton>

      <pagination
          v-if="page.total>5"
          :size="page.size"
          :total="page.total"
          :current="page.current"
          @changes="pageChange"
      />

    </div>


  </div>
</template>

<script>
import activeCard from "@/page/interactive/compontes/activeCard";
import {getCourse} from "@/api/activity";
import pagination from "../components/pagination.vue";
export default {
  name: "userList",
  data() {
    return {
      activityNameVal:'',
      activityList:[],
      loading:false,
      page: {
        current: 1,
        size: 5,
      },
    }
  },
  components:{
    activeCard,
    pagination
  },
  mounted() {
    this.loadGetCourse()
  },
  methods:{
    // 课程列表
    loadGetCourse() {
      this.loading=true
      let { page,activityNameVal } = this;
      let data = {
        keyword: activityNameVal,
      };
      getCourse(page, data).then((res) => {
        this.activityList = res.records;
        this.page.total = res.total
        this.loading=false
      });
    },


    // 分页
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetCourse();
    },


    // 搜索
    onSearch() {
      this.page.current = 1;
      this.loadGetCourse();
    },


    // 详情
    interactiveInfo(id,url){
      if(id && url){
        window.open(`${url}/activity/courseInfo?id=${id}`,'_blank')
      }else if(id){
        window.open(`${process.env.VUE_APP_MAINSITE_PREFIX}/activity/courseInfo?id=${id}`,'_blank')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user-active{
  padding-top: 16px;
  height: 100%;
  box-sizing: border-box;
  >.title{
    margin-bottom: 16px;
  }
}

.user-active-body {
  background: #FFFFFF;
  border-radius: 2px;
  min-height: calc(100% - 37px);
  overflow-y: auto;
  padding: 24px 32px 36px;

  > P {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-bottom: 24px;
  }

  > .search {
    margin-bottom: 24px;
  }
}

.home-page{
  text-align: right;
  margin-top: 20px;
}
</style>
