import { getAllTenantList } from '@/api/common'

export default {
  state: {
    tenants: []
  },
  mutations: {
    SET_TENANTS (state, tenants) {
      state.tenants = tenants
    }
  },
  actions: {
    LoadTenants ({ commit }) {
      return new Promise((resolve, reject) => {
        getAllTenantList().then(list => {
          commit('SET_TENANTS', list)
          resolve(list)
        })
      })
    }
  }
}
