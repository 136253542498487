<template>
  <span>
    {{ list.map((e) => e.nickName).join("、") }}
  </span>
</template>

<script>
import { getRoleUserList } from "@/api/res-role";

export default {
  props: {
    roleId: {
      type: String,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  async mounted() {
    const { roleId, companyId } = this;
    this.list = await getRoleUserList({ roleId, companyId });
  },
};
</script>

<style scoped></style>
