<template>
  <div class="user-interactive-box">
    <div class="user-interactive-content-box">
      <div class="user-interactive-filter">
        <div>
          <a-input-search
            style="width: 320px"
            placeholder="请输入关键词搜索"
            v-model="activityNameVal"
            @search="onSearch"
          >
            <a-button slot="enterButton"> <a-icon type="search" /> </a-button>
          </a-input-search>
        </div>
      </div>
      <div class="user-interactive-list-box">
        <div
          class="user-interactive-list"
          v-for="(item, index) in courseList"
          :key="index"
          @click="courseInfo(item.domain,item.courseId)"
        >
          <div class="user-interactive-list-img">
            <v-img
              :attachment-id="item.crsCourse.courseCoverAttmId"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </div>
          <div class="user-interactive-list-info">
            <p>{{ item.crsCourse.courseName }}</p>
<!--             <span v-if="item.tenantName" class="item-text">{{item.tenantName}}</span>-->
            <span>主讲人：{{ item.crsCourse.coursePresentecoursePresenter }}</span>
            <span>主讲单位：{{ item.crsCourse.courseCompanyName }}</span>
          </div>
        </div>
      </div>
    </div>
    <pagination
      :total="page.total"
      :current="page.current"
      @changes="pageChange"
    />
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import pagination from "../components/pagination.vue";
import { getCourse } from "@/api/activity";
export default {
  components: {
    VImg,
    pagination,
  },
  data() {
    return {
      courseList: [],
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      activityNameVal: "",
    };
  },
  mounted() {
    this.loadGetCourse();
    console.log('--------------------------------------------------')
  },
  methods: {
    // 课程列表
    loadGetCourse() {
      let { page, activityNameVal } = this;
      let data = {
        courseName: activityNameVal,
      };
      getCourse(page, data).then((res) => {
        console.log(res);
        this.courseList = res.records;
        this.page.total = res.total;
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetCourse();
    },
    // 搜索
    onSearch() {
      this.page.current = 1;
      this.loadGetCourse();
    },
    // 详情
    courseInfo(url,id) {
     /* this.$router.push({
        path: "/activity/courseInfo",
        query: { id },
      });*/
      window.open(`${url}/activity/courseInfo?id=${id}`)
    },
  },
};
</script>

<style lang="scss" scoped>

.user-interactive-box {
  height: 100%;
  .user-interactive-content-box {
    height: calc(100% - 42px);
    overflow-y: auto;
    margin-bottom: 10px;
  }
}
.user-interactive-filter {
  display: flex;
  > div {
    margin-right: 24px;
  }
}
.user-interactive-list-box {
  display: flex;
  flex-wrap: wrap;
  .user-interactive-list {
    width: 19.5%;
    margin: 16px 0.625% 0 0;
    transition: all 0.3s;
    background: #ffffff;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
    &:nth-child(5n) {
      margin-right: 0;
    }
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.08);
    }
    .user-interactive-list-img {
      position: relative;
      height: 175px;
      > span {
        position: absolute;
        left: 8px;
        top: 0;
      }
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .user-interactive-list-info {
      padding: 24px;
      > p {
        font-size: 16px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
      }
      > span {
        line-height: 11px;
        font-size: 12px;
        color: #8c8c8c;
        display: block;
        margin-top: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.item-text{
  display: inline-block;
  margin-top: 5px ;
  background: #F6F6F6;
  border-radius: 3px;
  padding: 2.6px;
  margin-right: 8px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  color: #666666;
}
</style>
