<template>
  <div>
    <div class="my-demand-body">
      <div>
        <div class="my-demand-filter-box">
          <div class="my-demand-filter-tab">
            <div class="my-demand-filter-tab-list">
              <div :class="{ active: stateCode == '' }" @click="stateCode = ''">
                全部
              </div>
              <div
                v-for="(item, index) in dict.DEMAND_STATUS"
                :key="item.itemValue"
                :class="{ active: stateCode == item.itemValue }"
                @click="stateCode = item.itemValue"
                :style="{ left: -(index + 1) + 'px' }"
              >
                {{ item.itemText }}
              </div>
            </div>
          </div>
          <div class="my-demand-filter-btn">
            <a-button class="btn-red" @click="demandApply('')"
              >提交需求信息单</a-button
            >
          </div>
        </div>
        <div class="my-demand-list">
          <a-table
            :columns="columns"
            :data-source="demandData"
            :pagination="pagination"
            @change="pageChange"
            :loading="loading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="fundAmount" slot-scope="text, record">
              {{ record.fundAmount || "-" }}元
              <span v-if="record.fundAmountStatus == true"> [可议价] </span>
            </template>

            <template slot="demandStatus" slot-scope="text">
              <p>{{ text | dict("DEMAND_STATUS") }}</p>
            </template>

            <div slot="operating" slot-scope="text, record">
              <div class="my-demand-operating">
                <template v-if="record.demandStatus == '1'">
                  <span
                    @click="
                      demandApply(
                        record.id,
                        record.demandStatus,
                        record.tenantId
                      )
                    "
                    >编辑</span
                  >
                  <span @click="demandDel(record.id)">删除</span>
                </template>

                <template v-else-if="record.demandStatus == '3'">
                  <span
                    @click="
                      demandDetail(
                        record.id,
                        record.demandStatus,
                        record.tenantId
                      )
                    "
                    >详情</span
                  >
                  <span @click="demandApply(record.id)">再次提交</span>
                </template>

                <template v-else-if="record.demandStatus == '5'">
                  <span
                    @click="
                      demandDetail(
                        record.id,
                        record.demandStatus,
                        record.tenantId
                      )
                    "
                    >详情</span
                  >
                  <span @click="changeEndTime(record.id)">修改截止时间</span>
                </template>

                <template v-else>
                  <span
                    @click="
                      demandDetail(
                        record.id,
                        record.demandStatus,
                        record.tenantId
                      )
                    "
                    >详情</span
                  >
                </template>

                <change-demand-end-time
                  ref="changeEndTimeRef"
                  @refreshData="loadData"
                />
              </div>
            </div>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { demandRemove, pageListByme } from "@/api/dmdDemand";
import { getTenantInfo } from "@/api/common";
import ChangeDemandEndTime from "@/page/demandManage/components/ChangeDemandEndTime";

const columns = [
  {
    title: "需求名称",
    dataIndex: "name",
  },
  {
    title: "需求类型",
    dataIndex: "demandTypeName",
    scopedSlots: { customRender: "demandTypeName" },
  },
  {
    title: "预算金额",
    dataIndex: "fundAmount",
    scopedSlots: { customRender: "fundAmount" },
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
  },
  {
    title: "创建人",
    dataIndex: "creatorName",
  },
  {
    title: "需求状态",
    dataIndex: "demandStatus",
    scopedSlots: { customRender: "demandStatus" },
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    scopedSlots: { customRender: "operating" },
  },
];

export default {
  name: "MyDemand",
  components: { ChangeDemandEndTime },
  data() {
    return {
      columns,
      stateCode: "",
      demandData: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      loading: true,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["dict", "companyId", "tenantId"]),
  },
  watch: {
    stateCode: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.LoadDictData(["DEMAND_CATALOGUE"]);
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    changeEndTime(id) {
      this.$refs.changeEndTimeRef.open(id);
    },
    loadData() {
      let { page } = this;
      let data = {
        demandStatus: this.stateCode,
        companyId: this.companyId,
        tenantId: this.tenantId,
      };
      this.loading = true;
      pageListByme(page, data).then((res) => {
        this.demandData = res.records;
        this.$set(this.pagination, "total", res.total);
        this.loading = false;
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadData();
    },

    demandDel(id) {
      let _this = this;
      this.$confirm({
        title: "您确定删除吗?删除后不可恢复！",
        okType: "default",
        onOk() {
          demandRemove(id).then((res) => {
            _this.$message.success("删除成功");
            _this.loadData();
          });
        },
        onCancel() {},
      });
    },
    demandApply(id) {
      if (id != "") {
        this.$router.push({
          path: "/demandManage/demand",
          query: { id },
        });
      } else {
        this.$router.push({
          path: "/demandManage/demand",
        });
      }
    },

    demandDetail(id, status, demandTenantId) {
      if (status == "6" || status == "4" || status == "5") {
        getTenantInfo({ id: demandTenantId }).then((res) => {
          window.open(`${res.domain}/demand/detail?id=${id}`);
        });
      } else {
        this.$router.push({
          path: "/demandManage/detail",
          query: { id },
        });
      }
    },
    demandInfo(id) {
      // TODO 跳区域门户
    },
  },
};
</script>

<style lang="scss" scoped>
.my-demand-body {
  padding-top: 16px;
  height: 100%;
  > div {
    background: #fff;
    padding: 24px 32px;
    min-height: 100%;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }
  .my-demand-filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .my-demand-filter-tab {
      display: flex;
      align-items: center;
      .my-demand-filter-tab-list {
        display: flex;
        > div {
          width: 96px;
          height: 32px;
          background: #fff;
          border: 1px solid #d9d9d9;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          transition: all 0.3s;
          position: relative;
          &.active {
            color: #ea0b06;
            background: rgba(234, 11, 6, 0.05);
            border-color: #ea0b06;
            z-index: 2;
          }
        }
      }
    }
    .my-demand-filter-btn {
      display: flex;
      align-items: center;
    }
  }
  .my-demand-list {
    margin-top: 24px;
    .my-demand-status {
      display: flex;
      align-items: center;
      > i {
        width: 6px;
        height: 6px;
        background: #bfbfbf;
        border-radius: 50%;
        margin-right: 8px;
        &.yus {
          background: #faad14;
        }
        &.yans {
          background: #52c41a;
        }
        &.fb {
          background: #1890ff;
        }
        &.btg {
          background: #ff4d4f;
        }
      }
    }
    .my-demand-operating {
      display: flex;
      > span {
        cursor: pointer;
        color: #ea0b06;
        margin-right: 20px;
        white-space: nowrap;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
<style>
.my-demand-list th,
.my-demand-list td {
  border-color: #f0f0f0 !important;
}
</style>
