<template>
  <div @click="handleClick" style="width: 100%; padding: 0px 16px">
    <slot v-if="!busy">获取验证码</slot>
    <slot v-else name="loading" :seconds="seconds"
      >{{ seconds }}秒后重新发送</slot
    >
  </div>
</template>

<script>
export default {
  name: "VCountDown",
  props: {
    max: {
      type: Number,
      default: 60,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: Function,
      default() {
        return () => {};
      },
    },
    oldPhone:{
      type: [Number,String],
      default: null,
    },
    newPhone:{
      type: [Number,String],
      default: null,
    }
  },
  data() {
    return {
      seconds: 0,
      busy: false,
    };
  },
  methods: {
    async handleClick() {
      if(this.oldPhone && this.newPhone && this.oldPhone===this.newPhone){
        this.$message.warning("不能与旧手机号相同！")
        return;
      }
      if (this.busy) {
        return;
      }
      this.busy = true;
      try {
        await this.trigger();
        this.getCode();
      } catch (e) {
        this.busy = false;
      }
    },
    getCode() {
      const { disabled } = this;
      if (disabled) {
        return;
      }
      const { max } = this;
      this.seconds = max;
      const stop = setInterval(() => {
        this.seconds -= 1;
        if (this.seconds <= 0) {
          this.busy = false;
          clearInterval(stop);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>




</style>
