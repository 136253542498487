<template>
  <div class="demand">
    <a-tabs default-active-key="1" style="height: 100%">
      <a-tab-pane key="1" tab="我的招标" style="height: 100%">
        <my-demand style="height: 100%" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="我的投标" force-render style="height: 100%">
        <my-demand-bid style="height: 100%" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import MyDemand from "@/page/demandManage/components/myDemand";
import MyDemandBid from "@/page/demandManage/components/myDemandBid";

export default {
  components: {
    MyDemand,
    MyDemandBid,
  },
};
</script>

<style lang="scss" scoped>
.demand {
  &::v-deep {
    .ant-tabs-content {
      height: calc(100% - 60px);
    }
  }
  @media screen and (max-width: 1600px) {
    max-width: 1256px;
  }
}
</style>
