<template>
  <div class="attachment">
    <div>
      <img src="@/assets/img/fujian.png" />
      <p>{{ info.fileName }}</p>
    </div>
    <!--    <a href="javascript:void(0)" @click="downloadAnnex">下载</a>-->
    <a :href="src" :download="info.fileName">下载</a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAttachment } from "@/api/common";
import { downloadFile } from "@/api/cjkf/scene";

export default {
  name: "VAttachment",
  props: {
    attachmentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      info: {
        fileName: "",
      },
    };
  },
  computed: {
    src() {
      const { attachmentId } = this;
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/download/${attachmentId}`;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.info = await getAttachment(this.attachmentId);
    },
    downloadAnnex() {
      downloadFile(this.attachmentId).then((res) => {
        if (!res) {
          return;
        }
        let url = window.URL.createObjectURL(new Blob([res]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", this.info.fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.attachment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    align-items: center;
    > img {
      margin-right: 8px;
    }
    > p {
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  > a {
    color: #1abc9c;
    cursor: pointer;
    display: none;
  }
  &:hover {
    background: #f5f5f5;
    > a {
      display: block;
    }
  }
}
</style>
