<template>
  <div class="user-home">
    <div class="user-home-left">
      <div class="user-home-enterprise" v-if="companyList != ''">
        <span v-if="!enterpriseInfo.companyLogoAttmId">{{ enterpriseInfo.companyName | intercept }}</span>
        <v-img v-else :attachment-id="enterpriseInfo.companyLogoAttmId" :default-img="require('@/assets/img/kctp.png')" />
        <div>
          <span>{{ enterpriseInfo.companyName }}</span>
          <div>
            <p>
              创建时间：<span>{{
                enterpriseInfo.createTime | moment("YYYY-MM-DD")
              }}</span>
            </p>
            <p>
              创建人：<span>{{ enterpriseInfo.creatorName || '--' }}</span>
            </p>
          </div>
          <p>
            企业认证：
            <img
              v-if="enterpriseInfo.verifyStatus == 1"
              src="@/assets/img/userinfo1.png"
              alt="图片"
            />
            <img
              v-else-if="enterpriseInfo.verifyStatus == 2"
              src="@/assets/img/userinfo4.png"
              alt="图片"
            />
            <img
              v-else-if="enterpriseInfo.verifyStatus == 3"
              src="@/assets/img/userinfo2.png"
              alt="图片"
            />
            <img
              v-else-if="enterpriseInfo.verifyStatus == 4"
              src="@/assets/img/userinfo3.png"
              alt="图片"
            />
          </p>
        </div>
      </div>
      <div class="user-home-personal" v-else>
        <span style=""
          ><v-img
            :attachment-id="userInfo.profilePictureAttmId"
            :default-img="require('@/assets/img/defaultavatar.png')"
        /></span>
        <div>
          <span>{{ userInfo.realName }} <v-icon type="icon-qunfengshouji" :style="{'color':userInfo.phone?'#50B674':''}"/><v-icon type="icon-weixin1" :style="{'color':userInfo.isBindWechat?'#50B674':''}"/></span>
          <div>
            <p>
              已绑定手机号：<span>{{ userInfo.phone | capitalize }}</span>
            </p>
          </div>
          <p>
            个人实名：<span
              :class="{
                wrz: userInfo.verifyStatus == 1,
                yrz: userInfo.verifyStatus == 3,
                drz: userInfo.verifyStatus == 2,
                wtg: userInfo.verifyStatus == 4,
              }"
              >{{ userInfo.verifyStatus | dict("USER_VERIFY_STATUS") }}</span
            >
          </p>
        </div>
      </div>
      <div class="user-home-enterprise-identity">
        <p v-if="companyList != ''">
          您在企业中为<span>{{ isOwner ? "所有者" : "成员" }}</span
          >身份，团队成员共{{ statisticsInfo.companyMemberNum }}位
        </p>
        <p v-else>
          暂无企业信息，去<span
            style="cursor: pointer"
            @click="$router.push('/userLayout/userInfo/addCompany')"
            >创建或关联企业>></span
          >
        </p>
        <div v-if="companyList != ''">
          <span><img src="@/assets/img/avatarnan.png" alt="图片" /></span>
        </div>
      </div>
      <div class="user-home-my-task">
        <div class="user-home-my-task-title">
          <p>我的待办</p>
          <!-- <span @click="$router.push('userLayout/userMessage')">更多</span> -->
        </div>
        <div class="user-home-my-task-list">
          <template v-if="handledList != ''">
            <p v-for="(el,index) in handledList" :key="index" @click="handledInfo(el.id,el.type,el.recordId,el.tenantId)">{{ el.content }}</p>
          </template>
          <a-empty  v-else/>
          <!-- <p v-else style="text-align:center;color:#595959">暂无待办</p> -->
        </div>
      </div>
      <my-consult />
      <div class="user-home-apply" v-if="companyList != ''">
        <p>快捷申请</p>
        <div>
<!--          <div @click="sceneAdd">
            <p><img src="@/assets/img/cangjicon.png" alt="图片" />场景申报</p>
          </div>-->
          <div @click="$router.push(`/userLayout/userScenes/add`)">
            <p>
              <img src="@/assets/img/gctdicon.png" alt="图片" />国产替代迁移申请
            </p>
          </div>
          <div @click="addServe">
            <p><img src="@/assets/img/fwicon.png" alt="图片" />发布服务</p>
          </div>
        </div>
      </div>
      <my-serve v-if="companyList != ''" />
      <user-notice />


<!--      <div class="user-home-solution" v-if="companyList != ''">
        <p>工厂必备生产管理解决方案</p>
        <span>随时随地管理车间</span>
        <a-button class="btn-red">立即体验</a-button>
      </div>-->



    </div>
    <div class="user-home-right">
      <div class="user-home-statistics" v-if="companyList != ''">
        <p>企业数据统计</p>
        <div>
          <div>
            <span>场景发榜</span>
            <p>{{ statisticsInfo.sceneReleaseNum}}</p>
          </div>
          <div>
            <span>场景揭榜</span>
            <p>{{ statisticsInfo.sceneAcceptNum}}</p>
          </div>
          <div>
            <span>迁移需求</span>
            <p>{{ statisticsInfo.orderNum}}</p>
          </div>
          <div>
            <span>发布的服务</span>
            <p>{{ statisticsInfo.serviceMarketNum}}</p>
          </div>
          <div>
            <span>企业成员</span>
            <p>{{ statisticsInfo.companyMemberNum}}</p>
          </div>
        </div>
      </div>
      <div class="user-home-statistics" v-else>
        <p>个人数据统计</p>
        <div>
          <div>
            <span>我的迁移</span>
            <p>{{ statisticsInfo.orderNum}}</p>
          </div>
          <div>
            <span>我的活动</span>
            <p>{{ statisticsInfo.activityNum}}</p>
          </div>
          <div>
            <span>我的课程</span>
            <p>{{ statisticsInfo.courseNum}}</p>
          </div>
          <div>
            <span>我的咨询</span>
            <p>{{ statisticsInfo.miscNum}}</p>
          </div>
          <div>
            <span>我的关注</span>
            <p>{{ statisticsInfo.focusNum}}</p>
          </div>
        </div>
      </div>
      <dynamic v-if="companyList != ''" />
      <my-focus />
      <my-activity />
    </div>
  </div>
</template>

<script>
import myConsult from "./components/myConsult.vue";
import myServe from "./components/myServe.vue";
import userNotice from "./components/userNotice.vue";
import dynamic from "./components/dynamic.vue";
import myFocus from "./components/myFocus.vue";
import myActivity from "./components/myActivity.vue";
import { mapGetters, mapActions } from "vuex";
import { getHandled,getHandledList } from "@/api/message";
import { companyStatistics, companyIsOwner,userStatistics } from "@/api/enterpriseManage";
import {getTenantInfo} from "@/api/common";
import {getDefaultCompany} from "@/api/res-company"
import VImg from "@/components/VImg";
import VIcon from "@/components/VIcon";
export default {
  components: {
    VImg,
    myConsult,
    myServe,
    userNotice,
    dynamic,
    myFocus,
    myActivity,
    VIcon
  },
  data() {
    return {
      enterpriseInfo: "",
      statisticsInfo: "",
      isOwner: false,
      handledList:[]
    };
  },
  mounted() {
    this.loadCompanyList();
    this.loadMessageList();
    this.loadStatistics();
    this.loadCompanyIsOwner();
    this.typeBool()
  },
  computed: {
      ...mapGetters([
    "companyId",
    "companyList",
    "userInfo",
    "company_status",
    "permissions"
  ]),
    type() {
      return this.$route.query.type;//门户发布需求传过来
    },
  },
  methods: {
    ...mapActions(["UserCompany"]),

    typeBool(){
        let _this=this
      if(this.type) {
        this.$confirm({
          title: '未关联企业',
          content: '您还未关联企业，请先关联企业后发布需求',
          okText: '立即关联',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            _this.$router.push('/userLayout/userInfo/addCompany')
          },
          class: 'test',
        });
      }
    },

    // 获取企业列表
    async loadCompanyList() {
      let { companyList, companyId } = this;
      if (companyList) {
        for (let index = 0; index < companyList.length; index++) {
          if (companyList[index].id == companyId) {
            this.enterpriseInfo = companyList[index];
          }
        }
      }
    },
    // 我的代办
    loadMessageList() {
      getHandledList({ current: 1, size: 4 },{companyId:this.companyId}).then((res)=>{
        console.log(res)
        this.handledList = res.records
      })
    },
    handledInfo(id,type,rid,tenantId){
      getHandled({toDoId:id}).then((res)=>{})
      if(type == 1 || type == 2){
        this.$router.push(`/enterpriseManage/verify`);
      }else if(type == 3 || type == 4){
        this.$router.push(`/userLayout/userCer`);
      }else if(type == 5){
        this.$router.push(`/enterpriseManage/member`);
      }else if(type == 6){
        this.$router.push(`/userLayout/userInfo`);
      }else if(type == 7){
        this.$router.push(`/userLayout/userScenes/info?id=${rid}`);
      }else if(type == 8){
        this.$router.push(`/interactive/userConsult`);
      }else if(type == 9){
        this.$router.push(`/serveManage`);
      }else if(type == 10){
        getTenantInfo({id:tenantId}).then((res) => {
            window.open(`${res.domain}/homeScenes/detail?id=${rid}`,'_blank')
        });
      }
    },
    // 统计
    loadStatistics() {
      if(this.companyId){
        companyStatistics({ companyId: this.companyId }).then((res) => {
          this.statisticsInfo = res;
        });
      }else{
        userStatistics().then((res) => {
          this.statisticsInfo = res;
        });
      }
    },
    // 企业所有者还是成员
    loadCompanyIsOwner() {
      if (this.companyId) {
        companyIsOwner(this.companyId).then((res) => {
          this.isOwner = res;
        });
      }
    },
    // 发布服务
    addServe(){
      let {permissions} = this
      let isPermission = false
      for (let index = 0; index < permissions.length; index++) {
        if(permissions[index].id == "000004"){
          isPermission = true
        }
      }
      if(!isPermission){
        this.$warning({
          title: "提示",
          okText: "我知道了",
          class: "declare-modal",
          content: (h) => <div>无权限发布!</div>,
          onOk() {},
        });
        return
      }
      if (this.company_status === "3") {
        this.$router.push(`/serveManage/releaseServe`)
      }else{
        this.$warning({
          title: "提示",
          okText: "我知道了",
          class: "declare-modal",
          content: (h) => <div>请查看企业认证是否通过!</div>,
          onOk() {},
        });
        return;
      }
    },
    // 场景申报
    sceneAdd() {
      let {permissions} = this
      let isPermission = false
      for (let index = 0; index < permissions.length; index++) {
        if(permissions[index].id == "000002"){
          isPermission = true
        }
      }
      if(!isPermission){
        this.$warning({
          title: "提示",
          okText: "我知道了",
          class: "declare-modal",
          content: (h) => <div>无权限申报!</div>,
          onOk() {},
        });
        return
      }
      if (this.company_status === "3") {
        this.$router.push(`/sceneManage/addScene`);
      }else{
        this.$warning({
          title: "提示",
          okText: "我知道了",
          class: "declare-modal",
          content: (h) => <div>请查看企业认证是否通过!</div>,
          onOk() {},
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.user-home {
  background: #fff;
  padding: 24px 0;
  display: flex;
  margin-top: 24px;
  > div {
    padding: 0 24px;
  }
  .user-home-left {
    width: 600px;
    border-right: 1px solid #e8e8e8;
    .user-home-enterprise {
      display: flex;
      > span {
        width: 72px;
        height: 72px;
        background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
        border-radius: 4px;
        padding: 4px 16px;
        font-size: 20px;
        color: #fff;
        margin-right: 24px;
      }
      >img{
        width: 72px;
        height: 72px;
        border-radius: 50%;
        display: block;
        margin-right: 24px;
      }
      > div {
        flex: 1;
        > span {
          font-size: 16px;
          color: #262626;
          line-height: 28px;
          display: block;
          margin-bottom: 6px;
        }
        > div {
          display: flex;
          margin-bottom: 6px;
          > p {
            display: flex;
            font-size: 12px;
            color: #8c8c8c;
            line-height: 22px;
            margin-right: 32px;
            > span {
              color: #262626;
            }
          }
        }
        > p {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #8c8c8c;
          line-height: 22px;
        }
      }
    }
    .user-home-personal {
      display: flex;
      > span {
        width: 72px;
        height: 72px;
        border-radius: 4px;
        font-size: 20px;
        color: #fff;
        margin-right: 24px;
        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
        }
      }
      > div {
        flex: 1;
        > span {
          font-size: 16px;
          color: #262626;
          line-height: 28px;
          display: block;
          margin-bottom: 6px;
          font-weight: bold;
          >i{
            color: #B4B6BA;
            margin-left: 12px;
          }
        }
        > div {
          margin-bottom: 6px;
          > p {
            font-size: 12px;
            color: #8c8c8c;
            line-height: 22px;
            > span {
              color: #262626;
            }
          }
        }
        > p {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #8c8c8c;
          line-height: 22px;
          > span {
            width: 64px;
            height: 22px;
            border-radius: 2px;
            margin-left: 12px;
            font-size: 12px;
            line-height: 22px;
            text-align: center;
            &.wrz {
              background: #ebebeb;
              color: #999999;
            }
            &.drz {
              background: #feefd4;
              color: #faad29;
            }

            &.yrz {
              background: #dcf6ee;
              color: #50d4ab;
            }

            &.wtg {
              background: #fedbdc;
              color: #fc4d4f;
            }
          }
        }
      }
    }
    .user-home-enterprise-identity {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: url(../../assets/img/userqybgbg.png) no-repeat;
      background-size: 100% 100%;
      padding: 0 24px;
      height: 42px;
      > p {
        font-size: 12px;
        color: #262626;
        > span {
          color: #ed0000;
        }
      }
      > div {
        display: flex;
        > span {
          width: 22px;
          height: 22px;
          border: 1px solid #ffffff;
          border-radius: 50%;
          > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: block;
          }
        }
      }
    }
    .user-home-my-task {
      margin-top: 24px;
      .user-home-my-task-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        > p {
          font-size: 16px;
          color: #262626;
          line-height: 24px;
        }
        > span {
          cursor: pointer;
          font-size: 14px;
          color: #ea0b06;
        }
      }
      .user-home-my-task-list {
        padding-bottom: 24px;
        border-bottom: 1px solid #e8e8e8;
        > p {
          font-size: 14px;
          color: #595959;
          line-height: 22px;
          margin-bottom: 16px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            color: #ea0b06;
          }
        }
      }
    }
    .user-home-apply {
      margin-top: 24px;
      padding-bottom: 8px;
      border-bottom: 1px solid #e8e8e8;
      > p {
        font-size: 16px;
        color: #262626;
        line-height: 24px;
        margin-bottom: 24px;
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > div {
          width: 269px;
          height: 56px;
          background: #f6f7fb;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0 16px;
          margin-bottom: 16px;
          &:hover {
            background: #fff2f2;
          }
          > p {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #262626;
            line-height: 24px;
            > img {
              margin-right: 8px;
              width: 22px;
            }
          }
        }
      }
    }
    .user-home-solution {
      margin-top: 24px;
      background: url(../../assets/img/userphoto.png) no-repeat;
      background-size: 100% 100%;
      height: 180px;
      padding: 24px;
      cursor: pointer;
      > p {
        font-size: 18px;
        color: #262626;
        line-height: 24px;
        margin-bottom: 8px;
      }
      > span {
        display: block;
        font-size: 12px;
        color: #595959;
        line-height: 22px;
        margin-bottom: 16px;
      }
    }
  }
  .user-home-right {
    flex: 1;
    .user-home-statistics {
      border-bottom: 1px solid #e8e8e8;
      > p {
        font-size: 16px;
        color: #262626;
        line-height: 24px;
      }
      > div {
        padding: 24px 0;
        display: flex;
        > div {
          flex: 1;
          > span {
            font-size: 14px;
            color: #8c8c8c;
            line-height: 22px;
            display: block;
          }
          > p {
            font-size: 30px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 38px;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
</style>

<style>
.test .ant-btn-danger{
  background-color: #EA0B06;
  border-color: #EA0B06;
}

</style>
