<template>
  <div>
    <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>企业资料</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="enterprise-manage-info">
      <div class="manage-info-box">
        <p>企业资料</p>
        <div class="manage-info-tab-body">
          <router-link
            tag="div"
            v-for="(item, index) in headNav"
            :key="index"
            active-class="active"
            :to="item.url"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="pt-bangdan-content-body">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headNav: [
        {
          name: "基本信息",
          url: "/enterpriseManage/info",
        },
        {
          name: "核心成员",
          url: "/enterpriseManage/coreMembers",
        },
        {
          name: "项目案例",
          url: "/enterpriseManage/projectCase",
        },
        {
          name: "资质证书",
          url: "/enterpriseManage/certificateList",
        },
      ],
    };
  },
  /* created() {
    this.bodyheight = document.body.clientHeight - 150;
  },*/
};
</script>

<style lang="scss" scoped>
.enterprise-manage-info {
  padding-top: 16px;
  height: calc(100% - 38px);
  .manage-info-box {
    min-height: 100%;
  }
  > div {
    background: #fff;
    padding: 24px 0;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 9px;
      padding: 0 32px;
    }
  }
  .manage-info-tab-body {
    height: 54px;
    padding-left: 32px;
    display: flex;
    border-bottom: 1px solid #e8e8e8;

    > div {
      font-size: 16px;
      color: #262626;
      line-height: 54px;
      margin-right: 48px;
      cursor: pointer;
    }
    > .active {
      color: #ea0b06;
      border-bottom: 2px solid #ea0b06;
    }
  }
}
</style>
