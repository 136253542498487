<template>
  <div class="add-scenes">
    <a-breadcrumb class="user-breadcrumb-box">
        <a-breadcrumb-item>
          <router-link to="/userLayout/userScenes" replace>迁移管理</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>提交国产替代需求单</a-breadcrumb-item>
    </a-breadcrumb>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
    >
      <div class="add-scenes-form-info">
        <p>需求描述</p>
        <a-form-model-item
          ref="adaptationType"
          label="我想迁移适配"
          prop="adaptationType"
        >
          <a-radio-group v-model="form.adaptationType">
            <a-radio value="1"> 硬件产品 </a-radio>
            <a-radio value="2"> 模组 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="orderDeviceList" label="设备名称">
          <div class="add-scenes-migrate">
            <a-button icon="plus-circle" @click="scenesEdit('add','','')">添加设备</a-button>
            <div>
              <div v-for="(el,index) in form.orderDeviceList" :key="index">
                <p>{{el.deviceName}}</p>
                <a-icon type="delete" @click="deleteMigrate(index)"/>
                <span @click="scenesEdit('edit',el,index)">编辑</span>
              </div>
            </div>
          </div>
        </a-form-model-item>
      </div>
      <div class="add-scenes-form-info">
        <p>企业联系信息</p>
        <a-form-model-item
          ref="companyName"
          label="企业名称"
          prop="companyName"
        >
          <a-input
            style="width: 400px"
            v-model="form.companyName"
            placeholder="请输入企业名称"
            @blur="
              () => {
                $refs.companyName.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="companyAddress" label="企业所在地" prop="companyAddress">
          <a-input
            style="width: 400px"
            v-model="form.companyAddress"
            placeholder="请输入企业所在地"
            @blur="
              () => {
                $refs.companyAddress.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="contactPerson"
          label="联系人"
          prop="contactPerson"
        >
          <a-input
            style="width: 400px"
            v-model="form.contactPerson"
            placeholder="请输入联系人姓名"
            @blur="
              () => {
                $refs.contactPerson.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="contactPhone"
          label="手机号"
          prop="contactPhone"
        >
          <a-input
            style="width: 400px"
            v-model="form.contactPhone"
            placeholder="请输入手机号"
            :max-length="11"
            @blur="
              () => {
                $refs.contactPhone.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
<!--        <a-form-model-item ref="code" label="验证码" prop="code">-->
<!--          <div style="display: flex">-->
<!--            <a-input-->
<!--              style="width: 283px"-->
<!--              v-model="form.code"-->
<!--              placeholder="请输入验证码"-->
<!--              @blur="-->
<!--                () => {-->
<!--                  $refs.code.onFieldBlur();-->
<!--                }-->
<!--              "-->
<!--            />-->
<!--            <a-button-->
<!--              class="btn-color-red"-->
<!--              style="margin-left: 16px"-->
<!--              :disabled="isCode"-->
<!--              @click="sendMobileCode"-->
<!--            >-->
<!--              <v-count-down>-->
<!--                <template #default>获取验证码</template>-->
<!--                <template #loading="scope"-->
<!--                  >{{ scope.seconds }}秒后重新发送</template-->
<!--                >-->
<!--              </v-count-down>-->
<!--            </a-button>-->
<!--          </div>-->
<!--        </a-form-model-item>-->
      </div>
      <div class="add-scenes-form-info">
        <a-form-model-item>
          <div style="display: flex">
            <a-button class="btn-red" @click="onSubmit" :loading="subloading"> 提 交 </a-button>
            <a-button style="margin-left: 16px" @click="resetForm" :loading="saveloading">保 存</a-button>
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>
    <!-- 添加设备 -->
    <add v-if="addVisible" :visible="addVisible" :type='scenesType' :info='scenesInfo' :index=scenesIndex v-model="addVisible" @add="addMigrate" @edit="editMigrate"/>
  </div>
</template>

<script>
import uploadFiles from "@/components/uploadFiles";
import { mapGetters, mapActions } from "vuex";
import { orderSave,getOrderInfo } from "@/api/order";
import { getTypeList } from "@/api/dict";
import VCountDown from "@/components/VCountDown";
import { sendMobile,checkCaptchaMobile } from "@/api/common";
import { getCurrentCompany } from "@/api/res-company";
import add from './add.vue'
export default {
  components: {
    uploadFiles,
    VCountDown,
    add
  },
  computed: {
    ...mapGetters(["userInfo", "dict",'companyId','tenantId']),
    addType: function () {
      return this.$route.params.type;
    },
    editInfo: function () {
      return this.$route.params.info;
    },
  },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 15 },
      mapOptions: [],
      subloading:false,
      saveloading:false,
      form: {
        orderDeviceList: [],
        companyName: "",
        companyType: "",
        contactPerson: "",
        contactPhone: "",
        companyAddress: "",
        orderStatus: "2",
        adaptationType: "",
       // code: "",
      },
      rules: {
        adaptationType: [
          { required: true, message: '请选择迁移适配类型', trigger: 'change' },
        ],
        orderDeviceList:[{ required: true, message: '您还未添加添加设备', trigger: 'change' },],
        companyName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        contactPerson: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "blur",
          },
        ],
        contactPhone: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
       /* code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],*/
      },
      uploadData: [],
      orderProductName: [],
      company: "",
      isCode: true,
      addVisible:false,
      scenesType:'add',
      scenesInfo:'',
      scenesIndex:''
    };
  },
  watch: {
    "form.contactPhone": function (val) {
      if (!/^1[3456789]\d{9}$/.test(val)) {
        this.isCode = true;
      } else {
        this.isCode = false;
      }
    },
  },
  mounted() {
    this.LoadDictData(["SCENE_TYPE"]);

    if (this.addType == "edit") {
      this.showForm();
    }else{
      this.loadCompany();
    }
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    // 添加/编辑设备
    scenesEdit(type,val,index){
      this.scenesType = type
      this.scenesInfo = val
      this.scenesIndex = index
      this.addVisible = true
    },
    loadCompany() {
      if(!this.companyId){
        return
      }
      getCurrentCompany(this.companyId).then((company) => {
        console.log(company);
        this.form.companyName = company.companyName;
        this.form.companyAddress = company.companyAddress;
        this.form.contactPerson = this.userInfo.realName;
        this.form.contactPhone = this.userInfo.phone;
      });
    },
    sendMobileCode() {
      const { form } = this;
      if (!form.contactPhone || form.contactPhone < 11) {
        this.$message.warning("手机号输入有误");
        return;
      }
      sendMobile(form.contactPhone).then(() => {
        this.$message.success("发送成功！");
      });
    },
    addMigrate(val){
      this.form.orderDeviceList.push(val)
    },
    editMigrate(val){
      this.form.orderDeviceList[val.index] = val.form
    },
    deleteMigrate(index){
      this.form.orderDeviceList.splice(index,1)
    },
    // 编辑
    showForm() {
      let { form, editInfo } = this;
      form.companyName = editInfo.companyName;
      form.orderStatus = editInfo.orderStatus;
      form.companyAddress = editInfo.companyAddress;
      form.contactPerson = editInfo.contactPerson;
      form.contactPhone = editInfo.contactPhone;
      form.adaptationType = editInfo.adaptationType;
      form.orderDeviceList = editInfo.orderDeviceList;
      this.loadOrderInfo()
     // form.code = "";
    },
    loadOrderInfo(){
      getOrderInfo(this.editInfo.id).then((res)=>{
        this.form.orderDeviceList = res.orderDeviceList
      })
    },
    // 提交
    onSubmit() {
      let _this = this;
      let { addType, form } = this;
      let data = form

      // if(data.orderDeviceList == ''){
      //   this.$message.warning("您还未添加添加设备");
      //   return
      // }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
        // ,code:form.code
          checkCaptchaMobile({mobile:form.contactPhone}).then((res)=>{
            this.subloading = true
           /* if(!res){
              this.$message.warning("验证码有误");
              return
            }*/


            for (let index = 0; index < data.orderDeviceList.length; index++) {
              data.orderDeviceList[index].fileList = JSON.stringify(data.orderDeviceList[index].fileList)
            }
            if (addType == "edit") {
              data.id = _this.editInfo.id;
              data.orderStatus = "2"
            }
            data.tenantId=this.tenantId
            data.companyId=this.companyId
            orderSave(data).then((res) => {
              this.subloading = false
              this.$message.success("提交成功");
              this.$router.push('/userLayout/userScenes')
              _this.onReset();
            });
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      let _this = this;
      let { addType, form } = this;
      let data = form
      data.orderStatus = "1"
      data.tenantId=this.tenantId
      data.companyId=this.companyId
      // if(data.orderDeviceList == ''){
      //   this.$message.warning("您还未添加添加设备");
      //   return
      // }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
        // ,code:form.code
          checkCaptchaMobile({mobile:form.contactPhone}).then((res)=>{
            this.saveloading = true
            /*if(!res){
              this.$message.warning("验证码有误");
              return
            }*/
            for (let index = 0; index < data.orderDeviceList.length; index++) {
              data.orderDeviceList[index].fileList = JSON.stringify(data.orderDeviceList[index].fileList)
            }
            if (addType == "edit") {
              data.id = _this.editInfo.id;
            }
            orderSave(data).then((res) => {
              this.saveloading = false
              this.$message.success("保存成功");
              this.$router.push('/userLayout/userScenes')
              _this.onReset();
            });
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onReset() {
      this.$refs.ruleForm.resetFields();
      this.form.uploadId = [];
    },
    // 上传
    uploadChange(val) {
      this.uploadData.push(val);
      this.form.uploadId.push(val.id);
    },
    delUploadData(index) {
      this.uploadData.splice(index, 1);
      this.form.uploadId.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-scenes-migrate{
  width: 560px;
  >button{
    line-height: 30px;
  }
  >div{
    >div{
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
      background: #F5F5F5;
      height: 24px;
      position: relative;
      >p{
        font-size: 12px;
        color: #262626;
        line-height: 18px;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      >span{
        position: absolute;
        font-size: 14px;
        color: #B93838;
        line-height: 14px;
        right: -50px;
        cursor: pointer;
      }
      >i{
        cursor: pointer;
        &:hover{
          color: #FF4D4F;
        }
      }
    }
  }
}
.add-scenes {
  padding-top: 16px;
  .add-scenes-head {
    display: flex;
    margin-bottom: 16px;
    > span {
      cursor: pointer;
      font-size: 14px;
      color: #8c8c8c;
      line-height: 14px;
    }
  }
  .add-scenes-form-info {
    background: #fff;
    padding: 24px;
    margin: 16px 0;
    > p {
      font-size: 16px;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 24px;
    }
    .add-scenes-upload-btn {
      display: flex;
      align-items: center;
      .add-scenes-upload-template {
        color: #ea0b06;
        margin-left: 24px;
        cursor: pointer;
      }
    }
    .add-scenes-upload-list {
      width: 560px;
      > div {
        height: 24px;
        background: #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
        margin-top: 16px;
        line-height: 24px;
        > p {
          display: flex;
          align-items: center;
          > span {
            font-size: 12px;
            margin-left: 8px;
          }
        }
        > span {
          cursor: pointer;
          display: none;
          color: #ff4d4f;
        }
        &:hover {
          > span {
            display: block;
          }
        }
      }
    }
  }
}
</style>
<style>
.add-scenes-form-info .ant-form-item-label {
  height: 32px;
  line-height: 32px;
}
.add-scenes-form-info .ant-form-item-control {
  line-height: 32px;
}
.add-scenes-form-info > div:last-child {
  margin-bottom: 0;
}
</style>
