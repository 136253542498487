<template>
  <a-upload
    name="file"
    list-type="picture-card"
    class="avatar-uploader certificate-uploader-box"
    :show-upload-list="false"
    :action="action"
    :disabled="disabled"
    :before-upload="beforeUpload"
    :style="widthHeightStyle"
    :headers="headers"
    @change="handleChange"
  >
    <slot :attachment-id="value">
      <img v-if="value" :src="src" alt="avatar" :style="widthHeightStyle" />
      <div v-else class="no-upload-img">
<!--        <a-icon :type="loading ? 'loading' : 'plus-circle'" theme="filled" />-->
        <div class="icon"></div>
        <img alt="图片" v-if="background" :src="background" />
        <div v-else class="ant-upload-text">上传</div>
      </div>
    </slot>
  </a-upload>
</template>
<script>
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";

export default {
  name: "VUploadIdCard",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileTypeCode: {
      type: String,
      required: true,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    background: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    headers() {
      const token = storage.get(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },
    widthHeightStyle() {
      const { width, height } = this;
      return {
        width,
        height,
        maxWidth: "100%",
      };
    },
    action() {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`;
    },
    src() {
      return `${process.env.VUE_APP_OBS_PREFIX}/${this.value}`;
    },
  },
  methods: {
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
      } else if (info.file.status === "done") {
        this.loading = false;
        this.fileName = info.file.name;
        this.$emit("input", info.file.response.data.id);
        this.$emit("change", info.file.response.data.id);
      } else if (info.file.status === "error") {
        this.$emit("error", info.file);
      }
    },
    beforeUpload(file) {
      const isImage = file.type.indexOf("image") !== -1;
      if (!isImage) {
        this.$message.error("只能上传图片!");
      }
      const isLt10M = file.size / 1024 / 1024 < 3;
      if (!isLt10M) {
        this.$message.error("文件不能超过3MB!");
      }
      return isImage && isLt10M;
    },
  },
};
</script>

<style>
.certificate-uploader-box {
  margin-right: 24px;
}
.certificate-uploader-box > .ant-upload {
  width: 300px;
  height: 168px;
  border-radius: 0;
  background-color: #FFFFFF;
  border: 1px solid #E8E8E8;
}
.certificate-uploader-box
  .ant-upload.ant-upload-select-picture-card
  > .ant-upload {
  width: 300px;
  height: 168px;
}
.certificate-uploader-box
  .ant-upload.ant-upload-select-picture-card
  > .ant-upload
  > img {
  max-width: 100%;
  max-height: 100%;
}
.ant-upload-select-picture-card .ant-upload-text {
  color: #666;
  font-size: 14px;
}

.no-upload-img {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

}
.no-upload-img > i {
  font-size: 32px;
  color: #bfbfbf;
  position: absolute;
  left: 50%;
  bottom: 20px;
  margin-left: -16px;
  transition: 0.3s ease;
}

.no-upload-img > .icon {
  width: 32px;
  height: 32px;
  font-size: 32px;
  color: #bfbfbf;
  position: absolute;
  left: 50%;
  bottom: 0px;
  margin-left: -16px;
  background: url("../assets/img/uploadIcon1.png") no-repeat;
  transition: 0.3s ease;
}


.ant-upload.ant-upload-select-picture-card:hover .no-upload-img>.icon{
  background: url("../assets/img/uploadIcon2.png") no-repeat;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #ea0b06;
}
.ant-upload.ant-upload-select-picture-card:hover .no-upload-img > i {
  color: #ea0b06;
}
</style>
