<template>
  <a-modal v-model="visibleShow" title="重置密码" width="480px" @cancel="cancel">
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
    >
        <a-form-model-item ref="password" label="密码" prop="password">
            <a-input
                v-model="form.password"
                placeholder="请填写密码"
                @blur=" () => { $refs.password.onFieldBlur(); } "
            />
        </a-form-model-item>
        <a-form-model-item ref="confirmPassword" label="确认密码" prop="confirmPassword">
            <a-input
                v-model="form.confirmPassword"
                 placeholder="请填写确认密码"
                @blur=" () => { $refs.confirmPassword.onFieldBlur(); } "
            />
        </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button @click="resetForm">取 消</a-button>
      <a-button class="btn-red" @click="onSubmit">确 定</a-button>
    </template>
  </a-modal>
</template>

<script>
import {mapGetters,mapActions } from "vuex";
import { memberNewPassword } from "@/api/enterpriseManage";
export default {
  props: ["visible","info"],
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写确认密码'));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      visibleShow: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        password: '',
        confirmPassword:''
      },
      rules: {
        password: [{ required: true, message: '请填写密码', trigger: 'blur' },],
        confirmPassword: [{ validator: validatePass, required: true, trigger: 'blur' },],
      },
    };
  },
  watch: {
    visible: function (val) {
      this.visibleShow = val;
    },
  },
  mounted(){
    console.log(this.info)
    this.visibleShow = this.visible;
  },
  methods:{
    cancel() {
      this.$emit("input", false);
    },
    onSubmit() {
      let _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = {
            newPassword:this.form.confirmPassword,
            resUserId:this.info.appliedBy
          }
          memberNewPassword(data).then((res)=>{
            _this.$message.success('修改成功');
            _this.$refs.ruleForm.resetFields();
            _this.$emit("input", false);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$emit("input", false);
    },
  }
};
</script>

<style>

</style>