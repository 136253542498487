import { PortalRequest as request } from "@/utils/request";

/**
 * 当前登录用户企业
 * @param data
 * @returns {AxiosPromise}
 */
export const getCurrentCompany = (id) => {
  return request({
    url: `/res-company/info/${id}`,
    method: "get",
  });
};
/**
 * 创建企业
 * @param data
 * @returns {AxiosPromise}
 */
export const createCompany = (data) => {
  return request({
    url: "/res-company/save",
    method: "post",
    data,
  });
};
/**
 * 编辑企业信息
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const editCompany = (id,data) => {
  return request({
    url: `/res-company/${id}/update`,
    method: "post",
    data,
  });
};

/**
 * 提交认证资料
 * @param data
 * @returns {AxiosPromise}
 */
export const applyCompanyCertification = (data) => {
  return request({
    url: "/res-company/apply-certification",
    method: "post",
    data,
  });
};
/**
 * 修改企业logo
 * @param companyLogoAttmId
 * @returns {AxiosPromise}
 */
export const changeLogo = (companyLogoAttmId) => {
  return request({
    url: "/res-company/change-logo",
    method: "post",
    data: { companyLogoAttmId },
  });
};

// 用户是否为成员管理审核人
export const getIsVerifier = () => {
  return request({
    url: `/join-company/isVerifier`,
    method: "get",
  });
};

// 获取企业信息
export const getCompanyInfo = (id) => {
  return request({
    url: `/res-company/info/${id}`,
    method: "get",
  });
};

// 加入企业
export const getAddCompany = (id) => {
  return request({
    url: `/join-company/${id}/apply-join`,
    method: "get",
  });
};

// 用户是否为企业创建者
export const getIsCreater = (id) => {
  return request({
    url: `/join-company/${id}/is-owner`,
    method: "get",
  });
};

//绑定拒绝
export const getInformation = () => {
  return request({
    url: '/join-company/new-result',
    method: "get",
  });
};

// 用户企业列表
export const getJoinCompanyList = (data) => {
  return request({
    url: `/join-company/user/company-list`,
    method: "post",
    data
  });
};

// 设置默认企业
export const getDefaultCompany = () => {
  return request({
    url: `/join-company/get-default-company`,
    method: "get",
  });
};



// 获取用户的注册来源区域
export const getSourceInfo = () => {
  return request({
    url: `/res-user-register-source/source-info`,
    method: "get",
  });
};