<template>
    <a-table
            :columns="columns"
            :data-source="data"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
    >
        <template slot="operate" slot-scope="text, record">
            <p class="user-scene-table-operate">
                <span @click="scenesDetail(record.sceneId, record.tenantId)">查看详情</span>
            </p>
        </template>
    </a-table>
</template>

<script>
    const columns = [
        {
            title: "榜单信息",
            dataIndex: "sceneTitle",
            key: "sceneTitle",
            width: 300,
            ellipsis: true,
        },
        {
            title: "需求方",
            dataIndex: "sceneCompanyName",
            key: "sceneCompanyName",
        },
        {
            title: "发布时间",
            dataIndex: "reviewedTime",
            key: "reviewedTime",
        },
        {
            title: "邀约时间",
            key: "bidInvitedTime",
            dataIndex: "bidInvitedTime",
        },
        {
            title: "申请截止时间",
            key: "applyDueDate",
            dataIndex: "applyDueDate",
        },
        {
            title: "操作",
            key: "operate",
            dataIndex: "operate",
            scopedSlots: {customRender: "operate"},
        },
    ];
    import {getInviteList} from "@/api/cjkf/scn-scene";
    import {mapGetters} from "vuex";
    import {getTenantInfo} from "@/api/common";

    export default {
        data() {
            return {
                columns,
                data: [],
                pagination: {
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: 0,
                    showTotal: (total) => `共 ${total} 项`,
                },
                page: {
                    current: 1,
                    size: 10,
                },
                loading: true,
            };
        },
        mounted() {
            this.loadData();
        },
        computed: {
            ...mapGetters(["companyId", "tenantId"]),
        },
        methods: {
            loadData() {
                this.loading = true;
                let data = {
                    bidCompanyId: this.companyId,
                    currentTenantId: this.tenantId,
                };
                getInviteList(this.page, data).then((res) => {
                    this.data = res.records;
                    this.pagination.total = res.total;
                    this.loading = false;
                });
            },
          handleTableChange(val){
            this.page.size = val.pageSize;
            this.page.current = val.current;
            this.loadData();
          },
            tablePage(page) {
                this.page.current = page.current;
                this.loadData();
            },
            scenesDetail(itemId, id) {
                getTenantInfo({id}).then((res) => {
                    window.open(`${res.domain}/homeScenes/detail?id=${itemId}`);
                });
            },

            scenesList() {
                this.$router.push("/homeScenes/historyList");
            },
        },
    };
</script>

<style lang="scss" scoped>
    .user-scene-table-operate {
        display: flex;

        > span {
            cursor: pointer;
            margin-right: 16px;
            color: #ea0b06;

            &:last-child {
                margin-right: 0;
            }
        }
    }
</style>
