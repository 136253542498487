<template>
  <img :src="srcUrl" alt="封面" />
</template>

<script>
import { downloadFile } from "@/api/cjkf/scene";

export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      srcUrl: require("@/assets/img/mediaApp2.png")
    };
  },
  watch: {
    id: function() {
      this.downloadAnnex();
    }
  },
  mounted() {
    this.downloadAnnex();
  },
  methods: {
    downloadAnnex() {
      if (this.id) {
        downloadFile(this.id).then((res) => {
          let urls = window.URL.createObjectURL(new Blob([res]));
          this.srcUrl = urls;
          this.$emit("input", urls);
        });
      } else {
        const i = this.getRandomInt(1, 10);
        // this.srcUrl = require(`@/assets/img/cj/${i}.png`);
      }
    },
    getRandomInt(min, max) {
      // 以下函数返回 min（包含）～ max（包含）之间的数字：
      return  Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }
};
</script>
