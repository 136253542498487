<template>
  <a-select
    v-model="val"
    :allow-clear="true"
    :disabled="disabled"
    :mode="mode"
    :placeholder="placeholder"
    @change="dataChange"
    @search="dataSelect"
  >
    <a-select-option
      v-for="(el,index) in tenants"
      :key="index"
      :value="el.id"
    >
      {{ el.tenantName }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VSelectTenant',
  props: {
    value: {
      type: [String, Array],
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '不选择默认为推送所有平台'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['tenants']),
    mode () {
      if (this.multiple) {
        return 'multiple'
      } else {
        return 'default'
      }
    },
    val: {
      get () {
        const { multiple } = this
        if (multiple) {
          return this.value || []
        }
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods:{
    dataChange(val,lab,x){
      this.$emit('areaChange',val,lab,x)
    },
    dataSelect(val,noe){
      this.$emit('textChange',val,noe)
    }
  },
}
</script>

<style scoped>

</style>
