<template>
  <div class="active-card">
    <div class="img">
      <v-img
          :attachment-id="val.coverUrl || val.courseCoverAttmId"
          :default-img="require('@/assets/img/kctp.png')"
      />
    </div>


    <div style="flex: 1">
      <div class="text">
      <p>{{val.conferenceName || val.courseName}}</p>
      <p v-if="type==='list'">{{val.summary}}</p>
      <p v-if="type==='active' && val.actActivity">{{val.actActivity.summary}}</p>
      </div>


      <div class="bottom" v-if="type==='active'">
        <p v-if="val.actActivity">
          活动时间：<span>{{val.actActivity.startTime}}</span>
        </p>
        <div v-if="val.address">
          <a-icon type="environment" style="color: #7B7B7B;margin-right: 4px"/>
          <span>{{val.address}}</span>
        </div>
      </div>


      <div class="bottom" v-if="type==='list'">
        <p>
          主讲人：<span>{{val.coursePresentecoursePresenter}}</span>
        </p>
        <div v-if="val.courseCompanyName">
          <img src="@/assets/img/danwei.png" style="margin-right: 4px;margin-bottom: 2px" alt="">
          <span>主讲单位：</span>
          <span>{{val.courseCompanyName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import VIcon from "@/components/VIcon";
export default {
  name: "activeCard",
  props:['type','val'],
  components:{
    VImg,
    VIcon
  }

}
</script>

<style scoped lang="scss">
.active-card{
  padding: 16px 16px 16px 0;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #E8E8E8;
  box-sizing: border-box;
  &:hover{
    background: #FAFAFA;
  }
  >.img{
    width: 204px;
    height: 155px;
    margin-right: 24px;
    >img{
      display: block;
      width: 204px;
      height: 155px;
    }
  }

  >div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    >.text{
      >:nth-child(1){
        font-size: 16px;
        font-weight: 500;
        color: #262626;
        line-height: 22px;
        margin-bottom: 9px;
      }
      >:nth-child(2){
        font-size: 12px;
        color: #595959;
        line-height: 20px;
      }
    }

    >.bottom{
      display: flex;
      justify-content: space-between;
      >:first-child{
        font-size: 14px;
        color: rgba(0,0,0,0.45);
        line-height: 20px;
      }
      >:last-child{
        font-size: 14px;
        color: rgba(0,0,0,0.45);
        line-height: 20px;
      }
    }
  }
}
</style>
