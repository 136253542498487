import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";

const getters = {
  access_token: () => storage.get(ACCESS_TOKEN),
  userInfo: (state) => state.user.info,
  isLogin: (state) => !!state.user.info,
  settings: (state) => state.settings.settings,
  dict: (state) => state.dict.dict,
  msg: (state) => state.msg,
  tenants: (state) => state.tenant.tenants,
  dictText: (state) => (code, value) => {
    const list = state.dict.dict[code];
    const arr = (list || [])
      .filter((e) => e.itemValue === value)
      .map((e) => e.itemText);
    // eslint-disable-next-line no-mixed-operators
    return (arr && arr.length && arr[0]) || "";
  },
  redirectUri: (state) => process.env.VUE_APP_REDIRECT_URI,
  companyId: (state) => state.user.company_id,
  companyList: (state) => state.user.company_list,
  companyInfo: (state) =>
    state.user.company_list.filter((e) => e.id === state.user.company_id)[0],
  tenantId: (state) => state.user.tenant_id,
  tenantList: (state) => state.user.tenant_list,
  permissions: (state) => state.user.permissions,
  company_status: (state) => state.user.company_status,
  companyIndex: (state) => state.companyIndex,
};

export default getters;
