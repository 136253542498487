import {PortalRequest as request} from "@/utils/request";

// 分页查看订单
export const getOrderList = (params, data) => {
    return request({
        url: "/order/page-list-me",
        method: "post",
        params: params,
        data,
    });
};

// 查看订单详情
export const getOrderInfo = (id) => {
    return request({
        url: `/order/${id}`,
        method: "get",
    });
};

// 删除一个草稿
export const orderRemove = (id) => {
    return request({
        url: `/order/${id}/remove`,
        method: "delete",
    });
};

// 新建/更新订单
export const orderSave = (data) => {
    return request({
        url: `/order/save`,
        method: "post",
        data
    });
};