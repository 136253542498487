import {PortalRequest as request} from "@/utils/request";

// 分页查看所有公开的订单
export const sceneList = (params, data) => {
    return request({
        url: "/scn-scene-activiti/list-public-scene",
        method: "post",
        params,
        data,
    });
};

// 查看场景详情
export const sceneDetail = (sceneId) => {
    return request({
        url: `/scn-scene-activiti/${sceneId}`,
        method: "get",
    });
};

// 前台相似场景
export const similarSceneList = (sceneId, {current, size}) => {
    return request({
        url: `/scn-scene-activiti/${sceneId}/list-similar-scene`,
        method: "get",
        params: {current, size},
    });
};

// 查看全部揭榜(不含邀约中)
export const getSceneAllBid = (sceneId) => {
    return request({
        url: `/scn-scene-activiti/${sceneId}/list-all-bid`,
        method: "get",
    });
};

// 查看订单揭榜信息
export const getBidInfo = (bidId) => {
    return request({
        url: `/scn-scene-activiti/bid/${bidId}/info`,
        method: "get",
    });
};

// 用户中心我的申报列表
export const getOwnCase = (params, data) => {
    return request({
        url: `/scn-scene-activiti/list-own-case`,
        method: "post",
        params,
        data,
    });
};
// 用户中心我的发榜列表
export const getOwnScene = (params, data) => {
    return request({
        url: `/scn-scene-activiti/list-own-scene`,
        method: "post",
        params,
        data,
    });
};
// 用户中心我的揭榜列表
export const getOwnSceneBids = (params, data) => {
    return request({
        url: `/scn-scene-activiti/list-own-bid`,
        method: "post",
        params,
        data,
    });
};
// 用户中心我的发榜终止揭榜
export const sceneCancelBid = (id) => {
    return request({
        url: `/scn-scene-activiti/${id}/cancel-bid`,
        method: "post",
    });
};

// 创建和保存订单
export const saveScene = (data) => {
    return request({
        url: `/scn-scene-activiti/save`,
        method: "post",
        data,
    });
};

// 删除已有草稿
export const sceneDelete = (sceneId) => {
    return request({
        url: `/scn-scene-activiti/${sceneId}/remove`,
        method: "delete",
    });
};

// 查看订单全部动态
export const getSceneMessages = (sceneId) => {
    return request({
        url: `/scn-scene-activiti/${sceneId}/message/list-all`,
        method: "get",
    });
};

// 选择一个揭榜方(即选帅)
export const chooseOrderBid = (bidId) => {
    return request({
        url: `/scn-scene-activiti/bid/${bidId}/choose`,
        method: "post",
    });
};
// 揭榜详情(我的方案)
export const sceneMyBidInfo = (id) => {
    return request({
        url: `/scn-scene-activiti/${id}/my-bid-info`,
        method: "get",
    });
};

// 我要揭榜
export const sceneBid = (data) => {
    return request({
        url: `/scn-scene-activiti/bid`,
        method: "post",
        data,
    });
};

// 分页查看订单投标
export const getOrderBids = ({current, size}) => {
    return request({
        url: `/scn-order-bid/page-list`,
        method: "post",
        params: {current, size},
    });
};

// 查看投标详情
export const getOrderBidInfo = (bidId) => {
    return request({
        url: `/scn-order-bid/${bidId}`,
        method: "get",
    });
};

// 分页查看我投标的订单
export const getOrderBidList = ({current, size}, data) => {
    return request({
        url: `/scn-order/page-bid-list`,
        method: "post",
        params: {current, size},
        data,
    });
};

// 投标操作(即揭榜)
export const bidOrder = (orderId, data) => {
    return request({
        url: `/scn-order-bid/${orderId}/bid`,
        method: "post",
        data,
    });
};

// 用户中心我的邀约列表
export const getInviteList = (params) => {
    return request({
        url: `/scn-scene-activiti/list-own-invitation`,
        method: "post",
        params,
    });
};
