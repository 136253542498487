<template>
  <div class="addCom">
    <p>创建或加入企业</p>
    <div class="create-body">
      <p>选择创建或加入企业</p>
      <div>
        <div>
          <img src="@/assets/img/createCom1.png" alt="">
          <p>创建企业</p>
          <p>只有企业创建者才能进行场景申报和场景揭榜</p>
          <a-button class="btn-red" @click="createCompany">
            创建企业
          </a-button>
        </div>

        <div>
          <img src="@/assets/img/createCom1.png" alt="">
          <p>加入企业</p>
          <p>关联企业不能进行场景申报和场景揭榜</p>
          <a-button class="btn-red" @click="addEnterprise">
            加入企业
          </a-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "addCompany",
  methods:{
    createCompany(){
     this.$router.push('/userLayout/userInfo/createCompany')
    },
    addEnterprise(){
      this.$router.push('/userLayout/userInfo/associationEnterprise')
    }
  }
}
</script>

<style scoped lang="scss">
.addCom{
  >p{
    font-size: 20px;
    font-weight: 500;
    color: #262626;
    line-height: 28px;
    margin: 24px 0 28px 32px;
  }
  >.create-body{
   margin: 0 auto;

   >p{
     font-size: 18px;
     color: rgba(0,0,0,0.85);
     line-height: 25px;
     margin-bottom: 80px;
     text-align: center;
   }
    >div{
      display: flex;
      width: 936px;
      justify-content: space-between;
      margin: 0 auto;
    >div{
      width: 440px;
      height: 430px;
      display: flex;
      flex-direction: column;
      align-items: center;

      background: #F8F9FC;
      border-radius: 8px;
      border: 1px solid #E8E8E8;
      >img{
        display: block;
        width: 68px;
        height: 68px;
        margin-bottom: 32px;
        margin-top: 72px;
      }
      >:nth-child(2){
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
        margin-bottom: 24px;
      }
      >:nth-child(3){
        font-size: 14px;
        color: #8C8C8C;
        line-height: 20px;
        margin-bottom: 88px ;
      }
    }
    }
  }

}
</style>
