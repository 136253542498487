<template>
  <a-modal
    v-model="visible"
    title="咨询详情"
    width="960px"
    @cancel="cancelInfo"
    wrap-class-name="cancel-info-modal"
  >
    <div class="cancel-info-box">
      <div class="cancel-info-content">
        <div>
          <p>咨询详情</p>
          <div>
            <p v-html="consultInfo.consultContent"></p>
            <template v-if="consultInfo.consultAttachments">
              <div v-for="item in consultInfo.consultAttachments" :key="item.id">
                <p><a-icon type="link" /><span>{{item.fileName}}</span></p>
                <a-icon type="download"  @click="download(item.id)"/>
              </div>
            </template>
          </div>
        </div>
        <div>
          <p>咨询人信息</p>
          <div>
            <!-- <span>企业名称：{{consultInfo.contactCompany?consultInfo.contactCompany:'--'}}</span> -->
            <span>联系人：{{consultInfo.contactPerson}}</span>
            <span>联系电话：{{consultInfo.contactDetail}}</span>
            <span>提交时间：{{consultInfo.consultTime | moment('YYYY-MM-DD HH:mm')}}</span>
          </div>
        </div>
      </div>
      <div class="cancel-info-reply">
          <p>回复内容 <span>{{consultInfo.replyTime}}</span></p>
          <div v-if="consultInfo.consultStatus == 3">
            <p>{{consultInfo.replyContent}}</p>
            <template v-if="consultInfo.replyAttachments">
              <div v-for="item in consultInfo.replyAttachments" :key="item.id">
                <p><a-icon type="link" /><span>{{item.fileName}}</span></p>
                <a-icon type="download"  @click="download(item.id)"/>
              </div>
            </template>
          </div>
          <div class="cancel-info-no-reply" v-else>
              <template v-if="consultInfo.consultStatus == 1">
                <img alt="图片" src="@/assets/img/icon5.png">
                <p>您的咨询已提交，请等待平台受理</p>
              </template>
              <template v-if="consultInfo.consultStatus == 4">
                <img alt="图片" src="@/assets/img/icon6.png">
                <p>抱歉！您的咨询已被关闭</p>
              </template>
              <template v-if="consultInfo.consultStatus == 2">
                <img alt="图片" src="@/assets/img/icon7.png">
                <p>平台已受理您的咨询，请耐心等待回复</p>
              </template>
          </div>
      </div>
    </div>
    <template slot="footer">
      <a-button @click="cancelInfo" class="btn-red">确 定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { getConsultInfo } from "@/api/news";
export default {
  props: ["show",'id'],
  data() {
    return {
      visible: false,
      consultInfo:'',
    };
  },
  mounted() {
    this.visible = this.show;
    this.loadGetNewsInfo()
  },
  methods: {
    cancelInfo() {
      this.$emit("input", false);
    },
    loadGetNewsInfo(){
      getConsultInfo(this.id).then((res)=>{
        this.consultInfo = res
      })
    },
    download(id) {
      window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-info-box{
    display: flex;
    color: #262626;
    >div{
        flex: 1;
        padding: 24px;
        &:first-child{
            border-right: 1px solid #f0f0f0;
        }
    }
    .cancel-info-content{
        >div{
            margin-bottom: 32px;
            &:last-child{
                margin-bottom: 0;
            }
            >p{
                line-height: 14px;
                padding-left: 6px;
                border-left: 4px solid #EA0B06;
                font-weight: bold;
                margin-bottom: 20px;
            }
            >div{
                >p{
                    line-height: 22px;
                    text-align: justify;
                }
                >span{
                    display: block;
                    line-height: 12px;
                    margin-bottom: 16px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                >div{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 12px;
                    height: 24px;
                    transition: all .3s;
                    padding: 0 8px;
                    >p{
                        display: flex;
                        align-items: center;
                        >i{
                            margin-right: 6px;
                            color: #8c8c8c;
                        }
                        >span{
                            font-size: 12px;
                            max-width: 300px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    >i{
                        cursor: pointer;
                        color: #EA0B06;
                        display: none;
                    }
                    &:hover{
                        background: #f5f5f5;
                        >i{
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .cancel-info-reply{
        >p{
            line-height: 14px;
            padding-left: 6px;
            border-left: 4px solid #EA0B06;
            font-weight: bold;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            >span{
                font-weight: 400;
                color: #bfbfbf;
                line-height: 14px;
                padding-left: 12px;
                margin-left: 12px;
                border-left: 1px solid #f0f0f0;
            }
        }
        >div{
            >p{
                line-height: 22px;
                text-align: justify;
            }
            >div{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 12px;
                height: 24px;
                transition: all .3s;
                padding: 0 8px;
                >p{
                    display: flex;
                    align-items: center;
                    >i{
                        margin-right: 6px;
                        color: #8c8c8c;
                    }
                    >span{
                        font-size: 12px;
                        max-width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                >i{
                    cursor: pointer;
                    color: #EA0B06;
                    display: none;
                }
                &:hover{
                    background: #f5f5f5;
                    >i{
                        display: block;
                    }
                }
            }
        }
        .cancel-info-no-reply{
            margin-top: 50px;
            >img{
                display: block;
                margin: 0 auto;
            }
            >p{
                line-height: 14px;
                margin-top: 32px;
                text-align: center;
            }
        }
    }
}
</style>
<style>
.cancel-info-modal .ant-modal-body {
  padding: 0;
}
</style>
