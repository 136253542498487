<template>
  <icon-font :type="type" />
</template>

<script>
import { Icon } from "ant-design-vue";
import icon from '../assets/font/font'
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: icon
});
export default {
  components: {
    IconFont,
  },
  props: ["type"],
};
</script>
