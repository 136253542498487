<template>
  <a-modal
    v-model="visible"
    centered
    title="绑定微信"
    @cancel="visible = false"
  >
    <div class="set-model">
      <div id="login_container"></div>
    </div>
    <template #footer>
      <div>
        <a-button @click="visible = false">取 消</a-button>
        <a-button @click="handleSubmit" class="btn-red">确 定</a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import { bindEmail } from "@/api/res-user";
import { getWechatParams } from "@/api/auth";

export default {
  data() {
    return {
      visible: false,
      appid: "",
      state: "",
      redirect_uri: "",
    };
  },
  methods: {
    open() {
      this.visible = true;
      const { appid, state, redirect_uri } = this;
      this.$nextTick(() => {
        window.WxLogin({
          self_redirect: false,
          id: "login_container",
          appid,
          scope: "snsapi_login",
          redirect_uri: encodeURIComponent(redirect_uri),
          state,
          style: "",
          href: "",
        });
      });
    },
    handleSubmit() {
      const { email, code } = this.form;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          bindEmail({ email, code }).then(() => {
            this.$message.info("绑定成功！");
            this.GetInfo();
            this.visible = false;
          });
        }
      });
    },
  },
  async mounted() {
    const { appid, state, redirect_uri } = await getWechatParams();
    this.appid = appid;
    this.state = state;
    this.redirect_uri = redirect_uri;
  },
};
</script>
<style lang="scss" scoped>
.set-model {
  display: flex;
  justify-content: center;
}
</style>
