<template>
  <a-select
    v-model="val"
    :mode="mode"
    :allow-clear="true"
    :disabled="disabled"
    style="width: 100%"
    :placeholder="placeholder"
  >
    <a-select-option
      v-for="(el,index) in dict[code]"
      :key="index"
      :value="el.itemValue"
    >
      {{ el.itemText }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'VSelectDict',
  props: {
    value: {
      type: [String, Array],
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    code: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters(['dict']),
    mode () {
      if (this.multiple) {
        return 'multiple'
      } else {
        return 'default'
      }
    },
    val: {
      get () {
        const { multiple } = this
        if (multiple) {
          return this.value || []
        }
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.LoadDictData([this.code])
  },
  methods: {
    ...mapActions(['LoadDictData'])
  }
}
</script>