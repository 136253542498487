<template>
  <a-date-picker
    v-model="val"
    :disabled-date="disabledDate"
    format="YYYY-MM-DD"
    :show-time="true"
  />
</template>

<script>
import moment from "moment";

export default {
  name: "VDatePickerAfter",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    val: {
      get() {
        const { value } = this;
        return value ? moment(value, "YYYY-MM-DD") : null;
      },
      set(val) {
        const value = val ? val.format("YYYY-MM-DD") : null;
        this.$emit("input", value);
      },
    },
  },
  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    },
  },
};
</script>
