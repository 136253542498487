import { PortalRequest as request } from "@/utils/request";

/**
 * 修改个人资料
 * @param data
 * @returns {AxiosPromise}
 */
export const updateUserInfo = (data) => {
  return request({
    url: "/res-user/update-info",
    method: "post",
    data,
  });
};
/**
 * 提交认证资料
 * @param data
 * @returns {AxiosPromise}
 */
export const applyUserCertification = (data) => {
  return request({
    url: "/res-user/apply-certification",
    method: "post",
    data,
  });
};
/**
 * 绑定手机
 * @returns {AxiosPromise}
 */
export const bindPhone = ({ phone, code }) => {
  return request({
    url: "/res-user/bind-phone",
    method: "post",
    data: { phone, code },
  });
};
/**
 * 修改手机
 * @returns {AxiosPromise}
 */
export const editPhone = ({ code, newPhone, newCode }) => {
  return request({
    url: "/res-user/modify-phone",
    method: "post",
    data: { code, newPhone, newCode },
  });
};
/**
 * 绑定邮箱
 * @returns {AxiosPromise}
 */
export const bindEmail = ({ email, code }) => {
  return request({
    url: "/res-user/bind-email",
    method: "post",
    data: { email, code },
  });
};
/**
 * 修改邮箱
 * @returns {AxiosPromise}
 */
export const editEmail = ({ code, newEmail, newCode }) => {
  return request({
    url: "/res-user/modify-email",
    method: "post",
    data: { code, newEmail, newCode },
  });
};

// 分页查询我要审批加入企业的申请
export const getJoinCompanyList = (params, data) => {
  return request({
    url: "/join-company/page-list-me",
    method: "post",
    params,
    data,
  });
};

// 同意加入企业
export const getAgreeJoin = (id) => {
  return request({
    url: `/join-company/${id}/agree-join`,
    method: "get",
  });
};

// 根据加入企业id拒绝加入
export const getRefuseJoin = (params) => {
  return request({
    url: `/join-company/refuse-join`,
    method: "post",
    data:params,
  });
};

// 用户首页统计数据
export const getUserHomeData = () => {
  return request({
    url: `/res-user/statistics`,
    method: "get",
  });
};

// 关联企业列表
export const getCompanyList = (params) => {
  return request({
    url: `/res-company/list-all-by-company-name`,
    method: "post",
    params,
  });
};

// 判断手机号是否已注册
export const getIsMobileRegistered = (phone) => {
  return request({
    url: `/res-user/is-mobile-registered`,
    method: "get",
    params: { phone },
  });
};
