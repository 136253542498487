import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";

import getters from "./getters";
import dict from "./modules/dict";
import settings from "./modules/settings";
import msg from "./modules/msg";
import tenant from './modules/tenant'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dict,
    settings,
    tenant,
    msg
  },
  state: {
    companyIndex:0,
  },
  mutations: {
    getCompanyIndex(state,data){
      state.companyIndex=data
    },
  },
  actions: {},
  getters,
});
