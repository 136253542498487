<template>
  <div class="my-scenes-detail-body">
    <div class="my-scenes-detail-head-body">
      <div class="my-scenes-detail-page-head">
        <router-link to="/userLayout/myScenes/list" tag="a"
          ><a-icon type="left" />返回上一页</router-link
        >
      </div>
      <div class="my-scenes-detail-info-body">
        <div class="my-scenes-detail-info">
          <strong>{{ sceneInfo.title }}</strong>
          <p>
            <span>{{ sceneInfo.field | dict("SCENE_FIELD") }}</span>
            <span>{{ sceneInfo.industry | dict("SCENE_INDUSTRY") }}</span>
            <!--            <span>{{ sceneInfo.skill | dict("SCENE_SKILL") }}</span>-->
          </p>
          <div>
            <span>场景编号：{{ sceneInfo.id }}</span>
            <span>发布时间：{{ sceneInfo.createTime }}</span>
            <span>发布单位：{{ sceneInfo.companyName }}</span>
            <span>联合申报单位：{{ sceneInfo.associate }}</span>
            <span>联系人：{{ sceneInfo.contactPerson }}</span>
            <span>联系方式：{{ sceneInfo.contactPhone }}</span>
            <span>场景地址：{{ sceneInfo.sceneAddress }}</span>
            <span>预估启动建设年月：{{ sceneInfo.expectedStartDate }}</span>
          </div>
        </div>
        <div class="my-scenes-detail-info-status">
          <span>状态</span>
          <!-- <img src="@/assets/img/dengdai.png"/> -->
          <p>{{ sceneInfo.sceneStatus | dict("SCENE_STATUS") }}</p>
          <span style="margin-top: 20px; color: red">{{
            sceneInfo.previewComment
          }}</span>
        </div>
      </div>
      <div class="my-scenes-detail-tab-body">
        <span :class="{ active: !detailTabIndex }" @click="detailTabIndex = 0"
          >场景说明</span
        >
        <!-- <span :class="{'active':detailTabIndex == 1}" @click="detailTabIndex = 1">公司简介</span> -->
      </div>
    </div>
    <div class="my-scenes-detail-content-body">
      <div class="my-scenes-detail-content">
        <detail-desc v-if="!detailTabIndex" :info="sceneInfo" />
        <enterprise-info v-else />
      </div>
    </div>
  </div>
</template>

<script>
import detailDesc from "./detailDesc.vue";
import enterpriseInfo from "./enterpriseInfo.vue";
import { sceneDetail } from "@/api/cjkf/scn-scene-activiti";
export default {
  components: {
    detailDesc,
    enterpriseInfo,
  },
  data() {
    return {
      detailTabIndex: 0,
      sceneInfo: {},
    };
  },
  computed: {
    sceneId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.sceneInfo = await sceneDetail(this.sceneId);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-scenes-detail-body {
  background: #f5f5f5;
  height: 100%;
  .my-scenes-detail-head-body {
    background: #fff;
    padding: 0 24px;
    .my-scenes-detail-page-head {
      display: flex;
      align-items: center;
      color: #8c8c8c;
      line-height: 14px;
      margin-bottom: 24px;
      padding-top: 16px;
      > a {
        color: #8c8c8c;
      }
    }
    .my-scenes-detail-info-body {
      display: flex;
      justify-content: space-between;
      .my-scenes-detail-info {
        width: 80%;
        > strong {
          font-size: 24px;
          line-height: 24px;
          display: block;
          margin-bottom: 16px;
        }
        > p {
          display: flex;
          margin-bottom: 24px;
          > span {
            height: 22px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            line-height: 20px;
            padding: 0 8px;
            margin-right: 8px;
          }
        }
        > div {
          display: flex;
          flex-wrap: wrap;
          > span {
            font-size: 14px;
            line-height: 14px;
            width: 25%;
            margin-bottom: 24px;
          }
        }
      }
      .my-scenes-detail-info-status {
        text-align: right;
        > span {
          color: #8c8c8c;
          display: block;
          line-height: 14px;
          margin-bottom: 24px;
        }
        > p {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 24px;
          line-height: 24px;
          > img {
            margin-right: 8px;
          }
        }
      }
    }
    .my-scenes-detail-tab-body {
      display: flex;
      > span {
        height: 56px;
        line-height: 56px;
        border-bottom: 2px solid transparent;
        font-size: 15px;
        margin-right: 40px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          border-color: #EA0B06;
          color: #EA0B06;
        }
      }
    }
  }
  .my-scenes-detail-content-body {
    height: calc(100% - 273px);
    padding: 24px;
    .my-scenes-detail-content {
      height: 100%;
      background: #fff;
      overflow-y: auto;
    }
  }
}
</style>
<style></style>
