import { PortalRequest as request } from "@/utils/request";

/**
 * 查询全部角色
 * @param companyId 企业ID
 * @param tenantId 租户ID
 * @returns {AxiosPromise}
 */
export const getResRoleList = ({ companyId, tenantId }) => {
  return request({
    url: "/res-role/total-list",
    method: "post",
    data: {
      companyId,
      tenantId,
    },
  });
};

export const getTotalPermissionList = () => {
  return request({
    url: "/res-permission/list",
    method: "post",
    data: {},
  });
};

export const getRoleUserList = (data) => {
  return request({
    url: "/res-role/user-list",
    method: "post",
    data,
  });
};

export const getUserList = (page, data) => {
  return request({
    url: "/res-user/page-list",
    method: "post",
    params: page,
    data,
  });
};

export const saveUserRole = (companyId, roleId, userIds) => {
  return request({
    url: "/res-role/save-user-role",
    method: "post",
    params: { companyId, roleId, userIds: userIds.join(",") },
  });
};

export const getPermissions = ({ companyId, tenantId }) => {
  return request({
    url: "/res-user/permissions",
    method: "post",
    data: { companyId, tenantId },
  });
};
