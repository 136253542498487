<template>
  <div class="dev-manage">
    <div>
      <p>开发管理</p>
      <div class="my-dev-filter-box">
        <div class="my-dev-filter-tab">
          <div class="my-dev-filter-tab-list">
            <span>所属类目：</span>
            <div :class="{ active: devCode == '' }" @click="changeType('')">
              全部
            </div>
            <div
              v-for="(item, index) in dict.PROJECT_TYPE"
              :key="item.itemValue"
              :class="{ active: devCode == item.itemValue }"
              @click="changeType(item.itemValue)"
              :style="{ left: -(index + 1) + 'px' }"
            >
              {{ item.itemText }}
            </div>
          </div>
        </div>
        <div class="my-dev-filter-btn">
          <a-input-search
            allowClear
            v-model="dataInfo.projectName"
            placeholder="请输入项目名称搜索"
            style="width: 272px; margin-right: 8px"
            @search="onSearch"
          />
          <a-button class="btn-red" icon="plus" @click="visible = true"
            >创建
          </a-button>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="devData"
        :pagination="pageData"
        :loading="loading"
        @change="pageChange"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="projectType" slot-scope="text">
          <p>{{ text | dict("PROJECT_TYPE") }}</p>
        </template>

        <div slot="operating" slot-scope="text, record">
          <div class="my-dev-operating">
            <span v-if="record.frontendUrl" @click="toWorkspace(record)"
              >进入开发</span
            >
            <span @click="projectSet(record)">项目设置</span>
          </div>
        </div>
      </a-table>
    </div>

    <a-modal v-model="visible" title="创建项目" width="480px" @cancel="cancel">
      <a-spin :spinning="spinning">
        <div>
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
          >
            <a-form-model-item ref="name" label="项目名称" prop="projectName">
              <a-input
                v-model="form.projectName"
                placeholder="请输入项目名称"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item ref="type" label="项目类型" prop="projectType">
              <a-select v-model="form.projectType" placeholder="请选择">
                <a-select-option
                  :value="item.itemValue"
                  v-for="(item, index) in dict.PROJECT_TYPE"
                  :key="index"
                  >{{ item.itemText }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>

      <template #footer>
        <a-button @click="cancel" :disabled="spinning">取 消</a-button>
        <a-button class="btn-red" :disabled="spinning" @click="submit"
          >确 定
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model="setting"
      title="设置"
      width="600px"
      @cancel="setting = false"
    >
      <div>
        <a-form-model
          ref="projectSet"
          :model="formSet"
          :rules="rulesSet"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="right"
        >
          <a-form-model-item ref="name" label="项目名称" prop="projectName">
            <a-input
              style="width: 350px"
              v-model="formSet.projectName"
              placeholder="请输入项目名称"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item ref="id" label="Appid" prop="appid">
            <div style="display: flex">
              <a-input
                style="width: 350px; margin-right: 20px"
                v-model="formSet.appid"
                disabled
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
              <a-button class="btn-red" @click="copyText">复制</a-button>
            </div>
          </a-form-model-item>
        </a-form-model>

        <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="projectDel"
        >
          <template slot="title">
            <p>应用删除后不可更改</p>
          </template>
          <a-button style="margin-left: 115px">删除</a-button>
        </a-popconfirm>
      </div>
      <template #footer>
        <a-button @click="setting = false">取 消</a-button>
        <a-button class="btn-red" @click="set">设置</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getProjectPageList,
  projectRemove,
  projectSave,
  projectUpdate,
} from "@/api/devManage";

const columns = [
  {
    title: "名称",
    dataIndex: "projectName",
    key: "projectName",
    width: 250,
    ellipsis: true,
  },
  /* {
    title: "所属类目",
    dataIndex: "category",
    key: "category",
  },*/
  {
    title: "类型",
    dataIndex: "projectType",
    key: "projectType",
    scopedSlots: { customRender: "projectType" },
  },
  {
    title: "创建者",
    dataIndex: "creatorName",
    key: "creatorName",
    scopedSlots: { customRender: "creatorName" },
  },
  {
    title: "成员数量",
    dataIndex: "memberNum",
    key: "memberNum",
  },
  {
    title: "最新版本",
    dataIndex: "newVersion",
    key: "newVersion",
    customRender: (text) => text || "V0.0.0",
  },
  {
    title: "更新时间",
    dataIndex: "updateTime",
    key: "updateTime",
    width: 200,
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    scopedSlots: { customRender: "operating" },
  },
];

export default {
  data() {
    return {
      columns,
      page: {
        current: 1,
        size: 10,
      },
      dataInfo: {
        projectType: "",
        projectName: "",
      },
      devCode: "",
      devData: [],
      pageData: {
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      projectId: "",
      projectData: "",
      spinning: false,
      loading: false,
      visible: false,
      setting: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      other: "",
      form: {
        projectName: "",
        projectType: undefined,
      },
      formSet: {
        projectName: "",
        appid: "",
      },
      rules: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        projectType: [
          { required: true, message: "请选择项目类型", trigger: "blur" },
        ],
      },
      rulesSet: {
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["dict", "companyId"]),
  },

  mounted() {
    this.LoadGetProjectPageList();
  },

  methods: {
    LoadGetProjectPageList() {
      this.loading = true;
      this.dataInfo.companyId = this.companyId;
      getProjectPageList(this.page, this.dataInfo).then((res) => {
        this.devData = res.records;
        this.loading = false;
        this.$set(this.pageData, "total", res.total);
      });
    },

    submit() {
      const { form } = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.spinning = true;
          this.form.companyId = this.companyId;
          projectSave(form).then(() => {
            this.$message.success("创建成功！");
            this.visible = false;
            this.spinning = false;
            this.$refs.ruleForm.resetFields();
            this.LoadGetProjectPageList();
          });
        }
      });
    },

    //打开设置弹窗
    projectSet(record) {
      this.formSet.projectName = record.projectName;
      this.formSet.appid = record.targetAppkey;
      this.projectId = record.id;
      this.projectData = record;
      this.setting = true;
    },

    // 删除项目
    projectDel() {
      projectRemove(this.projectId).then((res) => {
        this.$message.success("删除成功!");
        this.page = {
          current: 1,
          size: 10,
        };
        this.LoadGetProjectPageList();
        this.setting = false;
      });
    },

    //copy
    copyText() {
      navigator.clipboard.writeText(this.formSet.appid).then(() => {
        this.$message.success("复制成功!");
      });
    },

    set() {
      this.$refs.projectSet.validate((valid) => {
        if (valid) {
          this.projectData.projectName = this.formSet.projectName;
          projectUpdate(this.projectData).then(() => {
            this.$message.success("设置成功！");
            this.setting = false;
          });
        }
      });
    },

    changeType(id) {
      this.devCode = id;
      this.dataInfo.projectType = id;
      this.LoadGetProjectPageList();
    },

    onSearch() {
      this.page = {
        current: 1,
        size: 10,
      };
      this.LoadGetProjectPageList();
    },

    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.LoadGetProjectPageList();
    },

    cancel() {
      this.visible = false;
    },

    toWorkspace(record) {
      if (record.frontendUrl) {
        const url = `${
          process.env.VUE_APP_API_PORTAL_URL
        }/auth/render?redirect_uri=${encodeURIComponent(record.frontendUrl)}`;
        console.log(url);
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dev-manage {
  padding-top: 16px;

  > div {
    background: #fff;
    padding: 24px 32px;

    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }

  .my-dev-filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .my-dev-filter-tab {
      display: flex;
      align-items: center;

      .my-dev-filter-tab-list {
        display: flex;
        align-items: center;

        > div {
          width: 96px;
          height: 32px;
          background: #fff;
          border: 1px solid #d9d9d9;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          transition: all 0.3s;
          position: relative;

          &.active {
            color: #ea0b06;
            background: rgba(234, 11, 6, 0.05);
            border-color: #ea0b06;
            z-index: 2;
          }
        }
      }
    }

    .my-dev-filter-btn {
      display: flex;
      align-items: center;
    }
  }
}

.my-dev-operating {
  display: flex;

  > span {
    cursor: pointer;
    color: #ea0b06;
    margin-right: 20px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
