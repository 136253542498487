import { PortalRequest as request } from "@/utils/request";
/**
 * 增加浏览量
 * @returns {*}
 */
export const addViewed = (type, id) => {
  return request({
    url: `/visit/add-viewed/${type}/${id}`,
    method: "get",
  });
};
export const setPVAndUV = (data) => {
  return request({
    url: `/visit/set-pv-uv`,
    method: "post",
    data,
  });
};
