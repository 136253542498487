<template>
  <div class="user-scene-info">
    <a-breadcrumb class="user-breadcrumb-box">
        <a-breadcrumb-item>
          <router-link to="/sceneManage/scene" replace>我的场景</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>详情页</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="user-scene-info-head">
        <div class="user-scene-info-head-content">
            <div class="user-scene-info-head-info">
                <h5>{{sceneInfo.title }}</h5>
                <div>
                    <p>场景编号：<span>{{ sceneInfo.id }}</span></p>
                    <p>联合申报单位：<span>{{ sceneInfo.associate }}</span></p>
                    <p>场景地址：<span>{{ sceneInfo.sceneAddress }}</span></p>
                    <p>发布时间：<span>{{ sceneInfo.createTime }}</span></p>
                    <p>联系人：<span>{{ sceneInfo.contactPerson }}</span></p>
                    <p>预估启动建设年月：<span>{{ sceneInfo.expectedStartDate }}</span></p>
                    <p>发布单位：<span>{{ sceneInfo.companyName }}</span></p>
                    <p>联系方式：<span>{{ sceneInfo.contactPhone }}</span></p>
                </div>
            </div>
            <div class="user-scene-info-head-state">
                <span>状态</span>
                <p>{{ sceneInfo.sceneStatus | dict("SCENE_STATUS") }}</p>
            </div>
        </div>
        <div class="user-scene-info-head-tag">
            <span class="active">场景说明</span>
        </div>
    </div>
    <div class="user-scene-info-desc">
        <div v-if="sceneInfo.sceneKeywords && sceneInfo.sceneKeywords.length>0">
            <h6>关键词</h6>
            <p >{{sceneInfo.sceneKeywords.join('、')}}</p>
        </div>
        <div>
            <h6>场景简介</h6>
          <div v-html="sceneInfo.description"></div>
        </div>
        <div v-if="sceneInfo.requirement">
            <h6>场景建设需求</h6>
          <div v-html="sceneInfo.requirement"></div>
        </div>
        <div v-if="sceneInfo.resource">
            <h6>场景配套资源</h6>
          <div v-html="sceneInfo.resource"></div>
        </div>
        <div v-if="sceneInfo.fundSource">
            <h6>预估资金来源</h6>
          <div v-html="sceneInfo.fundSource"></div>
        </div>
    </div>
  </div>
</template>

<script>
import { sceneDetail } from "@/api/cjkf/scn-scene";
export default {
  data() {
    return {
      detailTabIndex: 0,
      sceneInfo: {},
    };
  },
  computed: {
    sceneId() {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.loadData(this.sceneId);
  },
  methods: {
    async loadData() {
      this.sceneInfo = await sceneDetail(this.sceneId);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-scene-info{
    padding-top: 16px;
    .user-scene-info-head{
        margin-top: 16px;
        background: #fff;
        padding: 24px 32px 0;
        .user-scene-info-head-content{
            display: flex;
            justify-content: space-between;
            .user-scene-info-head-info{
                width: 80%;
                >h5{
                    font-size: 20px;
                    color: #262626;
                    line-height: 28px;
                    margin-bottom: 10px;
                }
                >div{
                    margin-bottom: 24px;
                    display: flex;
                    flex-wrap: wrap;
                    >p{
                        width: 33.3%;
                        display: flex;
                        font-size: 14px;
                        color: #262626;
                        line-height: 22px;
                        margin-top: 8px;
                        >span{
                            color: #595959;
                        }
                    }
                }
            }
            .user-scene-info-head-state{
                >span{
                    display: block;
                    font-size: 14px;
                    color: #8C8C8C;
                    line-height: 22px;
                    text-align: right;
                    margin-bottom: 4px;
                }
                >p{
                    font-size: 20px;
                    color: #262626;
                    line-height: 28px;
                    text-align: right;
                }
            }
        }
        .user-scene-info-head-tag{
            display: flex;
            >span{
                border-bottom: 2px solid transparent;
                font-size: 14px;
                color: #262626;
                line-height: 22px;
                padding-bottom: 10px;
                cursor: pointer;
                &.active{
                    color: #EA0B06;
                    border-color: #EA0B06;
                }
            }
        }
    }
    .user-scene-info-desc{
        margin-top: 24px;
        background: #fff;
        border-radius: 2px;
        padding: 0 32px;
        >div{
            padding: 32px 0;
            border-bottom: 1px solid #E8E8E8;
            &:last-child{
                border-bottom: 0;
            }
            >h6{
                font-size: 16px;
                color: #262626;
                line-height: 24px;
                margin-bottom: 16px;
            }
            >p{
                font-size: 14px;
                color: #595959;
                line-height: 22px;
            }
        }
    }
}
</style>
