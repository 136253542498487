import router from "./router";
import store from "./store";
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { addViewed, setPVAndUV } from "@/api/visit";
import { generateUUID } from "ant-design-vue/lib/vc-select/util";

const loginRoutePath = "/login";
const defaultRoutePath = "/";

router.beforeEach(async (to, from, next) => {
  const promises = [];
  if (!store.getters.settings) {
    promises.push(store.dispatch("LoadSettings"));
  }
  if (!store.getters.tenants.length) {
    promises.push(store.dispatch("LoadTenants"));
  }

  if (to.query.tenantId) {
    store.dispatch("ChangeTenant", to.query.tenantId);
  }

  await Promise.all(promises);

  console.log(to.path);
  if (to.path === "/register") {
    window.location.href = `${process.env.VUE_APP_MAINSITE_PREFIX}/register`;
    return;
  }
  if (to.path === "/forgetPassword") {
    window.location.href = `${process.env.VUE_APP_MAINSITE_PREFIX}/forgetPassword`;
    return;
  }
  if (to.path === loginRoutePath) {
    window.location.href = `${process.env.VUE_APP_API_PORTAL_URL}/auth/render?redirect_uri=${store.getters.redirectUri}&state=${to.query.state}`;
    // window.location.href = store.getters.loginPage(from);
    return;
  }

  if (to.path === "/logout") {
    await store.dispatch("RevokeToken");
    window.location.href = `${process.env.VUE_APP_API_PORTAL_URL}/auth/logout?redirect_uri=${store.getters.redirectUri}`;
    // window.location.href = store.getters.loginPage(from);
    return;
  }

  if (to.path === "/authorized") {
    if (!to.query.code) {
      next({ path: defaultRoutePath });
      return;
    }
    try {
      await store.dispatch("CodeLogin", { ...to.query });
      await store.dispatch("GetInfo");
    } catch (e) {
      next({ path: defaultRoutePath });
      return;
    }
    if (to.query.state && storage.get(`history-${to.query.state}`)) {
      const { path: historyPath, query: historyQuery } = storage.get(
        `history-${to.query.state}`
      );
      next({ path: historyPath, query: historyQuery });
    } else {
      next({ path: defaultRoutePath });
    }
    return;
  }

  if (to.path === "/logout") {
    await store.dispatch("Logout");
    window.location.href = `${process.env.VUE_APP_SSO_PREFIX}/logout?client_id=${process.env.VUE_APP_CLIENT}&redirect_uri=${process.env.VUE_APP_ACCOUNT_PREFIX}`;
    return;
  }

  const token = storage.get(ACCESS_TOKEN);
  // 如果token不存在
  if (!token) {
    const key = generateUUID();
    storage.set(`history-${key}`, { path: to.path, query: to.query });
    next({ path: loginRoutePath, query: { state: key } });
    // 如果token存在并且当前用户信息已存在
  } else if (store.getters.userInfo) {
    await store.dispatch("UserCompany");
    await store.dispatch("UserTenant");
    await store.dispatch("getMsgNum");
    await store.dispatch("LoadMsg");
    next();
    // 如果token存在，并且用户信息不存在
  } else {
    // 获取用户信息
    try {
      await store.dispatch("GetInfo");
      await store.dispatch("UserCompany");
      await store.dispatch("UserTenant");
      await store.dispatch("getMsgNum");
      await store.dispatch("LoadMsg");
      next();
    } catch (e) {
      await store.dispatch("RevokeToken");
      const key = generateUUID();
      storage.set(`history-${key}`, { path: to.path, query: to.query });
      next({ path: loginRoutePath, query: { state: key } });
    }
  }
});

router.afterEach((to, from) => {
  if (to.path === "/policy/info") {
    addViewed("news", to.query.id);
  } else if (to.path === "/result/info") {
    addViewed("achv", to.query.id);
  } else if (to.path === "/activity/courseInfo") {
    addViewed("course", to.query.id);
  } else if (to.path === "/activity/activityInfo") {
    addViewed("course", to.query.id);
  }
  let path = to.fullPath;
  setPVAndUV({
    path,
  });
});
