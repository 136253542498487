<template>
  <a-upload
    name="file"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="action"
    :disabled="disabled"
    :before-upload="beforeUpload"
    :style="widthHeightStyle"
    :headers="headers"
    @change="handleChange"
  >
    <img v-if="value" :src="src" alt="avatar" :style="widthHeightStyle"/>
    <div v-else :style="widthHeightStyle" class="upload-img">
      <div>
        <a-icon :type="loading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">{{title}}</div>
      </div>
    </div>
  </a-upload>
</template>
<script>
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";

export default {
  name: "VUploadAvatar",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    title:{
      type: String,
      default: '上传图片',
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileTypeCode: {
      type: String,
      required: true,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    background: {
      type: String,
    },
    backgroundText: {
      type: String,
    },
    fontSize:{
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    headers() {
      const token = storage.get(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },
    widthHeightStyle() {
     const { width, height } = this;
      return {
        width,
        height,
      };
    },
    action() {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`;
    },
    src() {
      return `${process.env.VUE_APP_OBS_PREFIX}/${this.value}`;
    },
  },
  mounted() {

  },
  methods: {
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
      } else if (info.file.status === "done") {
        this.loading = false;
        this.fileName = info.file.name;
        this.$emit("input", info.file.response.data.id);
        this.$emit("change", info.file.response.data.id);
      } else if (info.file.status === "error") {
        this.$emit("error", info.file);
      }
    },
    beforeUpload(file) {
      const isImage = file.type.indexOf("image") !== -1;
      if (!isImage) {
        this.$message.error("只能上传图片!");
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("文件不能超过10MB!");
      }
      return isImage && isLt10M;
    },
  },
};
</script>

<style scoped>
.upload-img{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}


.avatar-uploader .ant-upload.ant-upload-select-picture-card{
  margin:0;
}

.avatar-uploader .ant-upload.ant-upload-select-picture-card > .ant-upload{
  padding: 0;
}

.user-info-enterprise-avatar-box {
 /* width: 104px;
  height: 104px;
  background: #ea0b06;
  border-radius: 4px;*/

 /* width: 80px;
  height: 80px;*/
  background: linear-gradient(90deg, #EA0B06 0%, #FF726F 100%);
  border-radius: 4px;

  color: #fff;
  font-size: 24px;
  padding: 7px 16px;
  line-height: 33px;
  margin: 0 auto;
}


</style>
