<template>
  <div class="core-member">
    <a-skeleton active :loading="loading" :paragraph="{ rows: 10 }">
      <div class="core-box">
        <member-card
          @click.native="handChange(el)"
          v-for="(el, index) in coreMemberList"
          :key="el.id"
          :num="colorNum(index)"
          :val="el"
          @removeCore="remove"
        ></member-card>
        <div class="add-member" @click="visible = true">
          <a-icon type="plus" class="icon" />添加核心成员
        </div>
      </div>
    </a-skeleton>
    <pagination
      class="page"
      v-if="page.total > 20"
      :size="page.size"
      :total="page.total"
      :current="page.current"
      @changes="pageChange"
    />
    <a-modal
      title="添加核心成员"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="coreMemberHandleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="coreMemberForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="memberName" label="姓名" prop="memberName">
          <a-input
            v-model="coreMemberForm.memberName"
            placeholder="请填写成员姓名"
            @blur="
              () => {
                $refs.memberName.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="position" label="职位" prop="position">
          <a-input
            v-model="coreMemberForm.position"
            placeholder="请填写成员职位"
            @blur="
              () => {
                $refs.position.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import pagination from "../components/pagination.vue";
import memberCard from "./components/memberCard";
import {
  saveCoreMember,
  getCoreMemberList,
  removeCoreMember,
} from "@/api/companyInfo";
import { mapGetters } from "vuex";
export default {
  name: "coreMembers",
  components: {
    memberCard,
    pagination,
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  data() {
    return {
      loading: false,
      delShow: false,
      visible: false,
      confirmLoading: false,
      coreMemberCondition: {},
      page: {
        current: 1,
        size: 20,
        total: 0,
      },
      coreMemberList: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      coreMemberForm: {},
      rules: {
        memberName: [
          { required: true, message: "请填写成员姓名", trigger: "blur" },
        ],
        position: [
          { required: true, message: "请填写成员职位", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.initCoreMemberData();
  },
  methods: {
    colorNum(index) {
      let id = Number(index) + 1;
      if (id <= 4) {
        return id;
      } else {
        let num = id - parseInt(String(id / 4)) * 4;
        if (num === 0) num = 4;
        return num;
      }
    },
    handChange(el) {
      console.log(el, "el-----");
      this.coreMemberForm.memberName = el.memberName;
      this.coreMemberForm.position = el.position;
      this.coreMemberForm.id = el.id;
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
      this.coreMemberForm = {};
    },
    coreMemberHandleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const { coreMemberForm, companyInfo } = this;
          coreMemberForm.businessLicense = companyInfo.socialCreditCode;
          this.confirmLoading = true;
          saveCoreMember(coreMemberForm).then(() => {
            this.confirmLoading = false;
            this.visible = false;
            this.$message.success("保存成功");
            this.coreMemberForm = {};
            this.initCoreMemberData();
          });
        } else {
          return false;
        }
      });
    },
    initCoreMemberData() {
      const { companyInfo, coreMemberCondition, page } = this;
      if (companyInfo.socialCreditCode) {
        coreMemberCondition.businessLicense = companyInfo.socialCreditCode;
        this.loading = true;
        getCoreMemberList(page, coreMemberCondition).then((res) => {
          if (res.total > 0 && res.records.length === 0) {
            this.page.current = this.page.current - 1;
            this.initCoreMemberData();
          } else {
            this.coreMemberList = res.records;
            this.page.total = res.total;
            this.loading = false;
          }
        });
      }
    },
    remove(id) {
      removeCoreMember(id).then(() => {
        this.initCoreMemberData();
      });
    },
    // 分页
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.initCoreMemberData();
    },
  },
};
</script>

<style scoped lang="scss">
.core-member {
  padding-left: 32px;
  box-sizing: border-box;
}
.core-box {
  display: flex;
  flex-wrap: wrap;
}
.add-member {
  width: 294px;
  height: 120px;
  margin-top: 24px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 2px;
  border: 1px dashed #d9d9d9;
  font-size: 16px;
  color: #595959;
  text-align: center;
  line-height: 120px;
  > .icon {
    margin-right: 10px;
  }
}
.page {
  text-align: right;
  margin-top: 20px;
  margin-right: 32px;
}
</style>
