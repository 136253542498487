import { PortalRequest as request } from "@/utils/request";

// 资质证书分页
export const getCertificateList = (pageParams, data) => {
  return request({
    url: "/qualification-certificate/page-list",
    method: "post",
    params: pageParams,
    data,
  });
};

// 资质证书详情
export const getCertificateInfo = (id) => {
  return request({
    url: `/qualification-certificate/info/${id}`,
    method: "get",
  });
};

// 资质证书新增或保存
export const saveCertificate = (data) => {
  return request({
    url: "/qualification-certificate/save",
    method: "post",
    data,
  });
};

// 资质证书删除
export const removeCertificate = (id) => {
  return request({
    url: `/qualification-certificate/remove/${id}`,
    method: "delete",
  });
};

// 项目案例分页
export const getProjectCaseList = (pageParams, data) => {
  return request({
    url: "/project-case/page-list",
    method: "post",
    params: pageParams,
    data,
  });
};

// 项目案例详情
export const getProjectCase = (id) => {
  return request({
    url: `/project-case/info/${id}`,
    method: "get",
  });
};

// 项目案例新增或保存
export const saveProjectCase = (data) => {
  return request({
    url: "/project-case/save",
    method: "post",
    data,
  });
};

// 项目案例删除
export const removeProjectCase = (id) => {
  return request({
    url: `/project-case/remove/${id}`,
    method: "delete",
  });
};

// 核心成员分页
export const getCoreMemberList = (pageParams, data) => {
  return request({
    url: "/core-member/page-list",
    method: "post",
    params: pageParams,
    data,
  });
};

// 核心成员详情
export const getCoreMember = (id) => {
  return request({
    url: `/core-member/info/${id}`,
    method: "get",
  });
};

// 核心成员新增或保存
export const saveCoreMember = (data) => {
  return request({
    url: "core-member/save",
    method: "post",
    data,
  });
};

// 核心成员删除
export const removeCoreMember = (id) => {
  return request({
    url: `/core-member/remove/${id}`,
    method: "delete",
  });
};
