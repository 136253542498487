<template>
  <div style=" height: 100%;">
  <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
    <a-breadcrumb-item>企业管理</a-breadcrumb-item>
    <a-breadcrumb-item>企业认证</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="verify-state">
    <div>
      <p>企业认证</p>
      <div>
        <span>
          <img src="@/assets/img/rzz.png" alt="图片" v-if="companydata.verifyStatus == 2">
          <img src="@/assets/img/rzcg.png" alt="图片" v-else-if="companydata.verifyStatus == 3">
          <img src="@/assets/img/rzsb.png" alt="图片" v-else-if="companydata.verifyStatus == 4">
        </span>
        <h4>企业{{companydata.verifyStatusName}}</h4>
        <p v-if="companydata.verifyStatus == 4">驳回原因：{{companydata.verifyFailedReason}}</p>
        <a-button class="btn-red" @click="editVerifyInfo">修改企业认证</a-button>
        <div>
          <p>企业认证信息</p>
          <div>
            <p style="white-space: nowrap">企业名称：<span style="white-space: normal">{{companydata.companyName}}</span></p>
            <p>信用代码：<span>{{companydata.socialCreditCode}}</span></p>
            <p>法定代表人：<span>{{companydata.legalPersonName}}</span></p>
            <p>身份证号：<span>{{companydata.legalPersonCitizenIdNo}}</span></p>
            <p>联系电话：<span>{{companydata.contactPhone}}</span></p>
            <p>提交时间：<span>{{companydata.applyTime}}</span></p>
            <p>关联区域：<span><v-tenant-list :ids="companydata.showTenantIds" /></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { getCompanyInfo } from "@/api/res-company";
import {mapGetters,mapActions } from "vuex";
import VTenantList from '@/components/VTenantList'
export default {
  components: {
    VTenantList
  },
  data(){
    return{
      companydata:{}
    }
  },
  computed: mapGetters(["companyId"]),
  mounted(){
    this.loadData();
  },
  methods:{
    loadData() {
      getCompanyInfo(this.companyId).then((company) => {
        this.companydata = company || {};
      });
    },
    editVerifyInfo(){
      this.$emit('input',1)
    }
  }
}
</script>

<style lang="scss" scoped>
.verify-state{

  height: calc(100% - 37px);
  padding-top: 16px;
  >div{
    min-height: 100%;
    background: #fff;
    padding: 24px 32px;
    >p{
      font-size: 20px;
      color: rgba(0,0,0,0.85);
      line-height: 28px;
      margin-bottom: 50px;
    }
    >div{
      >span{
        display: block;
        width: 72px;
        height: 72px;
        margin: 0 auto 24px;
        >img{
          display: block;
        }
      }
      >h4{
        text-align: center;
        font-size: 24px;
        color: rgba(0,0,0,0.85);
        line-height: 32px;
      }
      >p{
        margin-top: 16px;
        text-align: center;
        font-size: 14px;
        color: #8C8C8C;
        line-height: 22px;
      }
      >button{
        display: block;
        margin: 28px auto 32px;
      }
      >div{
        width: 1028px;
        margin: 0 auto;
        border-radius: 2px;
        border: 1px solid rgba(0,0,0,0.15);
        padding: 24px 40px 0;
        >p{
          font-size: 16px;
          color: #262626;
          line-height: 24px;
          margin-bottom: 20px;
        }
        >div{
          display: flex;
          flex-wrap: wrap;
          >p{
            width: 33.3%;
            margin-bottom: 24px;
            font-size: 14px;
            color: #262626;
            line-height: 22px;
            display: flex;
            >span{
              color: #595959;
            }
          }
        }
      }
    }
  }
}

</style>
