<template>
  <a-table
    :columns="qualificationCertificatePatentColumn"
    :data-source="qualificationCertificatePatentList"
    :pagination="pagination"
    :loading="loading"
    @change="paginationChange"
  >
    <template slot="action" slot-scope="text, record">
      <a href="javascript:void(0)" @click="$emit('edit', record)"> 编辑 </a>
      <a-divider type="vertical" />
      <a-popconfirm title="确定删除吗？" @confirm="removePatent(record.id)">
        <a href="javascript:void(0)"> 删除 </a>
      </a-popconfirm>
    </template>
  </a-table>
</template>

<script>
import { getCertificateList, removeCertificate } from "@/api/companyInfo.js";
import { mapGetters } from "vuex";
const qualificationCertificatePatentColumn = [
  {
    title: "编号",
    dataIndex: "rowIndex",
    key: "rowIndex",
    customRender: function (t, r, index) {
      return `00${parseInt(index) + 1}`;
    },
  },
  {
    title: "专利名称",
    dataIndex: "patentName",
    key: "patentName",
  },
  {
    title: "专利号",
    dataIndex: "patentNo",
    key: "patentNo",
  },
  {
    title: "颁发日期",
    dataIndex: "issueDate",
    key: "issueDate",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "patentTable",
  data() {
    return {
      loading: false,
      qualificationCertificatePatentColumn,
      qualificationCertificatePatentList: [],
      pagination: {
        pageSizeOptions: ["5", "10", "15", "20"],
        defaultPageSize: 5,
        hideOnSinglePage: false,
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      page: {
        current: 1,
        size: 5,
      },

      queryInfo: {},
    };
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  mounted() {
    this.loadGetCertificateList();
  },
  methods: {
    loadGetCertificateList() {
      const { page, companyInfo } = this;
      let data = {
        businessLicense: companyInfo.socialCreditCode,
        certificateType: "1",
      };
      this.loading = true;
      getCertificateList(page, data).then((res) => {
        this.qualificationCertificatePatentList = res.records;
        this.pagination.total = res.total;
        this.loading = false;
      });
    },

    removePatent(id) {
      removeCertificate(id).then(() => {
        this.loadGetCertificateList();
      });
    },

    paginationChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetCertificateList();
    },
  },
};
</script>

<style scoped></style>
