import storage from "store";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  SESSION_TENANT_ID,
} from "@/store/mutation-types";
import { getTokenByCode, getUserInfo, refreshToken } from "@/api/auth";
import { getJoinCompanyList } from "@/api/res-company";
import { getTenantList } from "@/api/common";
import store from "@/store";
import { getPermissions } from "@/api/res-role";

const user = {
  state: {
    access_token: "",
    refresh_token: "",
    permissions: [],
    info: null,
    company_id: "",
    company_status: "",
    company_list: [],
    tenant_id: "",
    tenant_list: [],
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token;
    },
    SET_REFRESH_TOKEN: (state, refresh_token) => {
      state.refresh_token = refresh_token;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_COMPANY_ID: (state, company_id) => {
      state.company_id = company_id;
    },
    SET_COMPANY_STATUS: (state, company_status) => {
      state.company_status = company_status;
    },
    SET_COMPANY_LIST: (state, company_list) => {
      state.company_list = company_list;
    },
    SET_TENANT_ID: (state, tenantId) => {
      storage.set(SESSION_TENANT_ID, tenantId);
      state.tenant_id = tenantId;
    },
    SET_TENANT_LIST: (state, tenant_list) => {
      state.tenant_list = tenant_list;
    },
  },
  actions: {
    async CodeLogin({ commit }, data) {
      const res = await getTokenByCode({
        ...data,
        redirect_uri: store.getters.redirectUri,
      });
      console.log(res);
      const accessToken = res.accessToken;
      const refreshToken = res.refreshToken;
      const expireIn = res.expireIn;
      const refreshTokenExpireIn = res.refreshTokenExpireIn;
      const tokenType = res.tokenType;
      storage.set(ACCESS_TOKEN, accessToken, 7 * 24 * 60 * 60 * 1000);
      storage.set(REFRESH_TOKEN, refreshToken, 7 * 24 * 60 * 60 * 1000);
      commit("SET_ACCESS_TOKEN", accessToken);
      commit("SET_REFRESH_TOKEN", refreshToken);
      return accessToken;
    },
    RefreshToken({ commit }, refresh_token) {
      return refreshToken(refresh_token).then((res) => {
        const accessToken = res.accessToken;
        const refreshToken = res.refreshToken;
        const scope = res.scope;
        const id_token = res.id_token;
        const token_type = res.token_type;
        const expires_in = res.expires_in;
        storage.set(ACCESS_TOKEN, accessToken, 7 * 24 * 60 * 60 * 1000);
        storage.set(REFRESH_TOKEN, refreshToken, 7 * 24 * 60 * 60 * 1000);
        commit("SET_ACCESS_TOKEN", accessToken);
        commit("SET_REFRESH_TOKEN", refreshToken);
        return accessToken;
      });
    },
    // 获取用户信息
    async GetInfo({ commit }) {
      const userInfo = await getUserInfo();
      if (!userInfo) {
        commit("SET_INFO", null);
        commit("SET_PERMISSIONS", []);
        storage.remove(ACCESS_TOKEN);
        storage.remove(REFRESH_TOKEN);
        throw "您的账号已被删除";
      }
      commit("SET_INFO", userInfo);
      return userInfo;
    },
    // 登出
    async RevokeToken({ commit }) {
      commit("SET_INFO", null);
      commit("SET_PERMISSIONS", []);
      storage.remove(ACCESS_TOKEN);
      storage.remove(REFRESH_TOKEN);
    },
    // 获取用户企业列表
    async UserCompany({ commit }) {
      const companyList = await getJoinCompanyList(["2"]);
      if (companyList != "" && store.state.user.company_id == "") {
        commit("SET_COMPANY_ID", companyList[0].id);
        commit("SET_COMPANY_STATUS", companyList[0].verifyStatus);
      }
      store.dispatch("LoadPermissions");
      commit("SET_COMPANY_LIST", companyList);
      return companyList;
    },
    async LoadPermissions({ commit }) {
      const companyId = store.getters.companyId;
      const tenantId = store.getters.tenantId;
      // const tenantId = null;
      // if (
      //   !companyId
      //   || !tenantId
      // ) {
      //   return;
      // }
      const permissions = await getPermissions({
        companyId,
        tenantId,
      });
      commit("SET_PERMISSIONS", permissions);
    },
    // 获取用户全部租户列表
    async UserTenant({ commit }) {
      const list = await getTenantList();
      const tenantList = [
        {
          tenantName: "全国站",
          id: "",
          domain: process.env.VUE_APP_MAINSITE_PREFIX,
        },
        ...list,
      ];
      commit("SET_TENANT_LIST", tenantList);

      const tenantId = storage.get(SESSION_TENANT_ID);
      if (tenantList.map((e) => e.id).indexOf(tenantId) === -1) {
        commit("SET_TENANT_ID", tenantList[0].id);
      } else {
        commit("SET_TENANT_ID", tenantId);
      }
      store.dispatch("LoadPermissions");
      return tenantList;
    },
    ChangeTenant({ commit }, tenantId) {
      commit("SET_TENANT_ID", tenantId);
    },
  },
};

export default user;
