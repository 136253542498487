<template>
  <div class="my-demand-apply-body">
    <a-breadcrumb class="user-breadcrumb-box">
      <a-breadcrumb-item
        ><router-link to="/demandManage" replace
          >需求管理</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item>需求申报</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="my-demand-apply-content-body">
      <p>需求申报</p>
      <div class="my-demand-apply-info-body">
        <div class="my-demand-apply-info">
          <a-form-model
            :model="demandInfo"
            :rules="rules"
            ref="ruleForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
          >
            <a-form-model-item label="需求名称" prop="name" ref="name">
              <a-input
                style="width: 630px"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
                placeholder="请填写需求名称"
                v-model="demandInfo.name"
              />
            </a-form-model-item>

            <a-form-model-item
              label="预算金额"
              prop="fundAmount"
              ref="fundAmount"
            >
              <a-input-number
                style="width: 200px"
                v-model="demandInfo.fundAmount"
              />

              <a-checkbox
                v-model="demandInfo.fundAmountStatus"
                style="margin-left: 10px"
                >可议价</a-checkbox
              >
            </a-form-model-item>

            <a-form-model-item label="实施周期" prop="carryOutTime">
              <a-range-picker
                v-model="demandInfo.carryOutTime"
                format="YYYY-MM-DD"
                style="width: 400px"
              />
            </a-form-model-item>

            <a-form-model-item label="所属类目" prop="demandType">
              <a-tree-select
                v-model="demandInfo.demandType"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="treeData"
                allow-clear
                placeholder="请选择所属类目"
                style="width: 150px"
                tree-default-expand-all
              />
            </a-form-model-item>
            <a-form-model-item label="需求描述" prop="demandDescribe">
              <a-textarea
                v-model="demandInfo.demandDescribe"
                placeholder="请输入需求描述"
                :rows="5"
                :max-length="300"
                style="width: 630px"
              />
            </a-form-model-item>
            <a-form-model-item label="报名截止时间" prop="demandEndTime">
              <v-date-picker-after v-model="demandInfo.demandEndTime" />
            </a-form-model-item>

            <a-form-model-item label="需求附件" prop="applyingAttmIds">
              <v-multi-upload
                v-model="demandInfo.applyingAttmIds"
                file-type-code="900002"
              >
                <a-button icon="upload">上传需求附件</a-button>
              </v-multi-upload>
            </a-form-model-item>

            <a-form-model-item
              label="联系人"
              prop="contactPerson"
              ref="contactPerson"
            >
              <a-input
                style="width: 630px"
                @blur="
                  () => {
                    $refs.contactPerson.onFieldBlur();
                  }
                "
                placeholder="请填写联系人姓名"
                v-model="demandInfo.contactPerson"
              />
            </a-form-model-item>
            <a-form-model-item
              label="手机号"
              prop="contactPhone"
              ref="contactPhone"
            >
              <a-input
                style="width: 630px"
                @blur="
                  () => {
                    $refs.contactPhone.onFieldBlur();
                  }
                "
                placeholder="请填写手机号"
                v-model="demandInfo.contactPhone"
              />
            </a-form-model-item>

            <a-row>
              <a-col :span="4"> </a-col>
              <a-col :span="20">
                <a-button
                  @click="handleSave('2')"
                  class="btn-red"
                  :disabled="btnStatus"
                  v-if="demandInfo.demandStatus == 3"
                >
                  再次提交
                </a-button>
                <a-button
                  @click="handleSave('2')"
                  class="btn-red"
                  :disabled="btnStatus"
                  v-if="demandInfo.demandStatus == 1"
                >
                  提交审核
                </a-button>
                <a-button
                  @click="handleSave('1')"
                  class="btn-white"
                  style="margin-left: 10px"
                  :disabled="btnStatus"
                  v-if="demandInfo.demandStatus == 1"
                  >保存
                </a-button>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  saveDemand,
  getDemandDetail,
  getDemandTypeTreeData,
} from "@/api/dmdDemand";
import { mapGetters } from "vuex";
import VMultiUpload from "@/components/VMultiUpload";
import dayjs from "dayjs";
import VDatePickerAfter from "@/components/VDatePickerAfter";
import VEditor from "@/components/VEditor";
export default {
  components: { VMultiUpload, VDatePickerAfter, VEditor },
  computed: {
    id() {
      return this.$route.query.id;
    },
    ...mapGetters(["companyId", "tenantId", "company_status", "userInfo"]),
  },
  data() {
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return {
      btnStatus: false,
      treeData: [],
      demandInfo: {
        name: null,
        fundAmount: null,
        carryOutStartTime: null,
        carryOutEndTime: null,
        carryOutTime: [],
        catalogue: null,
        demandDescribe: null,
        demandEndTime: null,
        applyingAttmIds: [],
        contactPerson: null,
        contactPhone: null,
        demandType: null,
        fundAmountStatus: false,
        demandStatus: "1",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      rules: {
        name: [
          {
            required: true,
            message: "请填写需求名称",
            trigger: "blur",
          },
        ],

        fundAmount: [
          {
            required: true,
            message: "请填写预算金额",
            trigger: "blur",
          },
        ],
        carryOutTime: [
          {
            required: true,
            message: "请选择实施周期",
            trigger: "blur",
          },
        ],
        demandType: [
          {
            required: true,
            message: "请选中所属类目",
            trigger: "change",
          },
        ],
        demandDescribe: [
          {
            required: true,
            message: "请填写需求描述",
            trigger: "blur",
          },
        ],
        demandEndTime: [
          {
            required: true,
            message: "请选择报名截止时间",
            trigger: "change",
          },
        ],
        contactPerson: [
          {
            required: true,
            message: "请填写联系人",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "请填写手机号",
            trigger: "blur",
          },
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
    // if (this.company_status !== "3") {
    //   this.$router.replace("/sceneManage/scene");
    //   this.$message.warning("请先完成企业认证！");
    // }
  },
  created() {
    this.initDemandTypeData();
  },
  methods: {
    initDemandTypeData() {
      getDemandTypeTreeData().then((res) => {
        this.treeData = res;
      });
    },
    async loadData() {
      if (this.id) {
        this.demandInfo = await getDemandDetail(this.id);
      }

      this.demandInfo.carryOutTime = [
        this.demandInfo.carryOutStartTime,
        this.demandInfo.carryOutEndTime,
      ];

      if (!this.demandInfo.contactPerson) {
        this.demandInfo.contactPerson = this.userInfo.realName;
      }
      if (!this.demandInfo.contactPhone) {
        this.demandInfo.contactPhone = this.userInfo.phone;
      }
    },

    handleSave(demandStatus) {
      this.btnStatus = true;
      if (this.demandInfo.carryOutTime.length > 0) {
        this.demandInfo.carryOutStartTime = dayjs(
          this.demandInfo.carryOutTime[0]
        ).format("YYYY-MM-DD");
        this.demandInfo.carryOutEndTime = dayjs(
          this.demandInfo.carryOutTime[1]
        ).format("YYYY-MM-DD");
      }

      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          await saveDemand({
            ...this.demandInfo,
            companyId: this.companyId,
            tenantId: this.tenantId,
            demandStatus: demandStatus,
          });
          await this.$message.success("操作成功！", 1);
          await this.$router.push("/demandManage");
        } else {
          this.btnStatus = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-demand-apply-body {
  padding-top: 16px;

  .my-demand-apply-content-body {
    margin-top: 16px;
    background: #fff;
    border-radius: 2px;
    padding: 24px 30px;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 24px;
    }
    .my-demand-apply-info-body {
      .my-demand-apply-info {
        //display: flex;
        // justify-content: center;

        .ant-form-item {
          display: flex;
        }

        .verify-box {
          text-align: center;
          padding-top: 100px;

          > img {
            display: block;
            margin: 0 auto;
          }

          > p {
            line-height: 14px;
            margin-top: 40px;
          }

          > div {
            display: flex;
            justify-content: center;
            margin-top: 80px;
          }
        }
      }
    }

    .my-demand-apply-faq {
      width: 280px;
      margin-left: 24px;

      .my-demand-apply-cx {
        position: relative;
        width: 280px;
        height: 80px;

        > img {
          width: 100%;
          height: 100%;
        }

        > div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
          padding: 20px 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            > p {
              text-shadow: 2px 2px 2px rgba(000, 000, 000, 0.25);
              font-size: 18px;
              line-height: 20px;
              margin-bottom: 8px;
              color: #fff;
            }

            > span {
              font-size: 12px;
              display: block;
              line-height: 12px;
              color: #fff;
            }
          }

          > span {
            height: 24px;
            border: 1px solid #ffffff;
            line-height: 22px;
            padding: 0 10px;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .my-demand-apply-faq-desc {
        margin-top: 24px;
        background: #fff;

        > p {
          display: flex;
          align-items: center;
          padding: 0 16px;
          height: 48px;
          border-bottom: 1px solid #f0f0f0;
        }

        > div {
          padding: 24px;
          line-height: 20px;
          text-align: justify;

          > p {
            margin-top: 20px;

            > span {
              color: #ea0b06;
            }
          }
        }
      }
    }
  }
}
</style>
