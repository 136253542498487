import { PortalRequest as request } from "@/utils/request";

//查看我提交的需求
export const pageListByme = (pageParams, data) => {
  return request({
    url: `/dmd-demand/page-list-me`,
    method: "post",
    params: pageParams,
    data,
  });
};

// 提交和保存订单
export const saveDemand = (data) => {
  return request({
    url: `/dmd-demand/save`,
    method: "post",
    data,
  });
};

// 获取需求详情
export const getDemandDetail = (id) => {
  return request({
    url: `/dmd-demand/${id}/detail`,
    method: "get",
  });
};

// 删除一个需求
export const demandRemove = (id) => {
  return request({
    url: `/dmd-demand/${id}/remove`,
    method: "delete",
  });
};

/**
 * 需求分类树
 * @returns {*}
 */
export const getDemandTypeTreeData = (id) => {
  return request({
    url: "/dmd-demand-type/tree-data",
    method: "get",
  });
};

/**
 * 获取我的投标列表
 * @param id
 * @returns list<T>
 */
export const getMyDemandBidList = (pageParams, data) => {
  return request({
    url: `/dmd-demand/page-list-bid-me`,
    method: "post",
    params: pageParams,
    data,
  });
};

// 获取需求详情
export const changeDemandEndTime = (data) => {
  return request({
    url: `/dmd-demand/change-demand-end-time`,
    method: "post",
    data,
  });
};
