<template>
<div>

  <a-input
      v-if="tagInput"
      ref="input"
      type="text"
      size="small"
      :value="inputValue"
      @change="tagAdd"
      @blur="handleInputConfirm"
      @keyup.enter="handleInputConfirm"
      style="width: 138px;height: 32px;"
  />
<!--  <a-tag  style="background: #fff; borderStyle: dashed;" >
    <a-icon type="plus" /> New Tag
  </a-tag>-->

  <a-button v-else icon="plus" @click="showInput">{{name}}</a-button>

  </div>
</template>

<script>
export default {
  name: "tagInput",
  props:{
    name:{
      type:String,
    }
  },
  data() {
    return {
      tagInput: false,
      inputValue: '',
    }
  },
  methods:{
    tagAdd(e){
        this.inputValue=e.target.value
    },

    showInput(){
      this.tagInput = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },


    handleInputConfirm(){
      const inputValue = this.inputValue;
      if(inputValue){
        this.$emit('tagChange',inputValue)
      }
      Object.assign(this, {
        tagInput: false,
        inputValue: '',
      });

    }

  }
}
</script>

<style scoped>

</style>
