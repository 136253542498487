<template>
  <div class="detail-desc-body">
    <p>关键词</p>
    <div>
      <span v-for="(item, index) in info.sceneKeywords" :key="index">
        {{ item }}
      </span>
    </div>
    <p>场景简介</p>
    <div v-html="info.description"></div>
    <p>场景建设需求</p>
    <div v-html="info.requirement"></div>
    <p>场景配套资源</p>
    <div v-html="info.resource"></div>
    <p>预估资金来源</p>
    <div v-html="info.fundSource"></div>
  </div>
</template>

<script>
export default {
  props: ["info"],
};
</script>

<style lang="scss" scoped>
.detail-desc-body {
  padding: 40px 24px;
  > p {
    font-size: 15px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 30px;
    border-left: 4px solid #EA0B06;
    padding-left: 10px;
  }
  > div {
    margin-bottom: 40px;
    display: flex;
    padding-left: 10px;
    line-height: 22px;
    text-align: justify;
    > span {
      height: 32px;
      background: rgba(185,56,56,.2);
      border: 1px solid rgba(185,56,56,.5);
      border-radius: 16px;
      padding: 0 16px;
      line-height: 30px;
      color: #EA0B06;
      margin-right: 16px;
    }
  }
}
</style>
