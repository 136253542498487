<template>
<div>
  <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
    <a-breadcrumb-item>企业管理</a-breadcrumb-item>
    <a-breadcrumb-item>企业认证</a-breadcrumb-item>
  </a-breadcrumb>
  <div class="user-info-personal-content">
    <div class="user-info-enterprise-steps">
        <p>企业认证</p>
        <!-- <div>
            <a-steps :current="0">
                <a-step title="提交认证资料" />
                <a-step title="等待资料审核" />
                <a-step title="企业认证成功" />
            </a-steps>
        </div> -->
    </div>
    <a-form-model
      :label-col="labelCol"
      :model="form"
      :rules="rules"
      :wrapper-col="wrapperCol"
      label-align="left"
      ref="ruleForm"
      class="user-info-enterprise-form"
    >
      <span class="user-info-title">企业信息</span>
      <div>
        <a-form-model-item
          label="企业名称"
          prop="companyName"
          ref="companyName"
        >
          <a-input
            @blur=" () => { $refs.companyName.onFieldBlur(); } "
            placeholder="请输入营业执照上企业名称"
            style="width: 630px"
            v-model="form.companyName"
          />
        </a-form-model-item>
        <a-form-model-item
          label="统一社会信用代码"
          prop="socialCreditCode"
          ref="socialCreditCode"

        >
          <a-input @blur=" () => { $refs.socialCreditCode.onFieldBlur(); } "
            placeholder="请输入证件上统一社会信用代码"
            style="width: 630px"
            v-model="form.socialCreditCode"
                   :disabled="companydata.verifyStatus=='3'"
          />
        </a-form-model-item>
        <a-form-model-item
          label="营业执照"
          prop="businessLicenceAttmId"
          ref="businessLicenceAttmId"
        >
          <div style="display: flex;">
            <div style="margin-right:16px">
                <v-upload-avatar
                    file-type-code="1012"
                    width="120px"
                    height="120px"
                    v-model="form.businessLicenceAttmId"
                />
            </div>
            <p class="certificate-annex-title">上传营业执照照片，用于自动填充企业名称和信用代码 <br>支持 JPG、JPEG、PNG、BMP 格式，大小不超过 5 MB</p>
          </div>
        </a-form-model-item>
        <a-form-model-item label="关联区域" ref="showTenantIds" prop="showTenantIds">
          <v-select-tenant
              v-model="form.showTenantIds"
              :multiple="true"
              placeholder="请选择关联区域"
              style="width: 630px"
              @blur="
                      () => {
                        $refs.showTenantIds.onFieldBlur();
                      }
                    "
          />
          <p class="certificate-annex-title">关联区域为该企业和上架的服务数据可以在哪些平台展示</p>
        </a-form-model-item>


      </div>
      <span class="user-info-title">申请人信息</span>
      <div>
        <a-form-model-item
          label="申请人类型"
          prop="applierType"
          ref="applierType"
        >
          <v-radio-dict
            code="COMPANY_APPLIER_TYPE"
            v-model="form.applierType"
          />
        </a-form-model-item>
        <a-form-model-item
          label="法定代表人姓名"
          prop="legalPersonName"
          ref="legalPersonName"
        >
          <a-input
            @blur=" () => { $refs.legalPersonName.onFieldBlur(); } "
            placeholder="请输入法定代表人姓名"
            style="width: 630px"
            v-model="form.legalPersonName"
          />
        </a-form-model-item>
        <a-form-model-item
          label="法定代表人身份证号"
          prop="legalPersonCitizenIdNo"
          ref="legalPersonCitizenIdNo"
        >
          <a-input
            @blur="() => {$refs.legalPersonCitizenIdNo.onFieldBlur();}"
            placeholder="请输入身份证号"
            style="width: 630px"
            v-model="form.legalPersonCitizenIdNo"
          />
        </a-form-model-item>
        <a-form-model-item
          label="企业注册地"
          prop="companyAddress"
          ref="companyAddress"
        >
          <a-input
            @blur="() => {$refs.companyAddress.onFieldBlur();}"
            placeholder="请输入企业注册地"
            style="width: 630px"
            v-model="form.companyAddress"
          />
        </a-form-model-item>
        <template v-if="form.applierType === '2'">
          <a-form-model-item
            label="委托授权人姓名"
            prop="agentPersonName"
            ref="agentPersonName"
          >
            <a-input
              @blur="() => {$refs.agentPersonName.onFieldBlur();}"
              placeholder="请输入委托授权人姓名"
              style="width: 630px"
              v-model="form.agentPersonName"
            />
          </a-form-model-item>
          <a-form-model-item
            label="委托授权人身份证号"
            prop="agentPersonCitizenIdNo"
            ref="agentPersonCitizenIdNo"
          >
            <a-input
              @blur="() => {$refs.agentPersonCitizenIdNo.onFieldBlur();}"
              placeholder="委托授权人身份证号"
              style="width: 630px"
              v-model="form.agentPersonCitizenIdNo"
            />
          </a-form-model-item>
        </template>
        <a-form-model-item v-if="form.applierType === '1'">
          <template #label ><span style="color: #f5222d">*</span> 法定代表人身份证扫描件</template>
          <div class="id-card-upload-box">
            <a-form-model-item prop="legalPersonCitizenIdPhotoFrontAttmId">
                <v-upload-id-card
                    :background="require('@/assets/img/idz.png')"
                    :value="form.legalPersonCitizenIdPhotoFrontAttmId"
                    file-type-code="1002"
                    v-model="form.legalPersonCitizenIdPhotoFrontAttmId"
                    width="300px"
                />
                <p style="text-align: center">证件人像面</p>
            </a-form-model-item>
            <a-form-model-item prop="legalPersonCitizenIdPhotoBackAttmId">
                <v-upload-id-card
                    :background="require('@/assets/img/idf.png')"
                    file-type-code="1003"
                    :value="form.legalPersonCitizenIdPhotoBackAttmId"
                    v-model="form.legalPersonCitizenIdPhotoBackAttmId"
                    width="300px"
                />
                <p style="text-align: center">证件国徽面</p>
            </a-form-model-item>
            <p class="certificate-annex-title">图片格式要求jpg、jpeg、bmp、png，不超过5M。<br />所有上传照片需清晰且未遮挡，上传证件信息需在证件有效期内。</p>
          </div>
        </a-form-model-item>
        <a-form-model-item v-if="form.applierType === '2'">
          <template #label ><span style="color: #f5222d">*</span> 委托授权人身份证扫描件</template>
          <div class="id-card-upload-box">
            <a-form-model-item prop="agentPersonCitizenIdPhotoFrontAttmId">
                <v-upload-id-card
                    :background="require('@/assets/img/idz.png')"
                    :value="form.agentPersonCitizenIdPhotoFrontAttmId"
                    file-type-code="1002"
                    v-model="form.agentPersonCitizenIdPhotoFrontAttmId"
                    width="300px"
                />
                <p style="text-align: center">证件人像面</p>
            </a-form-model-item>
            <a-form-model-item prop="agentPersonCitizenIdPhotoBackAttmId">
                <v-upload-id-card
                    :background="require('@/assets/img/idf.png')"
                    file-type-code="1003"
                    :value="form.agentPersonCitizenIdPhotoBackAttmId"
                    v-model="form.agentPersonCitizenIdPhotoBackAttmId"
                    width="300px"
                />
                <p style="text-align: center">证件国徽面</p>
            </a-form-model-item>
            <p class="certificate-annex-title">图片格式要求jpg、jpeg、bmp、png，不超过5M。<br />所有上传照片需清晰且未遮挡，上传证件信息需在证件有效期内。</p>
          </div>
        </a-form-model-item>
        <a-form-model-item
          v-if="form.applierType === '2'"
          label="委托人授权书"
          prop="authorizationLetterAttmId"
          ref="authorizationLetterAttmId"
        >
          <div>
            <v-upload-avatar
              file-type-code="1012"
              width="255px"
              height="255px"
              v-model="form.authorizationLetterAttmId"
            />
          </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 15, offset: 4 }">
          <div style="display: flex">
            <a-button @click="handleSubmit" class="btn-red"> 提交认证资料</a-button>
            <!-- <a-button @click="resetForm" style="margin-left: 16px">取消</a-button> -->
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</div>
</template>

<script>
import VUploadIdCard from "@/components/VUploadIdCard";
import VUploadAvatar from "@/components/VUploadAvatar";
import VSelectTenant from "@/components/VSelectTenant";
import {
  applyCompanyCertification,
  getCompanyInfo,
  createCompany
} from "@/api/res-company";
import VSelectDict from "@/components/VSelectDict";
import VRadioDict from "@/components/VRadioDict";
import {mapGetters } from "vuex";
export default {
  name: "CertificationForm",
  props:{
    companydata:{
      type:Object
    },
    sourceTenantId:{
      type: String
    }
  },
  components: {
    VRadioDict,
    VSelectDict,
    VUploadAvatar,
    VUploadIdCard,
    VSelectTenant
  },
  data() {

    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      company: {},
      form: {
        id:'',
        companyName: "",
        socialCreditCode: "",
        businessLicenceAttmId: "",
        applierType: "1",
        legalPersonName: "",
        legalPersonCitizenIdNo: "",
        companyAddress: "",
        agentPersonName: "",
        agentPersonCitizenIdNo: "",
        legalPersonCitizenIdPhotoFrontAttmId: "",
        legalPersonCitizenIdPhotoBackAttmId: "",
        agentPersonCitizenIdPhotoFrontAttmId:'',
        agentPersonCitizenIdPhotoBackAttmId:'',
        citizenIdPhotoHoldAttmId: "",
        contactPhone: "",
        authorizationLetterAttmId: "",
        showTenantIds: null
      },
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入营业执照上企业名称",
            trigger: "blur",
          },
        ],
        socialCreditCode: [
          {
            required: true,
            message: "请输入证件上统一社会信用代码",
            trigger: "blur",
          },
        ],
        businessLicenceAttmId: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        applierType:[{ required: true, message: '请选择申请人类型', trigger: 'change' },],
        legalPersonName: [
          { required: true, message: "请输入法定代表人姓名", trigger: "blur" },
        ],
        legalPersonCitizenIdNo: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {pattern:/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,message: "身份证格式错误",trigger: "blur"}
        ],
        companyAddress: [
          { required: true, message: "请输入企业注册地", trigger: "blur" },
        ],
        agentPersonName: [
          { required: true, message: "请输入委托授权人姓名", trigger: "blur" },
        ],
        agentPersonCitizenIdNo: [
          {
            required: true,
            message: "请输入委托授权人身份证号",
            trigger: "blur",
          },
        ],
        legalPersonCitizenIdPhotoFrontAttmId: [
          { required: true, message: "请上传证件人像面", trigger: "change" },
        ],
        legalPersonCitizenIdPhotoBackAttmId: [
          { required: true, message: "请上传证件国徽面", trigger: "change" },
        ],
        agentPersonCitizenIdPhotoFrontAttmId: [
          { required: true, message: "请上传证件人像面", trigger: "change" },
        ],
        agentPersonCitizenIdPhotoBackAttmId: [
          { required: true, message: "请上传证件国徽面", trigger: "change" },
        ],
        // citizenIdPhotoHoldAttmId: [
        //   {
        //     required: true,
        //     message: "请上传手持证件人像面照片",
        //     trigger: "change",
        //   },
        // ],
        authorizationLetterAttmId: [
          {
            required: true,
            message: "委托人授权书",
            trigger: "change",
          },
        ],
        showTenantIds:[
          {
            required: true,
            message: "请选择关联区域",
            trigger: "blur",
          },
        ],
        // contactPhone: [
        //   { required: true, message: "请输入联系电话", trigger: "blur" },
        //   { min: 11, max: 11, message: "联系电话输入有误", trigger: "blur" },
        // ],
      },
      loading: false,
      imageUrl: "",
    };
  },
  computed: mapGetters(["companyId"]),
  methods: {
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.verifyStatus = '2'
          this.form.id = this.companyId
          createCompany(this.form).then((res) => {
            console.log(res)
            if (res) {
              this.$warning({
                title: res,
              });
            } else {
              this.$message.success("提交成功");
              // this.loadData();
              this.$emit("input",2);
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    loadData() {
      const { form } = this;
      getCompanyInfo(this.companyId).then((company) => {
        this.company = company || {};
        this.form = company
        // Object.keys(form).forEach((k) => {
        //   this.form[k] = company[k];
        // });
      });
    },
  },
  mounted() {
    this.form = this.companydata
    if(!this.form.showTenantIds && this.sourceTenantId){
      this.form.showTenantIds=[]
      this.form.showTenantIds.push(this.sourceTenantId)
    }
    // this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.user-info-personal-content {
  background: #fff;
  margin-top: 16px;
  padding: 24px 0;
  .avatar-uploader::v-deep{
    .ant-upload.ant-upload-select-picture-card {
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
    }
  }
  .user-info-title {
    font-weight: bold;
    display: block;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    padding: 0 32px 15px;
    border-bottom: 1px solid #e9e9e9;
  }
  .user-info-enterprise-steps{
    padding: 0 32px;
    margin-bottom: 32px;
    >p{
        font-size: 20px;
        color: rgba(0,0,0,0.85);
        line-height: 28px;
        margin-bottom: 32px;
    }
    >div{
        width: 900px;
    }
  }
  .user-info-enterprise-form{
    >div{
        padding: 24px 32px 0;
    }
    .id-card-upload-box{
        display: flex;
    }
  }
  .certificate-annex-title {
    font-size: 12px;
    color: #8c8c8c;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .certificate-annex {
    display: flex;
    padding-bottom: 24px;
    border-bottom: 1px dashed #f0f0f0;

    > div {
      > p {
        text-align: center;
        line-height: 14px;
        color: #262626;
      }
    }
  }
}
</style>
