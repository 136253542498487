<template>
  <div class="interactive" style="height: auto;">

    <router-view />

  </div>



</template>

<script>
export default {
  name: "index",
  data(){
    return{
      title:'',
    }
  },


}
</script>

<style scoped lang="scss">



</style>
