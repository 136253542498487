<template>
  <div class="user-cer">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>账号中心</a-breadcrumb-item>
      <a-breadcrumb-item>个人实名</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="user-cer-body" v-if="isVerify == 1">
      <p class="title">个人实名</p>

      <!-- <a-steps :current="0" class="user-cer-steps">
        <a-step title="提交实名资料" />
        <a-step title="等待资料审核" />
        <a-step title="个人实名成功" />
      </a-steps> -->

      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
        class="user-cer-form"
      >
        <a-form-model-item ref="realName" label="姓名" prop="realName">
          <a-input
            style="width: 630px"
            v-model="form.realName"
            placeholder="请输入证件上的姓名"
            @blur="
              () => {
                $refs.realName.onFieldBlur();
              }
            "
          />
        </a-form-model-item>

        <a-form-model-item
          ref="citizenIdNo"
          label="证件号码"
          prop="citizenIdNo"
        >
          <a-input
            style="width: 630px"
            v-model="form.citizenIdNo"
            placeholder="请输入证件上的号码"
            @blur="
              () => {
                $refs.citizenIdNo.onFieldBlur();
              }
            "
          />
        </a-form-model-item>

        <a-form-model-item
          ref="citizenIdExpired"
          label="证件有效期"
          prop="citizenIdExpired"
        >
          <v-date-picker
            style="width: 630px"
            v-model="form.citizenIdExpired"
            placeholder="请输入证件有效期"
          />
        </a-form-model-item>

        <div class="form-img">
          <div class="form-img-label">身份证照片：</div>

          <a-form-model-item prop="citizenIdPhotoFrontAttmId">
            <v-upload-id-card
              v-model="form.citizenIdPhotoFrontAttmId"
              file-type-code="1002"
              :background="require('@/assets/img/idz.png')"
              width="300px"
              height="168px"
            />
            <p style="width: 300px; text-align: center">证件人像面</p>
          </a-form-model-item>

          <a-form-model-item prop="citizenIdPhotoBackAttmId">
            <v-upload-id-card
              v-model="form.citizenIdPhotoBackAttmId"
              file-type-code="1003"
              :background="require('@/assets/img/idf.png')"
              width="300px"
              height="168px"
            />
            <p style="width: 300px; text-align: center">证件国徽面</p>
          </a-form-model-item>

          <a-form-model-item prop="citizenIdPhotoHoldAttmId">
            <v-upload-id-card
              v-model="form.citizenIdPhotoHoldAttmId"
              file-type-code="1004"
              :background="require('@/assets/img/ids.png')"
              width="300px"
              height="168px"
            />
            <p style="width: 300px; text-align: center">手持证件人像面照片</p>
          </a-form-model-item>
        </div>

        <div style="margin-left: 242px">
          <a-button class="btn-red" @click="handleSubmit">
            提交实名资料
          </a-button>
        </div>
      </a-form-model>
      <div class="user-cer-explain">
        <div>注意事项:</div>
        <p>1、同一个身份证号只能认证一个账号；</p>
        <p>2、身份证国徽面与正面信息应为同一身份证的信息且在有效期内；</p>
        <p>3、所有上传照片需清晰且未遮挡，请勿进行美化和修改，以免认证失败；</p>
        <p>4、上传图片文件格式支持png，jpg和bmp；</p>
        <p>5、单张图片大小不超过3MB，尺寸最小为500px * 500px；</p>
        <p>
          6、所有上传信息均会被妥善保管，不会用于其他商业用途或传输给其他第三方；
        </p>
      </div>
    </div>
    <template v-else>
      <user-cer-result v-model="isVerify"/>
    </template>
  </div>
</template>

<script>
import VUploadIdCard from "@/components/VUploadIdCard";
import { applyUserCertification } from "@/api/res-user";
import { mapActions, mapGetters } from "vuex";
import VDatePicker from "@/components/VDatePicker";
import userCerResult from './userCerResult.vue'
export default {
  name: "index",
  data() {
    return {
      spinning: false,
      labelCol: { span: 3 },
      wrapperCol: { span: 15 },
      form: {
        realName: "",
        citizenIdNo: "",
        citizenIdExpired: "",
        citizenIdPhotoFrontAttmId: "",
        citizenIdPhotoBackAttmId: "",
        citizenIdPhotoHoldAttmId: "",
      },
      rules: {
        citizenIdExpired: [
          { required: true, message: "请输入证件有效期", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入证件上的姓名", trigger: "blur" },
        ],
        citizenIdNo: [
          { required: true, message: "请输入证件上的号码", trigger: "blur" },
        ],
        citizenIdPhotoFrontAttmId: [
          { required: true, message: "请上传证件人像面", trigger: "change" },
        ],
        citizenIdPhotoBackAttmId: [
          { required: true, message: "请上传证件国徽面", trigger: "change" },
        ],
        citizenIdPhotoHoldAttmId: [
          {
            required: true,
            message: "请上传手持证件人像面照片",
            trigger: "change",
          },
        ],
      },
      isVerify:'',//1为未提交实名认证
    };
  },

  components: {
    VDatePicker,
    VUploadIdCard,
    userCerResult
  },
  computed: mapGetters(["userInfo","dict"]),
  mounted(){
    console.log(this.userInfo,this.dict)
    this.isVerify = this.userInfo.verifyStatus
    if(this.userInfo.verifyStatus != '1'){
      this.form.realName = this.userInfo.realName
      this.form.citizenIdNo = this.userInfo.citizenIdNo
      this.form.citizenIdExpired = this.userInfo.citizenIdExpired
      this.form.citizenIdPhotoFrontAttmId = this.userInfo.citizenIdPhotoFrontAttmId
      this.form.citizenIdPhotoBackAttmId = this.userInfo.citizenIdPhotoBackAttmId
      this.form.citizenIdPhotoHoldAttmId = this.userInfo.citizenIdPhotoHoldAttmId
    }
  },
  methods: {
    ...mapActions(["GetInfo"]),
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.spinning = true;
          applyUserCertification(this.form).then(async () => {
            await this.GetInfo();
            await this.$emit("success");
            await this.$message.success("提交成功");
            this.spinning = false;
            this.isVerify = 2
          });
        } else {
          this.spinning = false;
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>

<style scoped lang="scss">
.user-cer {
  > .title {
    margin: 16px 0;
  }
}

.user-cer-body {
  background: #ffffff;
  border-radius: 2px;
  padding: 25px 0 67px 32px;
  > .title {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-bottom: 32px;
  }
  > .user-cer-steps {
    width: 872px;
    margin-bottom: 40px;
    &::v-deep {
      .ant-steps-item-process {
        .ant-steps-item-icon {
          background-color: #ea0b06;
          border-color: #ea0b06;
        }
      }

      .ant-steps-item-finish {
        .ant-steps-item-icon {
          border-color: #ea0b06;
        }
        .ant-steps-finish-icon {
          color: #ea0b06;
        }
      }

      .ant-steps-item-finish {
        .ant-steps-item-title::after {
          background-color: #ea0b06;
        }
      }
    }
  }
}

.user-cer-form {
  &::v-deep {
    .ant-form-item-label {
      width: 242px;
    }
  }
}

.form-img {
  display: flex;
  > .form-img-label {
    width: 242px;
  }
  > .form-img-label::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: "*";
  }
}

.user-cer-explain {
  position: relative;
  margin-left: 242px;
  margin-top: 40px;
  padding-top: 24px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1072px;
    height: 1px;
    background: #e9e9e9;
  }
  > div {
    font-size: 16px;
    font-weight: 500;
    color: #595959;
    line-height: 24px;
    margin-bottom: 12px;
  }
  > p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
}
</style>
