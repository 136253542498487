<template>
  <div>
    <div class="checklist">
    </div>
    <div class="my-fabang-body">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pages"
        @change="tablePage"
      >
        <div slot="sceneName" slot-scope="text, record">
          <div class="fabang-title-body">
            <span>
              <v-img :attachment-id="record.coverAttmId" />
            </span>
            <div>
              <strong>{{ record.title }}</strong>
              <p>
                <span>{{ record.field | dict("SCENE_FIELD") }}</span>
                <span>{{ record.industry | dict("SCENE_INDUSTRY") }}</span>
              </p>
            </div>
          </div>
        </div>
        <div slot="companyName" slot-scope="text">
          <div class="jiebang-unit">
            <p>{{ text }}</p>
          </div>
        </div>
        <div slot="fundAmount" slot-scope="text">￥{{ text }}</div>
        <div slot="sceneStatus" slot-scope="text, record">
          <span class="my-scenes-status">
            <i v-if="record.sceneStatus === '6'" class="sqz"></i>
            <i v-else-if="record.sceneStatus === '8'" class="yzb"></i>
            <i v-else-if="record.sceneStatus === '7'" class="wzb"></i>
            {{ record.sceneStatus | dict("SCENE_STATUS") }}
          </span>
        </div>
        <div slot="operating" slot-scope="text, record">
          <div class="my-scenes-operating">
            <span @click="scenesDetail(record.id,record.tenantId)">详情</span>
          </div>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import {getTenantInfo} from '@/api/common'
const columns = [
  {
    title: "榜单信息",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    width: 420,
    scopedSlots: { customRender: "sceneName" },
  },
  {
    title: "发布单位",
    dataIndex: "companyName",
    key: "companyName",
    ellipsis: true,
    width: 220,
    scopedSlots: { customRender: "companyName" },
  },
  {
    title: "发布时间",
    dataIndex: "previewedTime",
    key: "previewedTime",
  },
  {
    title: "申请截止时间",
    dataIndex: "applyDueDate",
    key: "applyDueDate",
  },
  {
    title: "状态",
    dataIndex: "sceneStatus",
    key: "sceneStatus",
    scopedSlots: { customRender: "sceneStatus" },
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    scopedSlots: { customRender: "operating" },
  },
];

import { getOwnSceneBids } from "@/api/cjkf/scn-scene";
import {mapGetters} from "vuex";

export default {
  components: { VImg },
  data() {
    return {
      data: [],
      columns,
      pages: {
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      page: {
        current: 1,
        size: 10,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: mapGetters(["tenant"]),
  methods: {
    loadData() {
      getOwnSceneBids(this.page).then((res) => {
        this.data = res.records;
        this.pages.total = res.total;
      });
    },
    tablePage(page) {
      this.page.current = page.current;
      this.loadData();
    },
    scenesDetail(itemId,id) {
      getTenantInfo({id}).then(res=>{
        window.open(`${res.domain}/homeScenes/detail?id=${itemId}`)
      })
    },
    scenesList() {
      this.$router.push("/homeScenes/historyList");
    },
  },
};
</script>

<style lang="scss" scoped>
.checklist {
  width: 1240px;
  margin: 0 auto 24px;
  text-align: right;
}

.my-fabang-body {
  width: 1240px;
  margin: 0 auto;
  background: #fff;
}

.fabang-title-body {
  display: flex;

  > span {
    width: 140px;
    max-height: 80px;
    margin-right: 16px;

    > img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  > div {
    flex: 1;

    > strong {
      color: #262626;
      font-size: 16px;
      display: block;
      line-height: 16px;
      margin-bottom: 16px;
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > p {
      display: flex;
      flex-wrap: wrap;

      > span {
        height: 22px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        line-height: 20px;
        padding: 0 8px;
        margin-right: 8px;
      }
    }
  }
}

.jiebang-unit {
  display: flex;
  align-items: center;

  > span {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    > img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  > p {
    flex: 1;
  }
}

.my-scenes-operating {
  display: flex;

  > span {
    cursor: pointer;
    margin-right: 24px;
    color: #EA0B06;

    &:last-child {
      margin-right: 0;
    }
  }
}

.my-scenes-status {
  display: flex;
  align-items: center;

  > i {
    width: 6px;
    height: 6px;
    background: #bfbfbf;
    border-radius: 50%;
    margin-right: 8px;

    &.sqz {
      background: #faad14;
    }

    &.yzb {
      background: #52c41a;
    }

    &.wzb {
      background: #ff4d4f;
    }
  }
}
</style>
<style>
.my-fabang-body th,
.my-fabang-body td {
  border-color: #f0f0f0 !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}
</style>
