<template>
  <a-modal v-model="addVisible" title="添加设备" width="640px" @cancel="cancel">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      class="add-dev-form"
    >
      <a-form-model-item ref="deviceName" label="设备名称" prop="deviceName">
        <a-input
          style="width: 464px"
          v-model="form.deviceName"
          placeholder="请输入设备名称"
          @blur="
            () => {
              $refs.deviceName.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item label="设备型号" prop="deviceModel">
        <a-input
          style="width: 464px"
          v-model="form.deviceModel"
          placeholder="请输入具体型号"
        />
      </a-form-model-item>
      <a-form-model-item label="主控SOC" prop="soc">
        <a-select
          style="width: 464px"
          v-model="form.soc"
          placeholder="请选择主控SOC"
        >
          <a-select-option
            v-for="el in dict.DEVICE_SOC"
            :key="el.itemValue"
            :value="el.itemValue"
            >{{ el.itemText }}</a-select-option
          >
        </a-select>
        <a-input
          v-if="form.soc == 8"
          style="width: 464px;margin-top:10px"
          v-model="form.socOther"
          placeholder="请输入主控SOC"
        />
      </a-form-model-item>
      <a-form-model-item label="SOC型号" prop="socModel">
        <a-input
          style="width: 464px"
          v-model="form.socModel"
          placeholder="请输入SOC型号"
        />
      </a-form-model-item>
      <a-form-model-item label="操作系统" prop="os">
        <a-select
          style="width: 464px"
          v-model="form.os"
          placeholder="请选择操作系统"
        >
          <a-select-option
            v-for="el in dict.DEVICE_OS"
            :key="el.itemValue"
            :value="el.itemValue"
            >{{ el.itemText }}</a-select-option
          >
        </a-select>
        <a-input
          v-if="form.os == 8"
          style="width: 464px;margin-top:10px"
          v-model="form.osOther"
          placeholder="请输入操作系统"
        />
      </a-form-model-item>
      <a-form-model-item>
        <div class="add-dev-form-upload">
            <p>设备及相关照片</p>
            <span class="add-dev-form-upload-subtitle">支持最多上传三张PNG/JPG格式照片</span>
            <v-upload-avatar fileTypeCode="4006" v-model="uploadData" :lists="fileList" @remove="removeFile" @list="listFile"/>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button @click="cancel">取 消</a-button>
      <a-button class="btn-red" @click="submit">确 定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import VUploadAvatar from '@/components/VUploadImgList.vue'
export default {
  components:{
    VUploadAvatar
  },
  props: ["visible",'type','info','index'],
  data() {
    return {
      addVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 15 },
      uploadData:'',
      form: {
        deviceName: "",
        deviceModel: "",
        soc: undefined,
        socOther: "",
        os: undefined,
        osOther: "",
        socModel: "",
        deviceAttmIds: [],
        fileList:[]
      },
      fileList:[],
      rules: {
        deviceName: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
        ],
        deviceModel: [
          { required: true, message: "请输入设备型号", trigger: "blur" },
        ],
        soc: [
          { required: true, message: "请选择主控芯片", trigger: "change" },
        ],
        os: [
          { required: true, message: "请选择操作系统", trigger: "change" },
        ],
        socModel: [
          { required: true, message: "请输入芯片型号", trigger: "blur" },
        ],
      },
    };
  },
  computed: mapGetters(["dict","settings"]),
  watch: {
    visible: function (val) {
      this.addVisible = val;
    },
    uploadData:function(val){
       this.form.deviceAttmIds.push(val)
    }
  },
  mounted() {
    this.addVisible = this.visible;
    if(this.type === 'edit'){
      let data = this.info
      if(data.deviceAttmIds != ''){
        let arr = []
        data.deviceAttmIds.forEach((element,index) => {
          let obj = {
            url:`${process.env.VUE_APP_OBS_PREFIX}/${element}`,
            uid:index,
            name:`image${index}`,
            status: 'done',
          }
          arr.push(obj)
        });
        this.fileList = arr
      }
      this.form = data
    }
  },
  methods: {
    cancel() {
      this.$emit("input", false);
    },
    removeFile(index){
        this.form.deviceAttmIds.splice(index,1)
    },
    listFile(val){
      this.form.fileList = val
    },
    submit() {
      let {type,index} = this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(type == 'add'){
            this.$emit("add", this.form);
          }else{
            let obj = {
              form:this.form,
              index
            }
            this.$emit("edit", obj);
          }
          this.$emit("input", false);
          this.form = {
                deviceName: "",
                deviceModel: "",
                soc: undefined,
                socOther: "",
                os: undefined,
                osOther: "",
                socModel: "",
                deviceAttmIds: [],
                fileList:[]
            }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style>
.add-dev-form .ant-form-item-label,
.add-dev-form .ant-form-item-control {
  line-height: 32px;
}
.add-dev-form-upload>p{
    font-size: 14px;
    color: #262626;
    line-height: 14px;
    margin-bottom: 14px;
}
.add-dev-form-upload-subtitle{
    height: 13px;
    font-size: 12px;
    color: #8C8C8C;
    line-height: 13px;
    display: block;
    margin-bottom: 18px;
}
</style>
