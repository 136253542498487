<template>
  <div>
    <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
      <a-breadcrumb-item>场景管理</a-breadcrumb-item>
      <a-breadcrumb-item>我的榜单</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="user-bangdan">
      <div class="user-bangdan-content">
        <h5>我的榜单</h5>
        <div>
          <div class="user-bangdan-tag">
            <span
              :class="{ active: bangdanTagIndex == 0 }"
              @click="bangdanTagIndex = 0"
              >我的发榜</span
            >
            <span
              :class="{ active: bangdanTagIndex == 1 }"
              @click="bangdanTagIndex = 1"
              >我的揭榜</span
            >
            <span
              :class="{ active: bangdanTagIndex == 2 }"
              @click="bangdanTagIndex = 2"
              >我的邀约</span
            >
          </div>
          <div class="user-bangdan-table">
            <fa-bang v-show="bangdanTagIndex == 0" />
            <jie-bang v-show="bangdanTagIndex == 1" />
            <invite v-show="bangdanTagIndex == 2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faBang from "./components/fabang.vue";
import jieBang from "./components/jiebang.vue";
import invite from "./components/invite.vue";
export default {
  components: {
    faBang,
    jieBang,
    invite,
  },
  data() {
    return {
      bangdanTagIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-bangdan {
  padding-top: 16px;
  .user-bangdan-content {
    // margin-top: 16px;
    border-radius: 2px;
    background: #fff;
    padding: 24px 0;
    > h5 {
      padding: 0 32px;
      margin-bottom: 24px;
      font-size: 20px;
      color: #262626;
      line-height: 28px;
    }
    > div {
      .user-bangdan-tag {
        padding: 0 32px;
        border-bottom: 1px solid #e8eaec;
        display: flex;
        > span {
          font-size: 14px;
          color: #595959;
          line-height: 22px;
          border-bottom: 2px solid transparent;
          padding-bottom: 10px;
          margin-right: 64px;
          cursor: pointer;
          &.active {
            color: #ea0b06;
            border-color: #ea0b06;
          }
        }
      }
      .user-bangdan-table {
        padding: 24px 32px;
      }
    }
  }
}
</style>
