<template>
  <a-modal v-model="addVisible" title="部门中添加人员" width="480px" @cancel="cancel">
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
    >
        <a-form-model-item ref="name" label="部门名称" prop="name">
            <a-input
                v-model="form.name"
                disabled
            />
        </a-form-model-item>
        <a-form-model-item ref="personnel" label="添加成员" prop="personnel">
            <a-select v-model="form.personnel" mode="tags" placeholder="请选择成员">
                <a-select-option v-for="el in memberList" :key="el.id" :value="el.id">{{el.realName}}</a-select-option>
            </a-select>
        </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button @click="resetForm">取 消</a-button>
      <a-button class="btn-red" @click="onSubmit">确 定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { getAddMemberList,getAddMember } from "@/api/enterpriseManage";
import {mapGetters,mapActions } from "vuex";
export default {
  props: ["visible",'info'],
  data() {
    return {
      addVisible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      form: {
        name: '',
        personnel:undefined
      },
      rules: {
        personnel: [{ required: true, message: '请选择成员', trigger: 'blur' },],
      },
      memberList:[]
    };
  },
  computed: mapGetters(["companyId"]),
  watch: {
    visible: function (val) {
      this.addVisible = val;
    },
  },
  mounted(){
    console.log(this.info)
    this.addVisible = this.visible;
    this.form.name = this.info.departmentName
    this.loadMemberList()
  },
  methods:{
    loadMemberList(){
      let data = {
        companyId:this.companyId,
        includeOwner:true,
        departmentId:this.info.id
      }
      getAddMemberList(data).then((res)=>{
        this.memberList = res
      })
    },
    cancel() {
      this.$emit("input", false);
    },
    onSubmit() {
      let _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = {
            companyId:this.companyId,
            departmentId:this.info.id,
            memberIdList:this.form.personnel
          }
          getAddMember(data).then((res)=>{
            console.log(res)
            _this.$message.success('添加成功');
            _this.$refs.ruleForm.resetFields();
            _this.$emit("input", false);
            this.$parent.loadCompanyMember()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$emit("input", false);
    },
  }
};
</script>

<style>

</style>
