<template>
  <div class="user-home-dynamic">
    <p>企业动态</p>
    <div class="user-home-dynamic-list" v-if="msgList != ''">
       <!-- @click="msgInfo(el.recordId,el.messageSource)" -->
      <div v-for="el in msgList" :key="el.id">
        <div style="width: 70%;margin-right: 40px">
          <img-type :type="el.msgType"/>
          <div>
            <p>{{el.msgTypeName}}</p>
            <a-tooltip overlayClassName="con-tool">
              <template slot="title">
                {{el.content}}
              </template>
              <span>{{el.content}}</span>
            </a-tooltip>
          </div>
        </div>
        <p style="white-space: nowrap">{{el.updateTime.slice(0,10)}}</p>
      </div>
    </div>
    <a-empty v-else/>
    <div class="user-home-dynamic-page" v-if="msgList != ''">
      <a-pagination size="small" :total="page.total" @change="pageChange"/>
    </div>
  </div>
</template>

<script>
import { getCompanyMsgList } from "@/api/message";
import { mapGetters, mapActions } from "vuex";
import imgType from './dynamicImg.vue'
export default {
  components:{
    imgType
  },
  data() {
    return {
      msgList: [],
      page: {
        current: 1,
        size: 4,
        total: 0,
      },
    };
  },
  computed: mapGetters(["companyId"]),
  mounted() {
    this.loadMessageList();
  },
  methods: {
    loadMessageList() {
      getCompanyMsgList(this.page, { companyId: this.companyId }).then((res) => {
        this.msgList = res.records;
        this.page.total = res.total
      });
    },
    pageChange(val){
        this.page.current = val
        this.loadMessageList();
    },
    msgInfo(id,type){
        if(type == '2'){
            this.$router.push(`/enterpriseManage/verify`);
        }else if(type == '3'){
            this.$router.push(`/userLayout/userInfo`);
        }else if(type == '8'){
            this.$router.push(`/sceneManage/sceneInfo/?id=${id}`);
        }else if(type == '9' || type == '11'){
            this.$router.push(`/enterpriseManage/member`);
        }else if(type == '10'){
            this.$router.push(`/serveManage`);
        }else if(type == '12'){
            this.$router.push(`/sceneManage/bangdan`);
        }else if(type == '4'){
            this.$router.push(`userLayout/userScenes/info?id=${id}`);
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.user-home-dynamic {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
  > p {
    font-size: 16px;
    color: #262626;
    line-height: 24px;
  }
  .user-home-dynamic-list {
    padding: 24px 0;
    > div {
      display: flex;
      //justify-content: space-between;
      align-items: center;
      padding: 12px;
      cursor: pointer;
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background: #fafafa;
      }
      > div {
        display: flex;
        align-items: center;
        > img {
          width: 38px;
          height: 38px;
          margin-right: 16px;
        }
        > div {
          max-width: calc(100% - 50px);
          > p {
            font-size: 14px;
            color: #595959;
            line-height: 22px;
          }
          > span {
            display: block;
            font-size: 14px;
            color: #8c8c8c;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      > p {
        font-size: 14px;
        color: #8c8c8c;
        line-height: 22px;
      }
    }
  }
  .user-home-dynamic-page {
    text-align: center;
  }

}


</style>


<style lang="scss">
.con-tool {
  max-width: 500px;
}
</style>
