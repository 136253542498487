<template>
  <a-table
    :columns="qualificationCertificateSoftwareColumn"
    :data-source="qualificationCertificateSoftwareList"
    :pagination="pagination"
    :row-key="(record) => record.id"
    :load="loading"
    @change="paginationChange"
  >
    <template slot="action" slot-scope="text, record">
      <a href="javascript:void(0)" @click="$emit('edit', record)"> 编辑 </a>
      <a-divider type="vertical" />
      <a-popconfirm title="确定删除吗？" @confirm="removeWork(record.id)">
        <a href="javascript:void(0)" style="color: red"> 删除 </a>
      </a-popconfirm>
    </template>
  </a-table>
</template>

<script>
import { getCertificateList, removeCertificate } from "@/api/companyInfo";
import { mapGetters } from "vuex";
export default {
  name: "workTable",
  data() {
    return {
      loading: false,
      qualificationCertificateSoftwareList: [],
      qualificationCertificateSoftwareColumn: [
        {
          title: "编号",
          dataIndex: "",
          key: "rowIndex",
          customRender: function (t, r, index) {
            return `00${parseInt(index) + 1}`;
          },
        },
        {
          title: "软件著作名称",
          dataIndex: "patentName",
          key: "patentName",
        },
        {
          title: "登记号",
          dataIndex: "patentNo",
          key: "patentNo",
        },
        {
          title: "版本号",
          dataIndex: "versionNo",
          key: "versionNo",
        },
        {
          title: "颁发日期",
          dataIndex: "issueDate",
          key: "issueDate",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        pageSizeOptions: ["5", "10", "15", "20"],
        defaultPageSize: 5,
        hideOnSinglePage: true,
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      page: {
        current: 1,
        size: 5,
      },
    };
  },
  computed: {
    ...mapGetters(["companyInfo"]),
  },
  mounted() {
    this.loadGetCertificateList();
  },
  methods: {
    loadGetCertificateList() {
      const { page, companyInfo } = this;
      let data = {
        businessLicense: companyInfo.socialCreditCode,
        certificateType: "2",
      };
      this.loading = true;
      getCertificateList(page, data).then((res) => {
        this.qualificationCertificateSoftwareList = res.records;
        this.pagination.total = res.total;
        this.loading = false;
      });
    },

    removeWork(id) {
      removeCertificate(id).then(() => {
        this.loadGetCertificateList();
      });
    },

    paginationChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetCertificateList();
    },
  },
};
</script>

<style scoped></style>
