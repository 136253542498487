import { PortalRequest as request } from "@/utils/request";

// 新用户注册
export const setUserRegisterEmail = (data) => {
  return request({
    url: "/res-user/register/email",
    method: "post",
    data,
  });
};
export const setUserRegisterMobile = (data) => {
  return request({
    url: "/res-user/register/mobile",
    method: "post",
    data,
  });
};

// 重置密码
/*export const setResetPassword = (data) => {
  return request({
    url: "/res-user/reset-password",
    method: "post",
    data,
  });
};*/

export const setResetPassword = (data) => {
  return request({
    url: "/common/mobile-reset-password",
    method: "post",
    data,
  });
};


/**
 * 重置密码校验手机验证码，后台会生成临时token来标识要修改的手机号返回到前台（临时token位5分钟有效期，短信验证码也是5分钟）
 * @param mobile
 * @param code
 * @returns {AxiosPromise}
 */
export const checkMobileByReset = ({ mobile, code }) => {
  return request({
    url: `/common/check-mobile-by-reset`,
    method: "post",
    data: { mobile, code },
  });
};

