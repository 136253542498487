<template>
  <div>
    <!--  <a-breadcrumb class="user-breadcrumb-box" style="margin-top: 16px">
    <a-breadcrumb-item>首页概况</a-breadcrumb-item>
    <a-breadcrumb-item>迁移管理</a-breadcrumb-item>
  </a-breadcrumb>-->
    <div class="my-scenes-body">
      <div>
        <p>迁移管理</p>
        <div class="my-scenes-filter-box">
          <div class="my-scenes-filter-tab">
            <div class="my-scenes-filter-tab-list">
              <div :class="{ active: stateCode == '' }" @click="stateCode = ''">
                全部
              </div>
              <div
                v-for="(item, index) in stateTypeData"
                :key="item.code"
                :class="{ active: stateCode == item.code }"
                @click="stateCode = item.code"
                :style="{ left: -(index + 1) + 'px' }"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="my-scenes-filter-btn">
            <a-button class="btn-red" @click="scenesApply('add', '')"
              >提交国产替代需求单</a-button
            >
          </div>
        </div>
        <div class="my-scenes-list">
          <a-table
            :columns="columns"
            :data-source="scenesData"
            :pagination="pagination"
            @change="pageChange"
            :loading="loading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <div slot="adaptationType" slot-scope="text">
              <span class="my-scenes-status">
                {{ text == "1" ? "硬件产品" : "模组" }}
              </span>
            </div>
            <template slot="titles" slot-scope="text, record">
              {{ record.title.replace(/,/g, " | ") }}
            </template>
            <div slot="statusName" slot-scope="text, record">
              <span class="my-scenes-status">
                {{ record.orderStatusName }}
              </span>
            </div>
            <div slot="operating" slot-scope="text, record">
              <div class="my-scenes-operating">
                <template v-if="record.orderStatus == '1'">
                  <span @click="scenesApply('edit', record)">编辑</span>
                  <span @click="scenesDel(record.id)">删除</span>
                </template>
                <span v-else @click="taskDetail(record.id)">详情</span>
              </div>
            </div>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const columns = [
  {
    title: "适配类型",
    dataIndex: "adaptationType",
    key: "adaptationType",
    scopedSlots: { customRender: "adaptationType" },
  },
  {
    title: "设备名称",
    dataIndex: "title",
    key: "title",
    ellipsis: true,
    // width: 300,
    scopedSlots: { customRender: "titles" },
  },
  {
    title: "联系人",
    dataIndex: "contactPerson",
    key: "contactPerson",
  },
  {
    title: "创建人",
    dataIndex: "creatorName",
    key: "creatorName",
  },
  {
    title: "更新时间",
    dataIndex: "appliedTime",
    key: "appliedTime",
    width: 190,
  },
  {
    title: "状态",
    dataIndex: "orderStatus",
    key: "orderStatus",
    scopedSlots: { customRender: "statusName" },
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    width: 160,
    scopedSlots: { customRender: "operating" },
  },
];
import pagination from "../components/pagination.vue";
import { getOrderList, orderRemove } from "@/api/order";
export default {
  data() {
    return {
      columns,
      stateTypeData: [
        {
          text: "草稿中",
          code: "1",
        },
        {
          text: "客服审核中",
          code: "2",
        },
        {
          text: "方案制作中",
          code: "3",
        },
        {
          text: "方案已完成",
          code: "4",
        },
        {
          text: "审核不通过",
          code: "5",
        },
      ],
      stateCode: "",
      scenesData: [],

      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      loading: true,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["companyId"]),
  },
  watch: {
    stateCode: function () {
      this.loadGetOrderList();
    },
  },
  mounted() {
    if (!this.companyId) {
      this.columns.splice(3, 1);
    }
    this.loadGetOrderList();
  },
  methods: {
    loadGetOrderList() {
      let { page } = this;

      let data = {
        orderStatus: this.stateCode,
        companyId: this.companyId,
      };
      this.loading = true;
      getOrderList(page, data).then((res) => {
        this.scenesData = res.records;
        this.$set(this.pagination, "total", res.total);
        this.loading = false;
      });
    },

    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetOrderList();
    },

    scenesDel(id) {
      let _this = this;
      this.$confirm({
        title: "您确定删除吗?删除后不可恢复！",
        okType: "default",
        onOk() {
          orderRemove(id).then((res) => {
            _this.$message.success("删除成功");
            _this.loadGetOrderList();
          });
        },
        onCancel() {},
      });
    },
    scenesApply(type, val) {
      this.$router.push({
        name: "userScenesAdd",
        params: {
          type,
          info: val,
        },
      });
    },
    taskDetail(id) {
      this.$router.push({
        path: "/userLayout/userScenes/info",
        query: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-scenes-body {
  height: 100%;
  padding-top: 16px;
  > div {
    background: #fff;
    padding: 24px 32px;
    min-height: 100%;
    > p {
      font-size: 20px;
      color: #262626;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }
  .my-scenes-filter-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .my-scenes-filter-tab {
      display: flex;
      align-items: center;
      .my-scenes-filter-tab-list {
        display: flex;
        > div {
          width: 96px;
          height: 32px;
          background: #fff;
          border: 1px solid #d9d9d9;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          transition: all 0.3s;
          position: relative;
          &.active {
            color: #ea0b06;
            background: rgba(234, 11, 6, 0.05);
            border-color: #ea0b06;
            z-index: 2;
          }
        }
      }
    }
    .my-scenes-filter-btn {
      display: flex;
      align-items: center;
    }
  }
  .my-scenes-list {
    margin-top: 24px;
    .my-scenes-status {
      display: flex;
      align-items: center;
      > i {
        width: 6px;
        height: 6px;
        background: #bfbfbf;
        border-radius: 50%;
        margin-right: 8px;
        &.yus {
          background: #faad14;
        }
        &.yans {
          background: #52c41a;
        }
        &.fb {
          background: #1890ff;
        }
        &.btg {
          background: #ff4d4f;
        }
      }
    }
    .my-scenes-operating {
      display: flex;
      > span {
        cursor: pointer;
        color: #ea0b06;
        margin-right: 20px;
        white-space: nowrap;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
<style>
.my-scenes-list th,
.my-scenes-list td {
  border-color: #f0f0f0 !important;
}
</style>
