import { getMessageCount , getAllMessageCount} from "@/api/message";

export default {
  state: {
    msgNum: null,
    serverMsg: null,
    systemMsg: null,
    interactMsg: null
  },
  mutations: {
    SET_MSG(state, msg) {
      state.msgNum = msg;
    },
    SET_MSG_SERVER(state,serverMsg) {
      state.serverMsg = serverMsg;
    },
    SET_MSG_SYSTEM(state,systemMsg) {
      state.systemMsg = systemMsg;
    },
    SET_MSG_INTERACT(state,interactMsg) {
      state.interactMsg = interactMsg;
    }
  },
  actions: {
    LoadMsg({ commit }) {
      return new Promise((resolve) => {
        getMessageCount().then((data) => {
          commit("SET_MSG_SERVER", data.serverNum);
          commit("SET_MSG_SYSTEM", data.systemNum);
          commit("SET_MSG_INTERACT", data.interactNum);
          resolve(data);
        });
      });
    },
    getMsgNum({commit}) {
      return new Promise((resolve) => {
        getAllMessageCount().then((data) => {
          commit("SET_MSG", data);
          resolve(data);
        });
      });
    }
  },
};
