import { render, staticRenderFns } from "./fabang.vue?vue&type=template&id=6572da74&scoped=true&"
import script from "./fabang.vue?vue&type=script&lang=js&"
export * from "./fabang.vue?vue&type=script&lang=js&"
import style0 from "./fabang.vue?vue&type=style&index=0&id=6572da74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6572da74",
  null
  
)

export default component.exports