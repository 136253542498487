<template>
  <div class="infoBody">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>账号中心</a-breadcrumb-item>
      <a-breadcrumb-item>基本资料</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="userInfo">
      <p class="user-info-title">基本资料</p>
      <div class="user-info-personal">
        <div class="user-info-personal-avatar-box">
          <v-upload-avatar
            :background="require('@/assets/img/defaultavatar.png')"
            :value="userInfo.profilePictureAttmId"
            @change="handleChangeLogo"
            file-type-code="1001"
            height="104px"
            width="104px"
          />
        </div>
        <div class="user-info-personal-info-box">
          <p>个人信息</p>
          <div>
            <span>姓名</span>
            <div>
              {{ userInfo.realName }}
              <span
                :class="{
                  wrz: userInfo.verifyStatus == 1,
                  yrz: userInfo.verifyStatus == 3,
                  drz: userInfo.verifyStatus == 2,
                  wtg: userInfo.verifyStatus == 4,
                }"
                >{{ userInfo.verifyStatus | dict("USER_VERIFY_STATUS") }}</span
              >
            </div>
            <p @click="$router.push(`/userLayout/userCer`)">查看或变更认证</p>
          </div>
          <div>
            <span>手机号</span>
            <div v-if="userInfo.phone">
              +86 {{ userInfo.phone.substring(0, 3) }}****{{
                userInfo.phone.substring(7, 11)
              }}
            </div>
            <p @click="bindInfo">{{ userInfo.phone ? "修改" : "前往绑定" }}</p>
          </div>
          <!-- <div>
            <span>微信号</span>
            <div>fxx18322193472</div>
            <p @click="bindInfo">修改</p>
          </div> -->
        </div>
      </div>

      <div class="user-info-company">
        <div class="user-info-company-box">
          <p>企业信息</p>
          <template v-if="companyListData">
            <div
              class="box-card"
              v-for="(el, index) in companyListData"
              :key="el.id"
            >
              <div class="user-info-personal-avatar-box">
                <v-upload-avatar
                  :background-text="
                    el.companyName ? el.companyName.substring(0, 4) : ''
                  "
                  :value="el.companyLogoAttmId"
                  @change="handleChangeCompanyLogo"
                  file-type-code="1011"
                  height="80px"
                  width="80px"
                  :disabled="true"
                  v-if="companyListData"
                />
                <!-- 未认证 -->
                <img
                  v-if="el.verifyStatus == 1"
                  style="margin-left: 9px"
                  src="@/assets/img/userinfo1.png"
                  alt=""
                />
                <!-- 已认证 -->
                <img
                  v-else-if="el.verifyStatus == 3"
                  style="margin-left: 9px"
                  src="@/assets/img/userinfo2.png"
                  alt=""
                />
                <!-- 认证失败 -->
                <img
                  v-else-if="el.verifyStatus == 4"
                  style="margin-left: 2.5px"
                  src="@/assets/img/userinfo3.png"
                  alt=""
                />
                <!--认证中 -->
                <img
                  v-else-if="el.verifyStatus == 2"
                  style="margin-left: 9px"
                  src="@/assets/img/userinfo4.png"
                  alt=""
                />
              </div>

              <div class="user-info-company-info">
                <div>
                  <div>企业名称</div>
                  <div>
                    <p>{{ el.companyName }}</p>
                    <div v-if="el.isDefault">默认企业</div>
                  </div>
                  <div>
                    <!--                    <template v-if="el.memberType == 1">
                      <div @click="editCompany(el.id)">编辑</div>
                    </template>-->

                    <div
                      v-if="el.verifyStatus == 1 || el.verifyStatus == 4"
                      @click="toVerify(el.id, el)"
                    >
                      {{ el.verifyStatus == 1 ? "前往认证" : "重新认证" }}
                    </div>

                    <div
                      v-if="!el.isDefault && el.memberType"
                      @click="defaultCompany(el.id)"
                    >
                      设为默认
                    </div>
                    <div
                      v-if="
                        el.memberType == 2 ||
                        el.joinCompanyVerifyStatus == 1 ||
                        el.joinCompanyVerifyStatus == 3
                      "
                      @click="unbind(el.id)"
                    >
                      {{ el.memberType == 2 ? "退出企业" : "删除" }}
                    </div>
                  </div>
                </div>

                <div>
                  <div>企业身份</div>
                  <div
                    v-if="el.memberType"
                    :class="{ '1': 'syz', '2': 'cy' }[el.memberType]"
                  >
                    {{ el.memberType | dict("MEMBER_TYPE") }}
                  </div>
                  <div
                    v-if="el.joinCompanyVerifyStatus == 1"
                    style="color: #ff8b06"
                  >
                    申请加入中
                  </div>
                  <div
                    v-if="el.joinCompanyVerifyStatus == 3"
                    style="color: #ea0b06"
                  >
                    申请加入被拒绝
                  </div>
                </div>

                <div>
                  <div>法人代表</div>
                  <div>{{ el.legalPersonName }}</div>
                </div>

                <div>
                  <div>成立时间</div>
                  <div>{{ el.establishmentDate }}</div>
                </div>

                <div>
                  <div>统一社会信用代码</div>
                  <div>{{ el.socialCreditCode }}</div>
                </div>

                <div>
                  <div>公司网址</div>
                  <div>{{ el.website }}</div>
                </div>

                <div>
                  <div>公司地址</div>
                  <div>{{ el.companyAddress }}</div>
                </div>
                <div v-if="el.joinCompanyVerifyStatus == 3">
                  <div>拒绝理由</div>
                  <div>{{ el.joinCompanyDenialReason }}</div>
                </div>
                <a-divider dashed />
              </div>
            </div>
          </template>
        </div>
      </div>

      <a-button class="btn-red bottom-btn" @click="createCompany">
        添加企业
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  changeLogo,
  getCurrentCompany,
  getIsCreater,
  getInformation,
  getJoinCompanyList,
  getDefaultCompany,
} from "@/api/res-company";
import { quitCompany } from "@/api/enterpriseManage";
import VUploadAvatar from "@/components/VUploadAvatar";
import { updateUserInfo } from "@/api/res-user";
export default {
  name: "userInfo",
  components: {
    VUploadAvatar,
  },
  computed: mapGetters(["userInfo", "companyList", "companyId"]),
  data() {
    return {
      company: null,
      isCreater: false,
      denialReason: "",
      visibleden: false,
      companyListData: "",
    };
  },
  mounted() {
    // this.loadCompany();
    // this.loadgetIsCreater();
    this.loadCompanyList();
    this.LoadDictData(["INDUSTRY"]);
    // getInformation().then((data) => {
    //   this.denialReason = data.denialReason;
    // });
  },
  methods: {
    ...mapActions([
      "GetInfo",
      "LoadDictData",
      "UserCompany",
      "LoadPermissions",
    ]),
    ...mapMutations(["getCompanyIndex"]),

    // 切换企业
    toggleCompany(id, el, index) {
      this.$store.commit("SET_COMPANY_ID", id);
      this.LoadPermissions();
      this.$store.commit("SET_COMPANY_STATUS", el.verifyStatus);
      this.getCompanyIndex(index);
    },

    //去认证
    async toVerify(id, el) {
      let indexData = 0;
      for (let el in this.companyList) {
        if (this.companyList[el].id === id) {
          indexData = el;
        }
      }
      await this.toggleCompany(id, el, indexData);
      this.$router.push("/enterpriseManage/verify");
    },

    // 获取企业列表
    loadCompanyList() {
      const verifyStatusList = ["1", "2", "3"];
      getJoinCompanyList(verifyStatusList).then((res) => {
        this.companyListData = res;
      });
    },
    // 编辑
    editCompany(id) {
      this.$router.push(`/userLayout/userInfo/createCompany?id=${id}`);
    },
    // 设置默认企业
    defaultCompany(id) {
      getDefaultCompany({ companyId: id }).then((res) => {
        if (res) {
          this.$warning({
            title: res,
          });
        } else {
          this.$message.success("设置成功");
          this.loadCompanyList();
        }
      });
    },
    unbind(id) {
      let _this = this;
      this.$confirm({
        title: "提示",
        content: "您确定 退出/删除 该企业？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          quitCompany({ companyId: id }).then(async (res) => {
            _this.$store.commit("SET_COMPANY_ID", "");
            _this.$message.success("退出成功");
            await _this.UserCompany();
            _this.loadCompanyList();
          });
        },
        onCancel() {},
      });
    },
    enterpriseInfo() {
      let { company, isCreater } = this;
      if (company != "" && company != null && isCreater) {
        this.$router.push("/userLayout/userInfo/enterpriseInfo");
      } else {
        this.$router.push("/userLayout/userInfo/associationEnterprise");
      }
    },
    loadCompany() {
      getCurrentCompany().then((company) => {
        this.company = company;
      });
    },
    handleChangeLogo(id) {
      const { userInfo } = this;
      updateUserInfo({
        profilePictureAttmId: id,
        signature: userInfo.signature,
      }).then(() => {
        this.GetInfo();
      });
    },
    handleChangeCompanyLogo(id) {
      changeLogo(id).then(() => {
        this.loadCompany();
      });
    },
    bindInfo() {
      this.$router.push("/userLayout/userSettings");
    },
    loadgetIsCreater() {
      getIsCreater(this.companyId).then((res) => {
        this.isCreater = res;
      });
    },

    createCompany() {
      this.$router.push("/userLayout/userInfo/addCompany");
    },
  },
};
</script>

<style lang="scss" scoped>
.infoBody {
  .title {
    margin: 16px 0;
  }
}

.text-info {
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
  &:hover {
    color: #ea0b06;
  }
}

.userInfo {
  padding: 24px 38px 32px 40px;
  background: #ffffff;
  min-height: calc(100% - 53px);
  > .user-info-title {
    font-size: 20px;
    font-weight: 500;
    color: #262626;
    line-height: 28px;
  }
}
.user-info-personal {
  margin-top: 48px;
  margin-left: 80px;
  display: flex;
  > .user-info-personal-avatar-box {
    width: 80px;
    height: 80px;
    margin-right: 136px;
  }
  > .user-info-personal-info-box {
    height: 176px;
    position: relative;
    flex: 1;
    > p {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      margin-bottom: 32px;
    }
    > div {
      display: flex;
      margin-bottom: 16px;
      > span {
        width: 158px;
        font-size: 14px;
        color: #8c8c8c;
        line-height: 22px;
      }
      > div {
        width: 388px;
        font-size: 14px;
        color: #262626;
        line-height: 22px;
        > span {
          display: inline-block;
          width: 64px;
          height: 22px;
          border-radius: 2px;
          margin-left: 12px;

          font-size: 12px;
          line-height: 22px;
          text-align: center;
          > .wrz {
            background: #ebebeb;
            color: #999999;
          }
          &.drz {
            background: #feefd4;
            color: #faad29;
          }

          &.yrz {
            background: #dcf6ee;
            color: #50d4ab;
          }

          &.wtg {
            background: #fedbdc;
            color: #fc4d4f;
          }
        }
      }
      > p {
        font-size: 14px;
        color: #ea0b06;
        line-height: 22px;
        cursor: pointer;
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
    }
  }
}

.user-info-company {
  > .user-info-company-box {
    margin-left: 80px;
    > p {
      margin-left: 216px;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      margin-bottom: 24px;
      margin-top: 24px;
    }
    > .box-card {
      display: flex;
    }
    .user-info-personal-avatar-box {
      width: 80px;
      height: 80px;
      margin-right: 136px;
      cursor: default;
    }
    .user-info-company-info {
      flex: 1;
      > div {
        display: flex;
        margin-bottom: 16px;
        .syz {
          color: #ea0b06;
        }
        .cy {
          color: #1890ff;
        }
        > :nth-child(1) {
          width: 158px;
          font-size: 14px;
          color: #8c8c8c;
          line-height: 22px;
        }
        > :nth-child(2) {
          min-width: 388px;
          display: flex;
          > p {
            font-size: 14px;
            color: #262626;
            line-height: 22px;
            margin-right: 12px;
          }
          > div {
            width: 64px;
            height: 22px;
            background: #fe7202;
            border-radius: 2px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            line-height: 22px;
          }
        }
        > :nth-child(3) {
          display: flex;
          font-size: 14px;
          color: #ea0b06;
          line-height: 22px;
          > div {
            cursor: pointer;
          }
          > :not(:first-child) {
            margin-left: 16px;
            position: relative;
            cursor: pointer;
            &:before {
              position: absolute;
              content: "";
              top: 4px;
              left: -7px;
              width: 1px;
              height: 14px;
              background: #e9e9e9;
            }
          }
        }
      }
      > :nth-last-child {
        margin-bottom: 24px;
      }
    }
  }
}

.bottom-btn {
  margin-left: 296px;
  margin-top: 20px;
}
</style>
