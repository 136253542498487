<template>
  <a-modal
    v-model="visible"
    title="选择角色成员"
    width="748px"
    @cancel="visible = false"
  >
    <div style="display: flex;justify-content: space-between;">
      <a-input-search
        placeholder="请输入姓名/账号/手机号"
        style="width: 272px"
        @search="handleSearch"
        v-model="realName"
      />
      <a-button @click="resetRole">重置</a-button>
    </div>
    <div class="enterprise-manage-role-select-title">
      <p>
        <a-icon type="info-circle" theme="filled" />
        已选择
        <span>{{ selectedRowKeys.length }}</span> 项
      </p>
      <span @click="empty">清空</span>
    </div>
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      row-key="id"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @change="handleChange"
    ></a-table>
    <template #footer>
      <a-button @click="handleCancel">取 消</a-button>
      <a-button @click="handleSubmit">确 定</a-button>
    </template>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getUserList, saveUserRole } from "@/api/res-role";

export default {
  computed: {
    ...mapGetters(["companyId"]),
  },
  data() {
    return {
      visible: false,
      realName: "",
      columns: [
        {
          title: "姓名",
          dataIndex: "realName",
          key: "realName",
        },
        // {
        //   title: "账号",
        //   dataIndex: "username",
        //   key: "username",
        // },
        {
          title: "手机号",
          dataIndex: "phone",
          key: "phone",
        },
      ],
      roleId: "",
      list: [],
      pagination: {
        showQuickJumper: true,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
        pageSize: 10,
        current: 1,
      },
      selectedRowKeys: [],
    };
  },
  methods: {
    ...mapActions(["GetInfo"]),
    resetRole(){
      this.realName = ''
      this.selectedRowKeys = []
      this.handleSearch();
    },
    open(role) {
      this.realName = "";
      this.roleId = role.id;
      this.selectedRowKeys = role.userIds || [];
      this.visible = true;
    },
    handleSubmit() {
      const { companyId, roleId, selectedRowKeys: userIds } = this;
      saveUserRole(companyId, roleId, userIds).then(() => {
        this.$message.info("操作成功！");
        this.$emit("ok");
        this.visible = false;
      });
    },
    handleCancel() {
      this.visible = false;
    },
    empty() {
      this.selectedRowKeys = [];
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleSearch() {
      this.pagination.current = 1;
      this.loadData();
    },
    async loadData() {
      const { current, pageSize: size } = this.pagination;
      const { companyId, realName } = this;
      const res = await getUserList({ current, size }, { companyId, realName });
      this.list = res.records;
      this.pagination.total = res.total;
    },
    handleChange(page, pageSize) {
      this.page.current = page;
      this.page.pageSize = pageSize;
      this.loadData();
    },
  },
  mounted() {
    this.handleSearch();
  },
};
</script>
<style lang="scss" scoped>
.enterprise-manage-role-select-title {
  margin: 16px 0;
  height: 40px;
  background: #fff2f2;
  border: 1px solid #ffb6b6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;

  > p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    display: flex;
    align-items: center;

    > i {
      color: #ea0b06;
      margin-right: 6px;
    }

    > span {
      color: #ea0b06;
      margin: 0 2px;
    }
  }

  > span {
    color: #ea0b06;
    cursor: pointer;
    font-size: 14px;
  }
}
</style>
