<template>
  <div class="user-consult">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>互动中心</a-breadcrumb-item>
      <a-breadcrumb-item>我的咨询</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="user-consult-body">
      <p>我的咨询</p>

      <div class="select">
        <div>
          咨询状态：
          <a-select
            placeholder="请选择"
            allow-clear
            style="width: 272px"
            v-model="interactiveStatus"
          >
            <a-select-option
              v-for="item in dict.CONSULT_STATUS"
              :key="item.itemValue"
              :value="item.itemValue"
            >
              {{ item.itemText }}
            </a-select-option>
          </a-select>
        </div>

        <a-input-search
          placeholder="请输入关键词搜索"
          style="width: 272px"
          @search="onSearch"
          allowClear
          v-model="newsName"
        />
      </div>

      <a-table
        :columns="columns"
        :data-source="consultData"
        :loading="loading"
        @change="handleTableChange"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :pagination="pagination"
      >
        <span slot="num" slot-scope="text, record, index">{{ index + 1 }}</span>

        <template slot="status" slot-scope="text, record">
          <p class="user-scene-table-state">
            <i :class="`state-${text}`"></i>{{ text | dict("CONSULT_STATUS") }}
          </p>
        </template>
        <template slot="operating" slot-scope="text, record">
          <p class="user-scene-table-operate">
            <span @click="consultInfo(record)">详情</span>
          </p>
        </template>
      </a-table>
    </div>

    <consult-info
      :show="consultInfoShow"
      v-if="consultInfoShow"
      v-model="consultInfoShow"
      :id="consultId"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "num",
    key: "num",
    width: 80,
    scopedSlots: { customRender: "num" },
  },
  {
    title: "咨询主题",
    dataIndex: "consultTitle",
    key: "consultTitle",
    ellipsis: true,
  },
  {
    title: "提交时间",
    dataIndex: "consultTime",
    key: "consultTime",
  },
  {
    title: "回复时间",
    dataIndex: "replyTime",
    key: "replyTime",
  },
  {
    title: "状态",
    dataIndex: "consultStatus",
    key: "consultStatus",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    width: 160,
    scopedSlots: { customRender: "operating" },
  },
];

import { getConsultList } from "@/api/news";
import consultInfo from "./info.vue";
import pagination from "../components/pagination.vue";
import { mapGetters } from "vuex";
export default {
  name: "userConsult",
  components: {
    consultInfo,
    pagination,
  },
  data() {
    return {
      interactiveType: undefined,
      interactiveStatus: undefined,
      columns,
      loading: false,
      consultData: [],
      consultInfoShow: false,
      page: {
        current: 1,
        size: 10,
      },
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 项`,
        total: 0,
      },
      newsName: "",
      consultId: "",
    };
  },
  computed: mapGetters(["dict"]),
  watch: {
    interactiveStatus: function (val) {
      this.loadGetNewsList();
    },
  },
  mounted() {
    this.loadGetNewsList();
  },
  methods: {
    loadGetNewsList() {
      let data = {
        consultTitle: this.newsName,
        consultStatus: this.interactiveStatus,
      };
      this.loading = true;
      getConsultList(this.page, data).then((res) => {
        this.consultData = res.records;
        this.page.total = res.total;
        this.$set(this.pagination, "total", res.total);
        this.loading = false;
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetNewsList();
    },
    onSearch() {
      this.loadGetNewsList();
    },
    consultInfo(val) {
      this.consultId = val.id;
      this.consultInfoShow = true;
    },
    handleTableChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetNewsList();
    },
  },
};
</script>

<style scoped lang="scss">
.user-consult {
  padding-top: 16px;
  height: 100%;
  box-sizing: border-box;
  > .title {
    margin-bottom: 16px;
  }
}

.user-consult-body {
  background: #ffffff;
  border-radius: 2px;
  min-height: calc(100% - 37px);
  overflow-y: auto;
  padding: 24px 32px 36px;

  > P {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-bottom: 24px;
  }

  > .select {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}

.user-scene-table-state {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #595959;
  > i {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin-right: 8px;
    &.state-1 {
      background: #facc14;
    }
    &.state-4 {
      background: #f5222d;
    }
    &.state-2 {
      background: #1890ff;
    }
    &.state-3 {
      background: #52c41a;
    }
  }
}

.user-scene-table-operate {
  > span {
    cursor: pointer;
    margin-right: 16px;
    color: #ea0b06;
  }
}
</style>
