<template>
  <a-upload
    name="file"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :action="action"
    :disabled="disabled"
    :before-upload="beforeUpload"
    :style="widthHeightStyle"
    :headers="headers"
    @change="handleChange"
  >
    <slot :attachment-id="value">
      <img
        v-if="value"
        :src="src"
        alt="avatar"
        :style="`width:${width};height:${height};`"
      />
      <div v-else>
        <template v-if="background && !backgroundText">
          <img alt="图片" :src="background" />
        </template>
        <template v-else-if="backgroundText">
          <div
            class="user-info-enterprise-avatar-box"
            :style="`width:${width};height:${height};font-size:${fontSize}`"
          >
            {{ backgroundText }}
          </div>
        </template>
        <template v-else>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">{{ uploadText }}</div>
        </template>
      </div>
    </slot>
  </a-upload>
</template>
<script>
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";

export default {
  name: "VUploadAvatar",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileTypeCode: {
      type: String,
      required: true,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    background: {
      type: String,
    },
    backgroundText: {
      type: String,
    },
    fontSize: {
      type: String,
    },
    uploadText: {
      type: String,
      default: "上传",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    headers() {
      const token = storage.get(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },
    widthHeightStyle() {
      /* const { width, height } = this;

      return {
        width,
        height,
        // maxWidth: "100%",
      };*/
    },
    action() {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`;
    },
    src() {
      return `${process.env.VUE_APP_OBS_PREFIX}/${this.value}`;
    },
  },
  mounted() {},
  methods: {
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
      } else if (info.file.status === "done") {
        this.loading = false;
        this.fileName = info.file.name;
        this.$emit("input", info.file.response.data.id);
        this.$emit("change", info.file.response.data.id);
      } else if (info.file.status === "error") {
        this.$emit("error", info.file);
      }
    },
    beforeUpload(file) {
      const isImage = file.type.indexOf("image") !== -1;
      if (!isImage) {
        this.$message.error("只能上传图片!");
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("文件不能超过10MB!");
      }
      return isImage && isLt10M;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-uploader::v-deep {
  .ant-upload.ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
    border: none;
    background-color: #fff;
  }
}
</style>

<style>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.avatar-uploader .ant-upload.ant-upload-select-picture-card {
  margin: 0;
}

.avatar-uploader .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}

.user-info-enterprise-avatar-box {
  /* width: 104px;
  height: 104px;
  background: #ea0b06;
  border-radius: 4px;*/

  /* width: 80px;
  height: 80px;*/
  background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
  border-radius: 4px;

  color: #fff;
  font-size: 24px;
  padding: 7px 16px;
  line-height: 33px;
  margin: 0 auto;
}
</style>
