<template>
  <div class="user-settings">
    <a-breadcrumb class="title">
      <a-breadcrumb-item>账号中心</a-breadcrumb-item>
      <a-breadcrumb-item>安全设置</a-breadcrumb-item>
    </a-breadcrumb>

    <div class="user-settings-content">
      <div>
        <div class="user-content-title">
          <p>安全设置</p>
        </div>

        <div class="user-content-item">
          <div>
            <img alt="图片" src="@/assets/img/mima.png" />
            <div>
              <div>
                <span>登录密码</span>
              </div>
              <p>
                设置安全性高的密码可以使账号更安全，上次重置时间：{{
                  userInfo.lastChangePwdTime || "-"
                }}
              </p>
            </div>
          </div>
          <span @click="editPassword">设置</span>
        </div>
        <div class="user-content-item" v-if="userInfo.phone">
          <div>
            <img alt="图片" src="@/assets/img/shouji.png" />
            <div>
              <div>
                <span>安全手机</span>
              </div>
              <p>
                用于登录账号/重置密码/其他安全验证，手机号：{{
                  userInfo.phone | capitalize
                }}
              </p>
            </div>
          </div>
          <span @click="editPhone">更改</span>
        </div>
        <div class="user-content-item" v-else>
          <div>
            <img alt="图片" src="@/assets/img/shouji.png" />
            <div>
              <div>
                <span>安全手机</span>
              </div>
              <p>用于登录账号/重置密码/其他安全验证，手机号：暂未绑定</p>
            </div>
          </div>
          <span @click="bindPhone">绑定</span>
        </div>

        <div class="user-content-item">
          <div>
            <img alt="图片" src="@/assets/img/setWeixin.png" />
            <div>
              <div>
                <span>微信</span>
              </div>
              <p v-if="userInfo.isBindWechat">
                可扫码登录，已授权绑定微信号：{{ userInfo.phone | capitalize }}
              </p>
              <p v-else>绑定微信，可扫码登录</p>
            </div>
          </div>
          <span v-if="userInfo.isBindWechat" @click="editWeixi">解绑</span>
          <span v-else @click="bindWeixi">绑定</span>
        </div>
      </div>
    </div>

    <a-modal
      :footer="null"
      :title="null"
      @cancel="cancel"
      centered
      v-model="visible"
    >
      <div class="lift-model">
        <p class="title">解绑操作提示</p>
        <p class="con">确定解绑当前微信账号？</p>
        <div class="btn">
          <a-button @click="visible = false">取消</a-button>
          <a-button @click="liftWeiXin" class="btn-red">确定</a-button>
        </div>
      </div>
    </a-modal>

    <bind-wechat ref="bindWechatRef" />

    <!--        <edit-password :show="editPasswordShow" v-model="editPasswordShow"/>-->
    <bind-password ref="bindPassword" />

    <bind-phone ref="bindPhoneRef" />
    <edit-phone ref="editPhoneRef" />
    <edit-email ref="editEmailRef" />
    <bind-email ref="bindEmailRef" />
  </div>
</template>

<script>
import { getLiftWeiXinQrcode } from "@/api/common";
import editPassword from "./editPassword.vue";
import EditPhone from "./EditPhone.vue";
import EditEmail from "./EditEmail.vue";
import {mapActions, mapGetters} from "vuex";
import BindEmail from "@/page/userSettings/BindEmail";
import BindPhone from "@/page/userSettings/BindPhone";
import BindPassword from "@/page/userSettings/BindPassword";
import BindWechat from "@/page/userSettings/BindWechat";
export default {
  components: {
    BindPhone,
    BindEmail,
    BindWechat,
    editPassword,
    EditPhone,
    EditEmail,
    BindPassword,
  },
  computed: mapGetters(["userInfo"]),
  data() {
    return {
      editPasswordShow: false,
      visible: false,
    };
  },
  methods: {
    ...mapActions(["GetInfo"]),

    editPassword() {
      this.$refs.bindPassword.open();
    },
    bindPhone() {
      this.$refs.bindPhoneRef.open();
    },
    editPhone() {
      this.$refs.editPhoneRef.open();
    },
    editEmail() {
      this.$refs.editEmailRef.open();
    },
    bindEmail() {
      this.$refs.bindEmailRef.open();
    },
    cancel() {
      this.visible = false;
    },
    editWeixi() {
      this.visible = true;
    },
    bindWeixi() {
      this.$refs.bindWechatRef.open();
    },

    liftWeiXin() {
      getLiftWeiXinQrcode().then((res) => {
        this.$message.success("微信解绑成功！");
        this.GetInfo();
        this.visible = false;

      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-settings {
  height: 100%;
  padding-top: 16px;

  > .title {
    margin-bottom: 16px;
  }

  .user-settings-content {
    height: calc(100% - 37px);
    overflow-y: auto;

    > div {
      //width: 1240px;
      margin: 0 auto;
      min-height: 100%;
      background: #fff;
      padding: 25px 40px;

      > .user-content-title {
        display: flex;
        align-items: center;

        > p {
          font-size: 20px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 28px;
          margin-right: 24px;
        }

        > div {
          width: 144px;
          height: 24px;
          background: #f6f7fb;
          padding: 0 16px;

          font-size: 14px;
          color: #595959;
          line-height: 24px;

          > span {
            color: #ff1a2e;
          }
        }
      }

      > .user-content-item {
        height: 120px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          align-items: center;
          flex: 1;

          > img {
            margin-right: 24px;
          }

          > div {
            flex: 1;

            > div {
              display: flex;
              align-items: flex-end;
              margin-bottom: 16px;

              > span {
                line-height: 15px;
                font-size: 16px;
              }

              > div {
                display: flex;
                align-items: center;
                margin-left: 16px;

                > span {
                  width: 64px;
                  height: 8px;
                  background: #f5f5f5;
                  margin-right: 8px;

                  &:last-child {
                    margin-right: 0;
                  }

                  &.active {
                    background: #52c41a;
                  }
                }

                > p {
                  margin-left: 8px;
                  line-height: 12px;
                  font-size: 14px;
                  color: #52c41a;
                }
              }
            }

            > p {
              line-height: 14px;
              font-size: 14px;
              color: #8c8c8c;
            }
          }
        }

        > span {
          width: 88px;
          height: 32px;
          background: #f5f5f5;
          border-radius: 2px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          color: #ea0b06;
          cursor: pointer;
        }
      }
    }
  }
}

.set-model {
  display: flex;
  justify-content: center;
}

.lift-model {
  .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-bottom: 12px;
  }
  .con {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    margin-bottom: 12px;
  }
  .btn {
    display: flex;
    justify-content: right;
    > :last-child {
      margin-left: 8px;
    }
  }
}
</style>
