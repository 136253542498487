<template>
    <a-modal
            :footer="null"
            @cancel="visible = false"
            title="重置账号密码"
            v-model="visible"
            width="640px"
            wrap-class-name="edit-phone-modal"
    >

        <a-steps :current="current" class="steps">
            <a-step title="安全验证"/>
            <a-step title="修改密码"/>
            <a-step title="操作成功"/>
        </a-steps>


        <div class="code-box" v-if="current===0">
            <a-button @click="sendMobileCode" class="btn-red" style="width: 250px;height: 40px;" v-if="!codeShow">
                通过{{phone}}验证
            </a-button>

            <div v-else>
                <p style="margin-bottom: 10px">验证码已发送至{{phone}}，请查收</p>
                <a-input-search
                        v-model="code"
                        @search="onSearch"
                        enter-button="验证"
                        size="large"
                        style="width: 400px;"
                />
            </div>

        </div>


        <div class="edit-password-box" v-if="current===1">
            <div>
                <span>新密码：</span>
                <div>
                    <a-input-password
                            placeholder="6~20位新密码，至少含字母和数字，不能包含空格"
                            style="width: 464px"
                            v-model="newPassword"
                    />
                </div>
            </div>
            <div>
                <span>确认新密码：</span>
                <div>
                    <a-input-password
                            placeholder="请确认新密码"
                            style="width: 464px"
                            v-model="passVal"
                    />
                    <div class="password-grade">
                        <div>
                            <span :class="{'active':msgText > 0}"></span>
                            <span :class="{'active':msgText > 1}"></span>
                            <span :class="{'active':msgText > 2}"></span>
                        </div>
                        <span v-if="msgText > 0">密码强度：{{msgText == 0 || msgText <= 1?'差':''}}{{msgText == 2?'中':''}}{{msgText > 2?'高':''}}</span>
                    </div>
                </div>
            </div>
          <div style="display: flex;justify-content: right">
            <a-button class="btn-red"  @click="submit">确认</a-button>
          </div>
        </div>

        <div class="success" v-if="current===2">
            <p>密码修改成功！</p>
        </div>


    </a-modal>
</template>

<script>
    import {checkPhoneCord, sendMobile} from "@/api/common";
    import {mapGetters} from "vuex";
    import {setResetPassword,checkMobileByReset} from "@/api/register";

    export default {
        name: "bind-password",

        data() {
            return {
                visible: false,
                codeShow: false,
                current: 0,
                code:'',
                passVal:'',
                msgText: "",
                oldPassword: '',
                newPassword: '',
                resetToken:'',
            }
        },
        computed: {
            ...mapGetters(["userInfo"]),
            phone() {
                return this.$options.filters.capitalize(this.userInfo.phone);
            },

        },
        watch:{
          passVal(newname) {
            if(newname.length >= 6){
              this.msgText = this.checkStrong(newname);
            }else{
              this.msgText = 0
            }
          },
          visible(){
                this.codeShow=false
                this.current=0
                this.code=''
                this.passVal=''
                this.msgText=""
                this.oldPassword=''
                this.newPassword=''
                this.resetToken=''
          }
        },

        methods: {


          open() {
            this.visible = true;
          },



            onSearch() {
                if(!this.code){
                  this.$message.warning('请输入验证码');
                  return
                }
                let params={
                  mobile:this.userInfo.phone,
                  code:this.code,
                }
                checkPhoneCord(params).then(() => {
                  checkMobileByReset(params).then(res=>{
                    this.resetToken=res["reset-password-token"]
                    this.current=1
                    this.code=''
                    this.$message.success("验证成功！");
                  })

                })
            },


            sendMobileCode() {
                sendMobile(this.userInfo.phone, "bind").then((res) => {

                    this.codeShow=true
                    this.$message.success("发送成功！");
                });
            },

            submit() {
                let { newPassword, passVal} = this
                let reg = /^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{6,20}$/

                if (!reg.test(newPassword)) {
                    this.$message.warning('新密码输入有误，由字母、数字、特殊字符，任意2种组成长度6-20位');
                    return
                }
                if (passVal != newPassword) {
                    this.$message.warning('密码输入不一致');
                    return
                }
                let data = {
                  password: newPassword,
                  token: this.resetToken,
                }
                setResetPassword(data).then(() => {
                    this.current=2
                })
            },


            // 密码强度
            checkStrong(sValue) {
                var modes = 0;
                //正则表达式验证符合要求的
                if (sValue.length < 1) return modes;
                if (/\d/.test(sValue)) modes++; //数字
                if (/[a-z]/.test(sValue)) modes++; //小写
                if (/[A-Z]/.test(sValue)) modes++; //大写
                if (/\W/.test(sValue)) modes++; //特殊字符

                //逻辑处理
                switch (modes) {
                    case 1:
                        return 1;
                    case 2:
                        return 2;
                    case 3:
                    case 4:
                        return sValue.length < 4 ? 3 : 4;
                }
                return modes;
            },
        }
    }
</script>

<style lang="scss" scoped>
  .steps{
    margin-bottom: 30px;
  }

  .code-box{
    display: flex;
    justify-content: center;
  }

  .success{
    >p{
      text-align: center;
      font-size: 20px;
    }
  }


  .edit-password-box {
        > div {
            display: flex;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            > span {
                line-height: 32px;
                width: 96px;
            }

            > div {
                .password-grade {
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    height: 12px;

                    > div {
                        display: flex;

                        > span {
                            width: 80px;
                            height: 4px;
                            background: #f5f5f5;
                            margin-right: 4px;

                            &:last-child {
                                margin-right: 16px;
                            }

                            &.active {
                                background: #52C41A;
                            }
                        }
                    }

                    > span {
                        font-size: 12px;
                        line-height: 12px;
                        color: #262626;
                    }
                }
            }
        }
    }

</style>

<style>
    .edit-phone-modal .ant-modal-body {
        padding: 40px;
    }
</style>
