var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-demand-body"},[_c('div',[_c('div',{staticClass:"my-demand-filter-box"},[_c('div',{staticClass:"my-demand-filter-tab"},[_c('div',{staticClass:"my-demand-filter-tab-list"},[_c('div',{class:{ active: _vm.stateCode == '' },on:{"click":function($event){_vm.stateCode = ''}}},[_vm._v(" 全部 ")]),_vm._l((_vm.dict.DEMAND_STATUS),function(item,index){return _c('div',{key:item.itemValue,class:{ active: _vm.stateCode == item.itemValue },style:({ left: -(index + 1) + 'px' }),on:{"click":function($event){_vm.stateCode = item.itemValue}}},[_vm._v(" "+_vm._s(item.itemText)+" ")])})],2)]),_c('div',{staticClass:"my-demand-filter-btn"},[_c('a-button',{staticClass:"btn-red",on:{"click":function($event){return _vm.demandApply('')}}},[_vm._v("提交需求信息单")])],1)]),_c('div',{staticClass:"my-demand-list"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.demandData,"pagination":_vm.pagination,"loading":_vm.loading,"rowKey":function (record, index) {
              return index;
            }},on:{"change":_vm.pageChange},scopedSlots:_vm._u([{key:"fundAmount",fn:function(text, record){return [_vm._v(" "+_vm._s(record.fundAmount || "-")+"元 "),(record.fundAmountStatus == true)?_c('span',[_vm._v(" [可议价] ")]):_vm._e()]}},{key:"demandStatus",fn:function(text){return [_c('p',[_vm._v(_vm._s(_vm._f("dict")(text,"DEMAND_STATUS")))])]}},{key:"operating",fn:function(text, record){return _c('div',{},[_c('div',{staticClass:"my-demand-operating"},[(record.demandStatus == '1')?[_c('span',{on:{"click":function($event){return _vm.demandApply(
                      record.id,
                      record.demandStatus,
                      record.tenantId
                    )}}},[_vm._v("编辑")]),_c('span',{on:{"click":function($event){return _vm.demandDel(record.id)}}},[_vm._v("删除")])]:(record.demandStatus == '3')?[_c('span',{on:{"click":function($event){return _vm.demandDetail(
                      record.id,
                      record.demandStatus,
                      record.tenantId
                    )}}},[_vm._v("详情")]),_c('span',{on:{"click":function($event){return _vm.demandApply(record.id)}}},[_vm._v("再次提交")])]:(record.demandStatus == '5')?[_c('span',{on:{"click":function($event){return _vm.demandDetail(
                      record.id,
                      record.demandStatus,
                      record.tenantId
                    )}}},[_vm._v("详情")]),_c('span',{on:{"click":function($event){return _vm.changeEndTime(record.id)}}},[_vm._v("修改截止时间")])]:[_c('span',{on:{"click":function($event){return _vm.demandDetail(
                      record.id,
                      record.demandStatus,
                      record.tenantId
                    )}}},[_vm._v("详情")])],_c('change-demand-end-time',{ref:"changeEndTimeRef",on:{"refreshData":_vm.loadData}})],2)])}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }