<template>
  <a-date-picker v-model="val" format="YYYY-MM-DD" :show-time="true" />
</template>

<script>
import moment from "moment";

export default {
  name: "VDatePicker",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    val: {
      get() {
        const { value } = this;
        return value ? moment(value, "YYYY-MM-DD") : null;
      },
      set(val) {
        const value = val ? val.format("YYYY-MM-DD") : null;
        this.$emit("input", value);
      },
    },
  },
};
</script>
