<template>
  <a-modal v-model="addVisible" title="新建部门" width="480px" @cancel="cancel">
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
    >
        <a-form-model-item ref="departmentName" label="部门名称" prop="departmentName">
            <a-input
                v-model="form.departmentName"
                :maxLength="17"
                 placeholder="请填写部门名称"
                @blur=" () => { $refs.departmentName.onFieldBlur(); } "
            />
        </a-form-model-item>
<!--        <a-form-model-item label="上级部门" prop="pid" v-if="(list != '' && !edit) || (edit && edit.pid && list != '')">
            <a-tree-select
              v-model="form.pid"
              show-search
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择上级部门"
              allow-clear
              tree-default-expand-all
              :tree-data="list"
            >
            </a-tree-select>
        </a-form-model-item>-->

      <a-form-model-item label="上级部门" prop="pid" v-if="(list != '' && !edit) || (edit && edit.pid && list != '')">
        <a-input
            v-model="departmentName"
            placeholder="请填写部门名称"
            disabled
        />
      </a-form-model-item>
        <a-form-model-item label="部门排序" prop="sortNum">
            <a-input v-model="form.sortNum"  placeholder="请填写部门排序"/>
        </a-form-model-item>
        <a-form-model-item label="部门描述" prop="description">
            <a-textarea v-model="form.description" placeholder="请输入部门描述" :rows="3" :max-length="300"/>
        </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button @click="cancel">取 消</a-button>
      <a-button class="btn-red" @click="onSubmit">确 定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { departmentSave } from "@/api/enterpriseManage";
import {mapGetters,mapActions } from "vuex";
export default {
  props: ["visible","list",'edit'],
  data() {
    return {
      addVisible: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      other: '',
      form: {
        departmentName: '',
        pid: undefined,
        sortNum: '',
        description: '',
      },
      departmentName:'',
      rules: {
        departmentName: [{ required: true, message: '请填写部门名称', trigger: 'blur' },],
        pid: [{ required: true, message: '请选择上级部门', trigger: 'change' }],
      },
    };
  },
  computed: mapGetters(["companyId"]),
  watch: {
    visible: function (val) {
      this.addVisible = val;
    },
  },
  mounted(){
    this.addVisible = this.visible;
    this.UserCompany()
    if(this.edit){
      console.log(this.edit)
      this.form = this.edit
    }
    if(this.list && this.list.length>0){
      this.departmentName=this.list[0].title
      this.form.pid=this.list[0].value
    }

  },
  methods:{
    ...mapActions(["UserCompany"]),
    cancel() {
      this.$refs.ruleForm.resetFields();
      this.$emit("input", false);
    },
    onSubmit() {
      let _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.companyId = this.companyId
          departmentSave(this.form).then((res)=>{
            console.log(res)
            _this.$message.success('添加成功');
            _this.$emit("input", false);
            _this.$refs.ruleForm.resetFields();
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
