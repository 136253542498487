<template>
  <div>
    <a-tag
      v-for="(item,index) in list"
      :key="index"
      color="blue"
    >
      {{ item.tenantName }}
    </a-tag>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VTenantList',
  props: {
    ids: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['tenants']),
    list () {
      const { tenants, ids } = this
      return tenants.filter(e => ids.indexOf(e.id) !== -1)
    }
  }
}
</script>

<style scoped>

</style>
