import { PortalRequest as request } from "@/utils/request";

export const getProjectPageList = (params, data) => {
  return request({
    url: "/pro-project-manage/page-list",
    method: "post",
    params: params,
    data,
  });
};

/**
 * 开发管理保存
 * @param data
 * @returns {AxiosPromise}
 */
export const projectSave = (data) => {
  return request({
    url: "/pro-project-manage/create",
    method: "post",
    data,
  });
};
/**
 * 开发管理保存
 * @param data
 * @returns {AxiosPromise}
 */
export const projectUpdate = (data) => {
  return request({
    url: "/pro-project-manage/update",
    method: "post",
    data,
  });
};

export const projectRemove = (id) => {
  return request({
    url: `/pro-project-manage/remove/${id}`,
    method: "delete",
  });
};
