import { render, staticRenderFns } from "./tagInput.vue?vue&type=template&id=a92bd974&scoped=true&"
import script from "./tagInput.vue?vue&type=script&lang=js&"
export * from "./tagInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a92bd974",
  null
  
)

export default component.exports