<template>
  <div
    class="member"
    :class="[`member-${num}`, delShow ? 'memberBg' : '']"
    @mouseenter="delShow = true"
    @mouseleave="delShow = false"
  >
    <div>{{ val.memberName.slice(0, 1) }}</div>
    <div>
      <div>{{ val.memberName }}</div>
      <div>{{ val.position }}</div>
    </div>

    <a-tooltip>
      <template slot="title"> 删除 </template>
      <img
        v-show="delShow"
        class="del"
        src="@/assets/img/trash.png"
        alt=""
        @click.stop="$emit('removeCore', val.id)"
      />
    </a-tooltip>
  </div>
</template>

<script>
export default {
  name: "memberCard",
  props: ["val", "num"],
  data() {
    return {
      delShow: false,
    };
  },
};
</script>

<style scoped lang="scss">
.member {
  width: 294px;
  height: 120px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  display: flex;
  padding: 28px 24px;
  margin: 24px 24px 0 0;
  position: relative;
  cursor: pointer;
  > :nth-child(1) {
    width: 64px;
    height: 64px;
    font-size: 32px;
    line-height: 64px;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    margin-right: 22px;
  }
  > :nth-child(2) {
    margin-top: 7px;
    > :first-child {
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 4px;
    }
    > :last-child {
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
.memberBg {
  background: #f5f5f5;
}

.member-1 > :nth-child(1) {
  background: #37d2b3;
}
.member-2 > :nth-child(1) {
  background: #52affc;
}
.member-3 > :nth-child(1) {
  background: #febe7c;
}
.member-4 > :nth-child(1) {
  background: #bca0db;
}

.del {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 24px;
  top: 52px;
  cursor: pointer;
}
</style>
