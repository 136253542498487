<template>
    <div class="my-fabang-body">
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pages"
                @change="tablePage"
        >
            <div slot="sceneName" slot-scope="text, record">
                <div class="fabang-title-body">
          <span>
            <v-img :attachment-id="record.coverAttmId"/>
          </span>
                    <div>
                        <strong>{{ record.title }}</strong>
                        <p>
                            <span>{{ record.field | dict("SCENE_FIELD") }}</span>
                            <span>{{ record.industry | dict("SCENE_INDUSTRY") }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div slot="fundAmount" slot-scope="text">￥{{ text }}</div>
            <div slot="sceneStatus" slot-scope="text, record">
        <span class="my-scenes-status">
          <i class="jbz" v-if="record.sceneStatus === '6'"></i>
          <i class="yds" v-else-if="record.sceneStatus === '8'"></i>
          <i class="yzz" v-else-if="record.sceneStatus === '7'"></i>
          {{ record.sceneStatus | dict("SCENE_STATUS") }}
        </span>
            </div>
            <div slot="operating" slot-scope="text, record">
                <div class="my-scenes-operating">
                    <span @click="scenesDetail(record.id,record.tenantId)">详情</span>
                    <span
                            @click="scenesPause(record.id)"
                            v-if="record.sceneStatus === '6'"
                    >终止</span
                    >
                </div>
            </div>
        </a-table>
    </div>
</template>

<script>
    import {getTenantInfo} from '@/api/common'
    import {mapActions, mapGetters} from "vuex";
    import {getOwnScene, sceneCancelBid} from "@/api/cjkf/scn-scene";
    import images from "@/page/components/image";
    import VImg from "@/components/VImg";

    const columns = [
        {
            title: "榜单信息",
            dataIndex: "title",
            key: "title",
            ellipsis: true,
            width: 500,
            scopedSlots: {customRender: "sceneName"},
        },
        {
            title: "发布时间",
            dataIndex: "previewedTime",
            key: "previewedTime",
        },
        {
            title: "申请截止时间",
            dataIndex: "applyDueDate",
            key: "applyDueDate",
        },
        {
            title: "状态",
            dataIndex: "sceneStatus",
            key: "sceneStatus",
            scopedSlots: {customRender: "sceneStatus"},
        },
        {
            title: "操作",
            dataIndex: "operating",
            key: "operating",
            scopedSlots: {customRender: "operating"},
        },
    ];
    export default {
        components: {
            VImg,
            images,
        },
        data() {
            return {
                data: [],
                columns,
                pages: {
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: 0,
                    showTotal: (total) => `共 ${total} 项`,
                },
                page: {
                    current: 1,
                    size: 10,
                },
            };
        },
        mounted() {
            this.LoadDictData(["SCENE_FIELD", "SCENE_INDUSTRY"]);
            this.loadGetOwnScene();
        },
        methods: {
            ...mapActions(["LoadDictData"]),
            loadGetOwnScene() {
                getOwnScene(this.page).then((res) => {
                    this.data = res.records;
                    this.pages.total = res.total;
                });
            },
            tablePage(page) {
                this.page.current = page.current;
                this.loadGetOwnScene();
            },
            scenesPause(id) {
                let _this = this;
                this.$confirm({
                    title: "您确定终止吗?",
                    onOk() {
                        sceneCancelBid(id).then((res) => {
                            _this.page.current = 1;
                            _this.loadGetOwnScene();
                        });
                    },
                    onCancel() {
                    },
                });
            },
            scenesDetail(id) {
                this.$router.push({
                    path: "/homeScenes/detail",
                    query: {id: id},
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .my-fabang-body {
        width: 1240px;
        margin: 0 auto;
        background: #fff;
    }

    .fabang-title-body {
        display: flex;

        > span {
            width: 140px;
            max-height: 80px;
            margin-right: 16px;

            > img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        > div {
            flex: 1;

            > strong {
                color: #262626;
                font-size: 16px;
                display: block;
                line-height: 16px;
                margin-bottom: 16px;
                max-width: 310px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            > p {
                display: flex;
                flex-wrap: wrap;

                > span {
                    height: 22px;
                    border: 1px solid #d9d9d9;
                    border-radius: 2px;
                    line-height: 20px;
                    padding: 0 8px;
                    margin-right: 8px;
                }
            }
        }
    }

    .my-scenes-operating {
        display: flex;

        > span {
            cursor: pointer;
            margin-right: 24px;
            color: #EA0B06;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .my-scenes-status {
        display: flex;
        align-items: center;

        > i {
            width: 6px;
            height: 6px;
            background: #bfbfbf;
            border-radius: 50%;
            margin-right: 8px;

            &.jbz {
                background: #52c41a;
            }

            &.yds {
                background: #1890ff;
            }

            &.yzz {
                background: #ff4d4f;
            }
        }
    }
</style>
<style>
    .my-fabang-body th,
    .my-fabang-body td {
        border-color: #f0f0f0 !important;
    }

    .ant-table-pagination.ant-pagination {
        margin: 16px;
    }
</style>
